import client from "services/client"

async function getSystemStatus() {
  const res = await client.get("admin/status")
  return res.data
}

async function getRequestsStatus() {
  const res = await client.get("admin/queries/rq_status")
  return res.data
}

async function getOutdatedQueries() {
  const res = await client.get("admin/queries/outdated")
  return res.data
}

async function getOrganizations() {
  const res = await client.get("organizations")
  return res.data
}

async function getOrganization(id) {
  const res = await client.get(`settings/organization/${id}`)
  return res.data
}

async function createOrganization(data) {
  const res = await client.post(`organization`, data)
  return res.data
}

async function disableOrganization(id) {
  const res = await client.post(`settings/organization/${id}`, {
    is_disabled: true,
  })
  return res.data
}

async function enableOrganization(id) {
  const res = await client.post(`settings/organization/${id}`, {
    is_disabled: false,
  })
  return res.data
}

async function updatedOrganizationLogo(id, data) {
  const res = await client.put(`organization/${id}`, data)
  if (res.status === 405) {
    throw new Error("Unable to update logo!")
  }
  return res.data
}

async function getCurrentOrganization() {
  const res = await client.get("organization")
  return res.data
}

async function getCurrentOrganizationStatus() {
  const res = await client.get("organization/status")
  return res.data
}

async function getCurrentOrganizationSettings() {
  const res = await client.get("settings/organization ")
  return res.data
}

async function updatedCurrentOrganizationLogo(data) {
  const res = await client.put(`organization`, data)
  if (res.status === 405) {
    throw new Error("Unable to update logo!")
  }
  return res.data
}

async function copyDashboard(data) {
  const res = await client.post("copy_dashboard", data)
  return res.data
}

async function copyAllDashboards(data) {
  const res = await client.post("/copy_dashboards", data)
  return res.data
}

async function getLogJob(job_id) {
  const res = await client.get(`/log_job/${job_id}`)
  return res.data
}

async function getLogInfo(log_id) {
  const res = await client.get(`/logs/${log_id}`)
  return res.data
}

async function getAllLogs() {
  const res = await client.get("/logs")
  return res.data
}

export {
  getSystemStatus,
  getRequestsStatus,
  getOutdatedQueries,
  getOrganizations,
  getOrganization,
  createOrganization,
  disableOrganization,
  enableOrganization,
  updatedOrganizationLogo,
  getCurrentOrganization,
  getCurrentOrganizationStatus,
  getCurrentOrganizationSettings,
  updatedCurrentOrganizationLogo,
  copyDashboard,
  copyAllDashboards,
  getLogJob,
  getLogInfo,
  getAllLogs,
}
