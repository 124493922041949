import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import Icon from "components/shared/Icon"
import Button from "components/shared/Button"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import { Space } from "antd"
import RequestsData from "components/requests/RequestsData"
import { PlusOutlined } from "@ant-design/icons"

function Requests() {
  const { session, tenantName } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Wrapper>
      <div className="header">
        <h1>{t("requests.requests")}</h1>
        <Space size="middle">
          {IS_ADMIN ? (
            <Button
              type="primary"
              onClick={() => navigate(`/${tenantName}/requests/create`)}
            >
              <PlusOutlined /> {t("requests.newRequest")}
            </Button>
          ) : null}
        </Space>
      </div>
      <RequestsData />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
`

export default Requests
