import styled from "styled-components"
import { Input as AntInput, Select as AntSelect } from "antd"
import variants from "./Input.styles"
import { extract } from "lib/utils"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"

const keys = ["shape", "size"]

export const Input = styled((props) => {
  return <AntInput {...extract(props, keys)} />
})`
  ${({ shape = "round" }) => variants.shape[shape]};
  ${({ size = "md" }) => variants.size[size]};

  line-height: 0;
  border: 1px solid #dbe8f4;

  &::placeholder {
    color: #98b0c6;
  }
`

export const Textarea = styled((props) => {
  return <AntInput.TextArea {...extract(props, keys)} />
})`
  ${({ shape = "round" }) => variants.shape[shape]};
  ${({ size = "md" }) => variants.size[size]};

  line-height: 0;
  padding: 8px 16px;
  border: 1px solid #dbe8f4;

  &::placeholder {
    color: #98b0c6;
  }
`

export const Select = styled((props) => {
  return <AntSelect {...extract(props, keys)} />
})`
  // ${({ shape = "round" }) => variants.shape[shape]};
  // ${({ size = "md" }) => variants.size[size]};

  // line-height: 0;

  // &::placeholder{
  //     color: #98B0C6;
  // }

  padding: 0;
`
