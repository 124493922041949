import React, { useState } from "react"
import { DatePicker, Input, Select } from "antd"
import styled from "styled-components"
import dayjs from "dayjs"
import Icon from "components/shared/Icon"
import Button from "components/shared/Button"
import QueryDropdown from "../Paramters/QueryDropdown"
import { useTranslation } from "react-i18next"

const WidgetParameters = ({
  widgetParam,
  parameters,
  updateData,
  visualization,
  onAfterUpdate,
}) => {
  const { t } = useTranslation()
  const [updateParams, setUpdateParams] = useState(widgetParam)
  const [showBtn, setShowBtn] = useState(false)

  const handelInputChange = (param, value) => {
    setUpdateParams(
      updateParams.map((p) => {
        return p.name === param.name ? { ...p, value: value } : p
      })
    )
    setShowBtn(true)
  }

  const onUpdateParams = () => {
    const updatedParameters = parameters.map((obj) => {
      updateParams.forEach((updatedObj) => {
        if (obj.name === updatedObj.name) {
          obj.value = updatedObj.value
        }
      })
      return obj
    })
    updateData(
      updatedParameters.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.name]: item.value === "d_now" ? dayjs() : item.value,
          }),
        {}
      )
    )
    setShowBtn(false)
    if (onAfterUpdate) {
      onAfterUpdate()
    }
  }

  const formatInput = (props) => {
    if (props.type === "enum") {
      return (
        <Select
          mode={props.multiValuesOptions && "multiple"}
          showArrow
          options={props.enumOptions
            .split("\n")
            .map((e) => ({ value: e, label: e }))}
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value)}
          style={{ minWidth: "150px", width: "160px" }}
          size="small"
        />
      )
    } else if (props.type === "query") {
      return (
        <QueryDropdown
          props={props}
          handelInputChange={handelInputChange}
          isCreated={false}
        />
      )
    } else if (props.type === "user" || props.type === "text") {
      return (
        <Input
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "date") {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value, "YYYY-MM-DD")
          }
          format="YYYY-MM-DD"
          showTime={props.type === "datetime-local"}
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (
      props.type === "datetime-local" ||
      props.type === "datetime-with-seconds"
    ) {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type === "datetime-with-seconds"
              ? dayjs(props.value, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value, "YYYY-MM-DD HH:mm")
          }
          format={
            props.type === "datetime-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (props.type === "date-range") {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props?.value?.start === "d_now"
              ? dayjs()
              : props.value.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value.start, "YYYY-MM-DD"),
            props.value.end === "d_now"
              ? dayjs()
              : props.value.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value.end, "YYYY-MM-DD"),
          ]}
          format="YYYY-MM-DD"
          onChange={(_, value) => {
            setUpdateParams((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            setShowBtn(true)
          }}
        />
      )
    } else if (
      props.type === "datetime-range" ||
      props.type === "datetime-range-with-seconds"
    ) {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props.value.start === "d_now"
              ? dayjs()
              : props.value.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.start === "datetime-local-seconds"
              ? dayjs(props.value.start, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.start, "YYYY-MM-DD HH:mm"),
            props.value.end === "d_now"
              ? dayjs()
              : props.value.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.end === "datetime-local-seconds"
              ? dayjs(props.value.end, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.end, "YYYY-MM-DD HH:mm"),
          ]}
          format={
            props.type === "datetime-range-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => {
            setUpdateParams((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            setShowBtn(true)
          }}
        />
      )
    }
  }

  return (
    <Wrapper>
      <div className="parametersContainer">
        {widgetParam.map((param, idx) => (
          <div key={idx} className="parameters">
            <label>{param.title}</label>
            {formatInput({ ...param })}
          </div>
        ))}
      </div>
      <Button
        size="xxs"
        type="secondary"
        shape="outlined"
        icon={<Icon type="checkSquare" />}
        className={`updateBtn ${showBtn ? "show" : null}`}
        onClick={onUpdateParams}
      >
        {t("global.applyChanges")}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 1rem;

  .parametersContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .parameters {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      font-size: 13px;
    }
  }

  .updateBtn {
    position: absolute;
    bottom: -1rem;
    z-index: 10;
    display: none;
  }
  .show {
    display: block;
  }
`

export default WidgetParameters
