import React from "react"
import styled from "styled-components"
import { useAuth } from "context/AuthContext"
import Button from "components/shared/Button"
import CustomLink from "components/shared/CustomLink/CustomLink"
import withETLWorkspace from "components/ETL/withETLWorkspace"
import DestinationsList from "components/ETL/Destinations/DestinationsList"
import { useTranslation } from "react-i18next"
import { PlusOutlined } from "@ant-design/icons"

const Destination = () => {
  const { t } = useTranslation()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Wrapper>
      <div className="header">
        <h2>{t("destinations.destinations")}</h2>
        {IS_ADMIN ? (
          <CustomLink to={`/destinations/etl/create`}>
            <Button type="primary">
              <PlusOutlined />
              {t("destinations.destinations")}
            </Button>
          </CustomLink>
        ) : null}
      </div>
      <DestinationsList />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
  padding: 1rem;
`

export default withETLWorkspace(Destination)
