import styled from "styled-components"
import Steps from "components/shared/Steps"
import { useCreateAgentContext, withProvider } from "context/CreateAgentContext"
import StepOne from "components/agents/ChooseRequest"
import StepTwo from "components/agents/ChooseTemplate"
import StepThree from "components/agents/SelectDestinations"
import StepFour from "components/agents/Summary"
import { useTranslation } from "react-i18next"

function CreateAgent() {
  const { t } = useTranslation()
  const { step } = useCreateAgentContext()

  return (
    <Wrapper>
      <Steps step={step} bar="arrow">
        <StepOne title={t("agents.chooseRequest")} />
        <StepTwo title={t("agents.chooseTemplate")} />
        <StepThree title={t("agents.destinations")} />
        <StepFour title={t("agents.summery")} />
      </Steps>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .steps-bar {
    min-height: 100px;
    padding: 32px 32px;
    background: var(--color-brand);
    align-items: flex-end;
    direction: ltr;
  }
`

export default withProvider(CreateAgent)
