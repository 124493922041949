import client from "services/client"

async function getETLConnectons() {
  const res = await client.get("/etl/connections")
  return res.data.data
}

async function getETLConnectionsList() {
  const res = await client.get("/etl/connection_list")
  return res.data
}

async function getETLConnectionDetails(connectionId) {
  const res = await client.post(`/etl/get_connection`, {
    connectionId: connectionId,
  })
  return res.data
}

async function addETLConnections(data) {
  // const res = await client.post(`/etl/connection`, data)
  const res = await client.post(`/etl/connection_create`, data)
  return res.data
}

async function deleteETLConnections(connectionId) {
  const res = await client.delete(`/etl/connection/${connectionId}`, {
    data: {},
  })
  return res.data
}

async function syncETLConnection(connectionId) {
  let res = await client.get(`/etl/connection_sync/${connectionId}`)
  if (res.data.job) {
    let status = res.data.job.status
    let jobId = res.data.job.id
    while (status === "running" || status === "incomplete") {
      res = await client.get(`/etl/get_job/${jobId}`)
      status = res.data.job.status
    }
    return res.data
  }
}

async function getETLSourceSchema(sourceId) {
  const res = await client.post(`/etl/sources_discove_schema`, {
    sourceId,
    disable_cache: true,
  })
  return res.data
}

async function updateETLConnection(connectionId, data) {
  const res = await client.patch(`/etl/connection/${connectionId}`, data)
  return res.data
}

async function getETLConnectionStatus(connectionId) {
  const res = await client.get(`/etl/connection_status/${connectionId}`)
  return res.data
}

async function getETLConnectionJobs(data) {
  const res = await client.post(`/etl/get_job_list`, data)
  return res.data
}

export {
  getETLConnectons,
  addETLConnections,
  deleteETLConnections,
  syncETLConnection,
  getETLConnectionsList,
  getETLSourceSchema,
  updateETLConnection,
  getETLConnectionDetails,
  getETLConnectionStatus,
  getETLConnectionJobs,
}
