import dayjs from "dayjs"

export function generateLayout(widgets) {
  return widgets?.map(({ id: widgetId, options }) => {
    const { position: pos } = options

    return {
      i: widgetId.toString(),
      x: pos.col,
      y: pos.row,
      w: pos.sizeX,
      h: pos.sizeY,
      minW: pos.minSizeX,
      maxW: pos.maxSizeX,
      minH: pos.minSizeY,
      maxH: pos.maxSizeY,
    }
  })
}
// Receives the currents widgets and current layout
// returns new widgets based on current layout
// It just reverse what the above method does

export function generateWidgets(widgets, layout) {
  const hash = layout?.reduce((acc, l) => {
    acc[l.i] = l
    return acc
  }, {})

  return widgets.map((widget) => {
    const widgetLayout = hash[widget.id.toString()]

    const { x, y, w, h, minW, minH, maxW, maxH } = widgetLayout

    return {
      ...widget,
      options: {
        ...widget.options,
        position: {
          ...widget.options.position,
          col: x,
          row: y,
          sizeX: w,
          sizeY: h,
          minSizeX: minW,
          maxSizeX: maxW,
          minSizeY: minH,
          maxSizeY: maxH,
        },
      },
    }
  })
}

// export function generateParameters(widgets) {
//   const dashboardParameters = new Map()
//   const paramValue = new Set()

// widgets.forEach((widget) => {
//   const { parameterMappings } = widget.options //{Data_form: {type: 'dashboard-level'}, Data_to: {}}
//   const parameters = widget?.visualization?.query?.options?.parameters //Array of objects

//   for (let key in parameterMappings) {
//     if (
//       !dashboardParameters.has(key) &&
//       parameterMappings[key].type === "dashboard-level"
//     ) {
//       dashboardParameters.set(key, null)
//     }
//   }

//   parameters.forEach((param) => {
//     if (
//       [...dashboardParameters.values()].includes([param.value]) &&
//       param.type === "query"
//     ) {
//       // dashboardParameters.set(param.title, {
//       //   type: param.type,
//       //   value: [...dashboardParameters.get(param.title).value, (param.value)],
//       //   enumValue: param.enumOptions
//       // })
//     } else {
//       dashboardParameters.set(param.title, {
//         type: param.type,
//         value: [param.value],
//         enumValue: param.enumOptions,
//       })
//     }
//   })
// })
// console.log(paramValue)
// console.log(dashboardParameters)
//   return dashboardParameters
// }

export function generateParameters(widgets, filters = [], userEmail) {
  const dashboardParameters = []
  widgets?.forEach((widget) => {
    const parameterMappings = widget?.options?.parameterMappings
    const widgetParameters =
      widget?.visualization?.query?.options?.parameters || []
    if (parameterMappings) {
      Object.values(parameterMappings).forEach((p) => {
        if (
          p.type === "dashboard-level" &&
          !dashboardParameters.some((e) => e.mapTo === p.name) &&
          !dashboardParameters.some((e) => e.name === p.name)
        ) {
          widgetParameters.forEach((wp, idx) => {
            if (wp.name === p.name || wp.name === p.mapTo) {
              dashboardParameters.push({
                ...wp,
                value: wp.type === "user" ? userEmail : wp.value,
                id: idx + 1,
                title: p.title || wp.title,
                mapTo: p.mapTo,
                requestId: widget.visualization.query.id,
              })
            }
          })
          // dashboardParameters.push(...widgetParameters.filter(v => v.name === p.name))
        }
      })
    }
    if (filters?.length > 0 || dashboardParameters) {
      dashboardParameters.sort((param1, param2) => {
        const index1 = filters.indexOf(param1.name)
        const index2 = filters.indexOf(param2.name)
        return index1 - index2
      })
    } else {
      dashboardParameters.sort((a, b) => {
        const aIndex = Object.values(parameterMappings).findIndex(
          (p) => p.mapTo === a.mapTo
        )
        const bIndex = Object.values(parameterMappings).findIndex(
          (p) => p.mapTo === b.mapTo
        )
        return aIndex - bIndex
      })
    }
  })

  return dashboardParameters
}
export function isObjEmpty(obj) {
  return JSON.stringify(obj) === "{}"
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function formatDate(date, format, type) {
  if (type === "PIVOT" || type === "TABLE") {
    const d = date?.data
    for (const column of d.columns) {
      if (column.type === "datetime") {
        for (const row of d.rows) {
          const datetimeValue = row[column.name]
          const formattedDate = dayjs(datetimeValue).format(format)
          row[column.name] = formattedDate
        }
      }
    }
  }

  return date
}
