import React, { useState } from "react"
import styled from "styled-components"
import { Collapse, Divider, Form, Input } from "antd"
import Button from "components/shared/Button"
import { useDestinationsTypes } from "hooks/destinations"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/destinations/email.png"
import { useTranslation } from "react-i18next"

const ChannelDetails = ({
  data,
  onEditDestination,
  onDeleteDestinaion,
  types,
}) => {
  const { t } = useTranslation()
  const { data: destinationTypes, isLoading: loadingTypes } =
    useDestinationsTypes()

  const [destinationData, setDestinationData] = useState(data)

  const currentType =
    !loadingTypes && destinationTypes.find((e) => e.type === data.type)

  // console.log(currentType?.configuration_schema)
  const {
    properties = {},
    extra_options = [],
    required = [],
  } = currentType && currentType?.configuration_schema

  const mainItem =
    extra_options.length > 0
      ? Object.entries(properties).filter(
          (e) => !extra_options.find((d) => d === e[0])
        )
      : Object.entries(properties)

  const extraItem = Object.entries(properties).filter((e) =>
    extra_options.find((d) => d === e[0])
  )

  const onFinish = () => {
    onEditDestination(destinationData)
  }

  const onChangeValue = (name, value) => {
    setDestinationData((current) => {
      if (name === "name") {
        return { ...current, [name]: value }
      } else {
        return {
          ...current,
          options: { ...current.options, [name]: value },
        }
      }
    })
  }

  return (
    <Details>
      <img
        src={`${destinationImages[data.type] || defaultImage}`}
        alt={data.type}
      />
      <h1>{data.type}</h1>
      <Form
        layout="vertical"
        className="form"
        initialValues={{
          name: data.name,
          ...data.options,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("global.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("global.requiredField"),
            },
          ]}
        >
          <Input onChange={(e) => onChangeValue("name", e.target.value)} />
        </Form.Item>
        <Divider />
        {mainItem.map((item) => (
          <Form.Item
            label={item[1].title || item[0]}
            name={item[0]}
            rules={[
              {
                required:
                  required.length > 0
                    ? required.find((e) => e === item[0])
                    : false,
                message: t("global.requiredField"),
              },
            ]}
          >
            <Input onChange={(e) => onChangeValue(item[0], e.target.value)} />
          </Form.Item>
        ))}
        {extraItem.length > 0 && (
          <Collapse bordered={false} ghost>
            <Collapse.Panel header={t("dataSource.additionalSttings")}>
              {extraItem.map((item) => (
                <Form.Item
                  label={item[1].title || item[0]}
                  name={item[0]}
                  rules={[
                    {
                      required:
                        required.length > 0
                          ? required.find((e) => e === item[0])
                          : false,
                      message: t("global.requiredField"),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => onChangeValue(item[0], e.target.value)}
                  />
                </Form.Item>
              ))}
            </Collapse.Panel>
          </Collapse>
        )}

        <Form.Item>
          <Button
            htmlType="submit"
            type="secondary"
            size="md"
            style={{ width: "100%" }}
          >
            {t("global.save")}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="sm" onClick={onDeleteDestinaion}>
            {t("global.delete")}
          </Button>
          {/* <Button type="primary" size="sm">Test Connection</Button> */}
        </Form.Item>
      </Form>
    </Details>
  )
}

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 4rem;
    margin-bottom: 0.5rem;
  }

  .form {
    width: 40%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
  }
`

export default ChannelDetails
