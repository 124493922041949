import React from "react"
import styled from "styled-components"
import ViewAgent from "./ViewAgent"

const AgentContent = () => {
  return (
    <Wrapper>
      <ViewAgent />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  background-color: #fff;
`

export default AgentContent
