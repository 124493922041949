import React from "react"
import styled from "styled-components"
import Text from "components/shared/Text"
import { Link } from "react-router-dom"
import { Avatar, Space, Tag } from "antd"

function Name({ user, ...delegated }) {
  const { name, profile_image_url, email } = user

  return (
    <NameWrapper {...delegated}>
      <Avatar src={profile_image_url} />
      <Space direction="vertical" size={0}>
        <Text>{name}</Text>
        <Text size="sm">{email}</Text>
      </Space>
    </NameWrapper>
  )
}

function Email({ user, ...delegated }) {
  const { id, email } = user
  return (
    <div>
      <Text size="xs">{email}</Text>
    </div>
  )
}

function Groups({ user, ...delegate }) {
  const { groups } = user
  return (
    <GroupsContainer>
      {groups?.map((group) => (
        <Tag
          key={group.id}
          color="#DEE3E8"
          style={{
            borderRadius: "12px",
            color: "#0D2F56",
          }}
        >
          <small>{group.name}</small>
        </Tag>
      ))}
    </GroupsContainer>
  )
}

const NameWrapper = styled.div`
  ${({ theme }) => theme.mixins.flexAlignCenter};
  min-width: 150px;

  button {
    margin: 0 8px 0 0;
    color: orange;
  }

  span {
    line-height: 0;
    margin: 0 4px;
  }
`

const GroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`

export { Name, Email, Groups }
