import { Steps } from "antd"

function NormalStepsBar({ steps, step }) {
  return (
    <Steps className="steps-bar" labelPlacement="vertical" current={step - 1}>
      {steps.map(({ title, description }, index) => (
        <Steps.Step title={title} description={description} key={index} />
      ))}
    </Steps>
  )
}

export default NormalStepsBar
