import React from "react"
import { useNavigate } from "react-router-dom"
import { useCreateUser } from "hooks/users"
import { Form, Input, message } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

function CreateNewUser({ onChange, onCancel, closeModal }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tenantName } = useAuth()
  const { mutate, isLoading } = useCreateUser()

  const onFinish = (value) => {
    mutate(
      {
        data: value,
      },
      {
        onSuccess: (newUser) => {
          if (newUser.message) {
            message.error({
              content: newUser.message,
            })
            return
          } else {
            navigate(`/${tenantName}/settings/users?pending=true`)
            closeModal()
          }
        },
        onError: (error) => {
          message.error({
            content: t("users.failedCreateUser"),
          })
        },
      }
    )
  }

  React.useEffect(() => {
    onChange(isLoading)
  }, [isLoading])

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t("global.name")}
        name="name"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("global.email")}
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <FormModal.Footer>
          <Button onClick={onCancel} type="cancel">
            {t("global.cancel")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("global.create")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default CreateNewUser
