import { Dropdown, Space } from "antd"
import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useDashboardState } from "../Provider"
import Text from "components/shared/Text"
import IconButton, { RoundIconButton } from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button/Button"
import Name from "./Name"
import ShareDashboard from "./ShareDashboard"
import { useAuth } from "context/AuthContext"
import { useAppContext } from "context/AppContext"
import RefreshSchedule from "./RefreshSchedule"
import Tags from "components/shared/Tags/Tags"
import { useDashboardTags } from "hooks/dashboards"

const RequestHeader = () => {
  const { toggleFullScreen } = useAppContext()
  const { t } = useTranslation()
  const {
    memoizedDashboard,
    onTogglePublish,
    onToggleArchive,
    onUpdateName,
    onEdit,
    editable,
    isSaving,
    onSaveLayout,
    onCancelEdit,
    onToggleFavorite,
    favLoading,
    onAddTags,
    updateLoading,
  } = useDashboardState()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  const {
    is_favorite: isFavorite,
    name,
    is_draft,
    is_archived,
  } = memoizedDashboard

  const { data: allTags } = useDashboardTags()

  const handleFavIconClick = () => {
    onToggleFavorite()
  }

  const icon = isFavorite ? "starFilled" : "starOutlined"

  const Items = () => {
    return [
      {
        key: "edit",
        label: t("global.edit"),
        onClick: onEdit,
      },
      {
        key: "publish",
        label: `${is_draft ? t("global.publish") : t("global.unpublish")}`,
        onClick: onTogglePublish,
      },
      {
        key: "archive",
        label: `${is_archived ? t("global.unarchive") : t("global.archive")}`,
        onClick: onToggleArchive,
      },
    ]
  }

  const NoEditActions = (
    <>
      {memoizedDashboard?.is_draft && (
        <Button
          type="primary"
          size="sm"
          className="publishBtn"
          onClick={() => onTogglePublish()}
        >
          <Icon type="send" className="sendIcon" />
          <p>{t("global.publish")}</p>
        </Button>
      )}

      <RefreshSchedule />

      <IconButton type="primary" onClick={toggleFullScreen}>
        <Icon type="fullscreen" />
      </IconButton>

      {IS_ADMIN ? (
        <FormModal
          title="Share Dashboard"
          width={600}
          Form={ShareDashboard}
          hasChanged={(change) => !!change}
          formProps={{
            id: memoizedDashboard.id,
            public_url: memoizedDashboard.public_url || null,
            isAdmin: true,
          }}
        >
          <IconButton
            type={memoizedDashboard?.public_url ? "primary" : "transparent"}
          >
            <Icon type="share" />
          </IconButton>
        </FormModal>
      ) : (
        memoizedDashboard?.public_url && (
          <FormModal
            title="Share Dashboard"
            width={600}
            Form={ShareDashboard}
            hasChanged={(change) => !!change}
            formProps={{
              id: memoizedDashboard.id,
              public_url: memoizedDashboard.public_url || null,
              isAdmin: false,
            }}
          >
            <IconButton
              type={memoizedDashboard?.public_url ? "primary" : "transparent"}
            >
              <Icon type="share" />
            </IconButton>
          </FormModal>
        )
      )}

      {IS_ADMIN && (
        <Dropdown
          menu={{
            items: Items(),
          }}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <IconButton type="transparent">
            <Icon type="verticalDots" />
          </IconButton>
        </Dropdown>
      )}
    </>
  )

  const editActions = (
    <>
      <Button
        size="md"
        type="primary"
        onClick={onSaveLayout}
        disabled={isSaving}
        loading={isSaving}
      >
        {t("global.save")}
      </Button>

      <Button size="md" type="cancel" onClick={onCancelEdit}>
        {t("global.cancel")}
      </Button>
    </>
  )

  return (
    <Header>
      <div className="left" id="dashboardName">
        <Space>
          <RoundIconButton
            size="sm"
            loading={favLoading}
            onClick={handleFavIconClick}
            className="icon"
          >
            <Icon type={icon} />
          </RoundIconButton>
          <Name name={name} onUpdateName={onUpdateName} IS_ADMIN={IS_ADMIN} />
        </Space>
        <Space>
          {is_draft && (
            <Text size="xs" className="qualities">
              {t("global.unpublished")}
            </Text>
          )}
          {is_archived && (
            <Text size="xs" className="qualities">
              {t("global.archived")}
            </Text>
          )}
        </Space>
        <Tags
          allTags={allTags}
          dashboaredTags={memoizedDashboard.tags}
          IS_ADMIN={IS_ADMIN}
          onAddTags={onAddTags}
          updateLoading={updateLoading}
        />
      </div>
      <Actions id="dashboardActions">
        {editable ? editActions : NoEditActions}
      </Actions>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & .qualities {
      padding: 4px 6px;
      background-color: grey;
      color: #fff;
      border-radius: 4px;
    }

    & .icon {
      color: rgb(251, 210, 8);
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &:hover {
    .tagBtn {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    .left {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .publishBtn {
    display: flex;
    align-items: baseline;
    gap: 0.2rem;
  }

  & .anticon.sendIcon svg {
    transform: rotate(-50deg);
    margin-left: 0.3rem;
  }
`

export default RequestHeader
