import { useEffect, useState } from "react"
import { DatePicker, Input, InputNumber, Select, Tooltip } from "antd"
import styled from "styled-components"
import dayjs from "dayjs"
import { useDashboardState } from "../Provider"
import Button from "components/shared/Button"
import Icon from "components/shared/Icon"
import { useAuth } from "context/AuthContext"
import QueryDropdown from "./QueryDropdown"
import { useTranslation } from "react-i18next"
import { DndContext, closestCenter } from "@dnd-kit/core"
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import IconButton from "components/shared/IconButton"

const SortableParameter = ({
  param,
  editable,
  onUpdateParam,
  onShowUpdateBtn,
}) => {
  const { session } = useAuth()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: param.id })

  const handelInputChange = (param, value) => {
    onUpdateParam((prev) =>
      prev.map((p) => {
        return p.name === param.name ? { ...p, value: value } : p
      })
    )
    onShowUpdateBtn(true)
  }

  const formatInput = (props) => {
    if (props.type === "enum") {
      return (
        <Select
          mode={props.multiValuesOptions && "multiple"}
          options={props.enumOptions
            .split("\n")
            .map((e) => ({ value: e, label: e }))}
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value)}
          popupMatchSelectWidth={false}
        />
      )
    } else if (props.type === "query") {
      return (
        <QueryDropdown
          props={props}
          handelInputChange={handelInputChange}
          isCreated={false}
        />
      )
    } else if (props.type === "user") {
      return (
        <Tooltip title={session?.user?.email}>
          <Input
            disabled
            defaultValue={session?.user?.email}
            onChange={(value) => handelInputChange(props, value.target.value)}
          />
        </Tooltip>
      )
    } else if (props.type === "text") {
      return (
        <Input
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "date") {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value, "YYYY-MM-DD")
          }
          format="YYYY-MM-DD"
          showTime={props.type === "datetime-local"}
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (
      props.type === "datetime-local" ||
      props.type === "datetime-with-seconds"
    ) {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type === "datetime-with-seconds"
              ? dayjs(props.value, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value, "YYYY-MM-DD HH:mm")
          }
          format={
            props.type === "datetime-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (props.type === "date-range") {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props?.value?.start === "d_now"
              ? dayjs()
              : props.value.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value.start, "YYYY-MM-DD"),
            props.value.end === "d_now"
              ? dayjs()
              : props.value.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value.end, "YYYY-MM-DD"),
          ]}
          format="YYYY-MM-DD"
          onChange={(_, value) => {
            onUpdateParam((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            onShowUpdateBtn(true)
          }}
        />
      )
    } else if (
      props.type === "datetime-range" ||
      props.type === "datetime-range-with-seconds"
    ) {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props.value.start === "d_now"
              ? dayjs()
              : props.value.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.start === "datetime-local-seconds"
              ? dayjs(props.value.start, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.start, "YYYY-MM-DD HH:mm"),
            props.value.end === "d_now"
              ? dayjs()
              : props.value.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.end === "datetime-local-seconds"
              ? dayjs(props.value.end, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.end, "YYYY-MM-DD HH:mm"),
          ]}
          format={
            props.type === "datetime-range-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => {
            onUpdateParam((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            onShowUpdateBtn(true)
          }}
        />
      )
    } else if (props.type === "number") {
      return (
        <InputNumber
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value)}
        />
      )
    }
  }

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }
  return (
    <div ref={setNodeRef} style={style} className="parameters">
      <div className="parametersHeader">
        <label>{param.title}</label>
        {editable ? (
          <IconButton
            {...attributes}
            {...listeners}
            type="transparent"
            size="xs"
          >
            <Icon type="holder" />
          </IconButton>
        ) : null}
      </div>
      {formatInput({ ...param })}
    </div>
  )
}

const Paramters = () => {
  const {
    dashboardParameters,
    setDashboardParameters,
    setShouldWidgetUpdate,
    editable,
  } = useDashboardState()
  const { t } = useTranslation()

  const [updateParams, setUpdateParams] = useState([])
  const [showBtn, setShowBtn] = useState(false)

  useEffect(() => {
    setUpdateParams(dashboardParameters)
  }, [dashboardParameters])

  const onUpdateParams = () => {
    setDashboardParameters(updateParams)
    setShouldWidgetUpdate(true)
    setShowBtn(false)
  }

  const handelOnDragEnd = (event) => {
    const { active, over } = event
    if (active.id === over.id) {
      return
    }
    setDashboardParameters((params) => {
      const oldIndex = params.findIndex((param) => param.id === active.id)
      const newIndex = params.findIndex((param) => param.id === over.id)
      return arrayMove(params, oldIndex, newIndex)
    })
  }

  if (!dashboardParameters.length) {
    return null
  }

  return (
    <Wrapper id="dashboardParameters">
      <div className="parametersContainer">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handelOnDragEnd}
        >
          <SortableContext
            items={dashboardParameters}
            strategy={horizontalListSortingStrategy}
            handle
          >
            {dashboardParameters.map((param) => (
              <SortableParameter
                key={param.id}
                param={param}
                handle={true}
                editable={editable}
                onUpdateParam={setUpdateParams}
                onShowUpdateBtn={setShowBtn}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <Button
        size="xxs"
        type="secondary"
        shape="outlined"
        icon={<Icon type="checkSquare" />}
        className={`updateBtn ${showBtn ? "show" : null}`}
        onClick={onUpdateParams}
      >
        {t("global.applyChanges")}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fff;
  margin: 1rem 1rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;

  .parametersContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .parameters {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      label {
        text-align: left;
      }

      .parametersHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .updateBtn {
    position: absolute;
    bottom: -1.6rem;
    z-index: 10;
    display: none;
  }
  .show {
    display: block;
  }
`

const HandelIcon = styled(IconButton)``

export default Paramters
