import { AppstoreOutlined, MenuOutlined } from "@ant-design/icons"
import { Divider, Space } from "antd"
import IconButton from "components/shared/IconButton"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const ApplicationFilter = ({ applications, layout, setLayout }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className="header">
        <h2>
          {t("applications.allApps")}
          <span>
            {applications?.length} {t("applications.applications")}
          </span>
        </h2>
        <Space size="small">
          <IconButton
            type={layout === "card" ? "transparent" : "secondary"}
            onClick={() => setLayout("list")}
          >
            <MenuOutlined />
          </IconButton>
          <IconButton
            type={layout === "card" ? "secondary" : "transparent"}
            onClick={() => setLayout("card")}
          >
            <AppstoreOutlined />
          </IconButton>
        </Space>
      </div>
      <Divider style={{ margin: 0 }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .filter {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export default ApplicationFilter
