import { Result, Spin } from "antd"
import styled from "styled-components"
import React from "react"
import { useLogs } from "hooks/admin"
import OrganizationsData from "components/admin/OrganizationsData"

const AdminOrganizationsData = () => {
  const { data, isLoading, isError } = useLogs()

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title="Faild load Admin page"
        subTitle="Please refrsh page or contact your Administrator"
      />
    )
  }

  return <OrganizationsData orgData={data} />
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AdminOrganizationsData
