import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  CloseCircleFilled,
  CloseCircleTwoTone,
  PauseCircleFilled,
  PauseCircleTwoTone,
  StopTwoTone,
  SyncOutlined,
} from "@ant-design/icons"
import {
  Avatar,
  Divider,
  List,
  Result,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd"
import Button from "components/shared/Button"
import Text from "components/shared/Text"
import {
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  fromUnixTime,
} from "date-fns"
import {
  useETLConnectionDetails,
  useETLConnectionHistory,
  useETLConnectionStatus,
  useSyncETLConnection,
  useUpdateETLConnections,
} from "hooks/ETL/connections"
import useQueryParams from "hooks/useQueryParams"
import useTypedParams from "hooks/useTypedParams"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const ConnectionStatus = ({ connection }) => {
  const { t } = useTranslation()
  const { data: connectionStatus, isLoading } = useETLConnectionStatus(
    connection?.connectionId
  )
  const { mutate: sync, isLoading: syncLoading } = useSyncETLConnection()

  const handelSyncConnection = () => {
    sync(connection?.connectionId, {
      onSuccess: (data) => {
        if (data?.job?.status === "succeeded") {
          notification.success({
            message: t("connections.successfullySync"),
          })
        } else if (data?.job?.status === "failed") {
          notification.error({
            message: t("connections.failedSync"),
          })
        } else if (data?.message) {
          notification.success({
            message: t("connections.syncTakeTime"),
          })
        }
      },
      onError: () => {
        notification.error({
          message: t("global.session.somethingWrong"),
        })
      },
    })
  }

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <>
      <div className="section">
        <Space>
          {connectionStatus?.[0]?.isRunning ? (
            <Space>
              <Tooltip title="Sync is running" arrow={false} placement="bottom">
                <Avatar
                  shape="square"
                  style={{
                    backgroundColor: "blue",
                  }}
                  icon={<SyncOutlined spin />}
                />
              </Tooltip>
              <Text size="lg">{t("connections.status.running")}</Text>
            </Space>
          ) : connectionStatus?.[0]?.lastSyncJobStatus ? (
            connectionStatus?.[0]?.lastSyncJobStatus === "failed" &&
            connection.status === "inactive" ? (
              <Space>
                <Tooltip
                  title="Disabled connection"
                  arrow={false}
                  placement="bottom"
                >
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: "DDD",
                    }}
                    icon={<PauseCircleTwoTone twoToneColor="#AAA" />}
                  />
                </Tooltip>
                <Text size="lg">{t("connections.status.action")}</Text>
              </Space>
            ) : connectionStatus?.[0]?.lastSyncJobStatus === "failed" &&
              connection.status === "active" ? (
              <Space>
                <Tooltip title="Failed sync" arrow={false} placement="bottom">
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: "#FA1D4A",
                    }}
                    icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
                  />
                </Tooltip>
                <Text size="lg">{t("connections.status.error")}</Text>
              </Space>
            ) : (
              <Space>
                <Tooltip
                  title="Succeeded sync"
                  arrow={false}
                  placement="bottom"
                >
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: "green",
                    }}
                    icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                  />
                </Tooltip>
                <Text size="lg">{t("connections.status.onTime")}</Text>
              </Space>
            )
          ) : (
            <Space>
              <Tooltip
                title="Sync was not started"
                arrow={false}
                placement="bottom"
              >
                <Avatar
                  shape="square"
                  style={{
                    backgroundColor: "gray",
                  }}
                  icon={<StopTwoTone twoToneColor="#AAA" />}
                />
              </Tooltip>
              <Text size="lg">{t("connections.status.pending")}</Text>
            </Space>
          )}
        </Space>
        <Button
          onClick={() => handelSyncConnection()}
          loading={syncLoading || connectionStatus?.[0]?.isRunning}
          disabled={
            connection.status !== "active" ||
            syncLoading ||
            connectionStatus?.[0]?.isRunning
          }
        >
          <span>{t("connections.Sync")}</span>
        </Button>
      </div>
      <Divider />
      {connection.status === "active" ? (
        <Table
          dataSource={connection?.syncCatalog?.streams}
          columns={[
            {
              title: t("global.tabels.status"),
              key: "status",
              render: (_) => {
                return (
                  <Space>
                    {connectionStatus?.[0]?.isRunning ? (
                      <Space>
                        <Tooltip
                          title="Sync is running"
                          arrow={false}
                          placement="bottom"
                        >
                          <Avatar
                            shape="square"
                            size="small"
                            style={{
                              backgroundColor: "blue",
                            }}
                            icon={<SyncOutlined spin />}
                          />
                        </Tooltip>
                        <Text>{t("connections.status.running")}</Text>
                      </Space>
                    ) : connectionStatus?.[0]?.lastSyncJobStatus ? (
                      connectionStatus?.[0]?.lastSyncJobStatus === "failed" &&
                      connection.status === "inactive" ? (
                        <Space>
                          <Tooltip
                            title="Disabled connection"
                            arrow={false}
                            placement="bottom"
                          >
                            <Avatar
                              shape="square"
                              size="small"
                              style={{
                                backgroundColor: "DDD",
                              }}
                              icon={<PauseCircleTwoTone twoToneColor="#AAA" />}
                            />
                          </Tooltip>
                          <Text>{t("connections.status.action")}</Text>
                        </Space>
                      ) : connectionStatus?.[0]?.lastSyncJobStatus ===
                          "failed" && connection.status === "active" ? (
                        <Space>
                          <Tooltip
                            title="Failed sync"
                            arrow={false}
                            placement="bottom"
                          >
                            <Avatar
                              shape="square"
                              size="small"
                              style={{
                                backgroundColor: "#FA1D4A",
                              }}
                              icon={
                                <CloseCircleTwoTone twoToneColor="#eb2f96" />
                              }
                            />
                          </Tooltip>
                          <Text>{t("connections.status.error")}</Text>
                        </Space>
                      ) : (
                        <Space>
                          <Tooltip
                            title="Succeeded sync"
                            arrow={false}
                            placement="bottom"
                          >
                            <Avatar
                              shape="square"
                              size="small"
                              style={{
                                backgroundColor: "green",
                              }}
                              icon={
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              }
                            />
                          </Tooltip>
                          <Text>{t("connections.status.onTime")}</Text>
                        </Space>
                      )
                    ) : (
                      <Space>
                        <Tooltip
                          title="Sync was not started"
                          arrow={false}
                          placement="bottom"
                        >
                          <Avatar
                            shape="square"
                            size="small"
                            style={{
                              backgroundColor: "gray",
                            }}
                            icon={<StopTwoTone twoToneColor="#AAA" />}
                          />
                        </Tooltip>
                        <Text>{t("connections.status.pending")}</Text>
                      </Space>
                    )}
                  </Space>
                )
              },
            },
            {
              title: t("global.tabels.stream"),
              dataIndex: "config",
              key: "config",
              render: (config) => config?.aliasName,
            },
            {
              title: t("global.tabels.lastRecord"),
              key: "latestSyncJobCreatedAt",
              render: () => {
                return connectionStatus?.[0]?.lastSuccessfulSync
                  ? formatDistanceToNow(
                      fromUnixTime(connectionStatus?.[0]?.lastSuccessfulSync)
                    )
                  : null
              },
            },
          ]}
          pagination={false}
        />
      ) : (
        <Text>{t("connections.reenable")}</Text>
      )}
    </>
  )
}

const ConnectionJobHistory = ({ connection }) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useQueryParams({
    rowOffset: Number,
    pageSize: Number,
  })

  const { rowOffset = 0, pageSize = 10 } = queryParams

  const { data: history, isLoading } = useETLConnectionHistory({
    configId: connection?.connectionId,
    configTypes: ["sync", "reset_connection"],
    pagination: {
      pageSize,
      rowOffset,
    },
  })

  const handleChange = (page, pageSize) => {
    setQueryParams({ rowOffset: page - 1, pageSize })
  }

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <div>
      <List
        dataSource={history?.jobs}
        pagination={{
          total: history?.totalJobCount,
          current: rowOffset + 1,
          pageSize,
          onChange: (page, pageSize) => handleChange(page, pageSize),
        }}
        renderItem={(item, idx) => (
          <List.Item key={idx} style={{ width: "100%" }}>
            <div className="section">
              {item?.job?.status === "succeeded" ? (
                <Space>
                  <CheckCircleFilled
                    style={{
                      color: "#00B1C1",
                      fontSize: "1.5rem",
                    }}
                  />
                  <Space
                    direction="vertical"
                    size={0}
                    style={{ direction: "ltr" }}
                  >
                    <Text size="lg">{t("connections.SyncSuccess")}</Text>
                    <Text size="sm">
                      {`
                        ${item?.job?.aggregatedStats?.bytesCommitted} Bytes | 
                        ${item?.job?.aggregatedStats?.recordsCommitted} records extracted | 
                        ${item?.job?.aggregatedStats?.recordsEmitted} records loaded | 
                        Job id: ${item?.job?.id} | 
                        `}
                      {formatDistanceStrict(
                        new Date(item?.job?.createdAt * 1000),
                        new Date(item?.job?.updatedAt * 1000),
                        { unit: "second" }
                      )}
                    </Text>
                  </Space>
                </Space>
              ) : item?.job?.status === "cancelled" ? (
                <Space>
                  <PauseCircleFilled
                    style={{
                      color: "#8C8CA1",
                      fontSize: "1.5rem",
                    }}
                  />
                  <Space
                    direction="vertical"
                    size={0}
                    style={{ direction: "ltr" }}
                  >
                    <Text size="lg">{t("connections.SyncCancel")}</Text>
                    <Text size="sm">
                      {`
                        ${item?.job?.aggregatedStats?.bytesCommitted} Bytes | 
                        ${item?.job?.aggregatedStats?.recordsCommitted} records extracted | 
                        ${item?.job?.aggregatedStats?.recordsEmitted} records loaded | 
                        Job id: ${item?.job?.id} | 
                        `}
                      {formatDistanceStrict(
                        new Date(item?.job?.createdAt * 1000),
                        new Date(item?.job?.updatedAt * 1000),
                        { unit: "second" }
                      )}
                    </Text>
                  </Space>
                </Space>
              ) : item?.job?.status === "failed" ? (
                <Space>
                  <CloseCircleFilled
                    style={{
                      color: "#FF4D50",
                      fontSize: "1.5rem",
                    }}
                  />
                  <Space
                    direction="vertical"
                    size={0}
                    style={{ direction: "ltr" }}
                  >
                    <Text size="lg">{t("connections.SyncFailed")}</Text>
                    <Text size="sm">
                      {`
                        ${item?.job?.aggregatedStats?.bytesCommitted} Bytes | 
                        ${item?.job?.aggregatedStats?.recordsCommitted} records extracted | 
                        ${item?.job?.aggregatedStats?.recordsEmitted} records loaded | 
                        Job id: ${item?.job?.id} | 
                        `}
                      {formatDistanceStrict(
                        new Date(item?.job?.createdAt * 1000),
                        new Date(item?.job?.updatedAt * 1000),
                        { unit: "second" }
                      )}
                    </Text>
                    <Text size="sm" color="danger">
                      {`
                          Failure Origin: ${item?.attempts?.[0]?.failureSummary?.failures?.[0]?.failureOrigin}, 
                          Message: ${item?.attempts?.[0]?.failureSummary?.failures?.[0]?.externalMessage}
                        `}
                    </Text>
                  </Space>
                </Space>
              ) : null}
              <Text>
                {format(fromUnixTime(item?.job?.createdAt), "p MM/dd/yyyy")}
              </Text>
            </div>
          </List.Item>
        )}
      />
    </div>
  )
}

const Connection = () => {
  const { t } = useTranslation()
  const { id } = useTypedParams({ id: String })
  const { data: connection, isLoading, isError } = useETLConnectionDetails(id)
  const { mutate: update, isLoading: updateLoading } = useUpdateETLConnections()

  const handelUpdateConnectionStatus = (connectionId, status) => {
    update(
      {
        connectionId,
        data: {
          status: status,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("connections.successfullyUpdate"),
          })
        },
        onError: () => {
          notification.error({
            message: t("connections.failedUpdate"),
          })
        },
      }
    )
  }

  const items = [
    {
      key: "1",
      label: t("connections.status.status"),
      children: <ConnectionStatus connection={connection} />,
    },
    {
      key: "2",
      label: t("connections.status.job"),
      children: <ConnectionJobHistory connection={connection} />,
    },
  ]

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  if (isError) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <div className="section">
        <Text size="heading">{connection?.name}</Text>
        <Space>
          <span>
            {connection.status === "active"
              ? t("connections.status.enabled")
              : t("connections.status.disabled")}
          </span>
          <Switch
            style={{ width: "30px" }}
            // size=""
            checked={connection.status === "active"}
            loading={updateLoading || connection.isSyncing}
            disabled={updateLoading || connection.isSyncing}
            onClick={(value) =>
              handelUpdateConnectionStatus(
                connection.connectionId,
                value ? "active" : "inactive"
              )
            }
          />
        </Space>
      </div>
      <Tabs defaultActiveKey="1" items={items} />
    </Wrapper>
  )
}

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-top: 1px solid #ccc;

  & .section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export default Connection
