import React from "react"

function useDebounce(value, interval = 50) {
  const [state, setState] = React.useState(value)

  React.useEffect(() => {
    const timeout = setTimeout(() => setState(value), interval)

    return () => clearTimeout(timeout)
  }, [value])

  return state
}

export default useDebounce
