import client from "services/client"
import AppError from "services/app-error"
import qs from "query-string"

// TODO: Throw AppError and handle different error cases

const { INVALID_PAGE_NUMBER } = AppError.codes

function getEndpoint(favorites, archived, my_requests) {
  if (favorites) return "/favorites"
  if (archived) return "/archive"
  if (my_requests) return "/my"
  return ""
}

function isObjEmpty(obj) {
  return JSON.stringify(obj) === "{}"
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function getRequestPage(options = {}) {
  const {
    page = 1,
    pageSize: page_size = 10,
    name: q,
    favorites,
    archived,
    myRequests: my_requests,
    tags,
    date_from,
    date_to,
  } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
    tags,
    date_from,
    date_to,
  })

  const endpoint = "/queries" + getEndpoint(favorites, archived, my_requests)

  const res = await client.get(`${endpoint}?${params}`)

  if (res.status === 400) {
    throw new AppError("Invalid request page number.", INVALID_PAGE_NUMBER)
  }

  return res.data
}

async function getRequestResult(query, RequestParameters) {
  if (query.latest_query_data_id && RequestParameters?.length === 0) {
    const res = await client.get(
      `/queries/${query.id}/results/${query.latest_query_data_id}.json`
    )
    if (res) {
      return res.data.query_result
    } else {
      return {
        error:
          "Error running Request: Unknown error occurred. Please try again later.",
      }
    }
  } else {
    try {
      const res = await client.post(`/queries/${query.id}/results`, {
        apply_auto_limit: false,
        id: query.id,
        max_age: -1,
        parameters: RequestParameters.reduce((acc, curr) => {
          acc[curr.name] =
            curr.value === "d_now"
              ? new Date().toISOString().split("T")[0]
              : curr.value === "d_yesterday"
              ? new Date(Date.now() - 86400000).toISOString().split("T")[0]
              : curr.value
          return acc
        }, {}),
      })
      if (res) {
        if (res.data.job) {
          let result = res.data.job.result
          let error = res.data.job.error
          // wait time num < 10 ? 2 : 4,
          // limit time, num 3 min
          let numRequests = 0
          let waitTime = 2000
          while (!result && !error) {
            numRequests++
            const r = await client.get(`/jobs/${res.data.job.id}`)
            result = r.data.job.result
            error = r.data.job.error
            if (numRequests > 10) {
              waitTime = 8000
            }
            if (numRequests * waitTime >= 180000) {
              return { error: "Error running Request: Timeout exceeded." }
              break
            }
            await sleep(waitTime)
          }
          if (result) {
            const { data } = await client.get(`/query_results/${result}`)
            return data.query_result
          } else if (error) {
            return { error: error }
          } else {
            return { error: "Unknown error" }
          }
        } else if (res.data.message) {
          return { error: res.data.message }
        } else if (res.data.query_result) {
          return res.data.query_result
        }

        return {
          error:
            "Error running Request: Unknown error occurred. Please try again later.",
        }
      } else {
        return {
          error:
            "Error running Request: Unknown error occurred. Please try again later.",
        }
      }
    } catch (error) {
      return {
        error:
          "Error running Request: Unknown error occurred. Please try again later.",
      }
    }
  }
}

async function updateRequestResult(id, newdata) {
  try {
    let res = await client.post(`/queries/${id}/results`, newdata)
    if (res) {
      if (res.data.job) {
        let result = res.data.job.result
        let error = res.data.job.error
        let numRequests = 0
        let waitTime = 2000
        while (!result && !error) {
          numRequests++
          const r = await client.get(`/jobs/${res.data.job.id}`)
          result = r.data.job.result
          error = r.data.job.error
          if (numRequests > 10) {
            waitTime = 8000
          }
          if (numRequests * waitTime >= 180000) {
            break
          }
          await sleep(waitTime)
        }
        if (result) {
          const { data } = await client.get(`/query_results/${result}`)
          return data.query_result
        } else if (error) {
          return { error: error }
        } else {
          return { error: "Error running Request: Timeout exceeded." }
        }
      } else if (res.data.message) {
        return { error: res.data.message }
      } else {
        return res.data.query_result
      }
    } else {
      return {
        error:
          "Error running Request: Unknown error occurred. Please try again later.",
      }
    }
  } catch (error) {
    return {
      error:
        "Error running Request: Unknown error occurred. Please try again later.",
    }
  }
}

async function getRequest(id) {
  const res = await client.get(`/queries/${id}`)

  return res.data
}

async function getRequestByName(name = "") {
  const res = await client.get(`/queries?q=${name}`)

  return res.data
}

async function getRequestsTags() {
  const res = await client.get("/queries/tags")

  return res.data.tags
}

async function getFavoritesRequests() {
  const res = await client.get(`/queries/favorites`)

  return res.data
}

async function addFavoriteRequest(id) {
  const res = await client.post(`/queries/${id}/favorite`)

  return res.data
}

async function removeFavoriteRequest(id) {
  const res = await client.delete(`/queries/${id}/favorite`)

  return res.data
}

async function createRequest(data) {
  const res = await client.post("/queries", data)
  return res.data
}

async function updateRequest(requestId, data) {
  const res = await client.post(`/queries/${requestId}`, data)
  return res.data
}

async function runRequest(requestId, data) {
  try {
    let res = await client.post(`/queries/${requestId}/results`, data)
    if (res.data.job) {
      let result = res.data.job.result
      let error = res.data.job.error
      let numRequests = 0
      let waitTime = 2000
      while (!result && !error) {
        numRequests++
        res = await client.get(`/jobs/${res.data.job.id}`)
        result = res.data.job.result
        error = res.data.job.error
        if (numRequests > 10) {
          waitTime = 8000
        }
        if (numRequests * waitTime >= 180000) {
          return { error: "Error running Request: Timeout exceeded." }
          break
        }
        await sleep(waitTime)
      }
      if (result) {
        res = await client.get(`/query_results/${result}`)
        return res.data.query_result
      } else if (error) {
        return { error: error }
      } else {
        return { error: "Something went wrong" }
      }
    } else if (res.data.message) {
      return { error: res.data.message }
    } else {
      return res.data.query_result
    }
  } catch (error) {
    return { error: "Server Error" }
  }
}

async function getJobs(jobId) {
  const res = await client.get(`/jobs/${jobId}`)
  return res.data
}

async function getQueryResult(id) {
  const res = await client.get(`/query_results/${id}`)
  return res.data
}

async function forkRequest(id) {
  const res = await client.post(`/queries/${id}/fork`)
  return res.data
}

async function createeQueryResult(data) {
  try {
    let res = await client.post(`/query_results`, data)
    if (res.data.job) {
      let result = res.data.job.result
      let error = res.data.job.error
      let numRequests = 0
      let waitTime = 2000
      while (!result && !error) {
        numRequests++
        res = await client.get(`/jobs/${res.data.job.id}`)
        result = res.data.job.result
        error = res.data.job.error
        if (numRequests > 10) {
          waitTime = 8000
        }
        if (numRequests * waitTime >= 180000) {
          return { error: "Error running Request: Timeout exceeded." }
          break
        }
        await sleep(waitTime)
      }
      if (result) {
        res = await client.get(`/query_results/${result}`)
        return res.data.query_result
      } else if (error) {
        return { error: error }
      } else {
        return { error: "Something went wrong" }
      }
    } else if (res.data.message) {
      return { error: res.data.message }
    } else {
      return res.data.query_result
    }
  } catch (error) {
    return { error: "Server Error" }
  }
}

async function runnewRequest(queryId, data) {
  let res = await client.post(`/queries/${queryId}/refresh`, data)
  if (res.data.job) {
    let result = res.data.job.result
    let error = res.data.job.error
    while (!result && !error) {
      let r = await client.get(`/jobs/${res.data.job.id}`)
      result = r.data.job.result
      error = r.data.job.error
    }
    if (result) {
      res = await client.get(`/query_results/${result}`)
    }
  }
  return res.data.query_result
}

async function getRequestDropdown(requestId, dropdownId) {
  const res = await client.get(`/queries/${requestId}/dropdowns/${dropdownId}`)
  return res.data
}
async function getQueryDropdown(id) {
  const res = await client.get(`/queries/${id}/dropdown`)
  return res.data
}

async function createVisualization(data) {
  const res = await client.post("/visualizations", data)
  return res.data
}

async function deleteVisualization(id) {
  const res = await client.delete(`/visualizations/${id}`)
  return res.data
}

async function updateVisualization(id, data) {
  const res = await client.post(`/visualizations/${id}`, data)
  return res.data
}

export {
  getRequest,
  getRequestResult,
  updateRequestResult,
  getRequestByName,
  getRequestPage,
  getRequestsTags,
  addFavoriteRequest,
  removeFavoriteRequest,
  createRequest,
  updateRequest,
  runRequest,
  getJobs,
  getQueryResult,
  forkRequest,
  createeQueryResult,
  runnewRequest,
  getFavoritesRequests,
  createVisualization,
  deleteVisualization,
  updateVisualization,
  getRequestDropdown,
  getQueryDropdown,
}
