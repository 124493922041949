import React, { useState } from "react"
import { DatePicker, Input, InputNumber, Select, message } from "antd"
import styled from "styled-components"
import dayjs from "dayjs"
import Button from "components/shared/Button/Button"
import Icon from "components/shared/Icon/Icon"
import { useAuth } from "context/AuthContext"
import { useRequestState } from "../Provider"
import FormModal from "components/shared/FormModal/FormModal"
import EditParameters from "../RequestSnippets/EditParameters"
import IconButton from "components/shared/IconButton/IconButton"
import { useUpdateRequest } from "hooks/requests"
import QueryDropdown from "components/dashboards/Paramters/QueryDropdown"

const isValidDate = (dateString) => {
  return dayjs(dateString, "YYYY-MM-DD", true).isValid()
}

const setDateValue = (props, formatt) => {
  if (props?.value === "d_now") {
    return dayjs()
  } else if (props?.value === "d_yesterday") {
    return dayjs().subtract(1, "day")
  } else if (isValidDate(props?.value)) {
    return dayjs(props?.value, formatt)
  } else {
    return undefined // default to current date if value is invalid
  }
}

const RequestParameters = ({ requestId }) => {
  const {
    memoizedRequest,
    RequestParameters,
    setRequestParameters,
    onUpdateData,
    isDrillDown,
  } = useRequestState()
  const { session } = useAuth()
  const updateRequest = useUpdateRequest()
  const [showBtn, setShowBtn] = useState(false)

  const handelInputChange = (param, value) => {
    setRequestParameters((prev) =>
      prev.map((p) => {
        return p.name === param.name ? { ...p, value: value } : p
      })
    )
    setShowBtn(true)
  }

  const onUpdateParams = () => {
    onUpdateData(RequestParameters)
    setShowBtn(false)
  }

  const formatInput = (props) => {
    if (props.type === "enum") {
      return (
        <Select
          mode={props.multiValuesOptions && "multiple"}
          maxTagCount={3}
          options={props.enumOptions
            .split("\n")
            .map((e) => ({ value: e, label: e }))}
          value={props.value}
          onChange={(value) => handelInputChange(props, value)}
          popupMatchSelectWidth={false}
        />
      )
    } else if (props.type === "query") {
      return (
        <QueryDropdown
          props={{ ...props, requestId: memoizedRequest.id }}
          handelInputChange={handelInputChange}
          isCreated={false}
        />
      )
    } else if (props.type === "user") {
      return (
        <Input
          value={session.user.email}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "text") {
      return (
        <Input
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "number") {
      return (
        <InputNumber
          onChange={(value) => handelInputChange(props, value)}
          defaultValue={props.value}
        />
      )
    } else if (props.type === "date") {
      return (
        <DatePicker
          defaultValue={setDateValue(props, "YYYY-MM-DD")}
          format="YYYY-MM-DD"
          showTime={props.type === "datetime-local"}
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (
      props.type === "datetime-local" ||
      props.type === "datetime-with-seconds"
    ) {
      return (
        <DatePicker
          defaultValue={setDateValue(
            props,
            props.type === "datetime-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          )}
          format={
            props.type === "datetime-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (props.type === "date-range") {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props.value?.start === "d_now"
              ? dayjs()
              : props.value?.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value?.start, "YYYY-MM-DD"),
            props.value?.end === "d_now"
              ? dayjs()
              : props.value?.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value?.end, "YYYY-MM-DD"),
          ]}
          format="YYYY-MM-DD"
          onChange={(_, value) => {
            setRequestParameters((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            // setUpdatedParameters((prev) =>
            //   prev.map((p) => {
            //     return p.name === props.name
            //       ? { ...p, value: { start: value[0], end: value[1] } }
            //       : p
            //   })
            // )
            setShowBtn(true)
          }}
        />
      )
    } else if (
      props.type === "datetime-range" ||
      props.type === "datetime-range-with-seconds"
    ) {
      return (
        <DatePicker.RangePicker
          defaultValue={[
            props.value.start === "d_now"
              ? dayjs()
              : props.value.start === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.start === "datetime-local-seconds"
              ? dayjs(props.value.start, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.start, "YYYY-MM-DD HH:mm"),
            props.value.end === "d_now"
              ? dayjs()
              : props.value.end === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type.end === "datetime-local-seconds"
              ? dayjs(props.value.end, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value.end, "YYYY-MM-DD HH:mm"),
          ]}
          format={
            props.type === "datetime-range-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => {
            setRequestParameters((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
            // setUpdatedParameters((prev) =>
            //   prev.map((p) => {
            //     return p.name === props.name
            //       ? { ...p, value: { start: value[0], end: value[1] } }
            //       : p
            //   })
            // )
            setShowBtn(true)
          }}
        />
      )
    }
  }

  const onEditParameters = (newParameter, closeModal) => {
    const updatedParameters = [...RequestParameters]
    const paramName = newParameter?.name.split(".")[0]
    const indexToUpdate = updatedParameters.findIndex(
      (param) => param.name === paramName
    )

    if (indexToUpdate !== -1) {
      updatedParameters[indexToUpdate] = {
        ...updatedParameters[indexToUpdate],
        ...newParameter,
      }
    }

    updateRequest.mutate(
      {
        requestId: memoizedRequest.id,
        data: {
          data_source_id: memoizedRequest.data_source_id,
          description: memoizedRequest.description,
          id: memoizedRequest.id,
          is_draft: memoizedRequest.is_draft,
          latest_query_data_id: memoizedRequest.latest_query_data_id,
          name: memoizedRequest.name,
          options: {
            ...memoizedRequest.options,
            parameters: updatedParameters,
          },
          query: memoizedRequest.query,
          schedule: memoizedRequest.schedule,
          version: memoizedRequest.version,
        },
      },
      {
        onSuccess: () => {
          setRequestParameters(updatedParameters)
          closeModal()
        },
        onError: () => {
          message.error("Faild Update Parameters")
        },
      }
    )
  }

  return (
    <Wrapper>
      <div className="parametersContainer">
        {RequestParameters?.map((param, idx) => (
          <InputContainer isDreillDown={isDrillDown} key={idx}>
            <div key={idx} className="parameters">
              <label>{param.title}</label>
              {formatInput({ ...param })}
            </div>
            <FormModal
              Form={EditParameters}
              hasChanged={(name) => !!name}
              title="Edit Parameter"
              formProps={{
                parameter: param,
                onEditParameters: onEditParameters,
                isLoading: updateRequest.isLoading,
              }}
            >
              <IconButton size="sm" type="transparent">
                <Icon type="settings" />
              </IconButton>
            </FormModal>
          </InputContainer>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding: 1rem 0;

  .parametersContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .parameters {
      min-width: 180px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label {
        text-align: left;
      }
    }
  }

  .updateBtn {
    position: absolute;
    margin-top: 0.1rem;
    z-index: 10;
    display: none;
  }
  .show {
    display: block;
  }
`

const InputContainer = styled.div`
  position: relative;

  button {
    display: ${(p) => (p.isDreillDown ? "none" : "block")};
    position: absolute;
    top: 0;
    right: 0;
  }
`

export default RequestParameters
