import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  getApplications,
  createApplications,
  deleteApplications,
  updateApplications,
} from "apis/applications"

const keys = {
  all: ["applications"],
  list: () => [...keys.all, "list"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

function useApplications(options = {}) {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getApplications(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useCreateApplications(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((data) => createApplications(data), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.list())
    },
  })
}

function useDeleteApplications(options) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id) => deleteApplications(id),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}

function useUpdateApplications(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateApplications(id, data), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.list())
    },
  })
}

export {
  useApplications,
  useCreateApplications,
  useDeleteApplications,
  useUpdateApplications,
}
