import axios from "axios"
import authService from "services/auth"
import auth from "services/auth"
import { getBaseUrl } from "../config"

const tenant = auth.getTenantName()
const BASE_URL = getBaseUrl(
  tenant === "enwz" ? "golf" : tenant === "euzq" ? "mawten" : "default"
)

const client = axios.create({
  baseURL: BASE_URL,
  validateStatus: (status) => status < 500,
})

client.interceptors.request.use((config) => {
  const auth = authService.getAuth()
  const URL = authService.getTenantURL()

  config.baseURL = `${URL}/api`

  if (auth) {
    config.headers["Authorization"] = `Bearer ${auth.access_token}`
  }

  return config
})

client.interceptors.response.use(
  async (res) => {
    const prevRequest = res?.config
    if ((res?.status === 404 || res?.status === 401) && !prevRequest?.sent) {
      prevRequest.sent = true
      const newAccessToken = await authService.refreshToken()
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
      return client(prevRequest)
    } else {
      return res
    }
  },
  async (err) => {
    const prevRequest = err?.config
    if (err && !prevRequest?.sent) {
      prevRequest.sent = true
      const newAccessToken = await authService.refreshToken()
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`
      return client(prevRequest)
    } else {
      throw err
    }
  }
)

export default client
