import { Result, Spin } from "antd"
import styled from "styled-components"
import React from "react"
import { useQueryOutdated } from "hooks/admin"
import QueryOutdated from "components/admin/QueryOutdated"

const AdminQueriesOutdated = () => {
  const { data, isLoading, isError, isRefetching } = useQueryOutdated()

  if (isLoading || isRefetching) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title="Faild load Admin page"
        subTitle="Please refrsh page or contact your Administrator"
      />
    )
  }

  return <QueryOutdated status={data} />
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AdminQueriesOutdated
