import React from "react"
import { Modal } from "antd"

function Fullscreen({ isFullscreen, children, vw, vh, onCancel }) {
  const { width, height, margin } = React.useMemo(() => {
    const width = window.innerWidth * (vw / 100)
    const height = window.innerHeight * (vh / 100)
    const margin = (window.innerHeight - height) / 2 - 100

    return {
      width,
      height,
      margin,
    }
  }, [vw, vh])

  const modal = (
    <Modal
      open={isFullscreen}
      width={width}
      style={{
        direction: "ltr",
        height: `${height}`,
        marginTop: `${margin}px`,
      }}
      title={null}
      footer={null}
      onCancel={onCancel}
      wrapClassName="full-screen-modal"
    >
      {children}
    </Modal>
  )

  return (
    <>
      {modal}
      {children}
    </>
  )
}

export default Fullscreen
