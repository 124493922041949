import React from "react"
import styled from "styled-components"
import { Form, Input } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import { useTranslation } from "react-i18next"

const APIKeyModel = ({ onChange, onCancel, closeModal, API }) => {
  const { t, i18n } = useTranslation()
  React.useEffect(() => onChange(API), [API, onChange])
  return (
    <Wrapper
      layout="vertical"
      direction={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
    >
      <Form.Item label={t("requests.apiKey")}>
        <Input value={API} readOnly />
      </Form.Item>
      <Form.Item label={t("requests.exampleApi")}>
        <Form.Item label={t("requests.apiCSVFormat")}>
          <Paragraph className="url" copyable>
            {`${window.location.href}/results.csv?api_key=${API}`}
          </Paragraph>
        </Form.Item>
        <Form.Item label={t("requests.apiJSONFormat")}>
          <Paragraph className="url" copyable>
            {`${window.location.href}/results.json?api_key=${API}`}
          </Paragraph>
        </Form.Item>
      </Form.Item>
    </Wrapper>
  )
}

const Wrapper = styled(Form)`
  .ant-typography {
    position: relative;
    background-color: #f4f4f4;
    padding: 0.5rem;
    border-radius: 0.3rem;

    .ant-typography-copy {
      position: absolute;
      bottom: 0;
      right: ${({ direction }) => (direction === "rtl" ? "unset" : "5px")};
      left: ${({ direction }) => (direction === "rtl" ? "5px" : "unset")};
    }
  }

  .ant-form-item {
    margin-bottom: 0.5rem !important;
  }
`

export default APIKeyModel
