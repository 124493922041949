import client from "services/client"
import qs from "query-string"

async function getGroupPage(options = {}) {
  const { page = 1, pageSize: page_size = 10, name: q } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
  })

  const res = await client.get(`/groups?${params}`)
  return res.data
}

async function getGroups() {
  const res = await client.get(`/groups`)
  return res.data
}

async function getGroupByName(name = "") {
  const res = await client.get(`/groups?q=${name}`)
  return res.data
}

async function getGroup(id) {
  const res = await client.get(`/groups/${id}`)
  return res.data
}

async function getGroupMembers(id) {
  const res = await client.get(`/groups/${id}/members`)
  return res.data
}

async function addGroupMember(id, data) {
  const res = await client.post(`/groups/${id}/members`, data)
  return res.data
}

async function deleteGroupMembers(id, memberId) {
  const res = await client.delete(`/groups/${id}/members/${memberId}`)
  return res.data
}

async function getGroupDataSources(id) {
  const res = await client.get(`/groups/${id}/data_sources`)
  return res.data
}

async function addGroupDataSources(id, data) {
  const res = await client.post(`/groups/${id}/data_sources`, data)
  return res.data
}

async function deleteGroupDataSources(id, sourceId) {
  const res = await client.delete(`/groups/${id}/data_sources/${sourceId}`)
  return res.data
}

async function createGroup(data) {
  const res = await client.post("/groups", data)

  if (res.status === 400) {
    return data
  }

  return res.data
}

async function updateGroup(id, data) {
  const res = await client.post(`/groups/${id}`, data)
  return res.data
}

async function deleteGroup(id) {
  const res = await client.delete(`/groups/${id}`)
  return res.data
}

export {
  getGroups,
  getGroupPage,
  getGroupByName,
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroupMembers,
  getGroupDataSources,
  deleteGroupMembers,
  deleteGroupDataSources,
  addGroupMember,
  addGroupDataSources,
}
