import * as Columns from "./Columns"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { Space, Tooltip } from "antd"
import { format } from "date-fns"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import { OffBtn, OnBtn } from "components/shared/Icon/CustomIcons"
import FormModal from "components/shared/FormModal"
import UpdateUser from "../UpdateUser"

export function formatDistanceDate(date) {
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true })
}

export function formatDate(date) {
  return format(new Date(date), "MM/dd/yyyy hh:mm")
}

export function renderNameColumn(_, user) {
  return <Columns.Name user={user} />
}

export function renderEmailColumn(_, user) {
  return <Columns.Email user={user} />
}

export function renderGroupsColumn(_, user) {
  return <Columns.Groups user={user} />
}

export function renderCreatedAtColumn(_, { created_at }) {
  return (
    <Tooltip title={formatDate(created_at)}>
      <small>{formatDistanceDate(created_at)}</small>
    </Tooltip>
  )
}

export function renderActiveAtColumn(_, { active_at }) {
  return (
    <Tooltip title={active_at && formatDate(active_at)}>
      <small>
        {active_at ? formatDistanceDate(active_at) : "User is not active yet"}
      </small>
    </Tooltip>
  )
}

export function renderDisableUserColumn(_, user) {
  const { id, is_disabled, onDisableUser, onEnableUser } = user
  return <></>
}

export function renderActionBtns(data, t) {
  const {
    id,
    user,
    is_invitation_pending,
    onDeleteUser,
    is_disabled,
    onDisableUser,
    onEnableUser,
  } = data
  return (
    <Space size={20}>
      <FormModal
        title={t("users.updateUser")}
        width={500}
        hasChanged={({ id }) => !!id}
        Form={UpdateUser}
        formProps={data}
        style={{ top: "1rem" }}
      >
        <IconButton type="transparent">
          <Icon type="editOutlined" />
        </IconButton>
      </FormModal>
      {user.id !== id && is_invitation_pending && (
        <IconButton type="transparent" onClick={() => onDeleteUser(id)}>
          <Icon type="deleteOutlined" />
        </IconButton>
      )}
      {is_disabled ? (
        <IconButton
          type="transparent"
          size="xs"
          onClick={() => onEnableUser(id)}
        >
          <OffBtn />
        </IconButton>
      ) : (
        <IconButton
          type="transparent"
          size="xs"
          onClick={() => onDisableUser(id)}
        >
          <OnBtn />
        </IconButton>
      )}
    </Space>
  )
}
