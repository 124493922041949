import { Avatar, Card, Col, Input, Row, Space, Spin, Tag } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"
import { useETLDestinationsDefination } from "hooks/ETL/destinations"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const CreateETLDestinations = () => {
  const { t } = useTranslation()
  const [destinationType, setDestinationType] = useState([])
  const [search, setSearch] = useState("")
  const { data: destnationsDefination, isLoading } =
    useETLDestinationsDefination()

  useEffect(() => {
    if (destnationsDefination) {
      setDestinationType(destnationsDefination.destinationDefinitions)
    }

    return () => {
      setDestinationType([])
    }
  }, [destnationsDefination])

  useEffect(() => {
    if (search.trim()) {
      const filteredDestinations =
        destnationsDefination?.destinationDefinitions?.filter((definition) =>
          definition?.name
            ?.toLowerCase()
            ?.includes(search?.trim()?.toLowerCase())
        )
      setDestinationType(filteredDestinations)
    } else {
      setDestinationType(destnationsDefination?.destinationDefinitions)
    }
  }, [search, destnationsDefination?.destinationDefinitions])

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <h1>{t("destinations.createDesDesc")}</h1>
        <Input.Search
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          size="large"
        />
      </div>
      <Row gutter={[16, 24]} wrap>
        {destinationType?.map((destnation) => (
          <Col
            key={destnation.destinationDefinitionId}
            span={6}
            style={{
              minWidth: 300,
              marginTop: 16,
            }}
          >
            <CustomLink
              to={`/destinations/etl/create/${
                destnation.destinationDefinitionId
              }?destinationType=${destnation.name
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
            >
              <Card
                style={{
                  borderRadius: "8px",
                }}
              >
                <Card.Meta
                  title={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Space
                        style={{
                          width: "65%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "clip",
                        }}
                      >
                        <Avatar src={destnation?.icon} shape="square" />
                        <Text size="sm">{destnation?.name}</Text>
                      </Space>
                      <p>
                        <Tag>{destnation?.supportLevel}</Tag>
                      </p>
                    </div>
                  }
                />
              </Card>
            </CustomLink>
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: first baseline;
  justify-content: center;

  .header {
    width: 100%;
  }
`

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default CreateETLDestinations
