import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  addETLDestination,
  getETLDestinationsDefination,
  getETLDestinationDefination,
  getETLDestinations,
  deleteETLDestination,
  getETLDestinationsList,
  testETLDestination,
} from "apis/ETL/destinations"

const keys = {
  all: ["etl-destinations"],
  list: () => [...keys.all, "list"],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  defination: () => [...keys.all, "defination"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useETLDestinations() {
  return useQuery({
    queryKey: keys.list(),
    // queryFn: () => getETLDestinations(),
    queryFn: () => getETLDestinationsList(),
    ...defaultQueryConfig,
  })
}

export function useETLDestinationDefination(id) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getETLDestinationDefination(id),
    ...defaultQueryConfig,
  })
}

export function useETLDestinationsDefination() {
  return useQuery({
    queryKey: keys.defination(),
    queryFn: () => getETLDestinationsDefination(),
    ...defaultQueryConfig,
  })
}

export function useCreateETLDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => addETLDestination(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}

export function useDeleteETLDestinations(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id) => deleteETLDestination(id),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
    ...options,
  })
}

export function useTestETLDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (destinationId) => testETLDestination(destinationId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}
