import React from "react"
import styled from "styled-components"
import { Spin } from "antd"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import Text from "components/shared/Text"
import { useCreateAgentContext } from "context/CreateAgentContext"
import Icon from "components/shared/Icon"
import Footer from "../CreateAgentFooter"
import IconButton from "components/shared/IconButton"
import { useDestinations } from "hooks/destinations"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/destinations/email.png"
import { useTranslation } from "react-i18next"

export function Destination({ onRemoveDestination, destination }) {
  return (
    <UserCard>
      <div>
        <Icon type="mailDark" />
        <Text color="secondary" size="sm">
          Name: {destination.name}
        </Text>
      </div>

      {onRemoveDestination ? (
        <IconButton
          type="transparent"
          shape="round"
          size="sm"
          onClick={onRemoveDestination}
        >
          <Icon type="delete" />
        </IconButton>
      ) : (
        <div></div>
      )}
    </UserCard>
  )
}

export function AgentSubscription({ user, onRemove }) {
  return (
    <UserCard>
      <div>
        <img
          src={`${destinationImages[user.type] || defaultImage}`}
          alt={user.type}
          width={40}
        />
        <Text color="secondary" size="sm">
          Name: {user.name}
        </Text>
      </div>

      <div>
        {onRemove ? (
          <IconButton
            type="transparent"
            size="sm"
            shape="round"
            onClick={onRemove}
          >
            <Icon type="delete" />
          </IconButton>
        ) : (
          <div></div>
        )}
      </div>
    </UserCard>
  )
}

function SelectUserFom({ closeModal }) {
  const { t } = useTranslation()
  const { users: selectedUsers, onSelectUsers } = useCreateAgentContext()
  const [currentSelectedUsers, setCurrentSelectedUsers] =
    React.useState(selectedUsers)

  const hash = currentSelectedUsers.reduce((acc, el) => {
    acc[el.id] = true
    return acc
  }, {})

  const { data: destinationsDate, isLoading: destinationLoading } =
    useDestinations()

  const handleSave = () => {
    onSelectUsers(currentSelectedUsers)
    closeModal()
  }

  const handleUserClick = (des) => {
    if (hash[des.id]) {
      const filtered = currentSelectedUsers.filter(({ id }) => id !== des.id)
      setCurrentSelectedUsers(filtered)
      return
    }

    setCurrentSelectedUsers([...currentSelectedUsers, des])
  }

  if (destinationLoading) {
    return (
      <UsersListLoader>
        <Spin size="large" />
      </UsersListLoader>
    )
  }

  return (
    <UsersList>
      <div className="info">
        <Text>
          {t("agents.usersCount")}: {destinationsDate.length}
        </Text>
        <Text>
          {t("agents.selectedUsers")}: {currentSelectedUsers.length}
        </Text>
      </div>

      <ul>
        {destinationsDate.map((des) => (
          <li
            className={
              hash[des.id] ? "user-list-item selected" : "user-list-item"
            }
            onClick={() => handleUserClick(des)}
          >
            <img
              src={`${destinationImages[des.type] || defaultImage}`}
              alt={des.type}
              width={68}
            />
            {des.name}
          </li>
        ))}
      </ul>

      <FormModal.Footer>
        <Button onClick={() => closeModal()} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button onClick={handleSave} type="primary">
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </UsersList>
  )
}

function Destinations() {
  const { t } = useTranslation()
  const { users, onNextStep, onPreviousStep, onRemoveUser } =
    useCreateAgentContext()

  return (
    <Wrapper>
      <div className="header">
        <Text color="brand">{t("agents.destinations")}</Text>

        <div>
          <FormModal
            title={t("agents.selectUser")}
            width={800}
            Form={SelectUserFom}
            hasChanged={() => false}
          >
            <Button type="primary">+ {t("agents.addDestination")}</Button>
          </FormModal>
        </div>
      </div>

      <div className="content">
        {users?.map((user) => (
          <AgentSubscription
            user={user}
            onRemove={() => onRemoveUser(user.id)}
            key={user?.id}
          />
        ))}
      </div>

      <Footer
        onNext={onNextStep}
        onPrevious={onPreviousStep}
        className="footer"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 32px;

  .header {
    ${({ theme }) => theme.mixins.flexSpaceBetween};
    margin: 0 0 32px;
  }

  .content {
    min-height: 200px;
  }
`

const UsersList = styled.div`
  .info {
    margin: 0 0 24px;

    span {
      margin: 0 16px 0 0;
      display: inline-block;
    }
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  li {
    padding: 8px;
    border: var(--border-0);
    border-radius: var(--border-radius-0);
    transition: all 0.2s;

    &:hover {
      background-color: rgb(0, 0, 0, 0.05);
      cursor: pointer;
    }

    &.selected {
      background-color: var(--color-brand);
      color: #fff;
    }
  }
`

const UsersListLoader = styled.div`
  height: 200px;
  ${({ theme }) => theme.mixins.flexCenter};
`

const UserCard = styled.div`
  border: var(--border-0);
  border-radius: var(--border-radius-0);
  padding: 16px;
  margin: 0 0 16px;

  ${({ theme }) => theme.mixins.flexSpaceBetween};

  ${Text} {
    margin: 0 8px;
  }
`

export default Destinations
