import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import qs from "query-string"

import {
  getDashboard,
  getDashboardByName,
  getDashboardPage,
  updateDashboard,
  addFavoriteDashboard,
  removeFavoriteDashboard,
  createDashboard,
  updateWidget,
  createWidget,
  removeWidget,
  getDashboardByTag,
  getDashboardTags,
  shareDashboard,
  deleteShareDashboard,
  getFavoriteDashboards,
  getPublicDashboard,
} from "apis/dashboards"

export const dashboardKeys = {
  all: ["dashboards"],
  lists: () => [...dashboardKeys.all, "list"],
  list: (pagination) => [...dashboardKeys.lists(), pagination],
  details: () => [...dashboardKeys.all, "detail"],
  detail: (id) => [...dashboardKeys.details(), id],
  searchs: () => [...dashboardKeys.all, "search"],
  search: (name) => [...dashboardKeys.searchs(), name],
  tags: () => [...dashboardKeys.all, "tags"],
  favorites: () => [...dashboardKeys.all, "favorites"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

// For CREATE, UPDATE, DELETE
const defaultMutationConfig = (queryClient) => {
  const onSettled = () => {
    queryClient.removeQueries(dashboardKeys.searchs())

    queryClient.prefetchQuery({
      queryKey: dashboardKeys.search(""),
      queryFn: () => getDashboardByName(""),
      ...defaultQueryConfig,
    })
  }

  return { onSettled }
}

// helpers

function getCurrentPaginationOptions() {
  const {
    page = 1,
    page_size: pageSize = 10,
    q: name,
    favorites = false,
    date_from,
    date_to,
  } = qs.parse(window.location.search)

  return { page, pageSize, name, favorites: Boolean(favorites) }
}

function updateCurrentDashboardPage(queryClient, id, data) {
  const pagination = getCurrentPaginationOptions()

  queryClient.setQueryData(dashboardKeys.list(pagination), (previous) => ({
    ...previous,
    results: previous.results.map((dashboard) =>
      dashboard.id === id ? { ...dashboard, ...data } : dashboard
    ),
  }))
}

function invalidateInActiveLists(queryClient) {
  queryClient.invalidateQueries({
    queryKey: dashboardKeys.lists(),
    refetchActive: false,
  })
}

function updateDashboardDetail(queryClient, id, data) {
  const dashboard = queryClient.getQueryData(dashboardKeys.detail(id))

  if (dashboard) {
    queryClient.setQueryData(dashboardKeys.detail(id), (previous) => ({
      ...previous,
      ...data,
    }))
  }
}

// Dashboard hooks
function useDashboards(pagination, options = {}) {
  return useQuery({
    queryKey: dashboardKeys.list(pagination),
    queryFn: () => getDashboardPage(pagination),
    ...defaultQueryConfig,
    ...options,
  })
}

function useSearchDashboards(name, options = {}) {
  return useQuery({
    queryKey: dashboardKeys.search(name),
    queryFn: () => getDashboardByName(name),
    enabled: !!name,
    ...defaultQueryConfig,
    ...options,
  })
}

function useDashboard(id, options = {}) {
  return useQuery({
    queryKey: dashboardKeys.detail(id),
    queryFn: () => getDashboard(id),
    ...defaultQueryConfig,
    ...options,
    enabled: !!id,
  })
}

function useDashboardByTags(tag, options = {}) {
  return useQuery({
    queryKey: dashboardKeys.search(tag),
    queryFn: () => getDashboardByTag(tag),
    ...defaultQueryConfig,
    ...options,
  })
}

function useUpdateDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateDashboard(id, data), {
    ...defaultMutationConfig(queryClient),
    ...options,
    onSuccess: (newDashboard) => {
      options.onSuccess?.(newDashboard)

      const { id } = newDashboard

      queryClient.setQueryData(dashboardKeys.detail(id), newDashboard)

      queryClient.invalidateQueries(dashboardKeys.lists())
      queryClient.invalidateQueries(dashboardKeys.tags())
      queryClient.refetchQueries(dashboardKeys.favorites())
    },
  })
}

function useFavoriteDashboards(options = {}) {
  return useQuery({
    queryKey: dashboardKeys.favorites(),
    queryFn: () => getFavoriteDashboards(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useUpdateFavoriteDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, is_favorite }) =>
      !is_favorite ? addFavoriteDashboard(id) : removeFavoriteDashboard(id),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(dashboardKeys.details())
        queryClient.invalidateQueries(dashboardKeys.lists())
        queryClient.refetchQueries(dashboardKeys.favorites())
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
}

function useCreateDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((dashboard) => createDashboard(dashboard), {
    ...defaultMutationConfig(queryClient),
    ...options,
    onSuccess: (dashboard) => {
      options.onSuccess?.(dashboard)
      queryClient.invalidateQueries(dashboardKeys.lists())
    },
  })
}

function useCreateWidget(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((widget) => createWidget(widget), {
    ...options,
    onSuccess: (widget) => {
      options.onSuccess?.(widget)
      queryClient.refetchQueries({
        queryKey: dashboardKeys.detail(widget.dashboard_id),
      })
    },
  })
}

function useUpdateWidget(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateWidget(id, data), {
    ...options,
    onSuccess: (widget) => {
      options.onSuccess?.(widget)
      queryClient.refetchQueries({
        queryKey: dashboardKeys.detail(widget.dashboard_id),
      })
    },
  })
}

function useRefreshDashboard(id, options = {}) {
  const queryClient = useQueryClient()

  return React.useCallback(
    () => queryClient.invalidateQueries(dashboardKeys.detail(id)),
    [id]
  )
}

function useRemoveWidget(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((id) => removeWidget(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardKeys.details())
    },
  })
}

function useDashboardTags(options = {}) {
  return useQuery({
    queryKey: dashboardKeys.tags(),
    queryFn: () => getDashboardTags(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useShareDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => shareDashboard(id), {
    ...options,
    onSuccess: (share) => {
      queryClient.invalidateQueries(dashboardKeys.details())
    },
  })
}

function useDeleteShareDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => deleteShareDashboard(id), {
    ...options,
    onSuccess: (share) => {
      queryClient.invalidateQueries(dashboardKeys.details())
    },
  })
}

function useGetPublicDashboard(tenant, token, options = {}) {
  return useQuery({
    queryFn: () => getPublicDashboard(tenant, token),
    ...options,
  })
}

export {
  useDashboard,
  useDashboards,
  useSearchDashboards,
  useUpdateDashboard,
  useUpdateFavoriteDashboard,
  useCreateDashboard,
  useUpdateWidget,
  useCreateWidget,
  useRefreshDashboard,
  useRemoveWidget,
  useDashboardByTags,
  useDashboardTags,
  useShareDashboard,
  useDeleteShareDashboard,
  useFavoriteDashboards,
  useGetPublicDashboard,
}
