import React from "react"
import styled from "styled-components"
import { useDashboardState } from "../Provider"
import Icon from "components/shared/Icon/Icon"
import Button from "components/shared/Button/Button"
import FormModal from "components/shared/FormModal/FormModal"
import CreateWidgetForm from "../CreateWidgetForm/CreateWidgetForm"
import CreateTextboxForm from "../CreateTextboxForm/CreateTextboxForm"
import { useTranslation } from "react-i18next"

const AddToDashbard = () => {
  const { editable } = useDashboardState()
  const { t } = useTranslation()

  return (
    <Wrapper editable={editable}>
      <div>
        <Icon type="widgets" className="icon" />
        <span>{t("dashboards.addDescription")}</span>
      </div>
      <div>
        <FormModal
          title={t("dashboards.addTextbox")}
          Form={CreateTextboxForm}
          hasChanged={(values) => !!values}
          width={700}
          style={{ top: "0.5rem", paddingBottom: "0" }}
        >
          <Button type="blue" size="sm">
            {t("dashboards.addTextbox")}
          </Button>
        </FormModal>
        <FormModal
          title={t("dashboards.addWidget")}
          Form={CreateWidgetForm}
          hasChanged={(values) => !!values}
          width={700}
          style={{ top: "0.5rem", paddingBottom: "0" }}
        >
          <Button type="primary" size="sm">
            {t("dashboards.addWidget")}
          </Button>
        </FormModal>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: ${(props) => (props.editable ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 90%;
  margin: auto;
  bottom: 1rem;
  z-index: 9999999;
  background-color: #fff;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  align-items: center;
  justify-content: space-between;

  .icon {
    font-size: 1.2rem;
    padding-right: 0.3rem;
  }
`

export default AddToDashbard
