import styled from "styled-components"
import { Result, Select, Spin } from "antd"
import { useDataSources } from "hooks/dataSources"
import { useEffect, useState, useRef } from "react"
import RequestQuery from "components/requests/RequestQuery"
import { useQuerySnippetsPage } from "hooks/queries"
import { useTranslation } from "react-i18next"
import DataSourceSchema from "components/requests/RequestSnippets/DataSourceSchema"

function SelectSource({ sources, sourceId, setSourceId, onAddSchema }) {
  const sourceOptions = sources?.map((s) => ({ label: s.name, value: s.id }))

  const onChangeSource = (value) => {
    setSourceId(value)
  }

  return (
    <Aside>
      <Select
        bordered={false}
        defaultValue={sourceOptions[0]}
        options={sourceOptions}
        size="large"
        style={{
          width: "100%",
          borderBottom: "1px solid #EAEAEA",
        }}
        onChange={onChangeSource}
      />
      <DataSourceSchema dataSourcesId={sourceId} onAddSchema={onAddSchema} />
    </Aside>
  )
}

function CreateRequest() {
  const chiledRef = useRef(null)
  const { t } = useTranslation()
  const [sourceId, setSourceId] = useState()
  const {
    data: sources,
    isLoading,
    isError: datasourceError,
  } = useDataSources()
  const {
    data: snippets,
    isLoading: snippetsLoading,
    isError: querySnippetsError,
  } = useQuerySnippetsPage()

  useEffect(() => {
    if (!isLoading && sources && !sources.message) {
      setSourceId(sources?.map((s) => s.id)[0])
    }
  }, [sources])

  const onAddSchema = (text) => {
    chiledRef.current.addSchema(text)
  }

  if (isLoading || snippetsLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (
    datasourceError ||
    querySnippetsError ||
    !sources ||
    !snippets ||
    sources.message ||
    snippets.message
  ) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <Content>
        <SelectSource
          sources={sources}
          sourceId={sourceId}
          setSourceId={setSourceId}
          onAddSchema={onAddSchema}
        />
        <RequestQuery sourceId={sourceId} snippets={snippets} ref={chiledRef} />
      </Content>
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  min-height: 100vh;
  /* background-color: #eff4f8; */
  padding: 16px;
  /* max-width: 1400px; */
`

const Content = styled.div`
  background-color: #fff;
  padding: 2rem;
  width: 100%;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
  align-items: start;
`

const Aside = styled.div`
  max-height: 80vh;
  overflow: auto;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
`

export default CreateRequest
