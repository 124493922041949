import React, { useRef } from "react"
import styled from "styled-components"
import { Avatar, Col, Dropdown, Modal, Space, notification } from "antd"
import IconButton from "components/shared/IconButton"
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons"
import { useDeleteApplications } from "hooks/applications"
import CustomLink from "components/shared/CustomLink/CustomLink"
import AppForm from "./AppForm"
import DashboardCount from "./DashboardCount"
import Text from "components/shared/Text"
import Icon from "components/shared/Icon"
import { useTranslation } from "react-i18next"

const ApplicationsCardView = ({ applications }) => {
  const { t } = useTranslation()
  const editButtonRef = useRef(null)
  const { mutateAsync } = useDeleteApplications()

  const onCancelEdit = () => {
    editButtonRef.current = null
  }

  const handelDeleteApp = (id) => {
    Modal.confirm({
      title: t("applications.deleteApp"),
      content: t("applications.deleteAppConfirem"),
      okText: t("global.delete"),
      okType: "danger",
      cancelText: t("global.cancel"),
      onOk: async () => {
        return mutateAsync(id).then(() => {
          notification.success({
            message: t("applications.deleteSuccessfully"),
            duration: 2,
            placement: "bottomRight",
          })
        })
      },
    })
  }

  const items = (app) => {
    return [
      {
        key: 0,
        icon: <EditOutlined />,
        label: (
          <>
            <span>{t("global.edit")}</span>
            <AppForm
              key={app.id}
              app={app}
              hideEdit={true}
              onCancelEdit={onCancelEdit}
              ref={editButtonRef}
            />
          </>
        ),
        onClick: () => {
          editButtonRef?.current?.click()
        },
      },
      {
        key: 1,
        danger: true,
        icon: <DeleteOutlined />,
        label: (
          <>
            <span>{t("global.delete")}</span>
          </>
        ),
        onClick: () => handelDeleteApp(app.id),
      },
    ]
  }

  return (
    <CardWrapper>
      {applications?.map((app) => (
        <Col span={8} key={app?.id} className="app-card">
          <div className="card-header">
            <Space>
              <Icon
                type="ApplicationsPageIcon"
                className="dashboardIcon"
                style={{ minWidth: "40px", fontSize: "1.8rem" }}
              />
              <Text size="lg">{app?.name}</Text>
            </Space>
            <Dropdown
              menu={{
                items: items(app),
              }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <IconButton type="transparent" style={{ zIndex: 999 }}>
                <EllipsisOutlined />
              </IconButton>
            </Dropdown>
          </div>
          <Text size="xl">{app?.tag}</Text>
          <DashboardCount tag={app?.tag} />
          <CustomLink to={`/application/${app?.tag}`} />
          <AppForm
            key={app.id}
            app={app}
            hideEdit={true}
            onCancelEdit={onCancelEdit}
            ref={editButtonRef}
          />
        </Col>
      ))}
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .app-card {
    padding: 1rem;
    border: 1px solid #f1f1f2;
    border-radius: 12px;
    max-width: 32%;
    min-width: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;

    & .card-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-name {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        span {
          color: #7e8299;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 100% */
          letter-spacing: -0.18px;
        }
      }
    }

    .app-tag {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
      letter-spacing: -0.6px;
      text-transform: uppercase;
      margin: 1rem auto;
    }

    .count {
      color: #0097c2;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 100% */
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 678px) {
    .app-card {
      max-width: 80%;
    }
  }
`

export default ApplicationsCardView
