import React from "react"

const AppContext = React.createContext()

// A context provider holding the remaining app global state
// the remaining state us handled with react-query
// states like ui, theme, etc
// data that is not api driven
// If the state gets more complex, use React.useReducer

function getClassNameByIndustry(industry) {
  switch (industry) {
    case "automotive":
      return "automotive-industry"
      break
    case "real-estate":
      return "realestate-industry"
      break
    case "field-services":
      return "field-services-industry"
      break
    default:
      return ""
  }
}

function AppContextProvider(props) {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(true)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [dark, setDark] = React.useState(false)

  const [industry, setIndustry] = React.useState("field-services")

  React.useLayoutEffect(() => {
    document.body.className = getClassNameByIndustry(industry)
  }, [industry])

  const toggleSidebar = React.useCallback(
    () => setIsSidebarCollapsed(!isSidebarCollapsed),
    [isSidebarCollapsed]
  )

  const toggleFullScreen = React.useCallback(
    () => setFullScreen(!fullScreen),
    [fullScreen]
  )

  const onChangeIndustry = React.useCallback((value) => setIndustry(value), [])

  const toggleTheme = React.useCallback(() => setDark(!dark), [dark])

  const value = React.useMemo(
    () => ({
      isSidebarCollapsed,
      toggleSidebar,
      industry,
      onChangeIndustry,
      dark,
      toggleTheme,
      fullScreen,
      toggleFullScreen,
    }),
    [
      isSidebarCollapsed,
      toggleSidebar,
      dark,
      toggleTheme,
      fullScreen,
      toggleFullScreen,
      industry,
      onChangeIndustry,
    ]
  )

  return <AppContext.Provider value={value} {...props} />
}

export function useAppContext() {
  const context = React.useContext(AppContext)

  if (!context) {
    throw new Error("useAppContext must be used inside a AppContextProvider")
  }

  return context
}

export default AppContextProvider
