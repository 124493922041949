import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import languageEn from "lib/langs/en.json"
import languageAr from "lib/langs/ar.json"

const resources = {
  en: {
    translation: languageEn,
  },
  ar: {
    translation: languageAr,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
