import React, { useState } from "react"
import client from "services/client"

export const DEFULT_LAYOUT = [
  {
    id: 0,
    options: {
      position: {
        autoHeight: false,
        sizeX: 1,
        sizeY: 6,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 0,
        row: 0,
      },
    },
  },
  {
    id: 1,
    options: {
      position: {
        autoHeight: false,
        sizeX: 1,
        sizeY: 6,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 1,
        row: 0,
      },
    },
  },
  {
    id: 2,
    options: {
      position: {
        autoHeight: false,
        sizeX: 1,
        sizeY: 6,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 2,
        row: 0,
      },
    },
  },
  {
    id: 3,
    options: {
      position: {
        autoHeight: false,
        sizeX: 1,
        sizeY: 6,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 3,
        row: 0,
      },
    },
  },
  {
    id: 4,
    options: {
      position: {
        autoHeight: false,
        sizeX: 1,
        sizeY: 6,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 4,
        row: 0,
      },
    },
  },
  {
    id: 5,
    options: {
      position: {
        autoHeight: false,
        sizeX: 2,
        sizeY: 10,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 0,
        row: 7,
      },
    },
  },
  {
    id: 6,
    options: {
      position: {
        autoHeight: false,
        sizeX: 3,
        sizeY: 10,
        minSizeX: 1,
        maxSizeX: 6,
        minSizeY: 2,
        maxSizeY: 1000,
        col: 3,
        row: 7,
      },
    },
  },
]

export const generateHomeLayout = (homepage_dashboard_id, widgets) => {
  if (homepage_dashboard_id) {
    return DEFULT_LAYOUT.map((layoutItem) => {
      const matchingWidget = widgets?.find(
        (widget) =>
          widget.options.position.x === layoutItem.options.position.col &&
          widget.options.position.y === layoutItem.options.position.row
      )
      if (matchingWidget) {
        return {
          ...layoutItem,
          ...matchingWidget,
          id: layoutItem.id,
          widgetId: matchingWidget.id,
        }
      }
      return layoutItem
    })
  }

  return DEFULT_LAYOUT
}

// Function to decode HTML entities
const decodeHtmlEntities = (text) => {
  const textArea = document.createElement("textarea")
  textArea.innerHTML = text
  return textArea.value
}

// Function to determine MIME type based on file extension
const getMimeType = (filename) => {
  const extension = filename.split(".").pop().toLowerCase()
  const mimeTypes = {
    csv: "text/csv",
    tsv: "text/tab-separated-values",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  }
  return mimeTypes[extension] || "application/octet-stream" // default fallback for unknown file types
}

export function DownloadLink({ url, filename, text }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const response = await client.get(url, { responseType: "blob" })
      const mimeType = getMimeType(filename)
      const fileContent =
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? response.data
          : decodeHtmlEntities(await response.data.text())
      const downloadLink = document.createElement("a")
      const href = window.URL.createObjectURL(
        new Blob([fileContent], { type: mimeType })
      )
      downloadLink.href = href
      downloadLink.download = filename
      document.body.appendChild(downloadLink)
      downloadLink.click()
      window.URL.revokeObjectURL(href)
      downloadLink.remove()
    } catch (error) {
      console.error("Error downloading file:", error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <a href={url} onClick={handleDownload}>
      {isLoading ? "Downloading..." : text}
    </a>
  )
}

export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`
}
