import styled from "styled-components"
import { Dropdown } from "antd"
import { useTranslation } from "react-i18next"
import { RoundIconButton } from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import { useAppContext } from "context/AppContext"
import { useAuth } from "context/AuthContext"
import CustomLink from "components/shared/CustomLink/CustomLink"
import golfLogo from "static/images/GolfLogo.png"
import NewLogo from "static/images/NewiDataWorkeroLogo.svg"
import LogoBg from "static/images/logobg.svg"
import LogoVector from "static/images/LogoVector.svg"

const ADMIN_MENU = () => {
  const { t } = useTranslation()
  return [
    {
      key: "users",
      label: <CustomLink to="/users">{t("global.pages.users")}</CustomLink>,
    },
    {
      key: "groups",
      label: <CustomLink to="/groups">{t("global.pages.groups")}</CustomLink>,
    },
    {
      key: "channels",
      label: (
        <CustomLink to="/channels">{t("global.pages.channels")}</CustomLink>
      ),
    },
    {
      key: "query",
      label: (
        <CustomLink to="/query_snippets">
          {t("global.pages.query_snippets")}
        </CustomLink>
      ),
    },
    {
      key: "general",
      label: (
        <CustomLink to="/settings/general">
          {t("global.pages.general")}
        </CustomLink>
      ),
    },
    {
      key: "me",
      label: <CustomLink to="/profile">{t("global.pages.account")}</CustomLink>,
    },
  ]
}

const DEFAULT_MENU = () => {
  const { t } = useTranslation()
  return [
    {
      key: "users",
      label: <CustomLink to="/users">{t("global.pages.users")}</CustomLink>,
    },
    {
      key: "groups",
      label: <CustomLink to="/groups">{t("global.pages.groups")}</CustomLink>,
    },
    {
      key: "query",
      label: (
        <CustomLink to="/query_snippets">
          {t("global.pages.query_snippets")}
        </CustomLink>
      ),
    },
    {
      key: "me",
      label: <CustomLink to="/profile">{t("global.pages.account")}</CustomLink>,
    },
  ]
}

const MOBILE_ADMIN_MENU = () => {
  const { t } = useTranslation()
  return [
    {
      key: "applications",
      label: (
        <CustomLink to="/applications">
          {t("global.pages.applications")}
        </CustomLink>
      ),
      icon: <Icon type="applications" />,
    },
    {
      key: "dashboards",
      label: (
        <CustomLink to="/dashboards">{t("global.pages.dashboards")}</CustomLink>
      ),
      icon: <Icon type="dashboard" />,
    },
    {
      key: "requests",
      label: (
        <CustomLink to="/requests">{t("global.pages.requests")}</CustomLink>
      ),
      icon: <Icon type="requests" />,
    },
    {
      key: "agents",
      label: <CustomLink to="/agents">{t("global.pages.agents")}</CustomLink>,
      icon: <Icon type="user" />,
    },
    {
      key: "sources",
      label: <CustomLink to="/sources">{t("global.pages.sources")}</CustomLink>,
      icon: <Icon type="sources" />,
    },
    {
      key: "destinations",
      label: (
        <CustomLink to="/destinations">
          {t("global.pages.destinations")}
        </CustomLink>
      ),
      icon: <Icon type="destination" />,
    },
    {
      key: "connections",
      label: (
        <CustomLink to="/connections">
          {t("global.pages.connections")}
        </CustomLink>
      ),
      icon: <Icon type="connections" />,
    },
    {
      type: "divider",
    },
    {
      key: "settings",
      label: (
        <CustomLink to="/settings/profile">
          {t("global.pages.general")}
        </CustomLink>
      ),
      icon: <Icon type="settings" />,
    },
    {
      type: "divider",
    },
  ]
}

const MOBILE_DEFSULT_MENU = () => {
  const { t } = useTranslation()
  return [
    {
      key: "applications",
      label: (
        <CustomLink to="/applications">
          {t("global.pages.applications")}
        </CustomLink>
      ),
      icon: <Icon type="applications" />,
    },
    {
      key: "dashboards",
      label: (
        <CustomLink to="/dashboards">{t("global.pages.dashboards")}</CustomLink>
      ),
      icon: <Icon type="dashboard" />,
    },
    {
      href: "requests",
      label: (
        <CustomLink to="/requests">{t("global.pages.requests")}</CustomLink>
      ),
      icon: <Icon type="requests" />,
    },
    {
      href: "agents",
      label: <CustomLink to="/agents">{t("global.pages.agents")}</CustomLink>,
      icon: <Icon type="user" />,
    },
    {
      type: "divider",
    },
    {
      key: "settings",
      label: (
        <CustomLink to="/settings/profile">
          {t("global.pages.general")}
        </CustomLink>
      ),
      icon: <Icon type="settings" />,
    },
    // {
    //   key: "profile",
    //   label: (
    //     <CustomLink to="/users/me">{t("global.pages.account")}</CustomLink>
    //   ),
    //   icon: <Icon type="userIcon" />,
    // },
    // {
    //   type: "divider",
    // },
  ]
}

function Header({ IS_ADMIN, tenantName, direction, Logo }) {
  const { isSidebarCollapsed } = useAppContext()
  const { logout } = useAuth()
  const { i18n, t } = useTranslation()

  const logOut = {
    key: "logout",
    label: <span onClick={logout}>{t("global.logout")}</span>,
    icon: <Icon type="logout" />,
  }

  const menuProps = {
    items: IS_ADMIN ? ADMIN_MENU() : DEFAULT_MENU(),
  }

  const mobileMenuProps = {
    items: IS_ADMIN
      ? [...MOBILE_ADMIN_MENU(), logOut]
      : [...MOBILE_DEFSULT_MENU(), logOut],
  }

  const langsItems = [
    { key: "en", label: "English" },
    { key: "ar", label: "العربية" },
  ]

  return (
    <Wrapper expanded={isSidebarCollapsed} direction={direction}>
      <div className="logo-wrapper">
        <div className="logo-vector" />
        <CustomLink to=" ">
          <img
            src={tenantName === "enwz" ? golfLogo : Logo ? Logo : NewLogo}
            alt="idataworkers logo"
            className="logoImg"
          />
        </CustomLink>
      </div>
      <Nav>
        {/* <RoundIconButton type="transparent" onClick={toggleTheme}>
          <Icon type="dark" size={16} />
        </RoundIconButton> */}
        {/* <Dropdown
          menu={menuProps}
          trigger={["click"]}
          overlayStyle={{ minWidth: "120px" }}
          className="setting-menu"
        >
          <RoundIconButton type="transparent">
            <Icon type="settings" />
          </RoundIconButton>
        </Dropdown> */}
        {/* 
        <RoundIconButton
          type="transparent"
          onClick={() =>
            navigate(`/${tenantName}/?tour=${IS_ADMIN ? "admin" : "default"}`)
          }
        >
          <Icon type="question" />
        </RoundIconButton> */}

        <Dropdown
          trigger={["click"]}
          menu={{
            items: langsItems,
            onClick: ({ key }) => i18n.changeLanguage(key),
            selectedKeys: [i18n.resolvedLanguage],
          }}
          placement="bottomRight"
        >
          <RoundIconButton type="transparent">
            <Icon type="global" />
          </RoundIconButton>
        </Dropdown>

        <Dropdown
          menu={mobileMenuProps}
          trigger={["click"]}
          className="mobile-menu"
        >
          <RoundIconButton type="transparent">
            <Icon type="menu" />
          </RoundIconButton>
        </Dropdown>
      </Nav>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: ${({ direction }) => (direction === "rtl" ? "unset" : 0)};
  left: ${({ direction }) => (direction === "rtl" ? 0 : "unset")};
  width: 100%;
  height: var(--header-height);
  z-index: 100;
  padding: 12px 32px;
  background-color: ${({ theme }) => theme.color.nav};
  border-bottom: 1px solid #ebedf1;
  transition: all 0.2s;

  .setting-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .logo-wrapper {
    opacity: 1;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    width: ${({ expanded }) =>
      expanded ? "calc(100% - 80px)" : "calc(100% - 240px)"};
    .setting-menu {
      display: block;
    }
    .mobile-menu {
      display: none;
    }
    .logo-wrapper {
      opacity: 0;
    }
  }
`

const Nav = styled.nav`
  ${({ theme }) => theme.mixins.flexAlignCenter};

  button {
    margin: 0 6px 0 0;
  }

  button:last-child {
    margin: 0;
  }
`

export default Header
