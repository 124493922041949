import React, { useEffect, useState } from "react"
import {
  useFavoriteDashboards,
  useUpdateFavoriteDashboard,
} from "hooks/dashboards"
import Text from "components/shared/Text"
import { Image, Result, Skeleton, Space, Tooltip, notification } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"
import EmptyFav from "static/images/empty/no-fav.svg"
import Button from "components/shared/Button"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import { useTranslation } from "react-i18next"
import styled, { keyframes } from "styled-components"

function LinkText({ href, children }) {
  return <CustomLink to={href || ""}>{children}</CustomLink>
}

const FavoriteDashboards = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useFavoriteDashboards()
  const { mutate, isLoading: favLoading } = useUpdateFavoriteDashboard()

  const [favoriteDashboards, setFavoriteDashboards] = useState([])
  const [removingId, setRemovingId] = useState(null)

  useEffect(() => {
    if (data && data.results) {
      setFavoriteDashboards(data.results)
    }
  }, [data])

  const handleUnFavDashboard = (id) => {
    setRemovingId(id) // Start the animation

    // Delay the actual removal from local state
    setTimeout(() => {
      // Remove the dashboard from the local state
      setFavoriteDashboards((prevDashboards) =>
        prevDashboards.filter((dash) => dash.id !== id)
      )

      mutate(
        {
          id,
          is_favorite: true,
        },
        {
          onSuccess: () => {
            setRemovingId(null) // Reset the animation state
          },
          onError: () => {
            // Revert the local state if the mutation fails
            setFavoriteDashboards((prevDashboards) => [
              ...prevDashboards,
              data.results.find((dash) => dash.id === id),
            ])
            setRemovingId(null) // Reset the animation state
            notification.error({
              message: t("dashboards.failedAddFav"),
            })
          },
        }
      )
    }, 500) // Match the duration of the animation
  }

  if (!isLoading && (isError || !data || data?.message)) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (favoriteDashboards.length === 0) {
    return (
      <EmptyWrapper id="fav">
        <Text size="xl" color="primary">
          {t("home.favDashboardPhase1")}
          <Text size="xl" color="brand">
            {t("home.favDashboardPhase2")}
          </Text>
          {t("home.favDashboardPhase3")}
        </Text>
        <Button size="sm">
          <LinkText href={`/dashboards`}>{t("home.addFav")}</LinkText>
        </Button>
        <Image
          width={200}
          src={EmptyFav}
          preview={false}
          alt="No Favorite Dashboard Yet!"
        />
      </EmptyWrapper>
    )
  }

  return (
    <List id="fav">
      <Skeleton loading={isLoading} active avatar>
        {favoriteDashboards.map((dash) => (
          <FavoriteDashboard
            key={dash.id}
            className={removingId === dash.id ? "removing" : ""}
          >
            <CustomLink to={`/dashboard/${dash.id}`} key={dash.id}>
              <Space>
                <Icon type="dashboardIcon" className="dashboardIcon" />
                <div className="dashboardTitle">
                  <span className="dashboardName">{dash.name}</span>
                  <span className="dashboardDesc">
                    {t("dashboards.dashboard")}
                  </span>
                </div>
              </Space>
            </CustomLink>
            <Tooltip title="Remove">
              <IconButton
                type="transparent"
                onClick={() => handleUnFavDashboard(dash.id)}
                loading={favLoading && removingId === dash.id}
                disabled={favLoading && removingId === dash.id}
              >
                <Icon type="starFilled" style={{ color: "#fbd208" }} />
              </IconButton>
            </Tooltip>
          </FavoriteDashboard>
        ))}
      </Skeleton>
    </List>
  )
}

const slideOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`

const FavoriteDashboard = styled.li`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  &.removing {
    animation: ${slideOutLeft} 0.5s forwards;
  }

  .dashboardIcon {
    font-size: 1.8rem;
  }

  .dashboardTitle {
    display: flex;
    flex-direction: column;

    .dashboardName {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .dashboardDesc {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 2rem;
  min-height: 5rem;
  border: 1px solid #e2e2e7;
  border-radius: 12px;
  margin-top: 1rem;
  max-height: 488px;
  overflow: auto;

  @media (min-width: 768px) {
    & {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
`

const EmptyWrapper = styled.div`
  /* background-color: #ecf5f8; */
  border: 1px solid #f1f1f2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;

  & .ant-typography {
    text-align: center;
  }
`

export default FavoriteDashboards
