import React, { useContext, useMemo } from "react"

interface SegmentAnalytics {
  page: (name?: string) => void
  reset: () => void
  alias: (newId: string) => void
  track: (name: string, properties: Record<string, unknown>) => void
  identify: (userId?: string, traits?: Record<string, unknown>) => void
  group: (organisationId: string, traits: Record<string, unknown>) => void
}
interface RudderStackAnalytics {
  page: (name?: string) => void
  reset: () => void
  ready: () => void
  load: () => void
  initialized: boolean
  alias: (newId: string) => void
  track: (name: string, properties: Record<string, unknown>) => void
  identify: (userId?: string, traits?: Record<string, unknown>) => void
  group: (organisationId: string, traits: Record<string, unknown>) => void
}

export type { SegmentAnalytics, RudderStackAnalytics }

export class AnalyticsService {
  constructor(private userId?: string, private version?: string) {}

  private getSegmentAnalytics = (): SegmentAnalytics | undefined =>
    window.analytics

  alias = (newId: string): void => this.getSegmentAnalytics()?.alias?.(newId)

  page = (name: string): void => this.getSegmentAnalytics()?.page?.(name)

  reset = (): void => this.getSegmentAnalytics()?.reset?.()

  track = (name: string, properties: Record<string, unknown>): void =>
    this.getSegmentAnalytics()?.track?.(name, {
      user_id: this.userId,
      ...properties,
      isync_version: this.version,
      environment: this.version === "dev" ? "dev" : "prod",
    })

  identify = (userId: string, traits: Record<string, unknown> = {}): void => {
    this.getSegmentAnalytics()?.identify?.(userId, traits)
  }

  group = (
    organisationId: string,
    traits: Record<string, unknown> = {}
  ): void => this.getSegmentAnalytics()?.group?.(organisationId, traits)
}

const analyticsServiceContext = React.createContext<AnalyticsService | null>(
  null
)

function AnalyticsServiceProvider({
  children,
  userId,
  version,
}: {
  children: React.ReactNode
  version?: string
  userId?: string
}) {
  const analyticsService: AnalyticsService = useMemo(
    () => new AnalyticsService(userId, version),
    [version, userId]
  )
  return (
    <analyticsServiceContext.Provider value={analyticsService}>
      {children}
    </analyticsServiceContext.Provider>
  )
}

export const useAnalytics = (): AnalyticsService => {
  const analyticsService = useContext(analyticsServiceContext)

  if (!analyticsService) {
    throw new Error(
      "analyticsService must be used within a AnalyticsServiceProvider."
    )
  }

  return analyticsService
}

export default React.memo(AnalyticsServiceProvider)
