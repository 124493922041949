import dotenv from "dotenv"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter as Router } from "react-router-dom"
import AppContextProvider from "context/AppContext"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import AuthProvider from "context/AuthContext"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import config from "config/analytics"
import SupportChat from "components/SupportChat"
import { AnalyticsLauncher } from "config/AnalyticsLauncher"
import "@icloudready/idataworkers-visualization/dist/index.css"

// import i18n (needs to be bundled ;))
import "./i18n"

dotenv.config()
// If we're in prod, boot up sentry
if (
  process.env.NODE_ENV === "production" &&
  process?.env?.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
})

// test

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <SupportChat idengagerchatConfig={config.idengagerchat} />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <AppContextProvider>
            <AnalyticsLauncher>
              <App />
            </AnalyticsLauncher>
          </AppContextProvider>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
