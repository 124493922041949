import styled from "styled-components"
import { Button as AntButton } from "antd"
import variants from "./IconButton.styles"
import { extract } from "lib/utils"

const keys = ["type", "shape", "size", "children"]

const IconButton = styled((props) => {
  const icon = props.children

  return <AntButton icon={icon} {...extract(props, keys)} />
})`
  ${({ size = "md" }) => variants.size[size]};
  ${({ type = "primary" }) => variants.type[type]};
  ${({ shape = "contained" }) => variants.shape[shape]};

  cursor: pointer;
  transition: all 0.3s;
  line-height: 0;
  background: var(--background);
  color: var(--color);
  width: var(--size);
  height: var(--size);
  font-size: var(--font-size);

  ${({ theme }) => theme.mixins.flexCenter};

  &:hover,
  &:focus,
  &:active {
    background: var(--background);
    color: var(--color);
  }

  svg {
    width: var(--font-size);
    height: var(--font-size);
  }
`

export function RoundIconButton(props) {
  return (
    <IconButton type="transparent" shape="round" key={props.id} {...props} />
  )
}

export default IconButton
