import { Result, Tabs } from "antd"
import styled from "styled-components"
import React from "react"
import { Outlet, useHref, useNavigate } from "react-router-dom"
import { useAuth } from "context/AuthContext"
import Button from "components/shared/Button"
import auth from "services/auth"

const Admin_Tabs = [
  {
    value: "admin/status",
    label: "System Status",
  },
  {
    value: "admin/queries/jobs",
    label: "RQ Status",
  },
  {
    value: "admin/queries/outdated",
    label: "Outdated Queries",
  },
  {
    value: "admin/organizations",
    label: "Organizations Management",
  },
  {
    value: "admin/organizations/data",
    label: "Organizations Data Management",
  },
]

const Admin = () => {
  const { session, tenantName } = useAuth()
  const IS_SUPER_ADMIN =
    session && session?.user?.permissions?.includes("super_admin")
  const IS_SUPER_ADMIN_TENANT =
    process.env.REACT_APP_SUPER_ADMIN_TENANT === auth.getTenantName()

  const navigate = useNavigate()
  const param = useHref()

  const onChangeTabs = (key) => {
    navigate(`${key}`)
  }

  const items = Admin_Tabs.map((a) => ({
    label: a.label,
    key: `/${tenantName}/${a.value}`,
    children: <Outlet />,
  }))

  if (!IS_SUPER_ADMIN || !IS_SUPER_ADMIN_TENANT) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="blue" shape="outlined" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    )
  }

  return (
    <Wrapper>
      <Tabs items={items} onTabClick={onChangeTabs} activeKey={param} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  /* background-color: #eff4f8; */
  padding: 16px;

  .ant-tabs-nav {
    background-color: #dbe8f4;
    height: 48px;
    padding: 0 0.1rem 0 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .ant-tabs-tab {
      /* font-size: 16px; */
      &:hover {
        color: #0097c2;
      }
    }

    .ant-tabs-ink-bar {
      border-bottom: 2px solid #0097c2;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #0097c2;
    }
  }
`

export default Admin
