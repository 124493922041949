import React from "react"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useCreateDataSource, useDataSourceTypes } from "hooks/dataSources"
import { useNavigate } from "react-router-dom"
import CreateForm from "components/shared/CreateForm/CreateForm"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import { PlusOutlined } from "@ant-design/icons"

const Createsources = () => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  const { data: dataSourceTypes, isLoading: loadingTypes } =
    useDataSourceTypes()
  const { mutate: createDataSource } = useCreateDataSource()

  const navigate = useNavigate()

  const onCreate = (data) => {
    createDataSource(
      {
        data,
      },
      {
        onSuccess: (newData) => {
          if (!newData.message) {
            navigate(`/${tenantName}/source/${newData.id}`)
          }
        },
      }
    )
  }

  return (
    <FormModal
      title={t("dataSource.newDataSource")}
      width={500}
      hasChanged={(name) => !!name}
      Form={CreateForm}
      formProps={{ Types: dataSourceTypes, onCreate: onCreate }}
      style={{ top: "1rem" }}
    >
      <Button type="primary" disabled={loadingTypes}>
        <PlusOutlined />
        {t("dataSource.newDataSource")}
      </Button>
    </FormModal>
  )
}

export default Createsources
