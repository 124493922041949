import React from "react"
import styled from "styled-components"
import AppForm from "components/Application/AppForm"
import ApplicationsdData from "components/Application/ApplicationsdData"
import { useTranslation } from "react-i18next"

const Applications = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className="header">
        <h1>{t("applications.applications")}</h1>
        <AppForm />
      </div>
      <ApplicationsdData />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
`

export default Applications
