class AppError extends Error {
  constructor(message = "", code = AppError.UNEXPECTED_ERROR, data = {}) {
    super(message)
    this.code = code
    this.data = data
  }
}

AppError.codes = {
  NETWORK_ERROR: "NETWORK_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  INVALID_PAGE_NUMBER: "PAGE_OUT_OF_RANGE",
  DASHBOARD_NOT_FOUND: "DASHBOARD_NOT_FOUND",
  REQUEST_NOT_FOUND: "REQUEST_NOT_FOUND",
}

export default AppError
