import client from "services/client"
import AppError from "lib/app-error"
import qs from "query-string"

const { QUERY_NOT_FOUND } = AppError.codes

async function getQueryResult(queryId) {
  const res = await client.get(`/queries/${queryId}/results`)

  if (res.status === 404) {
    throw new AppError("Query doesn't exist", QUERY_NOT_FOUND)
  }

  return res.data.query_result
}

async function getQuerySnippetsPage(options = {}) {
  const { page = 1, pageSize: page_size = 10, name: q } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
  })

  const res = await client.get(`/query_snippets?${params}`)
  return res.data
}

async function getQuerySnippets() {
  const res = await client.get(`/query_snippets`)
  return res.data
}

async function deleteQuerySnippets(id) {
  const res = await client.delete(`/query_snippets/${id}`)
  return res.data
}

async function createQuerySnippets(data) {
  const res = await client.post(`/query_snippets`, data)
  return res.data
}

async function updateQuerySnippets(id, data) {
  const res = await client.post(`/query_snippets/${id}`, data)
  return res.data
}

export {
  getQueryResult,
  getQuerySnippetsPage,
  getQuerySnippets,
  deleteQuerySnippets,
  createQuerySnippets,
  updateQuerySnippets,
}
