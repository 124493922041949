import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  addETLSource,
  deleteETLSource,
  getETLSourceDefination,
  getETLSourceList,
  getETLSources,
  getETLSourcesDefination,
  testETLSource,
} from "apis/ETL/sources"

const keys = {
  all: ["etl-sources"],
  list: () => [...keys.all, "list"],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  defination: () => [...keys.all, "defination"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useETLSources() {
  return useQuery({
    queryKey: keys.list(),
    // queryFn: () => getETLSourceList(),
    queryFn: () => getETLSourceList(),
    ...defaultQueryConfig,
  })
}

export function useETLSourcesDefination() {
  return useQuery({
    queryKey: keys.defination(),
    queryFn: () => getETLSourcesDefination(),
    ...defaultQueryConfig,
  })
}

export function useETLSourceDefination(id) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getETLSourceDefination(id),
    ...defaultQueryConfig,
  })
}

export function useCreateETLSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => addETLSource(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
    ...options,
  })
}

export function useDeleteETLSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id) => deleteETLSource(id),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
    ...options,
  })
}

export function useTestETLSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sourceId) => testETLSource(sourceId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}
