import { Dropdown, Space } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import useQueryParams from "hooks/useQueryParams"
import Icon from "components/shared/Icon/Icon"
import Button from "components/shared/Button/Button"
import { useDashboardState } from "../Provider"

function FormatTime(seconds) {
  const { t } = useTranslation()
  switch (seconds) {
    case 60:
      return `1 ${t("global.minute")}`
    case 300:
      return `5 ${t("global.minutes")}`
    case 600:
      return `10 ${t("global.minutes")}`
    case 1800:
      return `30 ${t("global.minutes")}`
    case 3600:
      return `1 ${t("global.hour")}`
    case 43200:
      return `12 ${t("global.hours")}`
    case 86400:
      return `1 ${t("global.day")}`
    default:
      return t("global.refresh")
  }
}

const RefreshSchedule = () => {
  const [queryParams, setQueryParams] = useQueryParams({ refresh: Number })
  const { setShouldWidgetUpdate } = useDashboardState()
  const { t } = useTranslation()

  const handleButtonClick = () => {
    setShouldWidgetUpdate(true)
  }
  const handleMenuClick = (e) => {
    if (!parseInt(e.key)) {
      setQueryParams({ refresh: e.key }, "delete")
    } else {
      setQueryParams({ refresh: e.key }, "push")
      setShouldWidgetUpdate(true)
    }
  }

  const items = [
    { label: `1 ${t("global.minute")}`, key: 60 },
    { label: `5 ${t("global.minutes")}`, key: 300 },
    { label: `10 ${t("global.minutes")}`, key: 600 },
    { label: `30 ${t("global.minutes")}`, key: 1800 },
    { label: `1 ${t("global.hour")}`, key: 3600 },
    { label: `12 ${t("global.hours")}`, key: 43200 },
    { label: `1 ${t("global.day")}`, key: 86400 },
  ]

  const items2 = [
    { label: `1 ${t("global.minute")}`, key: 60 },
    { label: `5 ${t("global.minutes")}`, key: 300 },
    { label: `10 ${t("global.minutes")}`, key: 600 },
    { label: `30 ${t("global.minutes")}`, key: 1800 },
    { label: `1 ${t("global.hour")}`, key: 3600 },
    { label: `12 ${t("global.hours")}`, key: 43200 },
    { label: t("global.disableRefresh"), key: null },
  ]

  const menuProps = {
    items: !queryParams?.refresh ? items : items2,
    onClick: handleMenuClick,
  }

  return (
    <Space.Compact>
      <Button type="primary" size="xs" onClick={handleButtonClick}>
        <Icon type="sync" />
        {FormatTime(queryParams.refresh)}
      </Button>
      <Dropdown trigger={["click"]} menu={menuProps}>
        <Button
          type="primary"
          size="xs"
          style={{
            borderLeft: "1px solid hsla(209, 53%, 91%, 1)",
          }}
        >
          <Icon type="DownArrow" />
        </Button>
      </Dropdown>
    </Space.Compact>
  )
}

export default RefreshSchedule
