import client from "services/client"

async function getOrganization() {
  const res = await client.get(`/settings/organization`)
  return res.data.settings
}

async function updateOrganization(data) {
  const res = await client.post(`/settings/organization`, data)
  return res.data
}

export { getOrganization, updateOrganization }
