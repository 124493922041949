import i18n from "i18next"

export const tours = (navigate, tenantName, isHomeDashboard, userName) => ({
  admin: [
    {
      title: (
        <p>
          {i18n.t("onboarding.welcomeTitle")}{" "}
          <span>iDateWorkers {userName}</span>
        </p>
      ),
      description: i18n.t("onboarding.welcomeDesc"),
      arrow: false,
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.addWidgetTitle")} 1/4`,
      description: i18n.t("onboarding.addWidgetDesc"),
      target: "#addWidgetCard",
      placement: "right",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.homeFavTitle")} 2/4`,
      description: i18n.t("onboarding.homeFavDesc"),
      target: "#fav",
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.homeAppTitle")} 3/4`,
      description: i18n.t("onboarding.homeAppDesc"),
      target: "#homeApp",
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.addDashboardTitle")} 4/4`,
      description: i18n.t("onboarding.addDashboardDesc"),
      target: "#newDashboard",
      placement: "bottom",
      nextButtonProps: {
        children: <span>{i18n.t("global.finish")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
  ],
  default: [
    {
      title: (
        <p>
          {i18n.t("onboarding.welcomeTitle")}{" "}
          <span>iDateWorkers {userName}</span>
        </p>
      ),
      description: i18n.t("onboarding.welcomeDesc"),
      arrow: false,
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.addWidgetTitle")} 1/3`,
      description: i18n.t("onboarding.addWidgetDesc"),
      target: "#addWidgetCard",
      placement: "right",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.homeFavTitle")} 2/3`,
      description: i18n.t("onboarding.homeFavDesc"),
      target: "#fav",
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.next")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
    {
      title: `${i18n.t("onboarding.homeAppTitle")} 3/3`,
      description: i18n.t("onboarding.homeAppDesc"),
      target: "#homeApp",
      placement: "top",
      nextButtonProps: {
        children: <span>{i18n.t("global.finish")}</span>,
      },
      prevButtonProps: {
        children: <span>{i18n.t("global.previous")}</span>,
      },
    },
  ],
})
