import { useQuery } from "react-query"
import client from "services/client"

const queryKey = () => ["session"]

const getSession = async () => {
  const res = await client.get("/session")
  return res.data
}

function useSession(options = {}) {
  return useQuery({
    queryKey: queryKey(),
    queryFn: () => getSession(),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  })
}

useSession.queryKey = queryKey
useSession.queryFn = getSession

export default useSession
