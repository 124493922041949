import { useParams } from "react-router-dom"

function typed(source, types) {
  const result = {}

  for (let [key, value] of Object.entries(source)) {
    const Type = types[key]
    if (typeof Type === "function") {
      result[key] = Type(value)
    } else {
      result[key] = value
    }
  }

  return result
}

function useTypedParams(types) {
  const params = useParams()

  return typed(params, types)
}

export default useTypedParams
