import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import client from "services/client"
import { useAuth } from "context/AuthContext"
import useQueryParams from "./useQueryParams"
import { useDashboardState } from "components/dashboards/Provider"

function isObjEmpty(obj) {
  return JSON.stringify(obj) === "{}"
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function getQueryParameters(widget, dashboardParam) {
  const result = {}
  const parameterMappings = widget?.options?.parameterMappings
  const parameters = widget?.visualization?.query.options?.parameters
  if (parameterMappings && !isObjEmpty(parameterMappings) && dashboardParam) {
    for (const param of parameters) {
      const mapping = parameterMappings[param?.name]
      if (mapping && mapping.type === "dashboard-level") {
        const mapToValue = mapping.mapTo || mapping.name
        if (dashboardParam.hasOwnProperty(mapToValue)) {
          let value = dashboardParam[mapToValue]
          if (value === "d_now") {
            value = dayjs().format("YYYY-MM-DD")
          } else if (value === "d_yesterday") {
            value = dayjs().subtract(1, "day").format("YYYY-MM-DD")
          }
          result[param.name] = value
        } else {
          // handle error if mapToValue not found in dashboardParam
        }
      } else {
        let value = param.value
        if (value === "d_now") {
          value = dayjs().format("YYYY-MM-DD")
        } else if (value === "d_yesterday") {
          value = dayjs().subtract(1, "day").format("YYYY-MM-DD")
        }
        result[param.name] = value
      }
    }
  } else {
    for (const param of parameters) {
      let value = param.value
      if (value === "d_now") {
        value = dayjs().format("YYYY-MM-DD")
      } else if (value === "d_yesterday") {
        value = dayjs().subtract(1, "day").format("YYYY-MM-DD")
      }
      result[param.name] = value
    }
  }
  return result
}

function formatDate(date, format, type) {
  if (type === "PIVOT") {
    const d = date?.data
    for (const column of d.columns) {
      if (column.type === "datetime") {
        for (const row of d.rows) {
          const datetimeValue = row[column.name]
          const formattedDate = dayjs(datetimeValue).format(format)
          row[column.name] = formattedDate
        }
      }
    }
  }

  return date
}

const useGetVisualizationData = ({ widget, dashboardParameters }) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [error, setError] = useState("")
  const [timer, setTimer] = useState(0)

  const [queryParams] = useQueryParams({ refresh: Number })
  const { session } = useAuth()
  const dateTimeFormat = session?.client_config?.dateTimeFormat

  const { shouldWidgetUpdate, setShouldWidgetUpdate } = useDashboardState()

  const { query, type } = widget.visualization
  const params = getQueryParameters(
    widget,
    Object.values(dashboardParameters).reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.name]:
            item.value === "d_now"
              ? dayjs().format("YYYY-MM-DD")
              : item.value === "d_yesterday"
              ? dayjs().subtract(1, "day").format("YYYY-MM-DD")
              : item.value,
        }),
      {}
    )
  )

  const fetchData = async () => {
    try {
      setIsLoading(true)
      setError(null)
      let res
      const startTime = dayjs()
      const timerInterval = setInterval(() => {
        const currentTime = dayjs()
        const elapsedSeconds = currentTime.diff(startTime, "second")
        setTimer(elapsedSeconds)
      }, 1000)
      if (query.latest_query_data_id && isObjEmpty(params)) {
        res = await client.get(
          `/queries/${query.id}/results/${query.latest_query_data_id}.json`
        )
      } else {
        const { data } = await client.post(`/queries/${query.id}/results`, {
          id: query.id,
          ...query.options,
          parameters: params,
        })
        if (data) {
          if (data.job) {
            let result = data.job.result
            let error = data.job.error
            let numRequests = 0
            let waitTime = 3000
            while (!result && !error) {
              numRequests++
              const r = await client.get(`/jobs/${data.job.id}`)
              result = r.data.job.result
              error = r.data.job.error
              if (numRequests > 10 && numRequests < 20) {
                waitTime = 8000
              } else if (numRequests > 20) {
                waitTime = 10000
              }
              await sleep(waitTime)
            }

            if (result) {
              const queryResultBody = await client.get(
                `/query_results/${result}`
              )
              setData(
                formatDate(
                  queryResultBody.data.query_result,
                  dateTimeFormat,
                  type
                )
              )
              setIsLoading(false)
              setError("")
              clearInterval(timerInterval)
            }

            if (error) {
              clearInterval(timerInterval)
              throw new Error(error)
            }
          } else if (data.message) {
            clearInterval(timerInterval)
            throw new Error(data.message)
          } else {
            clearInterval(timerInterval)
            res = { data }
          }
        } else {
          clearInterval(timerInterval)
          throw new Error("Server error")
        }
      }

      if (res) {
        setData(formatDate(res.data.query_result, dateTimeFormat, type))
        setIsLoading(false)
        clearInterval(timerInterval)
      }
    } catch (error) {
      setData(null)
      setIsLoading(false)
      setError(error.message)
    }
  }

  const updateData = async (parameters = params) => {
    try {
      setIsUpdateLoading(true)
      setError("")
      const startTime = dayjs()
      const timerInterval = setInterval(() => {
        const currentTime = dayjs()
        const elapsedSeconds = currentTime.diff(startTime, "second")
        setTimer(elapsedSeconds)
      }, 1000)
      const { data } = await client.post(`/queries/${query.id}/results`, {
        id: query.id,
        max_age: 0,
        parameters,
      })
      if (data) {
        if (data.job) {
          let result = data.job.result
          let error = data.job.error
          let numRequests = 0
          let waitTime = 3000
          while (!result && !error) {
            numRequests++
            const response = await client.get(`/jobs/${data.job.id}`)
            result = response.data.job.result
            error = response.data.job.error
            if (numRequests > 10 && numRequests < 20) {
              waitTime = 8000
            } else if (numRequests > 20) {
              waitTime = 10000
            }
            await sleep(waitTime)
          }
          if (result) {
            const queryResultBody = await client.get(`/query_results/${result}`)
            setData(
              formatDate(
                queryResultBody.data.query_result,
                dateTimeFormat,
                type
              )
            )
          } else if (error) {
            clearInterval(timerInterval)
            throw new Error(error)
          }
        } else {
          clearInterval(timerInterval)

          setData(formatDate(data.query_result, dateTimeFormat, type))
        }
      } else {
        clearInterval(timerInterval)

        throw new Error("Server Error")
      }
      setIsUpdateLoading(false)
      clearInterval(timerInterval)
    } catch (error) {
      setIsUpdateLoading(false)
      setError(error.message)
      setData(null)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (queryParams.refresh) {
      const intervalId = setInterval(updateData, queryParams.refresh * 1000)
      return () => clearInterval(intervalId)
    }
  }, [queryParams])

  useEffect(() => {
    if (shouldWidgetUpdate) {
      updateData()
      setShouldWidgetUpdate(false)
    }
  }, [shouldWidgetUpdate])

  return [data, isLoading, isUpdateLoading, error, updateData, params, timer]
}

export default useGetVisualizationData
