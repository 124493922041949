import client from "services/client"
import { useMutation } from "react-query"
import dayjs from "dayjs"
import React from "react"

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

async function getRequestData(
  request,
  requestParameters,
  query,
  requestType,
  abortControllerRef,
  setQueryTimer
) {
  const startTime = dayjs()
  abortControllerRef.current = new AbortController()
  let res
  if (requestType === "run") {
    res = await client.post(
      `/queries/${request.id}/results`,
      {
        apply_auto_limit: false,
        data_source_id: request.data_source_id,
        max_age: 0,
        query: query,
        parameters: requestParameters?.reduce((acc, curr) => {
          acc[curr.name] =
            curr.value === "d_now"
              ? new Date().toISOString().split("T")[0]
              : curr.value === "d_yesterday"
              ? new Date(Date.now() - 86400000).toISOString().split("T")[0]
              : curr.value
          return acc
        }, {}),
      },
      {
        signal: abortControllerRef.current.signal,
      }
    )
  } else if (requestType === "execute") {
    res = await client.post(
      `/query_results`,
      {
        apply_auto_limit: false,
        data_source_id: request.data_source_id,
        max_age: 0,
        query: query,
        parameters: requestParameters?.reduce((obj, item) => {
          let value
          if (item.value === "d_now") {
            value = new Date().toISOString().split("T")[0]
          } else if (item.value === "d_yesterday") {
            value = new Date(Date.now() - 86400000).toISOString().split("T")[0]
          } else {
            value = item.value
          }

          if (item.type === "query" || item.type === "enum") {
            if (item.multiValuesOptions && Array.isArray(item.value)) {
              value =
                item.multiValuesOptions.prefix === '"'
                  ? `"${item.value.join('", "')}"`
                  : item.multiValuesOptions.prefix === "'"
                  ? `'${item.value.join("', '")}'`
                  : `${item.value.join(", ")}`
            }
          }

          return { ...obj, [item.name]: value }
        }, {}),
      },
      {
        signal: abortControllerRef.current.signal,
      }
    )
  }
  if (res.data.job) {
    let result = res.data.job.result
    let error = res.data.job.error
    let numRequests = 0
    let waitTime = 2000
    const timerInterval = setInterval(() => {
      const currentTime = dayjs()
      const elapsedSeconds = currentTime.diff(startTime, "second")
      setQueryTimer(elapsedSeconds)
    }, 1000)
    while (!result && !error) {
      numRequests++
      res = await client.get(`/jobs/${res.data.job.id}`, {
        signal: abortControllerRef.current.signal,
      })
      result = res.data.job.result
      error = res.data.job.error
      if (numRequests > 10) {
        waitTime = 8000
      }
      // if (numRequests * waitTime >= 180000) {
      //   return { error: "Error running Request: Timeout exceeded." }
      //   break
      // }
      await sleep(waitTime)
    }
    if (result) {
      res = await client.get(`/query_results/${result}`, {
        signal: abortControllerRef.current.signal,
      })
      clearInterval(timerInterval)
      return res.data.query_result
    } else if (error) {
      clearInterval(timerInterval)
      throw new Error(error)
    } else {
      clearInterval(timerInterval)
      throw new Error("Something went wrong")
    }
  } else if (res.data.message) {
    throw new Error(res.data.message)
  }
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useRequestData(options = {}) {
  const abortControllerRef = React.useRef(null)
  const [queryTimer, setQueryTimer] = React.useState(0)

  const mutation = useMutation(
    ({ request, requestParameters, query, requestType }) =>
      getRequestData(
        request,
        requestParameters,
        query,
        requestType,
        abortControllerRef,
        setQueryTimer
      ),
    {
      ...options,
      ...defaultQueryConfig,
      onSuccess: (request) => {
        setQueryTimer(0)
      },
      onError: (request) => {
        setQueryTimer(0)
      },
    }
  )

  const reset = React.useCallback(() => {
    abortControllerRef.current?.abort()
    mutation.reset()
    setQueryTimer(0)
  }, [mutation.reset])

  return { ...mutation, reset, queryTimer }
}

export default useRequestData
