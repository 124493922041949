import React from "react"
import styled from "styled-components"
import Footer from "../CreateAgentFooter"
import { useCreateAgentContext } from "context/CreateAgentContext"
import { Select, Form, Input } from "antd"
import { t } from "i18next"
import { useTranslation } from "react-i18next"

function ChooseTemplate() {
  const { t } = useTranslation()
  const {
    template,
    onChangeTemplate,
    agentOptions,
    setAgentOptions,
    onNextStep,
    onPreviousStep,
  } = useCreateAgentContext()

  return (
    <Wrapper layout="vertical">
      <Form.Item label={t("agents.template")} name="template">
        <Select
          defaultValue={template}
          options={[
            {
              value: "default",
              label: t("agents.defaultTemplate"),
            },
            {
              value: "custom",
              label: t("agents.customTemplate"),
            },
          ]}
          onChange={onChangeTemplate}
        />
        {template === "custom" && (
          <CustomTemplate layout="vertical">
            <Form layout="vertical">
              <Form.Item label={t("agents.subject")}>
                <Input
                  defaultValue={agentOptions?.custom_subject}
                  onChange={(e) =>
                    setAgentOptions((current) => ({
                      ...current,
                      custom_subject: e.target.value,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item label={t("agents.body")}>
                <Input.TextArea
                  defaultValue={agentOptions?.custom_body}
                  onChange={(e) =>
                    setAgentOptions((current) => ({
                      ...current,
                      custom_body: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Form>
          </CustomTemplate>
        )}
      </Form.Item>

      <Footer
        onNext={onNextStep}
        onPrevious={onPreviousStep}
        className="footer"
      />
    </Wrapper>
  )
}

const Wrapper = styled(Form)`
  width: 80%;
  margin: auto;
`

const CustomTemplate = styled(Form)`
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #fbfbfb;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
  /* max-width: 500px; */
`

export default ChooseTemplate
