import React from "react"
import styled from "styled-components"
import Icon from "components/shared/Icon"
import { RoundIconButton } from "components/shared/IconButton"
import Text from "components/shared/Text"
import { Select } from "components/shared/Input"
import { Select as AntSelect, Spin } from "antd"
import { groupViz } from "./WidgetForm.utils"
import { Vis } from "@icloudready/idataworkers-visualization"
import useGetRequestData from "hooks/useGetRequestData"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const { Option, OptGroup } = AntSelect

function ConfigWidget(props) {
  const { t } = useTranslation()
  const {
    selectedRequest,
    selectedVis,
    setSelectedVis,
    onRemoveRequest,
    IS_COUNTER,
  } = props

  let parameters = selectedRequest.options?.parameters
  const params = (parameters = parameters?.reduce((x, param) => {
    return {
      ...x,
      [param.name]:
        param.value === "d_now" ? dayjs().format("YYYY-MM-DD") : param.value,
    }
  }, {}))

  // const { data, isLoading } = useRunRequest(selectedRequest.id, {
  //   apply_auto_limit: false,
  //   id: selectedRequest.id,
  //   max_age: 0,
  //   parameters,
  // })

  const [data, isLoading, error] = useGetRequestData({
    request: selectedRequest,
    RequestParameters: selectedRequest?.options?.parameters || [],
  })

  const onChangeVis = (id) => {
    setSelectedVis(selectedRequest?.visualizations.find((v) => v?.id === id))
  }

  return (
    <Wrapper>
      <RequestName>
        <Text size="xs">{selectedRequest?.name}</Text>
        <RoundIconButton size="sm" onClick={onRemoveRequest}>
          <Icon type="close" />
        </RoundIconButton>
      </RequestName>

      <RequestVisualization>
        <Text size="xs" as="p">
          {t("dashboards.chooseVis")}
        </Text>

        <Select
          onChange={onChangeVis}
          placeholder={
            IS_COUNTER
              ? "Select Only Counter Visualization"
              : "Select Visualization"
          }
        >
          {groupViz(selectedRequest?.visualizations)
            .filter(([type]) => (IS_COUNTER ? type === "COUNTER" : true))
            .map(([type, list], index) => (
              <OptGroup key={index} label={type}>
                {list.map((viz) => (
                  <Option value={viz.id} key={viz.id}>
                    {viz.name}
                  </Option>
                ))}
              </OptGroup>
            ))}
        </Select>
      </RequestVisualization>

      <RequestVis>
        {selectedRequest?.visualizations?.length === 0 ? (
          <p style={{ color: "red", textAlign: "center" }}>
            Selected Request dosn't have Visualization
          </p>
        ) : null}
        {isLoading ? (
          <Spin style={{ display: "block", margin: "auto" }} />
        ) : null}
        {data && selectedVis && params ? (
          <Vis
            key={selectedVis.id}
            data={data}
            widget={{
              visualization: selectedVis,
            }}
            params={params}
          />
        ) : null}
        {!isLoading && !data && error ? (
          <p style={{ color: "red", textAlign: "center" }}>
            🚫Faild Load request data
          </p>
        ) : null}
      </RequestVis>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 68vh;
  overflow: hidden;
`

const RequestName = styled.div`
  ${({ theme }) => theme.mixins.flexSpaceBetween};
  padding: 8px 16px;
  border-radius: var(--border-radius-0);
  border: 1px solid #dbe8f4;
  margin: 0 0 24px;
`

const RequestVisualization = styled.div`
  margin: 0 0 16px;

  p {
    margin: 0 0 12px;
  }

  ${Select} {
    width: 100%;
  }
`

const RequestVis = styled.div`
  height: 70%;
  overflow: auto;

  .widget-chart {
    height: 100%;
  }
`

export default ConfigWidget
