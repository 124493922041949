import React from "react"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useCreateDestination, useDestinationsTypes } from "hooks/destinations"
import { useNavigate } from "react-router-dom"
import CreateForm from "components/shared/CreateForm/CreateForm"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

const CreateChannels = () => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  const { data: destinationTypes, isLoading: loadingTypes } =
    useDestinationsTypes()
  const { mutate: createDestination, isLoading: createLoading } =
    useCreateDestination()

  const navigate = useNavigate()

  const onCreate = (data) => {
    createDestination(
      {
        data,
      },
      {
        onSuccess: (newData) => {
          navigate(`/${tenantName}/settings/channels/${newData.id}`)
        },
      }
    )
  }

  return (
    <FormModal
      title={t("channels.newChannels")}
      width={500}
      hasChanged={(name) => !!name}
      Form={CreateForm}
      formProps={{
        Types: destinationTypes,
        onCreate: onCreate,
        createLoading: createLoading,
      }}
      style={{ top: "1rem" }}
    >
      <Button type="primary" disabled={loadingTypes}>
        {t("channels.newChannels")}
      </Button>
    </FormModal>
  )
}

export default CreateChannels
