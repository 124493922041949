import React from "react"
import useResizeObserver from "use-resize-observer"
import ReactGridLayout from "react-grid-layout"
import { generateGridBackground } from "./GridLayout.utils"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

function GridLayout({
  isEditing = false,
  layout,
  margin = [15, 15],
  cols = 6,
  rowHeight = 25,
  width,
  height,
  children,
  ...delegated
}) {
  const cellSize = React.useMemo(() => {
    const marginSlotsCount = cols - 1
    const horizontalMargin = margin[0]
    const totalHorizontalMargin = marginSlotsCount * horizontalMargin
    const freeSpace = width - horizontalMargin * 2 - totalHorizontalMargin
    return {
      width: freeSpace / cols,
      height: rowHeight,
    }
  }, [cols, margin, rowHeight, width])

  const background = React.useMemo(
    () =>
      generateGridBackground({
        cellSize,
        width,
        cols,
        margin,
      }),
    [cellSize, width, cols, margin]
  )

  const style = {
    background: isEditing ? background : "",
    direction: "ltr",
  }

  let responsiveCols = cols
  if (width < 768) {
    // Small devices
    responsiveCols = 1
  }

  return (
    <ReactGridLayout
      key={responsiveCols}
      cols={responsiveCols}
      margin={margin}
      layout={layout}
      rowHeight={rowHeight}
      isResizable={isEditing}
      isDraggable={isEditing}
      style={style}
      width={width}
      {...delegated}
    >
      {children}
    </ReactGridLayout>
  )
}

export default function (props) {
  const { ref, width, height } = useResizeObserver()

  return (
    <div ref={ref}>
      {width && <GridLayout width={width} height={height} {...props} />}
    </div>
  )
}
