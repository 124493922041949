import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { tours } from "./GlobalTour.constants"
import { Tour } from "antd"

const GlobalTour = ({ tenantName, isHomeDashboard, userName }) => {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const tour = searchParams.get("tour")
  const steps =
    tour && tours(navigate, tenantName, isHomeDashboard, userName)[tour]

  const handleClose = () => {
    navigate(`/${tenantName}`)
  }

  return (
    <>
      <Tour
        open={!!tour}
        steps={steps
          ?.filter((step) => !step?.disable)
          ?.map((step) => {
            return {
              ...step,
              target: () => document.querySelector(step.target),
              // mask: {
              //   style: {
              //     boxShadow: 'inset 0 0 15px #fff',
              //   },
              //   color: 'transparent',
              // },
            }
          })}
        onClose={handleClose}
        placement="center"
        scrollIntoViewOptions={false}
      />
    </>
  )
}

export default GlobalTour
