import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import dayjs from "dayjs"
import useObjectMemo from "hooks/useObjectMemo"
import {
  useDeleteVisualization,
  useRequestResult,
  useRunRequest,
  useUpdateRequest,
  useUpdateRequestResult,
} from "hooks/requests"
import { notification } from "antd"
import { useTranslation } from "react-i18next"

const Array_regex =
  /^\s*\[\s*(?:"[^"]*"|'[^']*'|\d+)\s*(?:,\s*(?:"[^"]*"|'[^']*'|\d+)\s*)*\]\s*$/

const Object_regex = /^\s*\{[\s\S]*\}\s*$/

const RequestStateContext = createContext()

function RequestStateProvider({ request, isRefetching, children }) {
  const { t } = useTranslation()
  const [RequestParameters, setRequestParameters] = useState([])
  const [updatedParameters, setUpdatedParameters] = useState([])
  const [isLoadingReqParam, setIsLoadingReqParam] = useState(true)
  const [shouldWidgetUpdate, setShouldWidgetUpdate] = useState(false)
  const [requestData, setRequestData] = useState(null)
  const [requestError, setRequestError] = useState(null)
  const [isDrillDown, setIsDrillDown] = useState(false)

  const { value: memoizedRequest } = useObjectMemo(request)

  const { mutate: updateRequest, isLoading: updateLoading } = useUpdateRequest()
  const { mutate: runRequest, isLoading: isLoadingRunRequest } = useRunRequest()
  const { mutate: deleteVis } = useDeleteVisualization()
  const { mutate: updateDate, isLoading: updateDataLoading } =
    useUpdateRequestResult()

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const hash = location.hash
  const customURLFlag = useRef(true)

  useEffect(() => {
    const { options } = request
    let parameters = options?.parameters || []
    const search = window.location.search.replace(/&amp;/g, "%26")
    const params = new URLSearchParams(search)
    if (params.toString() && customURLFlag.current) {
      // User entered page with custom URL parameters
      const newRequestParameters = []
      parameters.forEach((param) => {
        const paramName = `p_${param.name}`
        let paramValue = params.get(paramName)
        if (paramValue !== null) {
          if (/\{\{.*\}\}/.test(paramValue)) {
            console.warn(`Invalid URL parameter value: ${paramValue}`)
            return
          }

          let decodedValue
          if (Array_regex.test(paramValue)) {
            decodedValue = JSON.parse(decodeURIComponent(paramValue)).map(
              (val) => val.replace(/&/g, "-amp;")
            )
          } else if (Object_regex.test(paramValue)) {
            decodedValue = JSON.parse(decodeURIComponent(paramValue))
          } else {
            decodedValue = decodeURIComponent(paramValue).replace(/&/g, "-amp;")
          }
          newRequestParameters.push({ ...param, value: decodedValue })
        }
      })
      setIsDrillDown(true)
      setRequestParameters(newRequestParameters)
      setUpdatedParameters(newRequestParameters)
      setIsLoadingReqParam(false)
      customURLFlag.current = false
    } else {
      // User entered page without custom URL parameters
      const newRequestParameters = []
      parameters.forEach((param) => {
        const paramName = `p_${param.name}`
        let paramValue = param.value
        if (Array.isArray(paramValue)) {
          paramValue = JSON.stringify(paramValue.map(encodeURIComponent))
        } else if (
          Object.prototype.toString.call(paramValue) === "[object Object]"
        ) {
          paramValue = JSON.stringify(paramValue)
        } else {
          paramValue = encodeURIComponent(paramValue)
        }
        params.set(paramName, paramValue)

        let decodedValue
        if (Array_regex.test(paramValue)) {
          decodedValue = JSON.parse(decodeURIComponent(paramValue)).map((val) =>
            val.replace(/&/g, "-amp;")
          )
        } else if (Object_regex.test(paramValue)) {
          decodedValue = JSON.parse(decodeURIComponent(paramValue))
        } else {
          decodedValue = decodeURIComponent(paramValue).replace(/&/g, "-amp;")
        }
        newRequestParameters.push({ ...param, value: decodedValue })
      })
      setRequestParameters(newRequestParameters)
      setUpdatedParameters(newRequestParameters)
      setSearchParams(params)
      setIsLoadingReqParam(false)
      customURLFlag.current = false
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}${
          window.location.hash
        }`
      )
    }
    window.location.hash = hash || 1
  }, [request])

  // useEffect(() => {
  //   const { options } = memoizedRequest
  //   let parameters = options?.parameters || []
  //   if (parameters) {
  //     const params = new URLSearchParams(window.location.search)

  //     const newRequestParameters = []
  //     parameters.forEach((param) => {
  //       const paramName = `p_${param.name}`
  //       let paramValue = param.value
  //       if (Array.isArray(paramValue)) {
  //         paramValue = JSON.stringify(paramValue.map(encodeURIComponent))
  //       } else if (
  //         Object.prototype.toString.call(paramValue) === "[object Object]"
  //       ) {
  //         paramValue = JSON.stringify(paramValue)
  //       } else {
  //         paramValue = encodeURIComponent(paramValue)
  //       }

  //       // Check if the parameter is already in the URL
  //       if (!params.has(paramName)) {
  //         params.set(paramName, paramValue)
  //       }

  //       if (/\{\{.*\}\}/.test(params.get(paramName))) {
  //         console.warn(`Invalid URL parameter value: ${paramValue}`)
  //         return
  //       }

  //       let decodedValue
  //       if (params.has(paramName)) {
  //         // Decode the parameter value if it was encoded
  //         decodedValue = Array_regex.test(params.get(paramName))
  //           ? JSON.parse(decodeURIComponent(params.get(paramName))).map(
  //             decodeURIComponent
  //           )
  //           : Object_regex.test(params.get(paramName))
  //             ? JSON.parse(decodeURIComponent(params.get(paramName)))
  //             : decodeURIComponent(params.get(paramName))
  //       } else {
  //         decodedValue = Array.isArray(paramValue)
  //           ? JSON.parse(decodeURIComponent(paramValue)).map(decodeURIComponent)
  //           : Object.prototype.toString.call(paramValue) === "[object Object]"
  //             ? JSON.parse(decodeURIComponent(paramValue))
  //             : decodeURIComponent(paramValue)
  //       }
  //       newRequestParameters.push({ ...param, value: decodedValue })
  //     })
  //     setRequestParameters(newRequestParameters)
  //     setUpdatedParameters(newRequestParameters)
  //     setSearchParams(new URLSearchParams(params.toString()))
  //     window.location.hash = hash || memoizedRequest.visualizations[0].id
  //     setIsLoadingReqParam(false)
  //   }
  // }, [memoizedRequest])

  const { isFetching: getDataLoading } = useRequestResult(
    memoizedRequest,
    RequestParameters,
    isLoadingReqParam,
    {
      onSuccess: (data) => {
        if (data.error) {
          setRequestError(data.error)
        } else {
          setRequestData(data)
        }
      },
      onError: (error) => {
        setRequestError(error)
      },
      enable: !isLoadingReqParam,
    }
  )

  const onUpdateData = React.useCallback(
    (params = RequestParameters) => {
      updateDate(
        {
          id: memoizedRequest.id,
          newdata: {
            id: memoizedRequest.id,
            parameters: params?.reduce((acc, curr) => {
              acc[curr.name] =
                curr.value === "d_now"
                  ? new Date().toISOString().split("T")[0]
                  : curr.value === "d_yesterday"
                  ? new Date(Date.now() - 86400000).toISOString().split("T")[0]
                  : curr.value
              return acc
            }, {}),
            apply_auto_limit: false,
            max_age: 0,
          },
        },
        {
          onSuccess: (data) => {
            if (data.error) {
              setRequestData(null)
              setRequestError(data.error)
            } else {
              setRequestError(null)
              setRequestData(data)
            }
            notification.success({
              message: t("requests.dataRefreshed"),
              duration: 2,
              placement: "bottomRight",
            })
          },
          onError: (error) => {
            setRequestError(error)
            notification.error({
              message: t("requests.faildDataRefreshed"),
              duration: 2,
              placement: "bottomRight",
            })
          },
        }
      )
    },
    [RequestParameters, memoizedRequest.id, updateDate]
  )

  const onTogglePublish = React.useCallback(() => {
    updateRequest(
      {
        requestId: memoizedRequest.id,
        data: { is_draft: !memoizedRequest.is_draft },
      },
      {
        onSuccess: (request) => {
          if (request.is_draft === true) {
            notification.warning({
              message: t("requests.requestUnpublish"),
              duration: 2,
              placement: "bottomRight",
            })
          } else if (request.is_draft === false) {
            notification.success({
              message: t("requests.requestPublish"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("requests.somethingWrong"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
      }
    )
  }, [memoizedRequest.is_draft])

  const onToggleArchive = React.useCallback(() => {
    updateRequest(
      {
        requestId: memoizedRequest.id,
        data: { is_archived: !memoizedRequest.is_archived },
      },
      {
        onSuccess: (request) => {
          if (request.is_archived === true) {
            notification.warning({
              message: t("requests.requestArchive"),
              duration: 2,
              placement: "bottomRight",
            })
          } else if (request.is_archived === false) {
            notification.success({
              message: t("requests.requestUnArchive"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("requests.somethingWrong"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
      }
    )
  }, [memoizedRequest.is_archived])

  const onAddTags = React.useCallback(
    (tags, callback) => {
      updateRequest(
        {
          requestId: memoizedRequest.id,
          data: { tags: tags },
        },
        {
          onSuccess: () => {
            callback()
          },
        }
      )
    },
    [memoizedRequest.tags]
  )

  const onUpdateSchedule = React.useCallback(
    (schedule, callback) => {
      updateRequest(
        {
          requestId: memoizedRequest.id,
          data: { schedule: schedule },
        },
        {
          onSuccess: () => callback(),
        }
      )
    },
    [memoizedRequest.schedule]
  )

  const onUpdateName = React.useCallback(
    (name) => {
      updateRequest({
        requestId: memoizedRequest.id,
        data: { name: name },
      })
    },
    [memoizedRequest.name]
  )

  const onUpdateParameters = React.useCallback(
    (parameters) => {
      runRequest(
        {
          requestId: memoizedRequest.id,
          data: {
            id: memoizedRequest.id,
            apply_auto_limit: false,
            max_age: 0,
            parameters: parameters,
          },
        }
        // { onSuccess: (newData) => setRequestResult(newData) }
      )
    },
    [memoizedRequest.options?.parameters]
  )

  const onDeleteVis = React.useCallback((id, callback) => {
    deleteVis(
      {
        id,
      },
      {
        onSuccess: (vis) => {
          if (vis) {
            notification.error({
              message: t("requests.vis.visNotDeleted"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            callback()
            notification.success({
              message: t("requests.vis.visDeleted"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("requests.vis.visNotDeleted"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [])

  const onUpdateDataSource = React.useCallback(
    (dataSourceId) => {
      updateRequest(
        {
          requestId: memoizedRequest.id,
          data: { data_source_id: dataSourceId },
        },
        {
          onSuccess: (request) => {
            if (request.id) {
              notification.success({
                message: t("requests.dataSourceUpdated"),
                duration: 2,
                placement: "bottomRight",
              })
            } else {
              notification.error({
                message: t("requests.dataSourceNotUpdated"),
                duration: 2,
                placement: "bottomRight",
              })
            }
          },
          onError: () => {
            notification.error({
              message: t("requests.dataSourceNotUpdated"),
              duration: 2,
              placement: "bottomRight",
            })
          },
        }
      )
    },
    [memoizedRequest.data_source_id]
  )

  const value = React.useMemo(
    () => ({
      memoizedRequest,
      RequestParameters,
      isLoadingReqParam,
      setRequestParameters,
      onTogglePublish,
      onToggleArchive,
      onAddTags,
      onUpdateSchedule,
      onUpdateParameters,
      onUpdateName,
      isLoadingRunRequest,
      shouldWidgetUpdate,
      setShouldWidgetUpdate,
      onDeleteVis,
      onUpdateDataSource,
      requestData,
      setRequestData,
      getDataLoading,
      requestError,
      setRequestError,
      onUpdateData,
      updateDataLoading,
      updatedParameters,
      setUpdatedParameters,
      isDrillDown,
      setIsDrillDown,
      updateLoading,
    }),
    [
      memoizedRequest,
      RequestParameters,
      isLoadingReqParam,
      setRequestParameters,
      onTogglePublish,
      onToggleArchive,
      onAddTags,
      onUpdateSchedule,
      onUpdateParameters,
      onUpdateName,
      isLoadingRunRequest,
      shouldWidgetUpdate,
      setShouldWidgetUpdate,
      onDeleteVis,
      onUpdateDataSource,
      requestData,
      getDataLoading,
      requestError,
      onUpdateData,
      updateDataLoading,
      updatedParameters,
      setUpdatedParameters,
      isDrillDown,
      setIsDrillDown,
      updateLoading,
    ]
  )

  return (
    <RequestStateContext.Provider value={value}>
      {children}
    </RequestStateContext.Provider>
  )
}

export function useRequestState() {
  const context = useContext(RequestStateContext)

  if (!context) {
    throw new Error(
      "useRequestState must be used inside a RequestStateProvider."
    )
  }

  return context
}

export default RequestStateProvider
