import React from "react"
import styled from "styled-components"
import { Spin } from "antd"
import { useDashboard } from "hooks/dashboards"
import DashboardStateProvider, {
  useDashboardState,
} from "components/dashboards/Provider/Provider"
import Widgets from "components/dashboards/Widgets"
import Text from "components/shared/Text"
import Paramters from "components/dashboards/Paramters"
import CustomLink from "components/shared/CustomLink/CustomLink"

const Header = () => {
  const { memoizedDashboard } = useDashboardState()

  return (
    <CustomLink to={`/dashboard/${memoizedDashboard.id}`}>
      <Text size="heading" color="blue" className="head">
        {memoizedDashboard.name}
      </Text>
    </CustomLink>
  )
}

const ApplicationDashboard = ({ id }) => {
  const {
    data: dashboard,
    isLoading,
    isFetching,
    isError,
    error,
  } = useDashboard(id)

  if (isError) {
    return <p>{error.message}</p>
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }
  return (
    <Wrapper>
      <DashboardStateProvider dashboard={dashboard} isRefetching={isFetching}>
        <Header />
        <Paramters />
        <Widgets />
      </DashboardStateProvider>
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 1rem 0;

  .head {
    padding: 1rem;
  }

  .main {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 24px;
  }
`

export default ApplicationDashboard
