import React from "react"
import { notification } from "antd"
import authService from "services/auth"
import AnimatedLogo from "components/shared/AnimatedLogo/AnimatedLogo"
import { useTranslation } from "react-i18next"
import useSession from "hooks/useSession"

const AuthContext = React.createContext()

const baseURL = window.location.pathname

function AuthProvider(props) {
  const { t } = useTranslation()
  const [auth, setAuth] = React.useState(() => authService.getAuth())
  const { data, isLoading } = useSession({
    enabled: !!auth && !baseURL.includes("/public/"),
  })

  React.useEffect(() => {
    function handleOnlineStatus() {
      if (navigator.onLine) {
        notification.success({
          message: t("global.session.onlineMsg"),
          description: t("global.session.onLineDesc"),
          placement: "bottomRight",
          duration: 4,
        })
      } else {
        notification.warning({
          message: t("global.session.offlineMsg"),
          description: t("global.session.offLineDesc"),
          placement: "bottomRight",
          duration: 4,
        })
      }
    }

    window.addEventListener("online", handleOnlineStatus)
    window.addEventListener("offline", handleOnlineStatus)

    return () => {
      window.removeEventListener("online", handleOnlineStatus)
      window.removeEventListener("offline", handleOnlineStatus)
    }
  }, [])

  React.useEffect(() => {
    authService.onLogin = () => setAuth(true)
    authService.onLogout = () => setAuth(false)
  }, [])

  const value = React.useMemo(
    () => ({
      auth: auth,
      isAuth: !!auth,
      login: (data) => authService.login(data),
      logout: () => authService.logout(),
      tenantName: authService.getTenantName(),
      session: data,
    }),
    [auth, data]
  )

  if (isLoading && auth && !baseURL.includes("/public/")) {
    return <AnimatedLogo />
  }

  return <AuthContext.Provider value={value} {...props} />
}

export default AuthProvider

export function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error("useAuth must be used inside an AuthProvider")
  }

  return context
}
