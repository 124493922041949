import { Result, Spin } from "antd"
import AccountDetails from "components/account/AccountDetails"
import { useGroups } from "hooks/groups"
import { useSession, useUser } from "hooks/users"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Account = () => {
  const { t } = useTranslation()
  const { data, isLoading: sessionLoading, isError } = useSession()
  const userId = data && data.user ? data?.user.id : null
  const { data: user, isLoading } = useUser(userId)
  const { data: all_groups, isLoading: groupsLoading } = useGroups()

  if (isLoading || groupsLoading || sessionLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }
  if (isError || !user || !data || !all_groups || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <AccountDetails user={user} allGroups={all_groups} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Account
