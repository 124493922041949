import { useQuery, useMutation, useQueryClient } from "react-query"

import {
  getAgents,
  getAgent,
  createAgent,
  updateAgent,
  getAgentDestinations,
  createAgentDestination,
  removeAgentDestination,
  muteAlert,
  unMuteAlert,
  deleteAgent,
  getAgentsPage,
  getTemplateAgents,
} from "apis/agents"

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

const keys = {
  all: ["agents"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  destinations: () => [...keys.all, "destinations"],
  destination: (id) => [...keys.destinations(), id],
}

function useAgentsPage(pagination, options = {}) {
  return useQuery({
    queryKey: keys.list(pagination),
    queryFn: () => getAgentsPage(pagination),
    ...defaultQueryConfig,
    ...options,
  })
}

function useAgents(options = {}) {
  return useQuery({
    queryKey: keys.lists(),
    queryFn: () => getAgents(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useTemplateAgents(options = {}) {
  return useQuery({
    queryKey: keys.lists(),
    queryFn: () => getTemplateAgents(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useAgent(id, options = {}) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getAgent(id),
    ...defaultQueryConfig,
    ...options,
  })
}

function useCreateAgent(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((agent) => createAgent(agent), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useUpdateAgent(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateAgent(id, data), {
    ...options,
    onSuccess: (agent) => {
      options.onSuccess?.(agent)

      // invalidate the list
      queryClient.invalidateQueries(keys.lists())

      // instantly update the agent
      queryClient.setQueryData(keys.detail(agent.id), agent)
    },
  })
}

function useAgentDestinations(id, options = {}) {
  return useQuery({
    queryKey: keys.destination(id),
    queryFn: () => getAgentDestinations(id),
    ...defaultQueryConfig,
    ...options,
    enabled: !!id,
  })
}

function useCreateAgentDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => createAgentDestination(id, data),
    onSuccess: (destination) => {
      return queryClient.invalidateQueries(keys.destinations())
    },
  })
}

function useRemoveAgentDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ agentId, subscriptionId }) =>
      removeAgentDestination(agentId, subscriptionId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.destinations())
    },
  })
}

function useMuteAgent(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => muteAlert(id),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(keys.details()),
        queryClient.invalidateQueries(keys.lists()),
      ])
    },
  })
}

function useUnMuteAgent(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => unMuteAlert(id),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(keys.details()),
        queryClient.invalidateQueries(keys.lists()),
      ])
    },
  })
}

function useDeleteAgent(options) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => deleteAgent(id),
    onSuccess: () => {
      return (
        queryClient.invalidateQueries(keys.details()),
        queryClient.invalidateQueries(keys.lists())
      )
    },
  })
}

export {
  useAgents,
  useCreateAgent,
  useAgent,
  useUpdateAgent,
  useAgentDestinations,
  useCreateAgentDestination,
  useRemoveAgentDestination,
  useMuteAgent,
  useUnMuteAgent,
  useDeleteAgent,
  useAgentsPage,
  useTemplateAgents,
}
