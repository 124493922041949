import styled from "styled-components"
import FavoriteDashboards from "components/dashboards/FavoriteDashboards"
import HomeDashboartd from "components/dashboards/HomeDashboartd"
import { Col, Row } from "antd"
import HomeApplication from "components/Application/HomeApplication"
import Text from "components/shared/Text"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import FormModal from "components/shared/FormModal"
import CreateDashboardForm from "components/dashboards/CreateDashboardForm"
import { PlusOutlined } from "@ant-design/icons"
import Button from "components/shared/Button"

function Home() {
  const { session } = useAuth()
  const { t } = useTranslation()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  return (
    <Wrapper>
      <div className="header">
        <Text size="xxl">{t("home.overview")}</Text>
        {IS_ADMIN ? (
          <FormModal
            title={t("dashboards.addDashboard")}
            width={500}
            hasChanged={({ name }) => !!name}
            Form={CreateDashboardForm}
          >
            <Button type="primary" shape="outlined" size="sm" id="newDashboard">
              <PlusOutlined /> {t("dashboards.newDashboard")}
            </Button>
          </FormModal>
        ) : null}
      </div>
      <div className="widgets">
        <HomeDashboartd />
      </div>
      <Row gutter={16} className="fav">
        <Col xs={24} lg={24} xl={8}>
          <Text size="xxl">{t("home.favDashboard")}</Text>
          <FavoriteDashboards />
        </Col>
        <Col xs={24} lg={24} xl={16}>
          <Text size="xxl">{t("home.yourApps")}</Text>
          <HomeApplication />
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  position: relative;
  height: 100%;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  & .widgets {
    min-height: 60vh;
  }

  & .fav {
    padding: 1rem;
  }

  @media (min-width: 768px) {
  }
`

export default Home
