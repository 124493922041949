import client from "services/client"
import AppError from "services/app-error"
import qs from "query-string"

const { INVALID_PAGE_NUMBER, DASHBOARD_NOT_FOUND, NETWORK_ERROR } =
  AppError.codes

async function getAgentsPage(options = {}) {
  const {
    page = 1,
    pageSize: page_size = 10,
    name: q,
    userId,
    date_from,
    date_to,
  } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
    userId,
    date_from,
    date_to,
  })

  const endpoint = "/alerts"

  const res = await client.get(`${endpoint}?${params}`)

  if (res.status === 400) {
    throw new AppError("Invalid agent page number.", INVALID_PAGE_NUMBER)
  }

  return res.data
}

async function getTemplateAgents() {
  const res = await client.get("/alerts?template=true")

  return res.data
}

async function getAgents() {
  const res = await client.get("/alerts")

  return res.data
}

async function getAgent(id) {
  const res = await client.get(`/alerts/${id}`)

  return res.data
}

async function createAgent(data) {
  const res = await client.post("/alerts", data)

  return res.data
}

async function updateAgent(id, data) {
  const res = await client.post(`/alerts/${id}`, data)

  return res.data
}

async function getAgentDestinations(id) {
  const res = await client.get(`/alerts/${id}/subscriptions`)

  return res.data
}

async function createAgentDestination(id, data) {
  const res = await client.post(`/alerts/${id}/subscriptions`, data)

  return res.data
}

async function removeAgentDestination(agentId, subscriptionId) {
  const res = await client.delete(
    `/alerts/${agentId}/subscriptions/${subscriptionId}`
  )

  return res.data
}

async function muteAlert(id) {
  const res = await client.post(`/alerts/${id}/mute`)

  return res.data
}

async function unMuteAlert(id) {
  const res = await client.delete(`/alerts/${id}/mute`)

  return res.data
}

async function deleteAgent(id) {
  const res = await client.delete(`/alerts/${id}`)

  return res.data
}

export {
  getAgents,
  getAgent,
  createAgent,
  updateAgent,
  getAgentDestinations,
  createAgentDestination,
  removeAgentDestination,
  muteAlert,
  unMuteAlert,
  deleteAgent,
  getAgentsPage,
  getTemplateAgents,
}
