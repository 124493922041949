import styled from "styled-components"
import { Typography, Skeleton } from "antd"
import variants from "./Text.styles"
import { extract } from "lib/utils"

const keys = ["size", "color"]

const Text = styled((props) => {
  return <Typography.Text {...extract(props, keys)} />
})`
  ${({ size = "md" }) => variants.size[size]};
  ${({ color = "primary" }) => variants.color[color]};

  line-height: 1.5;
  font-size: var(--size);
  color: var(--color);
  margin: 0;
`

Text.Skeleton = styled((props) => (
  <Skeleton active title={true} paragraph={false} {...props} />
))`
  h3.ant-skeleton-title {
    margin: 0;
  }
`

export function ExtraSmallText(props) {
  return <Text type="secondary" size="xs" {...props} />
}

export function AsyncText({ loading, ...props }) {
  if (loading) {
    return <Text.Skeleton />
  }

  return <Text {...props} />
}

export default Text
