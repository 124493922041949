import styled from "styled-components"
import React from "react"
import { useTranslation } from "react-i18next"
import FormModal from "../FormModal"
import Icon from "../Icon"
import CreateTags from "./CreateTags"
import Button from "../Button"
import { Tooltip } from "antd"

const Tags = ({
  allTags,
  dashboaredTags,
  IS_ADMIN,
  onAddTags,
  updateLoading,
}) => {
  const { t } = useTranslation()
  return (
    <TagsContainer>
      {dashboaredTags?.length > 0 &&
        dashboaredTags.map((tag, idx) => (
          <Tooltip key={idx} title={tag}>
            <span className="tags">{tag}</span>
          </Tooltip>
        ))}
      {IS_ADMIN ? (
        dashboaredTags <= 0 ? (
          <FormModal
            Form={CreateTags}
            hasChanged={({ dashboaredTags }) => !!dashboaredTags}
            title={t("global.addTag")}
            width={600}
            formProps={{
              allTags: allTags,
              dashboaredTags: dashboaredTags,
              onAddTags: onAddTags,
              updateLoading: updateLoading,
            }}
          >
            <Button
              title={t("gloabal.addTag")}
              size="xs"
              type="text"
              className="tagBtn"
            >
              {`+ ${t("global.addTag")}`}
            </Button>
          </FormModal>
        ) : (
          <FormModal
            Form={CreateTags}
            hasChanged={({ dashboaredTags }) => !!dashboaredTags}
            title={t("global.editTags")}
            width={600}
            formProps={{
              allTags: allTags,
              dashboaredTags: dashboaredTags,
              onAddTags: onAddTags,
              updateLoading: updateLoading,
            }}
          >
            <Button
              title={t("global.editTags")}
              size="xs"
              type="text"
              className="tagBtn"
            >
              <Icon type="edit" />
            </Button>
          </FormModal>
        )
      ) : null}
    </TagsContainer>
  )
}

const TagsContainer = styled.div`
  ${({ theme }) => theme.mixins.flexSpaceBetween};
  margin-right: auto;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;

  & .tags {
    padding: 1px 6px;
    background-color: #e7edf0;
    color: #000;
    border-radius: 4px;
    margin-right: 4px;
    font-size: small;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tagBtn {
    font-size: 12px;
    opacity: 0;
  }

  @media (max-width: 768px) {
    .tagBtn {
      opacity: 1;
    }
  }
`

export default Tags
