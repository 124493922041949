import React from "react"
import useQueryParams from "hooks/useQueryParams"
import {
  useDeleteUser,
  useDisableUser,
  useEnableUser,
  useSession,
  useUsersPage,
} from "hooks/users"
import { useGroups } from "hooks/groups"
import {
  renderActionBtns,
  renderActiveAtColumn,
  renderCreatedAtColumn,
  renderDisableUserColumn,
  renderEmailColumn,
  renderGroupsColumn,
  renderNameColumn,
} from "./Table.utils"
import { Result, Table } from "antd"
import { useTranslation } from "react-i18next"
import { TableSkeleton } from "components/shared/Table"

const Default_columns = () => {
  const { t } = useTranslation()
  return [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
      render: renderNameColumn,
    },
    // {
    //   title: t("global.tabels.email"),
    //   key: "email",
    //   dataIndex: "email",
    //   render: renderEmailColumn,
    // },
    {
      title: t("global.tabels.joined"),
      key: "created_by",
      dataIndex: "created_at",
      render: renderCreatedAtColumn,
    },
    {
      title: t("global.tabels.lastActiveAt"),
      key: "active_at",
      dataIndex: "active_at",
      render: renderActiveAtColumn,
    },
    {
      title: t("global.tabels.groups"),
      key: "groups",
      dataIndex: "groups",
      render: renderGroupsColumn,
      width: 200,
    },
  ]
}

const Admin_columns = () => {
  const { t } = useTranslation()
  return [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
      render: renderNameColumn,
    },
    // {
    //   title: t("global.tabels.email"),
    //   key: "email",
    //   dataIndex: "email",
    //   render: renderEmailColumn,
    // },
    {
      title: t("global.tabels.joined"),
      key: "created_by",
      dataIndex: "created_at",
      render: renderCreatedAtColumn,
    },
    {
      title: t("global.tabels.lastActiveAt"),
      key: "active_at",
      dataIndex: "active_at",
      render: renderActiveAtColumn,
    },
    {
      title: t("global.tabels.groups"),
      key: "groups",
      dataIndex: "groups",
      render: renderGroupsColumn,
      width: 200,
    },
    // {
    //   title: t("global.tabels.status"),
    //   key: "is_disabled",
    //   dataIndex: "is_disabled",
    //   render: renderDisableUserColumn,
    // },
    {
      title: t("global.tabels.actions"),
      key: "action",
      render: (_, data) => renderActionBtns(data, t),
    },
  ]
}

const UserTable = ({ isAdmin }) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useQueryParams({
    page: Number,
    page_size: Number,
    q: String,
    pending: Boolean,
    disabled: Boolean,
  })

  const {
    page = 1,
    page_size: pageSize = 10,
    q: name,
    pending = false,
    disabled = false,
  } = queryParams

  const { data, isLoading, isError } = useUsersPage({
    page,
    pageSize,
    name,
    pending,
    disabled,
  })

  // const { data: searchData, isLoadingSearch } = useSearchUser(name)
  // console.log(searchData)

  const { mutate: disable, isLoading: disableLoading } = useDisableUser()
  const { mutate: enable, isLoading: enableLoading } = useEnableUser()
  const { mutate: deleteUser, isLoading: deleteLoading } = useDeleteUser()

  const { data: allGroups } = useGroups()

  const { data: session, isLoading: sessionLoading } = useSession()

  const onDisableUser = (id) => {
    disable({
      id: id,
    })
  }

  const onEnableUser = (id) => {
    enable({
      id: id,
    })
  }

  const onDeleteUser = (id) => {
    deleteUser({
      id: id,
    })
  }

  const datasource = data
    ? data?.results?.map((data) => ({
        ...data,
        user: session?.user,
        allGroups,
        onDeleteUser,
        onEnableUser,
        onDisableUser,
        pending: queryParams.pending,
        disabled: queryParams.disabled,
      }))
    : []

  const handleChange = ({ current: page, pageSize: page_size }) => {
    setQueryParams({ page, page_size })
  }

  if (
    isLoading ||
    disableLoading ||
    enableLoading ||
    deleteLoading ||
    sessionLoading
  ) {
    return <TableSkeleton columns={Default_columns()} rowsCount={pageSize} />
  }

  // Used until we create a Result component
  // It will act as a Visitor that will receive the AppError and
  // returns the corresponding UI
  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Table
      scroll={{ x: "1000px" }}
      size="sm"
      rowKey="id"
      columns={isAdmin ? Admin_columns() : Default_columns()}
      dataSource={datasource}
      onChange={handleChange}
      pagination={{
        current: page,
        pageSize: pageSize,
        total: data.count,
      }}
    />
  )
}

export default UserTable
