import React from "react"
import styled from "styled-components"
import { Modal } from "antd"
import { confirmFormCancelation } from "./FormModal.utils"
import { CloseOutlined } from "@ant-design/icons"

/* 
    Usage:
        The FormModal Can receive ant antd Modal props: title, width, etc...

        <FormModal
            Form={CreateWidget}
            hasChanged={values => !!values.query}
            title="Add Widget"
        >
            {({ openModal }) => <button onClick={openModal}>Open Form</button>}

            OR

            <button>Open Form</button>
            
            using React.cloneElement to pass the openModal method
        </FormModal>
*/

function FormModal({
  Form,
  hasChanged,
  children,
  formProps = {},
  ...modalProps
}) {
  const values = React.useRef(null)
  const [isOpened, setIsOpened] = React.useState(false)

  const handleFormChange = React.useCallback(
    (formState) => (values.current = formState),
    []
  )

  const closeModal = React.useCallback(() => setIsOpened(false), [])

  const openModal = React.useCallback(() => setIsOpened(true), [])

  const handleCancel = React.useCallback(
    () =>
      confirmFormCancelation({
        cancel: closeModal,
        hasChanged: hasChanged(values.current),
      }),
    []
  )

  return (
    <>
      <ModalWapper
        keyboard={false}
        footer={null}
        open={isOpened}
        destroyOnClose={true}
        onCancel={handleCancel}
        maskClosable={false}
        {...modalProps}
      >
        {isOpened && (
          <Form
            onChange={handleFormChange}
            onCancel={handleCancel}
            closeModal={closeModal}
            {...formProps}
          />
        )}
      </ModalWapper>

      {typeof children === "function"
        ? children({ openModal })
        : React.cloneElement(children, { onClick: openModal })}
    </>
  )
}

const ModalWapper = styled(Modal)`
  .ant-modal-close-x {
    width: 100%;
    height: 100%;
  }
`

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin: 20px 0 0;
`

// The default FromModal Footer UI

FormModal.Footer = ModalFooter

export default FormModal
