import React from "react"
import styled from "styled-components"
import { Result, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { useRequest } from "hooks/requests"
import useTypedParams from "hooks/useTypedParams"
import RequestStateProvider from "components/requests/Provider"
import RequestHeader from "components/requests/Header/RequestHeader"
import RequestFooter from "components/requests/Footer/RequestFooter"
import TabContent from "components/requests/TabContent"
import RequestParameters from "components/requests/RequestParameters/RequestParameters"

function Request() {
  const { t } = useTranslation()
  const { id } = useTypedParams({ id: Number })
  const { data: request, isLoading, isFetching, isError } = useRequest(id)

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !request || request.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <RequestStateProvider request={request} isRefetching={isFetching}>
        <RequestHeader />
        <RequestParameters requestId={request?.id} />
        <Content>
          <TabContent />
        </Content>
        <RequestFooter />
      </RequestStateProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
`
const Content = styled.div`
  height: 100%;
  overflow: hidden;
`

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default Request
