import React, { useState, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import {
  Form,
  Input,
  Modal,
  Result,
  Space,
  notification,
  Button as AntdBtn,
  Select,
} from "antd"
import {
  useCreateApplications,
  useUpdateApplications,
} from "hooks/applications"
import Button from "components/shared/Button"
import Icon from "components/shared/Icon"
import { useDashboardTags } from "hooks/dashboards"

const AppForm = forwardRef(({ app, hideEdit, onCancelEdit }, ref) => {
  const ISNEW = !app
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const { data: tags } = useDashboardTags()
  const { mutate: create, isLoading: createLoading } = useCreateApplications()
  const { mutate: update, isLoading: updateLoading } = useUpdateApplications()

  const applicationsTags = tags?.map((tag) => ({
    label: tag.name,
    value: tag.name,
  }))

  const handelSave = (value) => {
    if (ISNEW) {
      create(
        {
          name: value.name,
          tag: value.tag,
        },
        {
          onSuccess: (data) => {
            if (data.name) {
              setIsSuccessOpen(true)
            } else {
              notification.error({
                message: data,
                duration: 2,
                placement: "bottomRight",
              })
            }
          },
          onError: (error) => {
            notification.error({
              message: error,
              duration: 2,
              placement: "bottomRight",
            })
          },
        }
      )
    } else {
      update(
        {
          id: app?.id,
          data: {
            name: value.name,
            tag: value.tag,
          },
        },
        {
          onSuccess: (data) => {
            if (data.name) {
              notification.success({
                message: t("applications.updateSuccessfully"),
                duration: 2,
                placement: "bottomRight",
              })
              setIsOpen(false)
            } else {
              notification.error({
                message: data,
                duration: 2,
                placement: "bottomRight",
              })
            }
          },
          onError: (error) => {
            notification.error({
              message: error,
              duration: 2,
              placement: "bottomRight",
            })
          },
        }
      )
    }
  }

  return (
    <div>
      {ISNEW ? (
        <Button
          type="primary"
          id="createAppBtn"
          onClick={() => setIsOpen(true)}
        >
          {t("applications.newApplication")}
        </Button>
      ) : (
        <AntdBtn
          type="text"
          icon={<Icon type="edit" />}
          onClick={() => setIsOpen(true)}
          ref={ref}
          style={{ display: hideEdit ? "none" : "block" }}
          id="editAppBtn"
        />
      )}
      <Modal
        open={isOpen}
        onCancel={() => {
          !ISNEW && onCancelEdit()
          form.resetFields()
          setIsOpen(false)
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handelSave}
          form={form}
          initialValues={app}
        >
          <Form.Item
            label={t("applications.applicationName")}
            name="name"
            rules={[{ required: "true" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("applications.applicationTag")}
            name="tag"
            rules={[{ required: "true" }]}
          >
            <Select options={applicationsTags} />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                alighItems: "center",
                justifyContent: "flex-end",
                gap: "1rem",
                padding: "0",
              }}
            >
              <Button
                onClick={() => {
                  !ISNEW && onCancelEdit()
                  setIsOpen(false)
                  form.resetFields()
                }}
                type="cancel"
              >
                {t("global.cancel")}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={createLoading || updateLoading}
                disabled={createLoading || updateLoading}
              >
                {t("global.save")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={isSuccessOpen}
        footer={null}
        onCancel={() => {
          form.resetFields()
          setIsOpen(false)
          setIsSuccessOpen(false)
        }}
      >
        <Result
          status="success"
          title={t("applications.createSuccessfully")}
          extra={[
            <Space direction="vertical">
              <Button
                type="primary"
                key="back"
                size="lg"
                onClick={() => {
                  form.resetFields()
                  setIsOpen(false)
                  setIsSuccessOpen(false)
                }}
              >
                {t("applications.appBack")}
              </Button>
              <Button
                type="text"
                key="new"
                onClick={() => {
                  form.resetFields()
                  setIsSuccessOpen(false)
                }}
              >
                {t("applications.createApp")}
              </Button>
            </Space>,
          ]}
        />
      </Modal>
    </div>
  )
})

export default AppForm
