import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  Checkbox,
  Col,
  ColorPicker,
  Form,
  Input,
  InputNumber,
  List,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  message,
  notification,
} from "antd"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { Vis } from "@icloudready/idataworkers-visualization"
import {
  useCreateVisualization,
  useRefreshRequest,
  useUpdateVisualization,
} from "hooks/requests"
import {
  ChartTypesOptions,
  getXAndYFromColumns,
  visualizationTypeOptions,
  colorPalettes,
} from "./utils"
import { useTranslation } from "react-i18next"
import TabelColumns from "./TabelColumns"

function CreateRequestVisualization({
  onChange,
  onCancel,
  closeModal,
  memoizedRequest,
  data,
  edit,
  visualization,
  params,
}) {
  const { t } = useTranslation()
  const { x, y, series, size } = getXAndYFromColumns(
    visualization?.options?.columnMapping
  )
  const { columns, rows } = data?.data
  const colNameOptions = columns.map((c) => ({
    value: c.name,
    label: c.friendly_name,
  }))

  const [selectedXColumn, setSelectedXColumn] = useState(edit ? x : null)
  const [selectedYColumns, setSelectedYColumns] = useState(edit ? y : [])
  const [selectedSeriesColumn, setSelectedSeriesColumn] = useState(
    edit ? series : null
  )
  const [selectedBubbleSizeColumn, setSelectedBubbleSizeColumn] = useState(
    edit ? size : null
  )

  const filteredYOptions = colNameOptions.filter((option) => {
    return (
      option.value !== selectedXColumn &&
      option.value !== selectedSeriesColumn &&
      !selectedYColumns.includes(option.value) &&
      option.value !== selectedBubbleSizeColumn
    )
  })

  const filteredSeriesOptions = colNameOptions.filter((option) => {
    return (
      option.value !== selectedXColumn &&
      !selectedYColumns.includes(option.value) &&
      option.value !== selectedSeriesColumn &&
      option.value !== selectedBubbleSizeColumn
    )
  })

  const filterBubbleSizeOptions = colNameOptions.filter((option) => {
    return (
      option.value !== selectedXColumn &&
      !selectedYColumns.includes(option.value) &&
      option.value !== selectedSeriesColumn &&
      option.value !== selectedBubbleSizeColumn
    )
  })

  const [visType, setVisType] = useState(
    edit ? visualization.type : visualizationTypeOptions[0].value
  )
  const [visName, setVisName] = useState(
    edit ? visualization.name : visualizationTypeOptions[0].label
  )
  const [visOptions, setVisOptions] = useState({})

  useEffect(() => {
    if (edit) {
      if (
        visualization.type === "TABLE" &&
        Object.keys(visualization.options).length === 0
      ) {
        setVisOptions({
          itemsPerPage: 25,
          columns: columns.map((col, idx) => ({
            alignContent: "left",
            allowHTML: true,
            allowSearch: false,
            booleanValues: ["false", "true"],
            displayAs: !isNaN(Date.parse(rows[0][col.name]))
              ? "datetime"
              : "string",
            highlightLinks: false,
            imageHeight: "",
            imageTitleTemplate: "{{ @ }}",
            imageUrlTemplate: "{{ @ }}",
            imageWidth: "",
            linkOpenInNewTab: true,
            linkTextTemplate: "{{ @ }}",
            linkTitleTemplate: "{{ @ }}",
            linkUrlTemplate: "{{ @ }}",
            name: col.name,
            order: 100000 + idx,
            title: col.friendly_name,
            type: col.type,
            visible: true,
          })),
        })
      } else {
        setVisOptions(visualization.options)
      }
    } else {
      if (visType === "COUNTER") {
        setVisOptions({
          counterColName: colNameOptions[0].value,
          stringDecimal: 0,
          tooltipFormat: "0,0.000",
        })
      } else if (visType === "CHART") {
        setVisOptions({
          globalSeriesType: ChartTypesOptions[0].value,
          sortX: true,
          legend: {
            enabled: true,
            placement: "auto",
            traceorder: "normal",
          },
          yAxis: [
            {
              type: "linear",
            },
            {
              type: "linear",
              opposite: true,
            },
          ],
          xAxis: {
            type: "-",
            labels: {
              enabled: true,
            },
          },
          error_y: {
            type: "data",
            visible: true,
          },
          series: {
            stacking: false,
            error_y: {
              type: "data",
              visible: true,
            },
          },
          seriesOptions: {},
          valuesOptions: {},
          columnMapping: {},
          direction: {
            type: "counterclockwise",
          },
          sizemode: "diameter",
          coefficient: 1,
          numberFormat: "0,0[.]00000",
          percentFormat: "0[.]00%",
          textFormat: "",
          missingValuesAsZero: true,
          showDataLabels: false,
          dateTimeFormat: "YYYY-MM-DD HH:mm",
          orientation: "v",
        })
      } else if (visType === "PIVOT") {
        setVisOptions({
          controls: {
            enabled: true,
          },
          rendererOptions: {
            table: {
              colTotals: true,
              rowTotals: true,
            },
          },
          rows: [],
          cols: [],
          vals: [],
          aggregatorName: "Sum",
          valueFilter: {},
          sorters: {},
          rowOrder: "key_a_to_z",
          colOrder: "key_a_to_z",
          derivedAttributes: {},
          rendererName: "Table",
          hiddenAttributes: [],
          hiddenFromAggregators: [],
          hiddenFromDragDrop: [],
          menuLimit: 500,
          unusedOrientationCutoff: 85,
        })
      } else if (visType === "TABLE") {
        setVisOptions({
          itemsPerPage: 25,
          columns: columns.map((col, idx) => ({
            alignContent: "left",
            allowHTML: true,
            allowSearch: false,
            booleanValues: ["false", "true"],
            displayAs: !isNaN(Date?.parse(rows?.[0]?.[col?.name]))
              ? "datetime"
              : "string",
            highlightLinks: false,
            imageHeight: "",
            imageTitleTemplate: "{{ @ }}",
            imageUrlTemplate: "{{ @ }}",
            imageWidth: "",
            linkOpenInNewTab: true,
            linkTextTemplate: "{{ @ }}",
            linkTitleTemplate: "{{ @ }}",
            linkUrlTemplate: "{{ @ }}",
            name: col.name,
            order: idx + 1,
            title: col.friendly_name,
            type: col.type,
            visible: true,
          })),
        })
      } else if (visType === "WORD_CLOUD") {
        setVisOptions({
          column: "",
          frequenciesColumn: "",
          wordLengthLimit: {
            min: null,
            max: null,
          },
          wordCountLimit: {
            min: null,
            max: null,
          },
        })
      } else {
        setVisOptions({})
      }
    }

    return () => {
      setVisOptions({})
    }
  }, [visType])

  const handleOptionChange = (value, name) => {
    const newOption = { ...visOptions }
    newOption[name] = value
    if (value === "waterfall") {
      newOption.measure = rows.map((row) => "relative")
    }
    setVisOptions(newOption)
  }

  React.useEffect(() => onChange(memoizedRequest), [memoizedRequest, onChange])

  const createVis = useCreateVisualization()
  const updateVis = useUpdateVisualization()
  const refreshVis = useRefreshRequest(memoizedRequest?.id)

  const handelSaveConfirm = () => {
    if (!visName) {
      notification.error({
        message: "You Must Enter Visualization Name",
      })
      return
    }
    edit
      ? updateVis.mutate(
          {
            id: visualization.id,
            data: {
              query_id: memoizedRequest.id,
              type: visType,
              name: visName,
              description: "",
              options: visOptions,
            },
          },
          {
            onSuccess: (newVis) => {
              if (newVis.id) {
                refreshVis()
                closeModal()
                notification.success({
                  message: t("requests.vis.visUptaded"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.error({
                  message: t("requests.vis.visNotUpdated"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("requests.vis.visNotUpdated"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      : createVis.mutate(
          {
            data: {
              query_id: memoizedRequest.id,
              type: visType,
              name: visName,
              description: "",
              options: visOptions,
            },
          },
          {
            onSuccess: (newVis) => {
              if (newVis.id) {
                refreshVis()
                closeModal()
                notification.success({
                  message: t("requests.vis.visCreated"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.error({
                  message: t("requests.vis.visNotCreated"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("requests.vis.visNotCreated"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
  }

  return (
    <>
      <Row gutter={8} style={{ height: "90%" }} className="formContent">
        <Col sm={24} md={8} style={{ height: "100%", overflow: "auto" }}>
          <Form layout="vertical" size="small" className="form">
            <Form.Item label={t("requests.visType")}>
              <Select
                disabled={edit}
                defaultValue={visType}
                options={visualizationTypeOptions}
                onChange={(value) => {
                  const selectedOption = visualizationTypeOptions.find(
                    (option) => option.value === value
                  )
                  setVisType(value)
                  setVisName(selectedOption.label)
                }}
              />
            </Form.Item>

            <Form.Item label={t("requests.visName")}>
              <Input
                value={visName}
                onChange={(event) => setVisName(event.target.value)}
              />
            </Form.Item>

            {visType === "TABLE" && (
              <>
                <Tabs>
                  <Tabs.TabPane tab={t("requests.cols")} key="columns">
                    <TabelColumns
                      columns={visOptions?.columns}
                      visOptions={visOptions}
                      onOptionsChange={setVisOptions}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t("requests.grid")} key="grid">
                    <Form.Item label={t("requests.itemPerPage")}>
                      <Select
                        value={visOptions.itemsPerPage}
                        options={[
                          {
                            value: 5,
                            label: 5,
                          },
                          {
                            value: 10,
                            label: 10,
                          },
                          {
                            value: 15,
                            label: 15,
                          },
                          {
                            value: 20,
                            label: 20,
                          },
                          {
                            value: 25,
                            label: 25,
                          },
                          {
                            value: 50,
                            label: 50,
                          },
                          {
                            value: 100,
                            label: 100,
                          },
                          {
                            value: 150,
                            label: 150,
                          },
                          {
                            value: 200,
                            label: 200,
                          },
                          {
                            value: 250,
                            label: 250,
                          },
                          {
                            value: 500,
                            label: 500,
                          },
                        ]}
                        onChange={(value) => {
                          setVisOptions({ ...visOptions, itemsPerPage: value })
                        }}
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>
              </>
            )}

            {visType === "COUNTER" && (
              <>
                <Form.Item label={t("requests.counterColName")}>
                  <Select
                    placeholder={t("requests.counter")}
                    value={visOptions.counterColName}
                    options={columns.map((c) => ({
                      label: c.friendly_name,
                      value: c.name,
                    }))}
                    onChange={(event) =>
                      handleOptionChange(event, "counterColName")
                    }
                  />
                </Form.Item>
                <Tabs>
                  <Tabs.TabPane tab={t("requests.format")} key="format">
                    <>
                      <Form.Item label={t("requests.formattingPlace")}>
                        <InputNumber
                          value={visOptions.stringDecimal}
                          onChange={(event) =>
                            handleOptionChange(event, "stringDecimal")
                          }
                          style={{
                            width: "100%",
                          }}
                          min={0}
                        />
                      </Form.Item>
                      <Form.Item label={t("requests.formattingPrefix")}>
                        <Input
                          value={visOptions.stringPrefix}
                          onChange={(event) =>
                            handleOptionChange(
                              event.target.value,
                              "stringPrefix"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t("requests.formattingSuffix")}>
                        <Input
                          value={visOptions.stringSuffix}
                          onChange={(event) =>
                            handleOptionChange(
                              event.target.value,
                              "stringSuffix"
                            )
                          }
                        />
                      </Form.Item>
                    </>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t("requests.drilldownUrl")}
                    key="drilldown"
                  >
                    <>
                      <Form.Item
                        label={t("requests.urlLink")}
                        name="linkUrlTemplate"
                      >
                        <Input
                          defaultValue={visualization?.options?.linkUrlTemplate}
                          onChange={(event) =>
                            handleOptionChange(
                              event.target.value,
                              "linkUrlTemplate"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item label={t("requests.urlName")}>
                        <Input
                          defaultValue={visualization?.options?.linkUrlName}
                          onChange={(event) =>
                            handleOptionChange(
                              event.target.value,
                              "linkUrlName"
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Checkbox
                          defaultChecked={
                            visualization?.options?.linkOpenInNewTab
                          }
                          onChange={(event) =>
                            handleOptionChange(
                              event.target.checked,
                              "linkOpenInNewTab"
                            )
                          }
                        >
                          {t("requests.openNewTap")}
                        </Checkbox>
                      </Form.Item>
                    </>
                  </Tabs.TabPane>
                </Tabs>
              </>
            )}

            {visType === "CHART" && (
              <Tabs>
                <Tabs.TabPane tab={t("requests.general")} key="General">
                  <>
                    <Form.Item label={t("requests.chartType")}>
                      <Select
                        value={visOptions.globalSeriesType}
                        options={ChartTypesOptions}
                        onChange={(event) => {
                          handleOptionChange(event, "globalSeriesType")
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("requests.xCol")}>
                      <Select
                        options={colNameOptions.filter((option) => {
                          // Only include options that haven't been selected for Y or series
                          return (
                            !selectedYColumns.includes(option.value) &&
                            option.value !== selectedSeriesColumn &&
                            option.value !== selectedBubbleSizeColumn
                          )
                        })}
                        onChange={(value) => {
                          setSelectedXColumn(value)
                          setVisOptions((current) => {
                            // Remove any existing key-value pairs with value 'x'
                            const updatedMapping = Object.fromEntries(
                              Object.entries(current.columnMapping).filter(
                                ([key, val]) => val !== "x"
                              )
                            )
                            // Add the new key-value pair
                            updatedMapping[value] = "x"
                            return {
                              ...current,
                              columnMapping: updatedMapping,
                            }
                          })
                        }}
                        value={selectedXColumn}
                        allowClear
                        placeholder={t("requests.chooseCol")}
                      />
                    </Form.Item>
                    <Form.Item label={t("requests.yCol")}>
                      <Select
                        options={filteredYOptions}
                        onChange={(value) => {
                          setSelectedYColumns(value)
                          setVisOptions((current) => {
                            // Remove any existing key-value pairs with value 'y'
                            const updatedMapping = Object.fromEntries(
                              Object.entries(current.columnMapping).filter(
                                ([key, val]) => val !== "y"
                              )
                            )
                            return {
                              ...current,
                              columnMapping: {
                                ...updatedMapping,
                                ...value.reduce(
                                  (acc, val) => ({ ...acc, [val]: "y" }),
                                  {}
                                ),
                              },
                            }
                          })
                        }}
                        value={selectedYColumns}
                        mode={
                          visOptions.globalSeriesType === "treemap"
                            ? ""
                            : "multiple"
                        }
                        allowClear
                        placeholder={t("requests.chooseCol")}
                      />
                    </Form.Item>
                    {visOptions.globalSeriesType !== "waterfall" && (
                      <Form.Item label={t("requests.groupBy")}>
                        <Select
                          options={filteredSeriesOptions}
                          onChange={(value) => {
                            setSelectedSeriesColumn(value)
                            setVisOptions((current) => {
                              // Remove any existing key-value pairs with value 'series'
                              const updatedMapping = Object.fromEntries(
                                Object.entries(current.columnMapping).filter(
                                  ([key, val]) => val !== "series"
                                )
                              )
                              // Add the new key-value pair
                              updatedMapping[value] = "series"
                              return {
                                ...current,
                                columnMapping: updatedMapping,
                              }
                            })
                          }}
                          value={selectedSeriesColumn}
                          allowClear
                          placeholder={t("requests.chooseCol")}
                        />
                      </Form.Item>
                    )}
                    {visOptions.globalSeriesType === "bubble" && (
                      <Form.Item label={t("requests.bubbleSize")}>
                        <Select
                          options={filterBubbleSizeOptions}
                          onChange={(value) => {
                            setSelectedBubbleSizeColumn(value)
                            setVisOptions((current) => {
                              // Remove any existing key-value pairs with value 'size'
                              const updatedMapping = Object.fromEntries(
                                Object.entries(current.columnMapping).filter(
                                  ([key, val]) => val !== "size"
                                )
                              )
                              // Add the new key-value pair
                              updatedMapping[value] = "size"
                              return {
                                ...current,
                                columnMapping: updatedMapping,
                              }
                            })
                          }}
                          value={selectedBubbleSizeColumn}
                          allowClear
                          placeholder={t("requests.chooseCol")}
                        />
                      </Form.Item>
                    )}
                    {visOptions.globalSeriesType === "bubble" && (
                      <Form.Item label={t("requests.bubbleCoe")}>
                        <InputNumber
                          value={visOptions.coefficient}
                          onChange={(value) =>
                            handleOptionChange(value, "coefficient")
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    )}
                    {visOptions.globalSeriesType === "bubble" && (
                      <Form.Item label={t("requests.bubbleprop")}>
                        <Select
                          defaultValue={visOptions.sizemode}
                          options={[
                            {
                              label: "Diameter",
                              value: "diameter",
                            },
                            {
                              label: "Area",
                              value: "area",
                            },
                          ]}
                          onChange={(value) => {
                            handleOptionChange(value, "sizemode")
                          }}
                        />
                      </Form.Item>
                    )}
                    {visOptions.globalSeriesType === "pie" && (
                      <Form.Item label={t("requests.direction")} name="hole">
                        <InputNumber
                          style={{
                            width: 200,
                          }}
                          defaultValue="0"
                          min="0"
                          max="0.9"
                          step="0.1"
                          onChange={(value) => {
                            setVisOptions((current) => {
                              return {
                                ...current,
                                hole: value,
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    )}
                    {visOptions.globalSeriesType === "pie" && (
                      <Form.Item
                        label={t("requests.direction")}
                        name="direction"
                      >
                        <Select
                          defaultValue="counterclockwise"
                          options={[
                            {
                              value: "clockwise",
                              label: "clockwise",
                            },
                            {
                              value: "counterclockwise",
                              label: "counterclockwise",
                            },
                          ]}
                          onChange={(value) => {
                            setVisOptions((current) => {
                              return {
                                ...current,
                                direction: {
                                  ...current.direction,
                                  type: value,
                                },
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    )}
                    {(visOptions.globalSeriesType === "column" ||
                      visOptions.globalSeriesType === "waterfall") && (
                      <Form.Item label="Orientation">
                        <Select
                          value={visOptions.orientation}
                          options={[
                            {
                              value: "v",
                              label: "Vertical",
                            },
                            {
                              value: "h",
                              label: "Horizontal",
                            },
                          ]}
                          onChange={(value) => {
                            setVisOptions((current) => {
                              return {
                                ...current,
                                orientation: value,
                              }
                            })
                          }}
                        />
                      </Form.Item>
                    )}
                    <Form.Item label={t("requests.legendPlacement")}>
                      {visOptions.globalSeriesType === "pie" ? (
                        <Select
                          defaultValue="right"
                          options={[
                            {
                              label: "Hide Legend",
                              value: "hide",
                            },
                            {
                              label: "Right",
                              value: "auto",
                            },
                            {
                              label: "Bottom",
                              value: "below",
                            },
                          ]}
                          onChange={(value) => {
                            setVisOptions((current) => {
                              if (value === "hide") {
                                return {
                                  ...current,
                                  legend: {
                                    ...current.legend,
                                    enabled: false,
                                  },
                                }
                              } else {
                                return {
                                  ...current,
                                  legend: {
                                    ...current.legend,
                                    enabled: true,
                                    placement: value,
                                  },
                                }
                              }
                            })
                          }}
                        />
                      ) : (
                        <Select
                          defaultValue="auto"
                          options={[
                            {
                              label: "Hide legend",
                              value: "hide",
                            },
                            {
                              label: "Auto",
                              value: "auto",
                            },
                          ]}
                          onChange={(value) => {
                            setVisOptions((current) => {
                              if (value === "hide") {
                                return {
                                  ...current,
                                  legend: {
                                    ...current.legend,
                                    enabled: false,
                                  },
                                }
                              } else {
                                return {
                                  ...current,
                                  legend: {
                                    ...current.legend,
                                    enabled: true,
                                    placement: value,
                                  },
                                }
                              }
                            })
                          }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={t("requests.legendOrder")}>
                      <Select
                        defaultValue="normal"
                        options={[
                          {
                            label: "Normal",
                            value: "normal",
                          },
                          {
                            label: "Reversed",
                            value: "reversed",
                          },
                        ]}
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            legend: {
                              ...current.legend,
                              traceorder: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("requests.stacking")}>
                      <Select
                        value={!!visOptions?.series?.stacking}
                        options={[
                          {
                            value: false,
                            label: "Disabled",
                          },
                          {
                            value: true,
                            label: "Stack",
                          },
                        ]}
                        disabled={
                          visOptions.globalSeriesType === "pie" ||
                          visOptions.globalSeriesType === "bubble" ||
                          visOptions.globalSeriesType === "scatter" ||
                          visOptions.globalSeriesType === "waterfall"
                        }
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            series: {
                              ...current.series,
                              stacking: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                  </>
                </Tabs.TabPane>
                {visOptions.globalSeriesType === "waterfall" && (
                  <Tabs.TabPane tab="Measure" key="measure">
                    <Table
                      dataSource={
                        !!selectedXColumn
                          ? rows.map((row, idx) => ({
                              name: row[selectedXColumn],
                              measure: visOptions.measure[idx],
                              order: idx,
                            }))
                          : []
                      }
                      columns={[
                        {
                          title: "X Names",
                          dataIndex: "name",
                          key: "name",
                        },
                        {
                          title: "Measure",
                          dataIndex: "measure",
                          key: "measure",
                          render: (_, record) => (
                            <Select
                              defaultValue="relative"
                              options={[
                                {
                                  label: "relative",
                                  value: "relative",
                                },
                                {
                                  label: "absolute",
                                  value: "absolute",
                                },
                                {
                                  label: "total",
                                  value: "total",
                                },
                              ]}
                              onChange={(value) => {
                                setVisOptions((prev) => {
                                  const updatedMeasure = [...prev.measure]
                                  updatedMeasure[record.order] = value
                                  return {
                                    ...prev,
                                    measure: updatedMeasure,
                                  }
                                })
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Tabs.TabPane>
                )}
                {visOptions.globalSeriesType !== "pie" && (
                  <>
                    <Tabs.TabPane tab={t("requests.xAxis")} key="xaxis">
                      <>
                        <Form.Item label={t("requests.scale")}>
                          <Select
                            defaultValue="-"
                            options={[
                              {
                                label: "Auto Detect",
                                value: "-",
                              },
                              {
                                label: "Datetime",
                                value: "date",
                              },
                              {
                                label: "Linear",
                                value: "linear",
                              },
                              {
                                label: "Logarithmic",
                                value: "log",
                              },
                              {
                                label: "Category",
                                value: "category",
                              },
                            ]}
                            onChange={(value) => {
                              setVisOptions((current) => ({
                                ...current,
                                xAxis: {
                                  ...current.xAxis,
                                  type: value,
                                },
                              }))
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={t("global.name")}>
                          <Input
                            onChange={(e) => {
                              setVisOptions((current) => ({
                                ...current,
                                xAxis: {
                                  ...current.xAxis,
                                  title: {
                                    ...current.xAxis.title,
                                    text: e.target.value,
                                  },
                                },
                              }))
                            }}
                          />
                        </Form.Item>
                        {/* <Form.Item label="Sort Values">
                    <Switch />
                  </Form.Item>
                  <Form.Item label="Reverse Order">
                    <Switch />
                  </Form.Item>
                  <Form.Item label="Show Labels">
                    <Switch />
                  </Form.Item> */}
                      </>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("requests.yAxis")} key="yaxis">
                      <>
                        <h4>{t("requests.leftXAxis")}</h4>
                        <Form.Item label={t("requests.scale")}>
                          <Select
                            defaultValue="linear"
                            options={[
                              {
                                label: "Datetime",
                                value: "date",
                              },
                              {
                                label: "Linear",
                                value: "linear",
                              },
                              {
                                label: "Logarithmic",
                                value: "log",
                              },
                              {
                                label: "Category",
                                value: "category",
                              },
                            ]}
                            onChange={(value) => {
                              setVisOptions((current) => ({
                                ...current,
                                yAxis: current.yAxis.map((item, index) => {
                                  if (index === 0) {
                                    return {
                                      ...item,
                                      type: value,
                                    }
                                  }
                                  return item
                                }),
                              }))
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={t("global.name")}>
                          <Input
                            onChange={(e) => {
                              setVisOptions((current) => ({
                                ...current,
                                yAxis: current.yAxis.map((item, index) => {
                                  if (index === 0) {
                                    return {
                                      ...item,
                                      title: {
                                        ...item.title,
                                        text: e.target.value,
                                      },
                                    }
                                  }
                                  return item
                                }),
                              }))
                            }}
                          />
                        </Form.Item>
                        <Space>
                          <Form.Item label={t("global.min")}>
                            <InputNumber
                              placeholder={t("global.auto")}
                              onChange={(value) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  yAxis: current.yAxis.map((item, index) => {
                                    if (index === 0) {
                                      if (value) {
                                        return {
                                          ...item,
                                          rangeMin: value,
                                        }
                                      }
                                    }
                                    return item
                                  }),
                                }))
                              }}
                            />
                          </Form.Item>
                          <Form.Item label={t("global.max")}>
                            <InputNumber
                              placeholder={t("global.auto")}
                              onChange={(value) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  yAxis: current.yAxis.map((item, index) => {
                                    if (index === 0) {
                                      return {
                                        ...item,
                                        rangeMax: value,
                                      }
                                    }
                                    return item
                                  }),
                                }))
                              }}
                            />
                          </Form.Item>
                        </Space>
                        <h4>{t("requests.rightXAxis")}</h4>
                        <Form.Item label={t("requests.scale")}>
                          <Select
                            defaultValue="linear"
                            options={[
                              {
                                label: "Datetime",
                                value: "date",
                              },
                              {
                                label: "Linear",
                                value: "linear",
                              },
                              {
                                label: "Logarithmic",
                                value: "log",
                              },
                              {
                                label: "Category",
                                value: "category",
                              },
                            ]}
                            onChange={(value) => {
                              setVisOptions((current) => ({
                                ...current,
                                yAxis: current.yAxis.map((item, index) => {
                                  if (index === 1) {
                                    return {
                                      ...item,
                                      type: value,
                                    }
                                  }
                                  return item
                                }),
                              }))
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={t("global.name")}>
                          <Input
                            onChange={(e) => {
                              setVisOptions((current) => ({
                                ...current,
                                yAxis: current.yAxis.map((item, index) => {
                                  if (index === 1) {
                                    return {
                                      ...item,
                                      title: {
                                        ...item.title,
                                        text: e.target.value,
                                      },
                                    }
                                  }
                                  return item
                                }),
                              }))
                            }}
                          />
                        </Form.Item>
                        <Space>
                          <Form.Item label={t("global.min")}>
                            <InputNumber
                              placeholder={t("global.auto")}
                              onChange={(value) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  yAxis: current.yAxis.map((item, index) => {
                                    if (index === 1) {
                                      return {
                                        ...item,
                                        rangeMin: value,
                                      }
                                    }
                                    return item
                                  }),
                                }))
                              }}
                            />
                          </Form.Item>
                          <Form.Item label={t("global.max")}>
                            <InputNumber
                              placeholder={t("global.auto")}
                              onChange={(value) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  yAxis: current.yAxis.map((item, index) => {
                                    if (index === 1) {
                                      return {
                                        ...item,
                                        rangeMax: value,
                                      }
                                    }
                                    return item
                                  }),
                                }))
                              }}
                            />
                          </Form.Item>
                        </Space>
                      </>
                    </Tabs.TabPane>
                  </>
                )}
                <Tabs.TabPane tab={t("requests.series")} key="series">
                  <>
                    <Table
                      columns={[
                        {
                          title: t("requests.order"),
                          dataIndex: "order",
                          key: "order",
                        },
                        {
                          title: t("requests.label"),
                          dataIndex: "label",
                          key: "order",
                          align: "center",
                          render: (value, record) => (
                            <Input
                              placeholder={value}
                              onChange={(e) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  seriesOptions: {
                                    ...current.seriesOptions,
                                    [record.label]: {
                                      ...current.seriesOptions[record.label],
                                      name: e.target.value,
                                    },
                                  },
                                }))
                              }}
                            />
                          ),
                        },
                        {
                          title: t("requests.yAxis"),
                          key: "yaxis",
                          align: "center",
                          render: (_, record) => (
                            <Radio.Group
                              defaultValue="left"
                              onChange={(e) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  seriesOptions: {
                                    ...current.seriesOptions,
                                    [record.label]: {
                                      ...current.seriesOptions[record.label],
                                      yAxis: e.target.value === "right" ? 1 : 0,
                                    },
                                  },
                                }))
                              }}
                            >
                              <Space size={1}>
                                <Radio value="left">{t("global.left")}</Radio>
                                <Radio value="right">{t("global.right")}</Radio>
                              </Space>
                            </Radio.Group>
                          ),
                        },
                        {
                          title: t("global.type"),
                          key: "type",
                          render: (_, record) => (
                            <Select
                              defaultValue={visOptions.globalSeriesType}
                              options={ChartTypesOptions}
                              onChange={(value) => {
                                setVisOptions((current) => ({
                                  ...current,
                                  seriesOptions: {
                                    ...current.seriesOptions,
                                    [record.label]: {
                                      ...current.seriesOptions[record.label],
                                      type: value,
                                    },
                                  },
                                }))
                              }}
                            />
                          ),
                        },
                      ]}
                      dataSource={selectedYColumns.map((y, idx) => ({
                        order: idx + 1,
                        label: y,
                      }))}
                    />
                  </>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("requests.colors")} key="colors">
                  <>
                    <Form.Item label="Color palettes:">
                      <Select
                        placeholder="Choose Customize color palettes"
                        popupMatchSelectWidth={false}
                        labelInValue={true}
                        options={colorPalettes.map((option) => ({
                          label: option.label,
                          value: JSON.stringify(option.value),
                        }))}
                        onChange={(value) => {
                          console.log(value)
                          setVisOptions((current) => ({
                            ...current,
                            colorPalettes:
                              value.value === "[]"
                                ? undefined
                                : JSON.parse(value.value),
                          }))
                        }}
                      />
                    </Form.Item>

                    {visOptions.globalSeriesType === "pie" ? (
                      <List
                        itemLayout="horizontal"
                        size="small"
                        dataSource={
                          !!selectedXColumn
                            ? [
                                ...new Set(
                                  rows.map((row) => row[selectedXColumn])
                                ),
                              ].map((name) => ({ name }))
                            : []
                        }
                        renderItem={(item, index) => (
                          <List.Item
                            actions={[
                              <ColorPicker
                                onChangeComplete={(color) => {
                                  setVisOptions((current) => ({
                                    ...current,
                                    colors: {
                                      ...current.colors,
                                      [index]:
                                        color.toHexString().toUpperCase() ===
                                        "TRANSPARENT"
                                          ? null
                                          : color.toHexString().toUpperCase(),
                                    },
                                  }))
                                }}
                              />,
                            ]}
                          >
                            {item.name}
                          </List.Item>
                        )}
                      />
                    ) : (
                      <List
                        itemLayout="horizontal"
                        size="small"
                        dataSource={selectedYColumns}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <ColorPicker
                                onChangeComplete={(color) => {
                                  setVisOptions((current) => ({
                                    ...current,
                                    seriesOptions: {
                                      ...current.seriesOptions,
                                      [item]: {
                                        ...current.seriesOptions[item],
                                        color:
                                          color.toHexString().toUpperCase() ===
                                          "TRANSPARENT"
                                            ? null
                                            : color.toHexString().toUpperCase(),
                                      },
                                    },
                                  }))
                                }}
                              />,
                            ]}
                          >
                            {item}
                          </List.Item>
                        )}
                      />
                    )}
                  </>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("requests.dataLabel")} key="labels">
                  <>
                    <Form.Item>
                      <Checkbox
                        checked={visOptions.showDataLabels}
                        onChange={(event) =>
                          handleOptionChange(
                            event.target.checked,
                            "showDataLabels"
                          )
                        }
                      >
                        {t("requests.showDataLabel")}
                      </Checkbox>
                    </Form.Item>
                    <Form.Item label={t("requests.numberFormat")}>
                      <Input
                        defaultValue={visOptions.numberFormat}
                        onChange={(event) =>
                          handleOptionChange(event.target.value, "numberFormat")
                        }
                      />
                    </Form.Item>
                    <Form.Item label={t("requests.percentFormat")}>
                      <Input
                        defaultValue={visOptions.percentFormat}
                        onChange={(event) =>
                          handleOptionChange(
                            event.target.value,
                            "percentFormat"
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item label={t("requests.dateTimeFormat")}>
                      <Input
                        defaultValue={visOptions.dateTimeFormat}
                        onChange={(event) =>
                          handleOptionChange(
                            event.target.value,
                            "dateTimeFormat"
                          )
                        }
                      />
                    </Form.Item>
                  </>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("requests.drilldownUrl")} key="drilldown">
                  <>
                    <Form.Item label={t("requests.urlTemplate")}>
                      <Input
                        defaultValue={visualization?.options?.linkUrlTemplate}
                        onChange={(event) =>
                          handleOptionChange(
                            event.target.value,
                            "linkUrlTemplate"
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        defaultChecked={
                          visualization?.options?.linkOpenInNewTab
                        }
                        onChange={(event) =>
                          handleOptionChange(
                            event.target.checked,
                            "linkOpenInNewTab"
                          )
                        }
                      >
                        {t("requests.openNewTap")}
                      </Checkbox>
                    </Form.Item>
                  </>
                </Tabs.TabPane>
              </Tabs>
            )}

            {visType === "PIVOT" && (
              <>
                <Form.Item>
                  <Switch
                    checked={visOptions?.controls?.enabled}
                    onChange={(value) => {
                      setVisOptions((current) => ({
                        ...current,
                        controls: {
                          ...current.controls,
                          enabled: !visOptions?.controls?.enabled,
                        },
                      }))
                    }}
                  />
                  <span style={{ paddingLeft: "0.5rem" }}>
                    {t("requests.pivotControls")}
                  </span>
                </Form.Item>
                <Form.Item>
                  <Switch
                    checked={visOptions?.rendererOptions?.table?.rowTotals}
                    onChange={(value) => {
                      setVisOptions((current) => ({
                        ...current,
                        rendererOptions: {
                          ...current.rendererOptions,
                          table: {
                            ...current.rendererOptions.table,
                            rowTotals:
                              !visOptions?.rendererOptions?.table?.rowTotals,
                          },
                        },
                      }))
                    }}
                  />
                  <span style={{ paddingLeft: "0.5rem" }}>
                    {t("requests.rowsControls")}
                  </span>
                </Form.Item>
                <Form.Item>
                  <Switch
                    checked={visOptions?.rendererOptions?.table?.colTotals}
                    onChange={(value) => {
                      setVisOptions((current) => ({
                        ...current,
                        rendererOptions: {
                          ...current.rendererOptions,
                          table: {
                            ...current.rendererOptions.table,
                            colTotals:
                              !visOptions?.rendererOptions?.table?.colTotals,
                          },
                        },
                      }))
                    }}
                  />
                  <span style={{ paddingLeft: "0.5rem" }}>
                    {t("requests.colsTotals")}
                  </span>
                </Form.Item>
              </>
            )}

            {visType === "WORD_CLOUD" && (
              <>
                <Form.Item label={t("requests.wordcols")}>
                  <Select
                    value={visOptions.column}
                    options={columns.map((c) => ({
                      label: c.friendly_name,
                      value: c.name,
                    }))}
                    onChange={(event) => handleOptionChange(event, "column")}
                  />
                </Form.Item>
                <Form.Item label={t("requests.freqCols")}>
                  <Select
                    placeholder={t("requests.counter")}
                    value={visOptions.frequenciesColumn}
                    options={columns
                      .map((c) => ({
                        label: c.friendly_name,
                        value: c.name,
                      }))
                      .concat({
                        label: t("requests.freqColsDesc"),
                        value: "",
                      })}
                    onChange={(event) =>
                      handleOptionChange(event, "frequenciesColumn")
                    }
                  />
                </Form.Item>
                <Form.Item label={t("requests.wordsLimit")}>
                  <Space>
                    <Form.Item>
                      <InputNumber
                        placeholder={t("global.min")}
                        min={1}
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            wordLengthLimit: {
                              ...current.wordLengthLimit,
                              min: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <InputNumber
                        placeholder={t("global.max")}
                        min={1}
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            wordLengthLimit: {
                              ...current.wordLengthLimit,
                              max: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item label={t("requests.freqLimit")}>
                  <Space>
                    <Form.Item>
                      <InputNumber
                        placeholder={t("global.min")}
                        min={1}
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            wordCountLimit: {
                              ...current.wordCountLimit,
                              min: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <InputNumber
                        placeholder={t("global.max")}
                        min={1}
                        onChange={(value) => {
                          setVisOptions((current) => ({
                            ...current,
                            wordCountLimit: {
                              ...current.wordCountLimit,
                              max: value,
                            },
                          }))
                        }}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </>
            )}
          </Form>
        </Col>
        <Col sm={24} md={16} style={{ height: "100%", overflow: "auto" }}>
          <div className="visualization">
            <Vis
              widget={{
                visualization: {
                  type: visType,
                  name: visName,
                  options: visOptions,
                },
              }}
              data={data}
              params={params}
              // key={visOptions}
              onEdit={(value) => setVisOptions(value)}
            />
          </div>
        </Col>
      </Row>
      <FormModal.Footer>
        <Button
          size="sm"
          type="secondary"
          onClick={handelSaveConfirm}
          loading={createVis.isLoading || updateVis.isLoading}
          disabled={createVis.isLoading || updateVis.isLoading}
        >
          {t("global.save")}
        </Button>

        <Button size="sm" onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </>
  )
}

const FormContainer = styled.div`
  .visualization {
    height: 100%;
    overflow: auto;
    padding-top: 1rem;

    .widget-chart {
      height: 100%;
    }

    .widget-error {
      background-color: #f77b72;
      color: #fff;
      padding: 0.5rem;
    }

    .widget-loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export default CreateRequestVisualization
