import React, { useEffect } from "react"
import * as FullStory from "@fullstory/browser"
import config from "config/analytics"
import useFullStory from "hooks/useFullStory"
import AnalyticsServiceProvider, { useAnalytics } from "hooks/useAnalytics"
import useTracker from "hooks/useOpenReplay"
import useSegment from "hooks/useSegment"
import { useAuth } from "context/AuthContext"

function WithAnalytics({
  customerId,
}: {
  customerId: string
  tenantName?: string
}) {
  // segment section
  useSegment(config.segment.enabled ? config.segment.token : "")
  const analyticsService = useAnalytics()
  useEffect(() => {
    analyticsService.identify(customerId)
  }, [analyticsService, customerId])

  // openreplay section
  const tracker = useTracker(config.openreplay)
  useEffect(() => {
    tracker.userID(customerId)
  }, [tracker, customerId])

  // fullstory section
  const initializedFullstory = useFullStory(config.fullstory)
  useEffect(() => {
    if (initializedFullstory) {
      FullStory.identify(customerId)
    }
  }, [initializedFullstory, customerId])

  return null
}

const AnalyticsLauncher: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { session } = useAuth()
  const customerId = session?.user?.id

  return (
    <AnalyticsServiceProvider userId={customerId} version={config.version}>
      <WithAnalytics customerId={customerId} />
      {children}
    </AnalyticsServiceProvider>
  )
}

export { AnalyticsLauncher }
