import React, { useState } from "react"
import styled from "styled-components"
import {
  Input,
  Tooltip,
  message,
  Tag,
  Space,
  Divider,
  Avatar,
  InputNumber,
} from "antd"
import Button from "components/shared/Button"
import Icon from "components/shared/Icon/Icon"
import { useRegenerateApi, useUpdateUser } from "hooks/users"
import { useTranslation } from "react-i18next"
import Text from "components/shared/Text"

const AccountDetails = ({ user, allGroups }) => {
  const { id, profile_image_url, name, email, api_key, phone } = user
  const groupsOptions =
    allGroups && Array.isArray(allGroups)
      ? allGroups?.map((m) => ({ label: m.name, value: m.id }))
      : []

  const [userName, setUserName] = useState(name)
  const [userEmail, setUserEmail] = useState(email)
  const [userPhone, setUserPhone] = useState(phone)
  const [phoneError, setPhoneError] = useState("")
  const { t } = useTranslation()
  const { mutate: generateApi, isLoading: generateLoading } = useRegenerateApi()
  const { mutate: update, isLoading: updateLoading } = useUpdateUser()

  const handleCopy = () => {
    navigator.clipboard.writeText(api_key)
    message.success(t("account.copied"))
  }

  const onRegenerateApi = () => {
    generateApi(
      {
        id: id,
      },
      {
        onSuccess: () => {
          message.success({
            content: t("account.regenerateKeySucces"),
          })
        },
        onError: () => {
          message.error({
            content: t("account.regenerateKeyFaild"),
          })
        },
      }
    )
  }

  const onUpdateUser = (id, data) => {
    update(
      {
        id: id,
        data: data,
      },
      {
        onSuccess: () => {
          message.success({
            content: "Successfuly Update Profile",
          })
        },
        onError: () => {
          message.error({
            content: "Failed Update Profile",
          })
        },
      }
    )
  }

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d+/
    if (!phoneRegex.test(phone)) {
      setPhoneError("Invaild Phone")
    } else {
      setPhoneError("")
    }
  }

  const onPhoneChange = (e) => {
    const value = e.target.value
    setUserPhone(value)
    // validatePhone(value)
  }

  const onFinish = () => {
    if (!phoneError) {
      onUpdateUser(id, {
        ...user,
        name: userName,
        email: userEmail,
        phone: userPhone,
      })
    } else {
      message.error({
        content: "Enter Valid Phone with Country Code",
      })
    }
  }

  return (
    <Details>
      <div className="header">
        <Space direction="vertical">
          <Text size="xl">{t("settings.myDetails.subTitle")}</Text>
          <Text size="xs" color="gray-1">
            {t("settings.myDetails.desc")}
          </Text>
        </Space>
      </div>
      <Divider />
      <div className="containt">
        <div className="row">
          <p>{t("settings.myDetails.photo")}</p>
          <div style={{ width: "100%" }}>
            <Avatar src={profile_image_url} size={64} />
          </div>
        </div>
        <Divider />
        <div className="row">
          <p>{t("global.tabels.name")}</p>
          <Input
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            size="large"
            style={{ minWidth: "250px" }}
          />
        </div>
        <Divider />
        <div className="row">
          <p>{t("global.tabels.email")}</p>
          <Input
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            size="large"
            style={{ minWidth: "250px" }}
          />
        </div>
        <Divider />
        <div className="row">
          <p>{t("global.phone")}</p>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={userPhone}
              onChange={onPhoneChange}
              size="large"
              style={{ minWidth: "250px" }}
            />
            {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}
          </Space>
        </div>
        <Divider />
        <div className="row">
          <p>{t("global.tabels.groups")}</p>
          <div style={{ width: "100%" }}>
            {groupsOptions?.map((group) => {
              return (
                <Tag
                  key={group?.value}
                  style={{ fontSize: "1rem", margin: "0.3rem" }}
                >
                  <small>{group?.label}</small>
                </Tag>
              )
            })}
          </div>
        </div>
        <Divider />
        <div
          className="row"
          style={{
            textAlign: "right",
          }}
        >
          <p>{t("requests.apiKey")}</p>
          <Input.Group compact>
            <Input
              style={{
                width: "calc(100% - 60px)",
                textAlign: "left",
                cursor: "default",
                minWidth: "250px",
              }}
              value={api_key}
              disabled
            />
            <Tooltip title={t("account.copyApi")}>
              <Button
                size="sm"
                type="primary"
                style={{ width: "20px" }}
                icon={<Icon type="copy" />}
                onClick={() => handleCopy()}
              />
            </Tooltip>
            <Button
              type="primary"
              shape="outlined"
              onClick={() => onRegenerateApi()}
              disable={generateLoading}
              loading={generateLoading}
              className="genrrateBtn"
            >
              {t("users.regenerate")}
            </Button>
          </Input.Group>
        </div>
      </div>
      <Divider />
      <div className="footer">
        <Button
          type="primary"
          size="sm"
          htmlType="submit"
          onClick={onFinish}
          disable={updateLoading}
          loading={updateLoading}
        >
          {t("global.save")}
        </Button>
      </div>
    </Details>
  )
}

const Details = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  /* padding: 1rem; */

  & input {
    border-radius: 8px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100px;
      border-radius: 50%;
      margin: 1rem auto 0;
    }
  }

  .containt {
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-top: 1rem;

      & p {
        width: 400px;
        font-weight: 500;
        text-align: left;
      }

      & .genrrateBtn {
        margin-top: 1rem;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export default AccountDetails
