import { Avatar, Col, List, Row, Skeleton, Space } from "antd"
import Icon from "components/shared/Icon"
import { useETLSources } from "hooks/ETL/sources"
import React from "react"
import styled from "styled-components"

const SourceConfig = ({ onNext }) => {
  const { data: sources, isLoading } = useETLSources()

  return (
    <div>
      <Header>
        <h2>Define source</h2>
      </Header>
      <Content>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <List
            size="large"
            dataSource={sources.sources}
            renderItem={(item) => (
              <List.Item className="listItem" onClick={() => onNext(item)}>
                <Row style={{ width: "100%" }}>
                  <Col span={8}>{item.name}</Col>
                  <Col span={8}>
                    <Space>
                      <Avatar src={item?.icon} shape="square" />
                      <span>{item?.sourceName}</span>
                    </Space>
                  </Col>
                  <Col span={8} style={{ textAlign: "right" }}>
                    <Icon type="right" />
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        )}
      </Content>
    </div>
  )
}

const Header = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const Content = styled.div`
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #fff;

  .listItem:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .listItem:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .listItem:hover {
    background-color: #efefef;
    cursor: pointer;
  }

  .sourceForm {
    padding: 1rem;

    & button {
      position: absolute;
      right: 0;
    }
  }
`

export default SourceConfig
