import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  getGroup,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroupPage,
  getGroupMembers,
  getGroupDataSources,
  deleteGroupMembers,
  deleteGroupDataSources,
  addGroupMember,
  addGroupDataSources,
  getGroups,
} from "apis/groups"

const keys = {
  all: ["groups"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  results: () => [...keys.all, "result"],
  result: (id) => [...keys.results(), id],
  searchs: () => [...keys.all, "search"],
  search: (name) => [...keys.searchs(), name],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

function useGroupsPage(pagination) {
  return useQuery({
    queryKey: keys.list(pagination),
    queryFn: () => getGroupPage(pagination),
    ...defaultQueryConfig,
  })
}

function useGroups() {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getGroups(),
    ...defaultQueryConfig,
  })
}

function useGroup(id) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getGroup(id),
    ...defaultQueryConfig,
  })
}

function useGroupMembers(id) {
  return useQuery({
    queryKey: keys.details(),
    queryFn: () => getGroupMembers(id),
    // ...defaultQueryConfig,
  })
}

function useGroupDataSources(id) {
  return useQuery({
    queryKey: keys.details(),
    queryFn: () => getGroupDataSources(id),
    // ...defaultQueryConfig,
  })
}

function useCreateGroup() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createGroup(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useUpdateGroup() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => updateGroup(id, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useDeleteGroup() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => deleteGroup(id),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useDeleteGroupMember() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, memberId }) => deleteGroupMembers(id, memberId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.details())
    },
  })
}

function useAddGroupMember() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => addGroupMember(id, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.details())
    },
  })
}

function useDeleteGroupSource() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, sourceId }) => deleteGroupDataSources(id, sourceId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.details())
    },
  })
}

function useAddGroupSource() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => addGroupDataSources(id, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.details())
    },
  })
}

export {
  useGroups,
  useGroupsPage,
  useGroup,
  useCreateGroup,
  useUpdateGroup,
  useDeleteGroup,
  useGroupMembers,
  useGroupDataSources,
  useDeleteGroupMember,
  useDeleteGroupSource,
  useAddGroupMember,
  useAddGroupSource,
}
