import React, { useState } from "react"
import {
  useDeleteETLConnections,
  useETLConnections,
  useSyncETLConnection,
  useUpdateETLConnections,
} from "hooks/ETL/connections"
import { useTranslation } from "react-i18next"
import {
  Avatar,
  Modal,
  Result,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import { formatDistanceToNow, fromUnixTime } from "date-fns"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
  PauseCircleTwoTone,
  StopTwoTone,
  SyncOutlined,
} from "@ant-design/icons"
import styled from "styled-components"

const ConnectionsList = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useETLConnections()
  const { mutate: sync, isLoading: syncLoading } = useSyncETLConnection()
  const { mutate: update, isLoading: updateLoading } = useUpdateETLConnections()
  const { mutateAsync } = useDeleteETLConnections()

  const [syncingConnectionId, setSyncingConnectionId] = useState(null)
  const [updateingConnectionId, setUpdateingConnectionId] = useState(null)

  const handelDeleteConnection = (connectionId) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("connections.deleteConnection"),
      content: t("connections.deleteConnectionConfirm"),
      onOk() {
        return mutateAsync(connectionId, {
          onSuccess: (data) => {
            if (data.message) {
              notification.error({
                message: t("connections.failedDelete"),
              })
            } else {
              notification.success({
                message: t("connections.successfullyDelete"),
              })
            }
          },
        })
      },
      onCancel() {},
    })
  }

  const handelSyncConnection = (connectionId) => {
    setSyncingConnectionId(connectionId)
    sync(connectionId, {
      onSuccess: (data) => {
        if (data?.job?.status === "succeeded") {
          notification.success({
            message: t("connections.successfullySync"),
          })
        } else if (data?.job?.status === "failed") {
          notification.error({
            message: t("connections.failedSync"),
          })
        } else if (data?.message) {
          notification.success({
            message: t("connections.syncTakeTime"),
          })
        }
      },
      onError: () => {
        notification.error({
          message: t("global.session.somethingWrong"),
        })
      },
      onSettled: () => {
        setSyncingConnectionId(null)
      },
    })
  }

  const handelUpdateConnectionStatus = (connectionId, status) => {
    setUpdateingConnectionId(connectionId)
    update(
      {
        connectionId,
        data: {
          status: status,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("connections.successfullyUpdate"),
          })
        },
        onError: () => {
          notification.error({
            message: t("connections.failedUpdate"),
          })
        },
        onSettled: () => {
          setUpdateingConnectionId(null)
        },
      }
    )
  }

  const columns = [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
      render: (_, object) => {
        return (
          <Space>
            {object?.isSyncing ? (
              <Tooltip title="Sync is running">
                <SyncOutlined spin style={{ minWidth: "20px" }} />
              </Tooltip>
            ) : object?.latestSyncJobStatus ? (
              object?.latestSyncJobStatus === "failed" &&
              object.status === "inactive" ? (
                <Tooltip
                  title="Disabled connection"
                  arrow={false}
                  placement="bottom"
                >
                  <PauseCircleTwoTone
                    twoToneColor="#AAA"
                    style={{ fontSize: "1.1rem", minWidth: "20px" }}
                  />
                </Tooltip>
              ) : object?.latestSyncJobStatus === "failed" &&
                object.status === "active" ? (
                <Tooltip title="Failed sync" arrow={false} placement="bottom">
                  <CloseCircleTwoTone
                    twoToneColor="#eb2f96"
                    style={{ fontSize: "1.1rem", minWidth: "20px" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Succeeded sync"
                  arrow={false}
                  placement="bottom"
                >
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "1.1rem", minWidth: "20px" }}
                  />
                </Tooltip>
              )
            ) : (
              <Tooltip
                title="Sync was not started"
                arrow={false}
                placement="bottom"
              >
                <StopTwoTone
                  twoToneColor="#AAA"
                  style={{ fontSize: "1.1rem", minWidth: "20px" }}
                />
              </Tooltip>
            )}
            <CustomLink to={`/connections/${object?.connectionId}`}>
              <Text>{object?.name}</Text>
            </CustomLink>
          </Space>
        )
      },
    },
    {
      title: t("global.tabels.source"),
      key: "source",
      dataIndex: "source",
      // sorter: (a, b) => a.source.name.localeCompare(b.source.name),
      render: (source) => (
        <Space key={source?.sourceId}>
          <Avatar src={source?.icon} shape="square" />
          <span>{source?.name}</span>
        </Space>
      ),
    },
    {
      title: t("global.tabels.destination"),
      key: "destination",
      dataIndex: "destination",
      // sorter: (a, b) => a.destination.name.localeCompare(b.destination.name),
      render: (destination) => (
        <Space key={destination?.destinationId}>
          <Avatar src={destination?.icon} shape="square" />
          <span>{destination?.name}</span>
        </Space>
      ),
    },
    {
      title: t("global.tabels.frequence"),
      key: "schedule",
      dataIndex: "schedule",
      render: (_, object) => {
        return object?.scheduleType === "manual"
          ? "Manual"
          : `${object?.scheduleData?.basicSchedule?.units} ${object?.scheduleData?.basicSchedule?.timeUnit}`
      },
    },
    {
      title: t("global.tabels.lastSync"),
      key: "latestSyncJobCreatedAt",
      dataIndex: "latestSyncJobCreatedAt",
      // sorter: (a, b) => fromUnixTime(a.latestSyncJobCreatedAt) - fromUnixTime(b.latestSyncJobCreatedAt),
      render: (latestSyncJobCreatedAt) => {
        return latestSyncJobCreatedAt
          ? formatDistanceToNow(fromUnixTime(latestSyncJobCreatedAt))
          : null
      },
    },
    {
      key: "actions",
      render: (connection) => {
        return (
          <Space key={connection.connectionId}>
            <IconButton
              type="transparent"
              shape="round"
              size="sm"
              danger
              onClick={() => handelDeleteConnection(connection.connectionId)}
            >
              <Icon type="delete" />
            </IconButton>
            {connection?.scheduleType === "manual" ? (
              <IconButton
                type="transparent"
                shape="round"
                size="sm"
                key={connection.connectionId}
                onClick={() => handelSyncConnection(connection.connectionId)}
                loading={
                  syncingConnectionId === connection.connectionId ||
                  connection.isSyncing
                }
                disabled={syncLoading || connection.isSyncing}
              >
                <Tooltip title="sync now">
                  <Icon type="sync" />
                </Tooltip>
              </IconButton>
            ) : (
              <Switch
                style={{ width: "30px" }}
                size="small"
                checked={connection.status === "active"}
                loading={
                  updateingConnectionId === connection.connectionId ||
                  connection.isSyncing
                }
                disabled={updateLoading || connection.isSyncing}
                onClick={(value) =>
                  handelUpdateConnectionStatus(
                    connection.connectionId,
                    value ? "active" : "inactive"
                  )
                }
              />
            )}
          </Space>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  if (isError || data?.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <Space direction="vertical" size={0}>
          <h2>
            {t("connections.allconnection")}
            {": "}{" "}
            <span>
              {data?.connections?.length} {t("connections.connection")}
            </span>
          </h2>
          {/* <Text size="xs">keep tracking your connections easily</Text> */}
        </Space>
      </div>
      <Table
        scroll={{ x: "800px" }}
        rowKey={(c) => c.connectionId}
        columns={columns}
        dataSource={data.connections}
      />
    </Wrapper>
  )
}

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;

  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
`

export default ConnectionsList
