import React from "react"
import RequestVisualizations from "../RequestVisualizations"
import RequestBody from "../Body/RequestBody"
import { useRequestState } from "../Provider"

const TabContent = () => {
  const { isDrillDown } = useRequestState()
  return (
    <>
      {
        {
          vis: <RequestVisualizations />,
          query: <RequestBody />,
        }[isDrillDown ? "vis" : "query"]
      }
    </>
  )
}

export default TabContent
