import styled from "styled-components"
import { Avatar, Button, Layout, Space } from "antd"
import { useAppContext } from "context/AppContext"
import Icon from "components/shared/Icon"
import CustomLink from "components/shared/CustomLink/CustomLink"
import golfLogo from "static/images/GolfLogo.png"
import NewLogo from "static/images/NewiDataWorkeroLogo.svg"
import LogoBg from "static/images/logobg.svg"
import LogoVector from "static/images/LogoVector.svg"
import { useAuth } from "context/AuthContext"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

function Sidebar({
  isCollapsed,
  fullScreen,
  direction,
  children,
  tenantName,
  Logo,
  IS_ADMIN,
}) {
  const { t } = useTranslation()
  const { toggleSidebar } = useAppContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { session, logout } = useAuth()
  const user = session?.user

  const isSettingsActive =
    pathname.endsWith("/profile") ||
    pathname.endsWith("/users") ||
    pathname.endsWith("/groups") ||
    pathname.endsWith("/channels") ||
    pathname.endsWith("/channels") ||
    pathname.endsWith("/query_snippets") ||
    pathname.endsWith("/general")

  return (
    <Wrapper
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      fullscreen={fullScreen ? "true" : undefined}
      direction={direction}
    >
      <div className="logo-wrapper">
        <div className="logo-vector" />
        <CustomLink to="/">
          <img
            src={tenantName === "enwz" ? golfLogo : Logo ? Logo : NewLogo}
            alt="idataworkers logo"
            className="logoImg"
          />
        </CustomLink>
      </div>
      <Button
        size="small"
        className="toggleBtn"
        id="toggleBtn"
        onClick={toggleSidebar}
      >
        <Icon type={`${isCollapsed ? "right" : "left"}`} />
      </Button>
      <div className="sidebar-menu">{children}</div>
      <div className="siderbar-footer">
        <div className="footer-profile">
          <CustomLink to={`/settings/profile`}>
            <Space>
              <Avatar
                size={24}
                style={{ marginRight: "-3px" }}
                src={user?.profile_image_url}
                alt={user?.name}
              />
              <div className="footer-name">
                <p>{user?.name}</p>
                <small>{user?.email}</small>
              </div>
            </Space>
          </CustomLink>
          <Button
            ghost
            icon={<Icon type="logout" />}
            onClick={() => logout()}
            style={{
              marginRight: "0.2rem",
            }}
          />
        </div>
        <Button
          size="large"
          icon={<Icon type="settings" />}
          onClick={() => navigate(`/${tenantName}/settings/profile`)}
          className={`bottomBtns profileBtn ${
            isSettingsActive ? "active" : ""
          }`}
        >
          {isCollapsed ? "" : t("global.pages.settings")}
        </Button>

        <Button
          size="large"
          icon={<Icon type="question" />}
          onClick={() =>
            navigate(`/${tenantName}/?tour=${IS_ADMIN ? "admin" : "default"}`)
          }
          className="bottomBtns helpBtn"
        >
          {isCollapsed ? "" : t("home.help")}
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Layout.Sider)`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #00244d;
  transition: all 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: ${({ collapsed }) => (collapsed ? "80px" : "240px")} !important;
  margin-left: ${({ fullscreen, direction }) =>
    fullscreen && direction === "ltr" ? "-200rem" : "0"};
  margin-right: ${({ fullscreen, direction }) =>
    fullscreen && direction === "rtl" ? "-200rem" : "0"};

  .toggleBtn {
    position: absolute;
    top: 100px;
    right: ${({ direction }) => (direction === "rtl" ? "unset" : "-2px")};
    left: ${({ direction }) => (direction === "rtl" ? "-2px" : "unset")};
    color: #fff;
    background: #26b3db;
    border: none;

    &:hover {
      color: #fff;
    }
  }

  .ant-layout-sider-children {
    ${(props) => props.theme.mixins.flexColumn};
  }

  &.ant-layout-sider-collapsed {
    width: var(--sidebar-with-collapsed);
  }

  .ant-menu,
  .ant-menu-submenu {
    background: transparent;
  }

  .ant-menu-item {
    color: #becede;
    opacity: 0.8;
    transition: all 0.2s ease-in;

    & > span {
      color: #becede;
      opacity: 0.8;

      &:hover {
        color: #becede;
        opacity: 1;
      }
    }

    .ant-menu-item-icon {
      color: #becede;
      opacity: 0.8;

      &:hover {
        color: #becede;
        opacity: 1;
      }
    }

    &.ant-menu-item-active,
    &.ant-menu-item-selected {
      color: #fff;
      opacity: 1;
    }

    &.ant-menu-item-selected {
      background: linear-gradient(107deg, #336094 13.39%, #449eb8 77.64%);
      width: 80%;
      color: #fff;
      outline: none;
      /* margin-left: ${({ direction }) =>
        direction === "rtl" ? "0" : "-2px"}; */
      /* margin-right: ${({ direction }) =>
        direction === "ltr" ? "0" : "-2px"}; */
      border-top-right-radius: ${({ direction }) =>
        direction === "rtl" ? "0" : "1rem"};
      border-bottom-right-radius: ${({ direction }) =>
        direction === "rtl" ? "0" : "1rem"};
      border-top-left-radius: ${({ direction }) =>
        direction === "rtl" ? "1rem" : "0"};
      border-bottom-left-radius: ${({ direction }) =>
        direction === "rtl" ? "1rem" : "0"};
    }

    &.ant-menu-item::after {
      border: none;
    }
  }

  .ant-menu-submenu-title {
    color: #becede;
    opacity: 0.8;

    &:hover {
      color: #becede;
      opacity: 1;

      .ant-menu-submenu-arrow {
        color: inherit;
      }
    }

    .ant-menu-submenu-arrow {
      color: inherit;
    }
  }

  .ant-menu-vertical,
  .ant-menu-inline {
    border-right: none;
  }

  .logo {
    display: none;
  }

  .menu-item {
    a {
      color: #becede;
      opacity: 0.8;

      &:hover {
        color: #becede;
        opacity: 1;
      }
    }

    & li > span {
      color: #becede;
      opacity: 0.8;

      &:hover {
        color: #becede;
        opacity: 1;
      }
    }
  }

  .logo-wrapper {
    position: relative;
    width: 100%;
    height: 120px;
    background: url(${LogoBg});
    background-position: ${({ collapsed }) => (collapsed ? "right" : "bottom")};
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    margin-bottom: 1rem;
    padding: 0 0.2rem;

    .logo-vector {
      display: ${({ collapsed }) => (collapsed ? "none" : "block")};
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${LogoVector});
      background-repeat: no-repeat;
      background-size: contain;
    }

    & a {
      display: block;
      width: 100%;
      height: 65px;
      margin-top: 1rem;
      position: relative;
      z-index: 99;

      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .siderbar-footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 1rem;
    font-size: small;
    color: #fff;

    & .footer-profile {
      display: flex;
      flex-direction: ${({ collapsed }) => (collapsed ? "column" : "row")};
      align-items: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
      justify-content: space-between;
      border-bottom: 1px solid #fff;
      padding-bottom: 1rem;
      padding-left: 0.9rem;
    }

    & p {
      display: ${({ collapsed }) => (collapsed ? "none" : "block")};
      margin: 0;
      font-weight: 500;
      color: #becede;
    }

    & small {
      display: ${({ collapsed }) => (collapsed ? "none" : "block")};
      margin: 0;
      font-weight: 400;
      color: #becede;
    }

    & button {
      border: none;
      background: transparent;
    }

    & .ant-btn.ant-btn-background-ghost {
      color: transparent;
    }

    & .menu {
      &.ant-menu-item-selected {
        background-color: transparent !important;
      }
    }

    & .bottomBtns {
      margin-left: ${({ collapsed }) => (collapsed ? "auto" : "0.7rem")};
      margin-right: ${({ collapsed }) => (collapsed ? "auto" : "0.7rem")};
      display: flex;
      align-items: center;
      justify-content: ${({ collapsed }) =>
        collapsed ? "center" : "flex-start"};
      padding-top: 1rem;
      padding-left: 0.4rem;
      color: #becede !important;
    }

    & .bottomBtns:hover {
      background-color: transparent !important;
      color: #fff !important;
    }

    & .helpBtn {
      margin-bottom: 1rem;
    }

    & .active {
      color: rgba(68, 158, 184, 1) !important;
    }
  }

  @media only screen and (min-width: 768px) {
    & {
      display: block;
    }
  }
`

export default Sidebar
