import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import useDebounce from "hooks/useDebounce"
import useQueryParams from "hooks/useQueryParams"
import React from "react"
import styled from "styled-components"
import CreateNewUser from "components/users/CreateNewUser"
import Table from "components/users/Tabel"
import Icon from "components/shared/Icon"
import { useAuth } from "context/AuthContext"
import { Input, Radio, Space } from "antd"
import { useTranslation } from "react-i18next"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"

function Header({ isAdmin }) {
  const { t, i18n } = useTranslation()
  const [name, setName] = React.useState("")
  const [queryParams, setQueryParams] = useQueryParams({
    disabled: String,
    pending: String,
  })

  const debounced = useDebounce(name.trim())

  const btnValue = queryParams?.disabled
    ? "disabled"
    : queryParams.pending
    ? "pending"
    : "active"

  React.useEffect(() => {
    setQueryParams({ q: debounced || undefined }, "push")
  }, [debounced, setQueryParams])

  return (
    <Head>
      <Radio.Group value={btnValue}>
        <CustomLink to="/settings/users">
          <Radio.Button
            value="active"
            style={{
              borderTopLeftRadius:
                i18n.resolvedLanguage === "ar" ? "0" : "0.5rem",
              borderBottomLeftRadius:
                i18n.resolvedLanguage === "ar" ? "0" : "0.5rem",
              borderTopRightRadius:
                i18n.resolvedLanguage === "ar" ? "0.5rem" : "0",
              borderBottomRightRadius:
                i18n.resolvedLanguage === "ar" ? "0.5rem" : "0",
              borderColor: "#99BAC7",
              backgroundColor: "#CAE7F3",
              color: "#265061",
            }}
          >
            {t("users.avtiveUsers")}
          </Radio.Button>
        </CustomLink>
        <CustomLink to="/settings/users?pending=true">
          <Radio.Button
            value="pending"
            style={{
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              borderColor: "#99BAC7",
              backgroundColor: "#CAE7F3",
              color: "#265061",
            }}
          >
            {t("users.pendingUsers")}
          </Radio.Button>
        </CustomLink>
        <CustomLink to="/settings/users?disabled=true">
          <Radio.Button
            value="disabled"
            style={{
              borderTopRightRadius:
                i18n.resolvedLanguage === "ar" ? "0" : "0.5rem",
              borderBottomRightRadius:
                i18n.resolvedLanguage === "ar" ? "0" : "0.5rem",
              borderTopLeftRadius:
                i18n.resolvedLanguage === "ar" ? "0.5rem" : "0",
              borderBottomLeftRadius:
                i18n.resolvedLanguage === "ar" ? "0.5rem" : "0",
              borderColor: "#99BAC7",
              backgroundColor: "#CAE7F3",
              color: "#265061",
            }}
          >
            {t("users.disabledUsers")}
          </Radio.Button>
        </CustomLink>
      </Radio.Group>

      <div className="desc">
        <div>
          <Space direction="vertical">
            <Text size="xl">{t("settings.users.subTitle")}</Text>
            <Text size="xs" color="gray-1">
              {t("settings.users.desc")}
            </Text>
          </Space>
        </div>
        <Space>
          <Input.Search
            placeholder={t("global.search")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              minWidth: "250px",
            }}
          />
          {isAdmin ? (
            <FormModal
              title={t("users.newUser")}
              width={500}
              hasChanged={({ isLoading }) => !!isLoading}
              Form={CreateNewUser}
            >
              <Button type="primary" size="sm">
                <Icon type="plusCircle" /> {t("users.newUser")}
              </Button>
            </FormModal>
          ) : null}
        </Space>
      </div>
    </Head>
  )
}

const Users = () => {
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Wrapper>
      <Header isAdmin={IS_ADMIN} />
      <Table isAdmin={IS_ADMIN} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: start;

  .links-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0.4rem auto;
  }

  @media (min-width: 768px) {
    .links-wrapper {
      flex-direction: row;
    }
  }
`
const Head = styled.div`
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  .desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &
    .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    background-image: linear-gradient(
      107.3deg,
      #336094 13.39%,
      #449eb8 77.64%
    ) !important;
    color: #ffffff !important;
  }

  & input {
    border-radius: 0.5rem;
  }

  @media (min-width: 768px) {
    .desc {
      flex-direction: row;
    }
  }
`

export default Users
