import { getBaseUrl } from "config"
import client from "services/client"

async function forgotPasswors(org, data) {
  const res = await client.post(
    `${getBaseUrl(
      org === "enwz" ? "golf" : org === "euzq" ? "mawten" : "default"
    )}/${org}/api/forgot`,
    data
  )
  return res.data
}

async function resetPassword(org, token, data) {
  const res = await client.post(
    `${getBaseUrl(
      org === "enwz" ? "golf" : org === "euzq" ? "mawten" : "default"
    )}/${org}/api/reset/${token}`,
    data
  )
  return res.data
}

export { forgotPasswors, resetPassword }
