import { css } from "styled-components"

const mixins = {
  flexCenter: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  flexColumnCenter: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  flexSpaceBetween: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  flexAlignCenter: css`
    display: flex;
    align-items: center;
  `,
  background: css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  `,
}

export const lightTheme = {
  nav: "#FFFFFF",
  main: "#EFF4F8",
  side: "#DBE8F4",
  text: "#000",
  border: "#FFFFFF",
  button: "#FFFFFF",
  table: "#0097c2",
  mainColor: "#0097c2",
}
export const darkTheme = {
  nav: "#00203A",
  main: "#041829",
  side: "#00203A",
  text: "#fff",
  border: "#000",
  button: "#00244D",
  table: "#00244D",
  mainColor: "#0097c2",
}

export default mixins
