import { Card, Col, Row, Statistic } from "antd"
import styled from "styled-components"
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns"
import React from "react"

const SystemStatus = ({ status }) => {
  return (
    <Container gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <Card title="General" size="small">
          <Card.Grid className="gridStyle">
            <Statistic
              title="Dashboards Count"
              value={status.dashboards_count}
            />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic title="Widgets Count" value={status.widgets_count} />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic title="Queries Count" value={status.queries_count} />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic
              title="Query Results Count"
              value={status.query_results_count}
            />
          </Card.Grid>
        </Card>
      </Col>
      <Col xs={24} md={12}>
        <Card title="Idataworkers Database" size="small">
          <Card.Grid className="fullgridStyle">
            <Statistic
              title="Redis used memory"
              value={status?.redis_used_memory_human}
            />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic
              title="Query Results Size"
              value={status?.database_metrics?.metrics[0][1]}
            />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic
              title="Idataworkers DB Size"
              value={status?.database_metrics?.metrics[1][1]}
            />
          </Card.Grid>
        </Card>
      </Col>
      <Col xs={24} md={12}>
        <Card title="Manager" size="small">
          <Card.Grid className="fullgridStyle">
            <Statistic
              title="Last Refresh at"
              value={formatDistanceToNowStrict(
                fromUnixTime(status?.manager?.last_refresh_at),
                {
                  addSuffix: true,
                }
              )}
            />
          </Card.Grid>
          <Card.Grid className="fullgridStyle">
            <Statistic title="version" value={status?.version} />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic
              title="Unused query results count"
              value={status?.unused_query_results_count}
            />
          </Card.Grid>
          <Card.Grid className="gridStyle">
            <Statistic
              title="Outdated Queries Count"
              value={status?.manager.outdated_queries_count}
            />
          </Card.Grid>
        </Card>
      </Col>
      {/* <Col xs={24} md={12}>
        <Card title="Queues" size="small">
          <Card.Grid style={gridStyle}>
            <Statistic
              title="default size"
              value={status?.manager?.queues?.default?.size}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="emails size"
              value={status?.manager?.queues?.emails?.size}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="periodic size"
              value={status?.manager?.queues?.periodic?.size}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="queries size"
              value={status?.manager?.queues?.queries?.size}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="scheduled_queries size"
              value={status?.manager?.queues?.scheduled_queries?.size}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="schemas size"
              value={status?.manager?.queues?.schemas?.size}
            />
          </Card.Grid>
        </Card>
      </Col> */}
    </Container>
  )
}

const Container = styled(Row)`
  padding: 1rem;

  .gridStyle {
    width: 50%;
    text-align: center;
  }

  .fullgridStyle {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 900px) {
    .gridStyle {
      width: 100%;
    }
  }
`

export default SystemStatus
