import styled from "styled-components"
import FormGroup from "components/shared/FormGroup"
import { useAuth } from "context/AuthContext"
import useFormState from "hooks/useFormState"
import { useMutation } from "react-query"
import { validate } from "./LoginForn.utils"
import Button from "components/shared/Button"
import { Alert, Input } from "antd"
import { useParams } from "react-router-dom"

function LoginForm() {
  const { login } = useAuth()
  const { tenantName } = useParams()

  const isTenantNameRequired = !tenantName

  const {
    mutate: onSubmit,
    isLoading,
    isError,
    error,
  } = useMutation((values) => login(values))

  const { values, handleChange, handleSubmit, errors } = useFormState({
    initialState: { email: "", password: "", tenantName },
    validate,
    onSubmit,
  })

  return (
    <Wrapper>
      {isError && <Alert type="error" message={error.message} />}

      <div style={{ margin: "0 0 16px" }}></div>

      <FormGroup label="Email" error={errors.email} className="fg">
        <Input
          name="email"
          onChange={handleChange}
          value={values.email}
          size="lg"
        />
      </FormGroup>

      <FormGroup label="Password" error={errors.password} className="fg">
        <Input.Password
          type="password"
          name="password"
          onChange={handleChange}
          value={values.password}
          size="lg"
        />
      </FormGroup>

      {isTenantNameRequired && (
        <FormGroup label="Tenant Name" error={errors.tenantName} className="fg">
          <Input
            name="tenantName"
            onChange={handleChange}
            value={values.tenantName}
            size="lg"
            placeholder="Enter full URL or only tenant name"
          />
        </FormGroup>
      )}

      <Button
        loading={isLoading}
        disabled={isLoading}
        type="secondary"
        size="lg"
        onClick={handleSubmit}
      >
        Login
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  ${({ theme }) => theme.mixins.flexColumn};
  padding-bottom: 1rem;

  .fg {
    margin: 0 0 18px;
  }

  button {
    margin: 20px 0 0;
  }
`

export default LoginForm
