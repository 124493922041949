import React, { useState } from "react"
import styled from "styled-components"
import { Empty, Result, Spin } from "antd"
import { useAuth } from "context/AuthContext"
import { useAgentsPage } from "hooks/agents"
import useQueryParams from "hooks/useQueryParams"
import { useTranslation } from "react-i18next"
import AgentFilter from "./AgentFilter"
import AgentListView from "./AgentListView"
import AgentCardView from "./AgentCardView"
import EmptyApps from "static/images/empty/no-apps.svg"
import AdminAgentForm from "./AdminAgentForm"
import AddUserAgentForm from "./AddUserAgentForm"

const AgentsdData = ({ IS_ADMIN }) => {
  const [layout, setLayout] = useState("list")
  const { t } = useTranslation()
  const { session } = useAuth()

  const [queryParams, setQueryParams] = useQueryParams({
    page: Number,
    page_size: Number,
    q: String,
    userId: Number,
    date_from: String,
    date_to: String,
  })

  const {
    page = 1,
    page_size: pageSize = 20,
    q: name,
    userId = IS_ADMIN ? undefined : session?.user?.id,
    date_from,
    date_to,
  } = queryParams

  const { data, isLoading, isError } = useAgentsPage({
    page,
    pageSize,
    name,
    userId,
    date_from,
    date_to,
  })

  const handleChange = ({ current: page, pageSize: page_size }) => {
    setQueryParams({ page, page_size })
  }

  if (isLoading) {
    return (
      <EmptyWrapper>
        <Spin className="spinIcon" />
      </EmptyWrapper>
    )
  }

  if (isError || !data || data?.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data?.length === 0 && !queryParams?.date_to) {
    return (
      <EmptyWrapper>
        <Empty
          image={EmptyApps}
          description={
            <>
              <span className="emptyTitle">{t("agents.emptyTitle")}</span>
              <span className="emptyDesc">{t("agents.emptyDesc")}</span>
              {!IS_ADMIN ? <AddUserAgentForm /> : <AdminAgentForm />}
            </>
          }
          className="empty"
        />
      </EmptyWrapper>
    )
  }

  return (
    <Wrapper>
      <AgentFilter
        layout={layout}
        setLayout={setLayout}
        agentsCount={data?.length}
      />
      {layout === "card" ? (
        <AgentCardView
          IS_ADMIN={IS_ADMIN}
          loading={isLoading}
          agent={data}
          queryParams={queryParams}
          onParamsChange={handleChange}
        />
      ) : (
        <AgentListView
          IS_ADMIN={IS_ADMIN}
          loading={isLoading}
          agent={data}
          queryParams={queryParams}
          onParamsChange={handleChange}
        />
      )}
    </Wrapper>
  )
}

const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinIcon {
    margin-top: 14rem;
  }

  & .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;

    & .ant-empty-image {
      width: 245px;
      height: 245px;
    }

    & .ant-empty-description {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.48px;
      text-transform: uppercase;

      & .emptyTitle {
        color: #0097c2;
      }

      & .emptyDesc {
        color: #143d66;
        margin-bottom: 1rem;
      }
    }
  }
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;
`

export default AgentsdData
