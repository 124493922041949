import React, { useState } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Divider, Input } from "antd"
import FormModal from "components/shared/FormModal/FormModal"
import Button from "components/shared/Button/Button"
import Text from "components/shared/Text/Text"
import { useCreateWidget, useUpdateWidget } from "hooks/dashboards"
import { calcVisPosition } from "visualizations/utils/calcVisPosition"
import { useDashboardState } from "../Provider"
import { useTranslation } from "react-i18next"

const { TextArea } = Input

const CreateTextboxForm = ({
  onCancel,
  onChange,
  closeModal,
  isEdit,
  widget,
}) => {
  const { t } = useTranslation()
  const { memoizedDashboard, dashboardWidgets, onAddWidget, onUpdateWidget } =
    useDashboardState()
  const [value, setValue] = useState(widget?.text || "")
  const { mutate: create, isLoading: createLoading } = useCreateWidget()
  const { mutate: edit, isLoading: editLoading } = useUpdateWidget()

  React.useEffect(() => onChange(value), [value, onChange])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSave = () => {
    isEdit
      ? edit(
          {
            id: widget.id,
            data: {
              dashboard_id: widget.dashboard_id,
              id: widget.id,
              text: value,
              widget: 1,
              options: widget.options,
            },
          },
          {
            onSuccess: (newWidget) => {
              onUpdateWidget(widget.id, newWidget)
              closeModal()
            },
          }
        )
      : create(
          {
            dashboard_id: memoizedDashboard.id,
            visualization_id: null,
            text: value,
            width: 1,
            options: {
              isHidden: false,
              position: calcVisPosition({}, dashboardWidgets),
            },
          },
          {
            onSuccess: (widget) => {
              onAddWidget(widget)
              closeModal()
            },
          }
        )
  }

  return (
    <Wrapper>
      <TextArea
        rows={5}
        value={value}
        onChange={handleChange}
        placeholder={t("dashboards.textboxPlaceholder")}
      />
      <Text size="xs">
        {t("dashboards.textboxLink")}{" "}
        <a href="https://commonmark.org/help/" target="_blank" rel="noreferrer">
          Markdown.
        </a>
      </Text>
      <Divider />
      {!!value && (
        <>
          <p>{t("dashboards.textboxPreview")}:</p>
          <div className="markdown">
            <ReactMarkdown children={value} />
          </div>
        </>
      )}
      <FormModal.Footer>
        <Button
          type="primary"
          onClick={handleSave}
          disabled={createLoading || editLoading}
          loading={createLoading || editLoading}
        >
          {t("global.save")}
        </Button>
        <Button type="cancel" onClick={onCancel}>
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
  .markdown {
    padding: 0.5rem;
    background-color: #eee;
  }
`

export default CreateTextboxForm
