import React from "react"
import styled from "styled-components"
import GridLayout from "components/shared/GridLayout"
import { generateLayout } from "./Provider/Provider.utils"
import { useAuth } from "context/AuthContext"
import { useDashboard } from "hooks/dashboards"
import { Image, Skeleton } from "antd"
import EmptyHome from "static/images/homeEmptyDashboard.svg"
import AddHomeWidget from "./AddHomeWidget"
import Widget from "./Widget"
import DashboardStateProvider from "./Provider/Provider"
import Text from "components/shared/Text"
import { useTranslation } from "react-i18next"
import { DEFULT_LAYOUT, generateHomeLayout } from "./utils"

const HomeDashboartd = () => {
  const { t } = useTranslation()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  const homepage_dashboard_id = session?.user?.homepage_dashboard_id

  const { data, isLoading, isFetching } = useDashboard(homepage_dashboard_id)

  const homeLayout = generateHomeLayout(homepage_dashboard_id, data?.widgets)
  const layout = generateLayout(DEFULT_LAYOUT)

  return (
    <GridLayout layout={layout} cols={5}>
      {homeLayout.map((layout) => {
        return layout?.widgetId ? (
          <WidgetWrapper
            key={layout?.id}
            id={layout?.id === 0 ? "addWidgetCard" : ""}
          >
            {isLoading ? (
              <SkeletonWrapper active />
            ) : (
              <>
                <DashboardStateProvider
                  dashboard={data}
                  isRefetching={isFetching}
                >
                  <Widget
                    widget={layout}
                    isPublic={true}
                    isHome={true}
                    isAdmin={IS_ADMIN}
                    editable={false}
                    dashboardParameters={{}}
                  />
                </DashboardStateProvider>
              </>
            )}
          </WidgetWrapper>
        ) : (
          <Wrapper
            key={layout?.id}
            id={layout?.id === 0 ? "addWidgetCard" : ""}
          >
            {isLoading ? (
              <SkeletonWrapper active />
            ) : (
              <>
                <Image src={EmptyHome} width={70} preview={false} />
                <Text size="sm" className="p">
                  {t("home.addWidget")}
                </Text>
                <AddHomeWidget
                  homepage_dashboard_id={homepage_dashboard_id}
                  position={layout?.options?.position}
                  user={session?.user}
                  layoutId={layout?.id}
                />
              </>
            )}
          </Wrapper>
        )
      })}
    </GridLayout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ecf5f8;
  position: relative;
  border: 1px solid #e2e2e7;
  border-radius: 12px;

  & .p {
    color: #0097c2;
    font-size: 1.3rem;
    font-weight: 400;
    padding-top: 0.5rem;
  }
`

const WidgetWrapper = styled.div`
  position: relative;
`

const SkeletonWrapper = styled(Skeleton.Image)`
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
`

export default HomeDashboartd
