import React, { useState } from "react"
import Text from "components/shared/Text"
import { Input } from "antd"

const Name = ({ name, onUpdateName, IS_ADMIN }) => {
  const [fouces, setFouces] = useState(false)
  const [value, setValue] = useState(name)

  const updateName = () => {
    onUpdateName(value)
    setFouces(false)
  }

  return (
    <div>
      {fouces && IS_ADMIN ? (
        <Input
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={updateName}
          onPressEnter={(e) => onUpdateName(e.target.value)}
        />
      ) : (
        <Text
          size="xl"
          onClick={() => setFouces(true)}
          style={{ cursor: IS_ADMIN ? "pointer" : "" }}
        >
          {name}
        </Text>
      )}
    </div>
  )
}

export default Name
