import React from "react"
import useQueryParams from "hooks/useQueryParams"
import { useSession } from "hooks/users"
// import { columns } from "./Table.utils"
import { useDeleteGroup, useGroupsPage } from "hooks/groups"
import {
  renderActionBtns,
  renderDataSouecesColumn,
  renderGroupColumn,
  renderMembersColumn,
} from "./Table.utils"
import { Result, Table } from "antd"
import { useTranslation } from "react-i18next"
import { TableSkeleton } from "components/shared/Table"

const Columns = (isAdmin) => {
  const { t } = useTranslation()

  if (isAdmin) {
    return [
      {
        title: t("global.tabels.groups"),
        key: "name",
        dataIndex: "name",
        render: renderGroupColumn,
      },
      {
        title: t("global.tabels.groups"),
        key: "email",
        dataIndex: "email",
        render: renderMembersColumn,
      },
      {
        title: t("global.tabels.dataSource"),
        key: "groups",
        dataIndex: "groups",
        render: renderDataSouecesColumn,
      },
      {
        title: t("global.tabels.actions"),
        key: "action",
        render: renderActionBtns,
      },
    ]
  } else {
    return [
      {
        title: t("global.tabels.groups"),
        key: "name",
        dataIndex: "name",
        render: renderGroupColumn,
      },
      {
        title: t("global.tabels.members"),
        key: "email",
        dataIndex: "email",
        render: renderMembersColumn,
      },
      {
        title: t("global.tabels.dataSource"),
        key: "groups",
        dataIndex: "groups",
        render: renderDataSouecesColumn,
      },
    ]
  }
}

const GroupTable = ({ isAdmin }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: Number,
    page_size: Number,
    q: String,
  })

  const { page = 1, page_size: pageSize = 10, q: name } = queryParams

  const { data, isLoading, isError } = useGroupsPage({
    page,
    pageSize,
    name,
  })

  const { mutate: deleteGroup, isLoading: deleteLoading } = useDeleteGroup()
  const { t } = useTranslation()
  const { data: session } = useSession()

  const onDeleteGroup = (id) => {
    deleteGroup({
      id: id,
    })
  }

  const datasource =
    data && Array.isArray(data)
      ? data?.map((data) => ({
          ...data,
          user: session?.user,
          onDeleteGroup,
        }))
      : []

  const handleChange = ({ current: page, pageSize: page_size }) => {
    setQueryParams({ page, page_size })
  }

  if (isLoading || deleteLoading) {
    return <TableSkeleton columns={Columns()} rowsCount={pageSize} />
  }

  // Used until we create a Result component
  // It will act as a Visitor that will receive the AppError and
  // returns the corresponding UI
  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Table
      scroll={{ x: "1000px" }}
      rowKey="id"
      columns={Columns(isAdmin)}
      dataSource={datasource}
      onChange={handleChange}
      pagination={{
        current: page,
        pageSize: pageSize,
        total: data.count,
      }}
    />
  )
}

export default GroupTable
