import React from "react"
import styled from "styled-components"
import useFormState from "hooks/useFormState"
import { useCreateDashboard } from "hooks/dashboards"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { Input } from "components/shared/Input"
import FormGroup from "components/shared/FormGroup"
import { useNavigate } from "react-router-dom"
import { message } from "antd"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

// This form will be used inside a FormModal component
// It will receive onChange, onCancel, closeModal props
// Check @components/shared/FormModal

const config = {
  initialState: { name: "" },
  validate: ({ name }) =>
    !name ? { name: "Dashboard name is required." } : {},
}

function CreateDashboardForm({ onChange, onCancel, closeModal }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tenantName } = useAuth()
  const { mutate, isLoading } = useCreateDashboard()

  const { values, errors, handleChange, handleSubmit } = useFormState({
    ...config,
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: ({ id }) => {
          if (id) {
            closeModal()
            navigate(`/${tenantName}/dashboard/${id}`)
          } else {
            message.error(t("dashboards.createFaild"))
          }
        },
      })
    },
  })

  React.useEffect(() => {
    onChange(values)
  }, [values, onChange])

  return (
    <Form>
      <FormGroup label={t("dashboards.dashboardnName")} error={errors.name}>
        <Input
          type="text"
          value={values.name}
          onChange={handleChange}
          size="sm"
          name="name"
        />
      </FormGroup>

      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

const Form = styled.form``

export default CreateDashboardForm
