import { Modal } from "antd"

export function confirmFormCancelation({ cancel, hasChanged }) {
  if (!hasChanged) {
    cancel()
    return
  }

  Modal.confirm({
    onOk: cancel,
    content: "Changes you made so far will not be saved. Are you sure?",
  })
}
