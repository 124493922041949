import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Form,
  Button as AntdBtn,
  Modal,
  Space,
  Steps,
  Select,
  Input,
  InputNumber,
  Alert,
  Row,
  Col,
  List,
  notification,
} from "antd"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import {
  useAgentDestinations,
  useCreateAgentDestination,
  useRemoveAgentDestination,
  useUpdateAgent,
} from "hooks/agents"
import { useCreateDestination, useDestinations } from "hooks/destinations"
import Button from "components/shared/Button"
import {
  CheckCircleFilled,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import Text from "components/shared/Text"
import Icon from "components/shared/Icon"

const EditUserAgentForm = ({ agent, iconSize = "middle" }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedDestination, setSelectedDestinations] = useState([])
  const [initialSelectedDestinations, setInitialSelectedDestinations] =
    useState([])
  const [stepFormValues, setStepFormValues] = useState({})

  const { session } = useAuth()
  const { data: subscriptions } = useAgentDestinations(agent?.id)

  const { data: destinations } = useDestinations()
  const { mutateAsync: createSubscription, isLoading: createDesLoading } =
    useCreateDestination()
  const { mutateAsync: addSubscription, isLoading: addDesLoading } =
    useCreateAgentDestination()
  const { mutateAsync: removeSubscription, isLoading: removeDesLoading } =
    useRemoveAgentDestination()
  const { mutate: updateAgent, isLoading: updateAgentLoading } =
    useUpdateAgent()

  const handleFormSubmit = async (values) => {
    const allValues = { ...stepFormValues, ...values }
    const { custom_body, custom_subject, ...rest } = agent?.options

    const EditAgent =
      allValues?.template === "custom"
        ? {
            ...agent,
            name: allValues?.name,
            userId: session?.user?.id,
            options: {
              ...agent?.options,
              value: allValues?.threshold,
              custom_subject: allValues?.custom_subject,
              custom_body: allValues?.custom_body,
            },
          }
        : {
            ...agent,
            name: allValues?.name,
            userId: session?.user?.id,
            options: {
              ...rest,
              value: allValues?.threshold,
            },
          }

    // Check for changes in selectedDestination
    const destinationsChanged =
      JSON.stringify(initialSelectedDestinations.sort()) !==
      JSON.stringify(selectedDestination.sort())

    if (destinationsChanged) {
      const removedDestinations = initialSelectedDestinations.filter(
        (dest) => !selectedDestination.includes(dest)
      )
      const addedDestinations = selectedDestination.filter(
        (dest) => !initialSelectedDestinations.includes(dest)
      )

      // Handle removal of destinations
      await Promise.all(
        removedDestinations.map(async (removedDest) => {
          const sub = subscriptions.find(
            (sub) => sub.destination.type === removedDest
          )
          if (sub) {
            await removeSubscription({
              agentId: sub.alert_id,
              subscriptionId: sub.id,
            })
          }
        })
      )

      // Handle addition of new destinations
      const newDestinations = []
      for (const dest of addedDestinations) {
        let existDestination = destinations?.find((des) => {
          if (des.type === dest && des.options) {
            if (
              des.type === "email" &&
              des?.options?.addresses === session?.user?.email
            ) {
              return true
            }
            if (
              des.type === "sms" &&
              session?.user?.phone &&
              des.options?.phones === session?.user?.phone
            ) {
              return true
            }
            return false
          }
          return false
        })
        if (!existDestination) {
          const newDestinationData = {
            type: dest,
            name: `${session?.user?.name} ${dest}`,
            options: {},
          }
          if (dest === "email" && session?.user?.email) {
            newDestinationData.options.addresses = session?.user?.email
          }
          if (dest === "sms" && session?.user?.phone) {
            newDestinationData.options.phones = session?.user?.phone
          }

          if (Object.keys(newDestinationData.options).length === 0) {
            notification.error({
              message:
                "Failed to create new destination. Please make sure you have added the correct information in your account (email and phone).",
            })
          } else {
            existDestination = await createSubscription({
              data: newDestinationData,
            })
          }
        }
        newDestinations.push(existDestination)
      }
      await Promise.all(
        newDestinations.map(async (destination) => {
          await addSubscription({
            id: agent.id,
            data: {
              destination_id: destination.id,
              alert_id: agent.id,
            },
          })
        })
      )
    }

    updateAgent(
      {
        id: agent.id,
        data: EditAgent,
      },
      {
        onSuccess: () => {
          form.resetFields()
          setCurrentStep(0)
          setSelectedDestinations([])
          setInitialSelectedDestinations([])
          setStepFormValues({})
          setIsOpen(false)
          notification.success({
            message: t("agents.agentUpdated"),
          })
        },
        onError: () => {
          notification.error({
            message: t("agents.agentNotUpdated"),
          })
        },
      }
    )
  }

  const handleNextStep = async () => {
    try {
      const values = await form.validateFields()
      setStepFormValues({ ...stepFormValues, ...values })
      setCurrentStep((prev) => prev + 1)
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1)
  }

  useEffect(() => {
    if (agent && subscriptions) {
      const initialDestinations = subscriptions?.map(
        (sub) => sub?.destination?.type
      )
      setSelectedDestinations(initialDestinations)
      setInitialSelectedDestinations(initialDestinations)
    }
    return () => {
      setSelectedDestinations([])
      setInitialSelectedDestinations([])
    }
  }, [subscriptions, agent])

  useEffect(() => {
    if (isOpen && agent) {
      form.setFieldsValue({
        name: agent.name,
        threshold: agent?.options?.value,
        template: agent?.options?.custom_subject ? "custom" : "default",
        custom_subject: agent?.options?.custom_subject || "",
        custom_body: agent?.options?.custom_body || "",
      })
    }
  }, [isOpen, agent, form])

  const validateToDate = (_, value) => {
    if (selectedDestination?.length !== 0) {
      return Promise.resolve()
    }
    return Promise.reject(t("agents.addAtlestoneDes"))
  }

  const isLoading =
    createDesLoading || addDesLoading || removeDesLoading || updateAgentLoading
  const hasPhone = Boolean(session?.user?.phone)

  const footer = (
    <Footer>
      {currentStep === 0 ? (
        <>
          <div />
          <Space>
            <Button
              type="primary"
              shape="outlined"
              htmlType="submit"
              form="editAgentForm"
              loading={isLoading}
              disabled={isLoading}
            >
              {t("global.save")}
            </Button>
            <Button type="primary" onClick={handleNextStep}>
              {t("global.next")}
            </Button>
          </Space>
        </>
      ) : currentStep === 1 ? (
        <>
          <Button type="primary" shape="outlined" onClick={handlePreviousStep}>
            {t("global.previous")}
          </Button>
          <Space>
            <Button
              type="primary"
              shape="outlined"
              htmlType="submit"
              form="editAgentForm"
              loading={isLoading}
              disabled={isLoading}
            >
              {t("global.save")}
            </Button>
            <Button type="primary" onClick={handleNextStep}>
              {t("global.next")}
            </Button>
          </Space>
        </>
      ) : (
        <>
          <Button type="primary" shape="outlined" onClick={handlePreviousStep}>
            {t("global.previous")}
          </Button>
          <Button
            form="editAgentForm"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {t("global.save")}
          </Button>
        </>
      )}
    </Footer>
  )

  const initialValues = {
    ...agent,
    agentId: agent.id,
    threshold: agent?.options?.value,
    template: agent?.options?.custom_subject ? "custom" : "default",
    custom_subject: agent?.options?.custom_subject || "",
    custom_body: agent?.options?.custom_body || "",
  }

  return (
    <>
      <AntdBtn
        type="text"
        shape="circle"
        size={iconSize}
        icon={<EditOutlined />}
        onClick={() => setIsOpen(true)}
      />

      <Wrapper
        open={isOpen}
        onCancel={() => {
          form.resetFields()
          setCurrentStep(0)
          setSelectedDestinations([])
          setInitialSelectedDestinations([])
          setStepFormValues({})
          setIsOpen(false)
        }}
        title={
          <Space direction="vertical">
            <Text>{t("agents.editAgent")}</Text>
            <Text size="xs" color="gray-1">
              {t("agents.editAgentDesc")}
            </Text>
          </Space>
        }
        footer={footer}
        width={1000}
      >
        <div className="stepsContainer">
          <Steps
            progressDot
            current={currentStep}
            items={[
              {
                title: t("agents.chooseAgent"),
              },
              {
                title: t("agents.chooseTemplate"),
              },
              {
                title: t("agents.destinations"),
              },
            ]}
          />
        </div>
        <Form
          form={form}
          name={`editAgentForm_${agent.id}`}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleFormSubmit}
        >
          {currentStep === 0 ? (
            <div className="formRow">
              <Form.Item
                label={t("agents.agentName")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label={t("agents.agentsReminder")}>
                <Form.Item label={agent?.varname}>
                  <Space>
                    {agent?.vartype === "number" ? (
                      <Form.Item
                        name="threshold"
                        rules={[
                          { required: true, message: "Please enter value" },
                        ]}
                      >
                        <InputNumber style={{ minWidth: "230px" }} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="threshold"
                        rules={[
                          { required: true, message: "Please enter value" },
                        ]}
                      >
                        <Input style={{ minWidth: "230px" }} />
                      </Form.Item>
                    )}
                  </Space>
                </Form.Item>
                <Alert type="info" showIcon message={t("agents.agentsInfo")} />
              </Form.Item>
            </div>
          ) : currentStep === 1 ? (
            <>
              <div className="formRow">
                <Form.Item
                  label={t("agents.agentTemplate")}
                  name="template"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={t("agents.chooseTemplate")}
                    options={[
                      { label: "Default", value: "default" },
                      { label: "Custom", value: "custom" },
                    ]}
                  />
                </Form.Item>
              </div>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const template = getFieldValue("template")
                  return template === "default" ? null : template ===
                    "custom" ? (
                    <CustomTemplate layout="vertical">
                      <Form.Item
                        label={t("agents.subject")}
                        name={"custom_subject"}
                        rules={[{ required: true }]}
                      >
                        <Input defaultValue="" />
                      </Form.Item>
                      <Form.Item
                        label={t("agents.body")}
                        name={"custom_body"}
                        rules={[{ required: true }]}
                      >
                        <Input.TextArea rows={5} defaultValue="" />
                      </Form.Item>
                    </CustomTemplate>
                  ) : null
                }}
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item>
                <Row gutter={[8, 8]}>
                  <Col xs={24} lg={8}>
                    <div className="userInfo">
                      <Icon type="idCard" />
                      <span>{t("global.name")}:</span>
                      <span>{session?.user?.name}</span>
                    </div>
                  </Col>
                  <Col xs={24} lg={8}>
                    <div className="userInfo">
                      <Icon type="email" />
                      <span>{t("global.email")}:</span>
                      <span>{session?.user?.email}</span>
                    </div>
                  </Col>
                  <Col xs={24} lg={8}>
                    <div className="userInfo">
                      <Icon type="phone" />
                      <span>{t("global.phone")}:</span>
                      <span>{session?.user?.phone}</span>
                    </div>
                    {hasPhone ? null : (
                      <span style={{ color: "red" }}>
                        {t("agents.addPhoneMessage")}
                      </span>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={t("agents.addAgentDestination")}
                name="destinations"
                rules={[{ validator: validateToDate }]}
              >
                <div>
                  <List
                    grid={{
                      gutter: 16,
                      column: 5,
                    }}
                    dataSource={[
                      { title: t("global.email"), value: "email" },
                      ...(hasPhone
                        ? [{ title: t("global.sms"), value: "sms" }]
                        : []),
                    ]}
                    className="h-80 w-full overflow-y-auto overflow-x-hidden"
                    renderItem={(item) => {
                      const isSelect = selectedDestination.find(
                        (menubar) => menubar === item.value
                      )
                      return (
                        <List.Item>
                          <div className="destination">
                            <div className="flex items-center gap-2">
                              <Space>
                                <Icon type="email" />
                                <span className="font-medium text-brand-900">
                                  {item.title}
                                </span>
                              </Space>
                            </div>
                            <div>
                              {isSelect ? (
                                <Button
                                  type="link"
                                  size="small"
                                  icon={
                                    <CheckCircleFilled
                                      style={{ color: "#0A4384" }}
                                    />
                                  }
                                  className="flex items-center text-red-500 hover:text-red-500"
                                  onClick={() =>
                                    setSelectedDestinations((prev) =>
                                      prev.filter(
                                        (member) => member !== item.value
                                      )
                                    )
                                  }
                                />
                              ) : (
                                <Button
                                  type="link"
                                  size="small"
                                  icon={<PlusCircleOutlined />}
                                  className="flex items-center text-brand-500 hover:text-brand-500"
                                  onClick={() =>
                                    setSelectedDestinations((prev) => [
                                      ...prev,
                                      item.value,
                                    ])
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </List.Item>
                      )
                    }}
                  />
                </div>
              </Form.Item>
            </>
          )}
        </Form>
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Modal)`
  & .ant-modal-body {
    height: 450px;
    overflow-y: auto;
  }

  & .stepsContainer {
    width: 60%;
    margin: auto;
    padding-bottom: 1rem;
  }

  & .formRow {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 2rem /* 32px */;
  }

  & .userInfo {
    background-color: #dbe8f4;
    color: #00244d;
    padding: 1rem 0.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
    font-size: 14px;
    font-weight: 500;

    & .anticon {
      font-size: 18px;
    }
  }

  & .destination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #00244d;
    border-radius: 8px;
    padding: 0.3rem;
    font-size: 14px;
    font-weight: 500;
  }
`
const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`
const CustomTemplate = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #fbfbfb;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
`

export default EditUserAgentForm
