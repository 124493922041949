import { Result } from "antd"
import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="Something went wrong!"
          subTitle="Please refrsh page or contact your Administrator"
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
