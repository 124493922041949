import React, { useState } from "react"
import styled from "styled-components"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import SelectRequest from "./SelectRequest"
import ConfigWidget from "./ConfigWidget"
import { useCreateWidget } from "hooks/dashboards"
import { calcVisPosition } from "visualizations/utils/calcVisPosition"
import { useDashboardState } from "../Provider"
import { useTranslation } from "react-i18next"

function CreateWidgetForm({ onCancel, onChange, closeModal }) {
  const { t } = useTranslation()
  const { memoizedDashboard, dashboardWidgets, onAddWidget } =
    useDashboardState()
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedVis, setSelectedVis] = useState(null)
  const { mutate, isLoading } = useCreateWidget()

  React.useEffect(() => onChange(selectedRequest), [selectedRequest, onChange])

  const onRemoveRequest = React.useCallback(() => {
    setSelectedRequest(null)
  }, [])

  const onAddRequest = React.useCallback((request) => {
    setSelectedRequest(request)
    setSelectedVis(request.visualizations[0])
  }, [])

  const handleSave = React.useCallback(() => {
    mutate(
      {
        dashboard_id: memoizedDashboard?.id,
        visualization_id: selectedVis.id,
        text: "",
        width: 1,
        options: {
          parameterMappings: {},
          isHidden: false,
          position: calcVisPosition(selectedVis, dashboardWidgets),
        },
      },
      {
        onSuccess: (widget) => {
          onAddWidget(widget)
          closeModal()
        },
      }
    )
  }, [selectedRequest, selectedVis])

  return (
    <Wrapper>
      <div className="content">
        {selectedRequest ? (
          <ConfigWidget
            selectedRequest={selectedRequest}
            onRemoveRequest={onRemoveRequest}
            selectedVis={selectedVis}
            setSelectedVis={setSelectedVis}
          />
        ) : (
          <SelectRequest onAddRequest={onAddRequest} />
        )}
      </div>

      <FormModal.Footer className="footer">
        <Button type="cancel" onClick={onCancel} size="sm">
          {t("global.cancel")}
        </Button>
        <Button
          type="primary"
          disabled={isLoading || !selectedRequest || !selectedVis}
          loading={isLoading}
          onClick={handleSave}
          size="sm"
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 73vh;
  overflow: hidden;
  & .footer {
    margin: 5px !important;
  }
`

export default CreateWidgetForm
