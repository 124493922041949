import { Form, Select } from "antd"
import React from "react"
import FormModal from "../FormModal"
import { useTranslation } from "react-i18next"
import Button from "../Button"

const CreateTags = ({
  onChange,
  onCancel,
  closeModal,
  allTags,
  dashboaredTags,
  onAddTags,
  updateLoading,
}) => {
  const { t } = useTranslation()

  React.useEffect(() => onChange(dashboaredTags), [dashboaredTags, onChange])

  const handelSaveConfirm = (value) => {
    onAddTags(value.tags, closeModal)
  }

  return (
    <Form
      initialValues={{
        tags: dashboaredTags,
      }}
      onFinish={handelSaveConfirm}
    >
      <Form.Item label="Tags" name="tags">
        <Select
          mode="tags"
          // defaultValue={dashboaredTags}
          options={allTags.map((tag) => ({ label: tag.name, value: tag.name }))}
        />
      </Form.Item>
      <FormModal.Footer>
        <Button
          type="secondary"
          htmlType="submit"
          disable={updateLoading}
          loading={updateLoading}
        >
          {t("global.save")}
        </Button>

        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

export default CreateTags
