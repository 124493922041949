import React from "react"
import styled from "styled-components"
import Text from "components/shared/Text"
import { Link } from "react-router-dom"
import { Avatar, Form, Input, List, Select, Tag } from "antd"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useUsers } from "hooks/users"
import {
  useAddGroupMember,
  useAddGroupSource,
  useDeleteGroupMember,
  useDeleteGroupSource,
  useGroupDataSources,
  useGroupMembers,
} from "hooks/groups"
import { useDataSources } from "hooks/dataSources"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"

const GroupMembers = (props) => {
  const { onChange, onCancel, closeModal, id, user } = props
  const { t } = useTranslation()

  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const { data, isFetching } = useGroupMembers(id)
  const { mutate, isLoading: deleteLoading } = useDeleteGroupMember()

  const { data: users } = useUsers()
  const { mutate: addMember, isLoading: addLoading } = useAddGroupMember()

  const membersOptions = users?.results
    ?.filter((u) => !data?.some((d) => d.id === u.id))
    ?.map((m) => ({ value: m.id, label: m.name }))

  const onDeleteMember = (memberId) => {
    mutate({
      id: id,
      memberId: memberId,
    })
  }

  const onFinish = (value) => {
    addMember(
      {
        id: id,
        data: value,
      },
      {
        onSuccess: (value) => {
          if (value.message) {
            return
          } else {
          }
        },
      }
    )
  }

  React.useEffect(() => {
    onChange(data)
  }, [data, onChange])

  const locale = {
    emptyText: t("groups.emptyMembers"),
  }

  return (
    <>
      {IS_ADMIN && (
        <Form layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item label={t("groups.addMember")} name="user_id">
            <Select options={membersOptions} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
              {t("global.add")}
            </Button>
          </Form.Item>
        </Form>
      )}
      <List
        loading={isFetching || deleteLoading || addLoading}
        itemLayout="horizontal"
        dataSource={data}
        locale={locale}
        renderItem={(item) => (
          <List.Item
            actions={[
              user.id !== item.id && IS_ADMIN && (
                <IconButton
                  size="sm"
                  type="text"
                  onClick={() => onDeleteMember(item.id)}
                >
                  <Icon type="delete" />
                </IconButton>
              ),
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.profile_image_url} />}
              title={item.name}
              description={item.email}
            />
          </List.Item>
        )}
        style={{
          height: "500px",
          overflow: "auto",
        }}
      />
    </>
  )
}

const GroupDataSources = (props) => {
  const { onChange, id } = props
  const { t } = useTranslation()
  const { data, isFetching } = useGroupDataSources(id)
  const { mutate, isLoading: deleteLoading } = useDeleteGroupSource()

  const { data: sources } = useDataSources()
  const { mutate: addSource, isLoading: addLoading } = useAddGroupSource()

  const sourcesOptions = sources
    ?.filter((u) => !data?.some((d) => d.id === u.id))
    ?.map((m) => ({ value: m.id, label: m.name }))

  const onDeleteSource = (sourceId) => {
    mutate({
      id: id,
      sourceId: sourceId,
    })
  }

  const onFinish = (value) => {
    addSource(
      {
        id: id,
        data: value,
      },
      {
        onSuccess: (value) => {
          if (value.message) {
            return
          } else {
          }
        },
      }
    )
  }
  // data_source_id
  React.useEffect(() => {
    onChange(data)
  }, [data])

  const locale = {
    emptyText: t("groups.emptyDataSources"),
  }

  return (
    <>
      <Form layout="vertical" className="form" onFinish={onFinish}>
        <Form.Item label={t("groups.addDataSource")} name="data_source_id">
          <Select options={sourcesOptions} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
            {t("global.add")}
          </Button>
        </Form.Item>
      </Form>
      <List
        loading={isFetching || deleteLoading || addLoading}
        itemLayout="horizontal"
        dataSource={data}
        locale={locale}
        renderItem={(item) => (
          <List.Item
            actions={[
              <IconButton
                size="sm"
                type="transparent"
                onClick={() => onDeleteSource(item.id)}
              >
                <Icon type="delete" />
              </IconButton>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item.profile_image_url} />}
              title={item.name}
            />
          </List.Item>
        )}
        style={{
          height: "500px",
          overflow: "auto",
        }}
      />
    </>
  )
}

function Name({ group, ...delegated }) {
  const { name, type } = group

  return (
    <NameWrapper {...delegated}>
      <Text size="sm">{name}</Text>
      {type === "builtin" && (
        <Tag>
          <Text size="xxs">{type}</Text>
        </Tag>
      )}
    </NameWrapper>
  )
}

function Member({ group, ...delegated }) {
  const { t } = useTranslation()
  return (
    <FormModal
      title={t("groups.groupMembers")}
      width={500}
      hasChanged={({ data }) => !!data}
      Form={GroupMembers}
      formProps={group}
      style={{
        top: "10px",
      }}
    >
      <Button type="text" size="sm">
        {t("groups.groupMembers")}
      </Button>
    </FormModal>
  )
}

function DataSource({ group, ...delegate }) {
  const { t } = useTranslation()
  return (
    <FormModal
      title={t("groups.groupDS")}
      width={600}
      hasChanged={({ data }) => !!data}
      Form={GroupDataSources}
      formProps={group}
      style={{
        top: "10px",
      }}
    >
      <Button type="text" size="sm">
        {t("requests.dataSource")}
      </Button>
    </FormModal>
  )
}

const NameWrapper = styled.div`
  ${({ theme }) => theme.mixins.flexAlignCenter};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export { Name, Member, DataSource }
