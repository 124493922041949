import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Tag,
  notification,
} from "antd"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Icon from "components/shared/Icon/Icon"
import Button from "components/shared/Button"
import useDebounce from "hooks/useDebounce"
import { useRunRequest, useSearchRequests } from "hooks/requests"
import { useSearchDashboards } from "hooks/dashboards"
import styled from "styled-components"
import { useUpdateAgent } from "hooks/agents"

const EditAdminAgentForm = ({ agent }) => {
  const [form] = Form.useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState("")
  const [dashboard, setDashboard] = useState("")
  const [selectQuery, setSelectQuery] = useState(agent.query)
  const [columns, setColumns] = useState([])
  const [queryDataError, setQueryDataError] = useState(false)
  const [rearmUnit, setRearmUnit] = useState("h")
  const [rearmValue, setRearmValue] = useState(1)

  const searchQuery = useDebounce(query)
  const searchDashboard = useDebounce(dashboard)

  const { t } = useTranslation()
  const { data: requests, isLoading: loadingRequests } =
    useSearchRequests(searchQuery)
  const { data: dashboards, isLoading: loadingDashboard } =
    useSearchDashboards(searchDashboard)
  const { mutate: getRequestData, isLoading: getQueryDataLoading } =
    useRunRequest()
  const { mutate: update, isLoading: updateLoading } = useUpdateAgent()

  const onCancel = () => {
    setQuery("")
    setDashboard("")
    setColumns([])
    setQueryDataError(false)
    form.resetFields()
    setIsOpen(false)
  }

  const handleSubmin = (values) => {
    const rearmInSeconds =
      rearmUnit === "s"
        ? rearmValue
        : rearmUnit === "m"
        ? rearmValue * 60
        : rearmUnit === "h"
        ? rearmValue * 3600
        : rearmUnit === "d"
        ? rearmValue * 86400
        : rearmValue * 604800

    const data = {
      name: values.name,
      dashboard: values.dashboard,
      query_id: values.query_id,
      rearm: values.rearm === "many" ? rearmInSeconds : values.rearm,
      options: {
        column: values.options.column,
        op: values.options.op,
        value: values.options.value,
        custom_subject: values.options.custom_subject,
        custom_body: values.options.custom_body,
      },
    }
    update(
      {
        id: agent.id,
        data,
      },
      {
        onSuccess: (agent) => {
          if (agent.id) {
            notification.success({
              message: t("agents.agentUpdated"),
              duration: 2,
              placement: "bottomRight",
            })
            onCancel()
          } else {
            notification.error({
              message: t("agents.agentNotUpdated"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("agents.agentNotUpdated"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }

  useEffect(() => {
    getRequestData(
      {
        requestId: selectQuery.id,
        data: {
          id: selectQuery.id,
          apply_auto_limit: false,
          max_age: 0,
          parameters: selectQuery?.options?.parameters?.reduce(
            (obj, item) =>
              Object.assign(obj, {
                [item.title]:
                  item.value === "d_now"
                    ? new Date().toISOString().split("T")[0]
                    : item.value,
              }),
            {}
          ),
        },
      },
      {
        onSuccess: (result) => {
          setColumns(
            result?.data?.columns?.map((data) => ({
              label: data.friendly_name,
              value: data.name,
            })) || []
          )
        },
        onError: () => {
          form.setFieldValue(["options", "column"], undefined)
          form.setFieldValue(["options", "value"], undefined)
          setQueryDataError(true)
        },
      }
    )

    return () => {
      setColumns([])
      setQueryDataError(false)
    }
  }, [selectQuery])

  const footer = (
    <Space>
      <Button
        type="primary"
        shape="outlined"
        disabled={updateLoading}
        onClick={onCancel}
      >
        {t("global.cancel")}
      </Button>
      <Button
        form="edit_admin_agent_form"
        type="primary"
        htmlType="submit"
        loading={updateLoading}
        disabled={updateLoading}
      >
        {t("global.save")}
      </Button>
    </Space>
  )

  return (
    <>
      <Button type="primary" size="sm" onClick={() => setIsOpen(true)}>
        <Space>
          <Icon type="edit" />
          <span>{t("global.edit")}</span>
        </Space>
      </Button>
      <Modal
        title="Edit Agent"
        open={isOpen}
        onCancel={onCancel}
        footer={footer}
        style={{
          top: "1rem",
        }}
      >
        <Form
          name="edit_admin_agent_form"
          layout="vertical"
          form={form}
          onFinish={handleSubmin}
          initialValues={{
            ...agent,
            query_id: agent.query.id,
            rearm: agent.rearm > 1 ? "many" : agent.rearm,
            template: agent?.options?.custom_subject ? "custom" : "default",
          }}
          style={{
            height: "74vh",
            overflow: "auto",
          }}
        >
          <Form.Item
            label={t("global.name")}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Dashboard" name="dashboard">
            <Select
              showSearch
              allowClear
              placeholder="Search by dashboard name"
              className="select"
              notFoundContent={null}
              filterOption={false}
              onSearch={setDashboard}
              loading={loadingDashboard}
              options={(dashboards?.results || [])?.map((dashboard) => {
                return {
                  label: (
                    <>
                      {dashboard.name}{" "}
                      {dashboard.is_draft && <Tag>Unpublished</Tag>}
                    </>
                  ),
                  value: dashboard.id,
                  disabled: dashboard.is_draft,
                }
              })}
            />
          </Form.Item>
          <Form.Item
            label={t("requests.query")}
            name="query_id"
            rules={[{ required: true }]}
            extra={queryDataError && "Failed Get Query Data"}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select your request"
              className="select"
              notFoundContent={null}
              filterOption={false}
              defaultValue={agent.query.name}
              onSearch={setQuery}
              loading={loadingRequests || getQueryDataLoading}
              options={(requests?.results || [{ ...agent.query }])?.map(
                (req) => {
                  return {
                    label: (
                      <>
                        {req.name} {req.is_draft && <Tag>Unpublished</Tag>}
                      </>
                    ),
                    value: req.id,
                    disabled: req.is_draft,
                  }
                }
              )}
              onSelect={(queryId) => {
                setSelectQuery(requests?.results?.find((q) => q.id === queryId))
                form.setFieldValue(["options", "column"], undefined)
                form.setFieldValue(["options", "value"], undefined)
              }}
            />
          </Form.Item>
          <Form.Item label={t("agents.triggerWhen")}>
            <Space>
              <Form.Item
                label={t("agents.valuesCol")}
                name={["options", "column"]}
                rules={[{ required: true }]}
              >
                <Select options={columns} loading={getQueryDataLoading} />
              </Form.Item>
              <Form.Item
                label={t("agents.condition")}
                name={["options", "op"]}
                rules={[{ required: true }]}
              >
                <Select
                  optionLabelProp="value"
                  dropdownMatchSelectWidth={false}
                  options={[
                    {
                      value: ">",
                      label: "> greater than",
                    },
                    {
                      value: ">=",
                      label: "≥ greater than or equal",
                    },
                    {
                      value: "<",
                      label: "< less than",
                    },
                    {
                      value: "<=",
                      label: "≤ less than or equal",
                    },
                    {
                      value: "==",
                      label: "= equals",
                    },
                    {
                      value: "!=",
                      label: "≠ not equal to",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("agents.threshold")}
                name={["options", "value"]}
                rules={[{ required: true }]}
              >
                <Input loading={getQueryDataLoading} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={t("agents.whenTrigger")} name="rearm">
            <Select
              optionLabelProp="display"
              dropdownMatchSelectWidth={false}
              options={[
                {
                  value: null,
                  display: "Just once",
                  label: "Just once until back to normal",
                },
                {
                  value: 1,
                  display: "Each time alert is evaluated",
                  label: "Each time alert is evaluated until back to normal",
                },
                {
                  value: "many",
                  display: "At most every",
                  label: "At most every ... when alert is evaluated",
                },
              ]}
              onChange={(value) => {
                if (value === "many") {
                  form.setFieldValue("rearmUnit", "h")
                  form.setFieldValue("rearmValue", 1)
                }
              }}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const rearm = getFieldValue("rearm")
              if (rearm === "many") {
                return (
                  <Space>
                    <Form.Item
                      label="Value"
                      name="rearmValue"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={1}
                        onChange={(value) => setRearmValue(value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Unit"
                      name="rearmUnit"
                      rules={[{ required: true }]}
                    >
                      <Select
                        value={rearmUnit}
                        onChange={(value) => setRearmUnit(value)}
                        options={[
                          { value: "s", label: "Seconds" },
                          { value: "m", label: "Minutes" },
                          { value: "h", label: "Hours" },
                          { value: "d", label: "Days" },
                          { value: "w", label: "Weeks" },
                        ]}
                      />
                    </Form.Item>
                  </Space>
                )
              }
            }}
          </Form.Item>
          <Form.Item label={t("agents.template")} name="template">
            <Select
              options={[
                {
                  value: "default",
                  label: t("agents.defaultTemplate"),
                },
                {
                  value: "custom",
                  label: t("agents.customTemplate"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const template = getFieldValue("template")
              if (template === "custom") {
                return (
                  <CustomTemplate>
                    <Form.Item
                      label={t("agents.subject")}
                      name={["options", "custom_subject"]}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t("agents.subject")}
                      name={["options", "custom_body"]}
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </CustomTemplate>
                )
              }
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const CustomTemplate = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #fbfbfb;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
`

export default EditAdminAgentForm
