import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Sidebar from "../Sidebar"
import Header from "../Header"
import { DefaultNavigation } from "../Navigation"
import { useAppContext } from "context/AppContext"
import { useAuth } from "context/AuthContext"

function Layout({ children }) {
  const { session, tenantName } = useAuth()
  const { i18n } = useTranslation()

  const USER = session && session?.user
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  const IS_SUPER_ADMIN =
    session && session?.user?.permissions?.includes("super_admin")

  const ORGLOGO = session && session?.org_logo

  const { isSidebarCollapsed, fullScreen } = useAppContext()

  const direction = i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"

  return (
    <>
      <Header
        IS_ADMIN={IS_ADMIN}
        direction={direction}
        tenantName={tenantName}
        Logo={ORGLOGO}
      />

      <Sidebar
        isCollapsed={isSidebarCollapsed}
        fullScreen={fullScreen}
        direction={direction}
        tenantName={tenantName}
        Logo={ORGLOGO}
        IS_ADMIN={IS_ADMIN}
        style={{
          minWidth: "290px",
        }}
      >
        <DefaultNavigation
          IS_ADMIN={IS_ADMIN}
          IS_SUPER_ADMIN={IS_SUPER_ADMIN}
          USER={USER}
        />
      </Sidebar>

      <Main
        isCollapsed={isSidebarCollapsed}
        fullScreen={fullScreen}
        direction={direction}
      >
        {children}
      </Main>
    </>
  )
}

const Main = styled.main`
  margin-left: 0;
  margin-top: var(--header-height);
  transition: all 0.2s;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    margin-left: ${({ isCollapsed, fullScreen, direction }) =>
      fullScreen || direction === "rtl"
        ? "0"
        : isCollapsed
        ? "var(--sidebar-width-collapsed)"
        : "240px"};

    margin-right: ${({ isCollapsed, fullScreen, direction }) =>
      fullScreen || direction === "ltr"
        ? "0"
        : isCollapsed
        ? "var(--sidebar-width-collapsed)"
        : "240px"};
  }
`

export default Layout
