import React, { useEffect, useState } from "react"
import styled from "styled-components"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import {
  Input,
  List,
  Steps,
  Form,
  Collapse,
  Divider,
  Checkbox,
  Select,
  InputNumber,
  Upload,
} from "antd"
import Avatar from "antd/lib/avatar/avatar"
import {
  datasourceImages,
  destinationImages,
} from "components/shared/Icon/CustomIcons.utils"
import { useTranslation } from "react-i18next"
import { UploadOutlined } from "@ant-design/icons"

const getBase64OfFile = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()

    fr.addEventListener("load", (e) => {
      const base64Data = fr.result.split("base64,")[1]
      resolve(base64Data)
    })

    fr.addEventListener("error", (err) => {
      reject(err)
    })

    fr.readAsDataURL(file)
  })
}

const FormContainer = ({ type, onPrevious, onSubmit, isLoading }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const {
    properties = {},
    extra_options = [],
    required = [],
    secret = [],
  } = type.configuration_schema

  const mainItem =
    extra_options.length > 0
      ? Object.entries(properties).filter(
          (e) => !extra_options.find((d) => d === e[0])
        )
      : Object.entries(properties)

  const extraItem = Object.entries(properties).filter((e) =>
    extra_options.find((d) => d === e[0])
  )

  let defaultValue = Object.entries(properties)
    .filter((e) => e[1].default)
    .map((e) => ({ [e[0]]: e[1].default }))

  defaultValue = Object.assign({}, ...defaultValue)

  return (
    <Form
      layout="vertical"
      size="middle"
      style={{ minWidth: "350px" }}
      form={form}
      onFinish={onSubmit}
      initialValues={defaultValue}
    >
      <Form.Item
        label={t("global.name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {type.type === "results" && (
        <Form.Item name="options" hidden>
          <Input value={{}} />
        </Form.Item>
      )}
      <Divider />
      {mainItem.map((item) => (
        <Form.Item
          label={item[1].type !== "boolean" && (item[1].title || item[0])}
          name={item[0]}
          key={item[[0]]}
          rules={[
            {
              required:
                required.length > 0
                  ? required.find((e) => e === item[0])
                  : false,
              message: t("global.requiredField"),
            },
          ]}
        >
          {secret.find((e) => e === item[0]) ? (
            item[1]?.title?.includes("File") ? (
              <Upload
                maxCount={1}
                accept=".json,application/json"
                listType="text"
                beforeUpload={false}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            ) : (
              <Input.Password />
            )
          ) : item[1].type === "number" ? (
            <InputNumber style={{ width: "100%" }} />
          ) : item[1].type === "boolean" ? (
            <Checkbox>{item[1].title || item[0]}</Checkbox>
          ) : !!item[1].enum ? (
            <Select defaultValue={item[1].enum[0]}>
              {item[1].enum.map((e) => (
                <Select.Option value={e}>{e}</Select.Option>
              ))}
            </Select>
          ) : (
            <Input />
          )}
        </Form.Item>
      ))}
      {extraItem.length > 0 && (
        <Collapse bordered={false} ghost>
          <Collapse.Panel header={t("dataSource.additionalSttings")}>
            {extraItem.map((item) => (
              <Form.Item
                label={item[1].type !== "boolean" && (item[1].title || item[0])}
                name={item[0]}
                rules={[
                  {
                    required:
                      required.length > 0
                        ? required.find((e) => e === item[0])
                        : false,
                    message: t("global.requiredField"),
                  },
                ]}
              >
                {secret.find((e) => e === item[0]) ? (
                  <Input.Password />
                ) : item[1].type === "number" ? (
                  <InputNumber style={{ width: "100%" }} />
                ) : item[1].type === "boolean" ? (
                  <Checkbox>{item[1].title || item[0]}</Checkbox>
                ) : !!item[1].enum ? (
                  <Select defaultValue={item[1].enum[0]}>
                    {item[1].enum.map((e) => (
                      <Select.Option value={e}>{e}</Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input />
                )}
              </Form.Item>
            ))}
          </Collapse.Panel>
        </Collapse>
      )}

      <Form.Item>
        <FormModal.Footer>
          <Button type="cancel" onClick={onPrevious}>
            {t("global.previous")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("global.create")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

function CreateForm(props) {
  const { t } = useTranslation()
  const { onChange, onCancel, closeModal, Types, onCreate, createLoading } =
    props
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedType, setSelectedType] = useState(null)
  const [disableBtn, setDisableBtn] = useState(true)
  const [searchName, setSearchName] = useState("")
  const [filteredTypes, setFilteredTypes] = useState(Types)

  useEffect(() => {
    const newFilteredArray = Types.filter((item) =>
      item.name.toLowerCase().includes(searchName.toLowerCase())
    )
    setFilteredTypes(newFilteredArray)
  }, [Types, searchName])

  const onSelect = (source) => {
    setSelectedType(source)
    setCurrentStep(1)
    setDisableBtn(false)
  }

  const onSubmit = async (value) => {
    let data = { type: selectedType.type }

    for (const key in value) {
      if (key === "name") {
        data = { ...data, [key]: value[key] }
      } else if (key === "jsonKeyFile") {
        const base64Data = await getBase64OfFile(value[key].file.originFileObj)
        data = { ...data, options: { ...data.options, [key]: base64Data } }
      } else {
        data = { ...data, options: { ...data.options, [key]: value[key] } }
      }
    }
    onCreate(data, closeModal)
  }

  const onPrevious = () => {
    setCurrentStep(0)
    setDisableBtn(true)
  }

  useEffect(() => onChange(currentStep), [currentStep, onChange])

  return (
    <Container>
      <Steps size="small" progressDot current={currentStep} className="steps">
        <Steps.Step title={t("channels.typeSelection")} />
        <Steps.Step title={t("channels.configuration")} />
        <Steps.Step title={t("global.done")} />
      </Steps>

      {currentStep === 0 && (
        <>
          <Input.Search
            placeholder={t("global.search")}
            style={{ marginBottom: "0.5rem" }}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            // onSearch={(e) => setSearchName(e)}
          />
          <List
            className="itemsList"
            itemLayout="horizontal"
            dataSource={filteredTypes}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => onSelect(item)}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`${
                        datasourceImages[item.type] ||
                        destinationImages[item.type]
                      }`}
                    />
                  }
                  title={item.name}
                />
              </List.Item>
            )}
          ></List>
          <FormModal.Footer>
            {currentStep === 0 && (
              <Button onClick={onCancel} type="cancel">
                {t("global.cancel")}
              </Button>
            )}
            <Button type="primary" onClick={onSubmit} disabled={disableBtn}>
              {t("global.create")}
            </Button>
          </FormModal.Footer>
        </>
      )}

      {currentStep === 1 && selectedType && (
        <FormContainer
          type={selectedType}
          onPrevious={onPrevious}
          onSubmit={onSubmit}
          isLoading={createLoading}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  .steps {
    margin-bottom: 1rem;
  }

  .itemsList {
    max-height: 60vh;
    overflow: auto;
  }
`
// const Wrapper = styled.form`
//   ${({ theme }) => theme.mixins.flexColumn};

//   .center {
//     text-align: center;
//   }

//   .fg {
//     margin: 0 0 18px;
//   }

//   button {
//     margin: 20px 0 0;
//   }
// `

export default CreateForm
