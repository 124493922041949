import { Form, Image, Input } from "antd"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Text from "components/shared/Text"
import Table from "components/shared/Table"
import Button from "components/shared/Button"
import Icon from "components/shared/Icon"
import FormModal from "components/shared/FormModal"
import {
  useCreateOrganization,
  useDisablOrganization,
  useEnableOrganization,
  useOrganization,
  useUpdateOrganizationLogo,
} from "hooks/admin"
import IconButton from "components/shared/IconButton"
import { OffBtn, OnBtn } from "components/shared/Icon/CustomIcons"
import { FileExcelTwoTone, FileImageTwoTone } from "@ant-design/icons"

function formatDate(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm")
}

function OrganizationStatuse({ org }) {
  const { data, isLoading } = useOrganization(org?.id, { enabled: !!org?.id })
  const disable = useDisablOrganization(org.id)
  const enable = useEnableOrganization(org.id)

  if (isLoading) {
    return "Loading..."
  }
  return (
    <>
      {data?.settings?.is_disabled ? (
        <IconButton
          type="transparent"
          size="xs"
          key={org.id}
          onClick={() => enable.mutate()}
          loading={enable.isLoading}
          disable={enable.isLoading}
        >
          <OffBtn />
        </IconButton>
      ) : (
        <IconButton
          type="transparent"
          size="xs"
          key={org.id}
          onClick={() => disable.mutate()}
          loading={disable.isLoading}
          disable={disable.isLoading}
        >
          <OnBtn />
        </IconButton>
      )}
    </>
  )
}

function CreateOrganizationForm({ onChange, onCancel, closeModal, orgs }) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useCreateOrganization()
  const [form] = Form.useForm()

  // Extract slugs from the array
  const slugs = orgs.map((item) => item.slug)

  // Custom validation function
  const validateSlug = (rule, value) => {
    if (slugs.includes(value)) {
      return Promise.reject("Slug already exists")
    }
    return Promise.resolve()
  }

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  const handelSave = (value) => {
    mutate(value, {
      onSuccess: () => closeModal(),
    })
  }

  return (
    <Form form={form} layout="vertical" onFinish={handelSave}>
      <Form.Item
        label="Org Name"
        name="org_name"
        rules={[{ required: "true" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Org Slug"
        name="org_slug"
        rules={[{ required: "true" }, { validator: validateSlug }]}
        normalize={(value) => value.toLowerCase()}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="User Name"
        name="user_name"
        rules={[{ required: "true" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: "true", type: "email" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: "true" }]}
      >
        <Input type="password" autoComplete="new-password" />
      </Form.Item>

      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

function EditOrganizationLogo({ onChange, onCancel, closeModal, org }) {
  const { t } = useTranslation()
  const [logoUrl, setLogoUrl] = useState(org.logo)
  const { mutate, isLoading } = useUpdateOrganizationLogo()

  const handelUpdateLogo = (value) => {
    mutate(
      {
        id: org.id,
        data: value,
      },
      {
        onSuccess: closeModal,
      }
    )
  }

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  return (
    <Form
      layout="vertical"
      initialValues={{ ...org }}
      onFinish={handelUpdateLogo}
    >
      <Form.Item label="Logo Url" name="logo">
        <Input onChange={(e) => setLogoUrl(e.target.value)} />
      </Form.Item>
      <Form.Item label="Image">
        <Image src={logoUrl} width={200} height={60} />
      </Form.Item>
      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

const Columns = () => {
  const { t } = useTranslation()

  return [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Slug",
      key: "slug",
      dataIndex: "slug",
    },
    {
      title: t("global.tabels.createdAt"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => (
        <Text size="sm">{formatDate(created_at)}</Text>
      ),
    },
    {
      title: t("global.tabels.lastExecuted"),
      key: "updated_at",
      dataIndex: "updated_at",
      render: (_, { updated_at }) => (
        <Text size="sm">{updated_at ? formatDate(updated_at) : ""}</Text>
      ),
    },
    {
      title: "Logo",
      Key: "logo",
      dataIndex: "logo",
      render: (_, org) => (
        <>
          {!!org?.logo ? (
            <FormModal
              title="Edit Organization Logo"
              width={600}
              hasChanged={({ isLoading }) => !!isLoading}
              Form={EditOrganizationLogo}
              formProps={{ org }}
            >
              <FileImageTwoTone style={{ fontSize: "1.6rem" }} />
            </FormModal>
          ) : (
            <FormModal
              title="Edit Organization Logo"
              width={600}
              hasChanged={({ isLoading }) => !!isLoading}
              Form={EditOrganizationLogo}
              formProps={{ org }}
            >
              <FileExcelTwoTone
                style={{ fontSize: "1.6rem" }}
                twoToneColor="#eb2f96"
              />
            </FormModal>
          )}
        </>
      ),
    },
    {
      title: t("global.tabels.actions"),
      render: (_, value) => <OrganizationStatuse org={value} />,
    },
  ]
}

const OrganizationsManagment = ({ organizations }) => {
  return (
    <div>
      <FormModal
        title="Create Organization"
        width={500}
        hasChanged={({ isLoading }) => !!isLoading}
        Form={CreateOrganizationForm}
        formProps={{ orgs: organizations?.organization }}
      >
        <Button type="blue" size="lg">
          <Icon type="plusCircle" /> Add Organization
        </Button>
      </FormModal>
      <Table
        scroll={{ x: "max-contentmax" }}
        size="sm"
        rowKey="id"
        columns={Columns()}
        dataSource={organizations?.organization}
        // pagination={false}
        style={{
          marginTop: "1rem",
        }}
      />
    </div>
  )
}

export default OrganizationsManagment
