import { Steps } from "antd"
import SelectedStep from "components/connections/SelectedStep"
import React, { useState } from "react"
import styled from "styled-components"

const CreateConnections = () => {
  const [step, setStep] = useState(0)
  return (
    <Wrapper>
      <Steps
        current={step}
        items={[
          {
            title: "Define source",
          },
          {
            title: "Define destination",
          },
          {
            title: "Configure connection",
          },
        ]}
      />
      <SelectedStep step={step} onChangeStep={setStep} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem;
`

export default CreateConnections
