import { useEffect } from "react"
import { Form, Input, Result, notification } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import Icon from "components/shared/Icon"
import { useAuth } from "context/AuthContext"
import { useCreateETLWorkspace } from "hooks/ETL/workspace"
import auth from "services/auth"

const CreateWorkspaceForm = ({ onChange, onCancel, closeModal }) => {
  const { mutate, isLoading } = useCreateETLWorkspace()

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  const handelSave = (values) => {
    mutate(values, {
      onSuccess: () => {
        return auth.logout()
      },
    })
  }

  return (
    <Form onFinish={handelSave}>
      <p style={{ color: "red" }}>
        Important: After Create Workspace Logout Required to Complete Workspace
        Configuration
      </p>
      <Form.Item name="name" label="Workspace name">
        <Input />
      </Form.Item>
      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          Create
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

const withETLWorkspace = (BaseComponent) => {
  return function CheckETLWorkspace(props) {
    const { session } = useAuth()

    if (!session?.org_workspace) {
      return (
        <Result
          title="There isn't workspace related to this tenant"
          subTitle="Please create workspace first to enable ETL functionlity"
          extra={
            <FormModal
              title="Create Workspace"
              width={500}
              hasChanged={({ name }) => !!name}
              Form={CreateWorkspaceForm}
            >
              <Button type="blue" size="lg" id="createAppBtn">
                <Icon type="plusCircle" />
                Create Workspace
              </Button>
            </FormModal>
          }
        />
      )
    }

    return <BaseComponent {...props} />
  }
}

export default withETLWorkspace
