import React from "react"
import styled from "styled-components"
import { Image, Result, Space, Spin } from "antd"
import { useApplications } from "hooks/applications"
import { useTranslation } from "react-i18next"
import Text from "components/shared/Text"
import EmptyApps from "static/images/empty/no-apps.svg"
import AppForm from "./AppForm"
import ApplicationsListView from "./ApplicationsListView"

const HomeApplication = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useApplications()

  if (!isLoading && (isError || !data || data?.message)) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data?.length === 0) {
    return (
      <EmptyWrapper id="homeApp">
        <Space direction="vertical" size={0}>
          <Text size="xl" color="primary">
            {t("home.homeAppPhase1")}
          </Text>
          <Text size="xl" color="brand">
            {t("home.homeAppPhase2")}
          </Text>
        </Space>
        <AppForm />
        <Image
          width={200}
          src={EmptyApps}
          preview={false}
          alt="No Favorite Dashboard Yet!"
        />
      </EmptyWrapper>
    )
  }

  return (
    <Spin spinning={isLoading}>
      <Wrapper id="homeApp">
        <ApplicationsListView applications={data} />
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  border: 1px solid #e2e2e7;
  border-radius: 12px;
  margin-top: 1rem;
  max-height: 488px;
  overflow: auto;
`

const EmptyWrapper = styled.div`
  /* background-color: #ecf5f8; */
  border: 1px solid #f1f1f2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 2rem;

  & .ant-typography,
  .ant-space {
    text-align: center;
  }
`

export default HomeApplication
