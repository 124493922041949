import React from "react"
import * as CustomIcons from "./CustomIcons"
import {
  MenuOutlined,
  SettingFilled,
  BellFilled,
  BellOutlined,
  HomeOutlined,
  UserOutlined,
  PullRequestOutlined,
  PlusCircleOutlined,
  DashboardOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
  ShareAltOutlined,
  EllipsisOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  CloseCircleFilled,
  EditFilled,
  CloseSquareOutlined,
  CheckSquareOutlined,
  CaretDownOutlined,
  SlackOutlined,
  MailOutlined,
  MailFilled,
  ThunderboltFilled,
  DeleteFilled,
  MoreOutlined,
  ExportOutlined,
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  AppstoreAddOutlined,
  SendOutlined,
  TableOutlined,
  WarningFilled,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  GlobalOutlined,
  HolderOutlined,
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons"

import onBtn from "static/images/onBtn.svg"
import offBtn from "static/images/offBtn.svg"
// The icon component is just a facade wrapping
// all icons used in our website, makes it easier to handle
// the different icons used.

const icons = {
  automotive: CustomIcons.Automotive,
  dark: CustomIcons.DarkMode,
  realestate: CustomIcons.RealEstate,
  connections: CustomIcons.Connections,
  sources: CustomIcons.Sources,
  destination: CustomIcons.Destination,
  datastorage: CustomIcons.DataStorage,
  applications: CustomIcons.Applications,
  userIcon: CustomIcons.UserIcon,
  deleteIcon: CustomIcons.DeleteIcon,
  menu: MenuOutlined,
  bellOn: BellFilled,
  bellOff: BellOutlined,
  settings: SettingFilled,
  home: HomeOutlined,
  user: UserOutlined,
  requests: PullRequestOutlined,
  plusCircle: PlusCircleOutlined,
  dashboard: DashboardOutlined,
  starFilled: StarFilled,
  starOutlined: StarOutlined,
  sync: SyncOutlined,
  share: ShareAltOutlined,
  horizontalDots: EllipsisOutlined,
  verticalDots: MoreOutlined,
  fullscreenExit: FullscreenExitOutlined,
  fullscreen: FullscreenOutlined,
  plus: PlusOutlined,
  close: CloseCircleFilled,
  closeSquare: CloseSquareOutlined,
  checkSquare: CheckSquareOutlined,
  edit: EditFilled,
  delete: DeleteFilled,
  caret: CaretDownOutlined,
  slack: SlackOutlined,
  mailLight: MailOutlined,
  mailDark: MailFilled,
  bolt: ThunderboltFilled,
  export: ExportOutlined,
  copy: CopyOutlined,
  editOutlined: EditOutlined,
  deleteOutlined: DeleteOutlined,
  menuIn: MenuFoldOutlined,
  menuOut: MenuUnfoldOutlined,
  onBtn,
  offBtn,
  DownArrow: DownOutlined,
  widgets: AppstoreAddOutlined,
  send: SendOutlined,
  tabel: TableOutlined,
  warn: WarningFilled,
  question: QuestionCircleOutlined,
  check: CheckOutlined,
  x: CloseOutlined,
  archiv: InboxOutlined,
  eyeOpen: EyeOutlined,
  eyeClose: EyeInvisibleOutlined,
  alignLeft: AlignLeftOutlined,
  alignCenter: AlignCenterOutlined,
  alignRight: AlignRightOutlined,
  // logout: LogoutOutlined,
  global: GlobalOutlined,
  holder: HolderOutlined,
  rightArrow: CaretRightOutlined,
  downArrow: CaretDownOutlined,
  left: LeftOutlined,
  right: RightOutlined,
  logout: CustomIcons.LogoutIcon,
  filter: CustomIcons.FilterIcon,
  dashboardIcon: CustomIcons.DashboardIcon,
  agentIcon: CustomIcons.AgentIcon,
  idCard: CustomIcons.IdCardIcon,
  email: CustomIcons.EmailIcon,
  phone: CustomIcons.PhoneIcon,
  OverviewPageIcon: CustomIcons.OverviewPageIcon,
  ApplicationsPageIcon: CustomIcons.ApplicationsPageIcon,
  DashboardPageIcon: CustomIcons.DashboardPageIcon,
  AgentsPageIcon: CustomIcons.AgentsPageIcon,
  requestIcon: CustomIcons.RequestIcon,
}

function Icon({ type, ...delegated }) {
  const Component = icons[type]

  if (!Component) {
    throw new Error(`Unrecognized icon type: ${type}.`)
  }

  return <Component {...delegated} />
}

export default Icon
