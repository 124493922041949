import React, { useEffect } from "react"
import { ChatWidget } from "@icloudready/chat-widget"
import { useAuth } from "context/AuthContext"

type IdengagerchatConfig = {
  accountId: string
  baseUrl: string
  // enableStorytime: boolean
  debug: boolean
}

type IProps = {
  idengagerchatConfig: IdengagerchatConfig
  customer: {
    name: string
    email: string
    external_id: string
    metadata?: { [key: string]: any }
  }
  onClick?: () => void
}

const SupportChat: React.FC<IProps> = ({ idengagerchatConfig, customer }) => {
  const { session } = useAuth()

  // useEffect(() => {
  //   if (idengagerchatConfig.enableStorytime) {
  //     Storytime.init({
  //       accountId: idengagerchatConfig.accountId,
  //       baseUrl: idengagerchatConfig.baseUrl,
  //       customer: {
  //         name: customer?.name || session?.user?.name,
  //         email: customer?.email || session?.user?.email,
  //         external_id: customer?.external_id || session?.user?.id,
  //         metadata: customer?.metadata,
  //       },
  //       debug: idengagerchatConfig.debug,
  //     })
  //   }
  // }, [customer, idengagerchatConfig])

  return (
    // <ChatButton onClick={onClick}>
    //   <FontAwesomeIcon icon={faComment} />
    // </ChatButton>
    <>
      <ChatWidget
        accountId={idengagerchatConfig.accountId}
        title="Welcome to Idataworkers!"
        subtitle="Ask us anything in the chat window below 😊"
        newMessagePlaceholder="Start typing..."
        customer={{
          external_id: session?.user?.id,
          email: session?.user?.email,
          name: session?.user?.name,
          metadata: {},
        }}
        // customer={{}}
        primaryColor="#0097c2"
        greeting="Hi there! How can I help you?"
        baseUrl={idengagerchatConfig.baseUrl}
      />
    </>
  )
}

export default SupportChat
