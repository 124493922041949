import React, { useState } from "react"
import styled from "styled-components"
import { Collapse, Divider, Form, Input } from "antd"
import Button from "components/shared/Button"
import { useDataSourceTypes } from "hooks/dataSources"
import { datasourceImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/datasource/mucs.png"
import { useTranslation } from "react-i18next"

const SourceDetails = ({
  data,
  onEditSource,
  onDeleteSource,
  types,
  onTestSource,
  testLoading,
  updateLoading,
}) => {
  const { t } = useTranslation()
  const { data: sourceTypes, isLoading: loadingTypes } = useDataSourceTypes()

  const [sourceData, setSourceData] = useState(data)

  const currentType =
    !loadingTypes && sourceTypes.find((e) => e.type === data.type)

  const {
    properties = {},
    extra_options = [],
    required = [],
  } = currentType && currentType?.configuration_schema

  const mainItem =
    extra_options.length > 0
      ? Object.entries(properties).filter(
          (e) => !extra_options.find((d) => d === e[0])
        )
      : Object.entries(properties)

  const extraItem = Object.entries(properties).filter((e) =>
    extra_options.find((d) => d === e[0])
  )

  const onFinish = () => {
    onEditSource(sourceData)
  }

  const onChangeValue = (name, value) => {
    setSourceData((current) => {
      if (name === "name") {
        return { ...current, [name]: value }
      } else {
        return {
          ...current,
          options: { ...current.options, [name]: value },
        }
      }
    })
  }

  return (
    <Details>
      <img
        src={`${datasourceImages[data.type] || defaultImage}`}
        alt={data.type}
      />
      <h1>{data.type}</h1>
      <Form
        layout="vertical"
        className="form"
        initialValues={{
          name: data.name,
          ...data.options,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("global.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("global.requiredField"),
            },
          ]}
        >
          <Input onChange={(e) => onChangeValue("name", e.target.value)} />
        </Form.Item>
        <Divider />
        {mainItem.map((item) => (
          <Form.Item
            label={item[1].title || item[0]}
            name={item[0]}
            rules={[
              {
                required:
                  required.length > 0
                    ? required.find((e) => e === item[0])
                    : false,
                message: t("global.requiredField"),
              },
            ]}
          >
            <Input onChange={(e) => onChangeValue(item[0], e.target.value)} />
          </Form.Item>
        ))}
        {extraItem.length > 0 && (
          <Collapse bordered={false} ghost>
            <Collapse.Panel header={t("dataSource.additionalSttings")}>
              {extraItem?.map((item) => (
                <Form.Item
                  label={item[1].title || item[0]}
                  name={item[0]}
                  rules={[
                    {
                      required:
                        required.length > 0
                          ? required.find((e) => e === item[0])
                          : false,
                      message: t("global.requiredField"),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => onChangeValue(item[0], e.target.value)}
                  />
                </Form.Item>
              ))}
            </Collapse.Panel>
          </Collapse>
        )}

        <Form.Item>
          <Button
            htmlType="submit"
            type="secondary"
            size="md"
            style={{ width: "100%" }}
            disabled={updateLoading}
            loading={updateLoading}
          >
            {t("global.save")}
          </Button>
        </Form.Item>
        <Form.Item>
          <div className="footer-btn">
            <Button type="primary" size="sm" onClick={onDeleteSource}>
              {t("global.delete")}
            </Button>
            <Button
              type="blue"
              size="sm"
              onClick={onTestSource}
              disabled={testLoading}
              loading={testLoading}
            >
              {t("dataSource.testConnection")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Details>
  )
}

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 4rem;
    margin-bottom: 0.5rem;
  }

  .form {
    width: 40%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .footer-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
  }
`

export default SourceDetails
