import { useAuth } from "context/AuthContext"
import React from "react"
import { Link } from "react-router-dom"

const CustomLink = ({ to, children }) => {
  const { tenantName } = useAuth()
  return <Link to={`/${tenantName}${to}`}>{children}</Link>
}

export default CustomLink
