import React from "react"
import styled from "styled-components"
import { RoundIconButton } from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import Text from "components/shared/Text"
import { useUpdateFavoriteRequest } from "hooks/requests"
import { Space, Tag } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"

function Name({ request, ...delegated }) {
  const {
    id,
    name,
    is_favorite: isFavorite,
    is_draft,
    is_archived,
    tags,
  } = request

  const { mutate, isLoading } = useUpdateFavoriteRequest()

  const handleClick = React.useCallback(() => mutate(request), [isFavorite])

  const icon = isFavorite ? "starFilled" : "starOutlined"

  return (
    <NameWrapper {...delegated}>
      <Space.Compact>
        <RoundIconButton size="sm" loading={isLoading} onClick={handleClick}>
          <Icon type={icon} />
        </RoundIconButton>

        <CustomLink to={`/requests/${id}`}>
          <Text className="requestName">{name}</Text>
        </CustomLink>
      </Space.Compact>
      {tags?.map((tag, i) => (
        <Tag key={i}>{tag}</Tag>
      ))}
      {is_draft && <Tag color="volcano">Unpublished</Tag>}
      {is_archived && <Tag color="red">Archived</Tag>}
    </NameWrapper>
  )
}

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;

  button {
    margin: 0 8px 0 0;
    color: rgb(251, 210, 8);

    &:hover,
    &:visited,
    &:active,
    &:focus {
      color: rgb(251, 210, 8);
    }
  }

  @media (min-width: 768px) {
    .requestName {
      font-size: 12px;
    }
  }
`

export { Name }
