import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { DatePicker, Input, InputNumber, Select } from "antd"
import dayjs from "dayjs"
import { useCreateQueryResult, useCreateRequest } from "hooks/requests"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import IconButton from "components/shared/IconButton"
import AddParameters from "./AddParameters"
import { Vis } from "@icloudready/idataworkers-visualization"

import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-mysql"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"
import { setCompleters } from "ace-builds/src-noconflict/ext-language_tools"
import { useAuth } from "context/AuthContext"
import Icon from "components/shared/Icon/Icon"
import EditParameters from "../RequestSnippets/EditParameters"
import RequestDropwon from "components/dashboards/Paramters/RequestDropdown"
import { useTranslation } from "react-i18next"

const RequestQuery = forwardRef(({ sourceId, snippets }, ref) => {
  const { t, i18n } = useTranslation()
  const { session, tenantName } = useAuth()
  const createRequest = useCreateRequest()
  const createQuery = useCreateQueryResult()
  const navigate = useNavigate()
  const [query, setQuery] = useState("")
  const [parameters, setParameters] = useState([])
  const [queryResult, setQueryResult] = useState()
  const [queryError, setQueryError] = useState()
  const editorRef = useRef(null)

  const data = snippets?.map((d) => ({
    caption: d.trigger,
    snippet: d.snippet,
    type: "snippet",
  }))

  useImperativeHandle(ref, () => ({
    addSchema(text) {
      const editor = editorRef.current.editor
      const session = editor.getSession()
      const cursorPosition = editor.getCursorPosition()
      const row = cursorPosition.row
      const column = cursorPosition.column
      session.insert({ row, column }, text)
    },
  }))

  // const queryId = parameters.find((param) => param.type === "query")?.queryId
  // const {
  //   data: dropdownData,
  //   isLoading,
  //   isError,
  //   refetch,
  // } = useGetQueryDropdown(queryId, {
  //   enabled: !!queryId,
  // })

  // useEffect(() => {
  //   if (queryId) {
  //     refetch()
  //   }
  // }, [parameters])

  useEffect(() => {
    const completer = {
      getCompletions: function (editor, session, pos, prefix, callback) {
        let completions = data

        completions.forEach((i) => {
          completions.push({
            caption: i.caption,
            snippet: i.snippet,
            type: i.type,
          })
        })
        callback(null, completions)
      },
    }

    setCompleters([completer])
  }, [])

  const onAddSchema = (text) => {
    const editor = editorRef.current.editor
    const session = editor.getSession()
    const cursorPosition = editor.getCursorPosition()
    const row = cursorPosition.row
    const column = cursorPosition.column
    session.insert({ row, column }, text)
  }

  const onAddParameters = (param) => {
    setParameters((current) => [...current, param])
    const editor = editorRef.current.editor
    const session = editor.getSession()
    const cursorPosition = editor.getCursorPosition()
    const row = cursorPosition.row
    const column = cursorPosition.column
    const paramStr =
      param.type === "date-range" ||
      param.type === "datetime-range" ||
      param.type === "datetime-range-with-seconds"
        ? `{{${param.title}.start}} {{${param.title}.end}}`
        : `{{${param.title}}}`
    session.insert({ row, column }, paramStr)
    setQuery(session.getValue())
  }

  const onSaveQuery = () => {
    createRequest.mutate(
      {
        data: {
          data_source_id: sourceId,
          latest_query_data_id: null,
          name: "New Request",
          options: { parameters, apply_auto_limit: false },
          query: query,
          schedule: null,
        },
      },
      {
        onSuccess: (query) => {
          navigate(`/${tenantName}/requests/${query.id}`)
        },
      }
    )
  }

  const onExecuteQuery = () => {
    createQuery.mutate(
      {
        data: {
          apply_auto_limit: false,
          data_source_id: sourceId,
          max_age: 0,
          parameters: parameters.reduce(
            (obj, item) =>
              Object.assign(obj, {
                [item.name]:
                  item.type === "query" || item.type === "enum"
                    ? item.multiValuesOptions && Array.isArray(item.value)
                      ? item.multiValuesOptions?.prefix === '"'
                        ? `"${item.value.join('", "')}"`
                        : item.multiValuesOptions.prefix === "'"
                        ? `'${item.value.join("', '")}'`
                        : `${item.value.join(", ")}`
                      : item.value
                    : item.value,
              }),
            {}
          ),
          query: query,
        },
      },
      {
        onSuccess: (query) => {
          if (query.error) {
            setQueryError(query.error)
            setQueryResult(null)
          } else if (query.query_result) {
            setQueryResult(query.query_result)
            setQueryError(null)
          } else {
            setQueryResult(query)
            setQueryError(null)
          }
        },
      }
    )
  }

  const onFieldsChange = (value, name) => {
    setParameters((current) =>
      current.map((obj) => {
        if (obj.name === name) {
          return { ...obj, value: value }
        }
        return obj
      })
    )
  }
  const handleEditorChange = (value) => {
    const addedFields = findAddedFields(query, value)
    const deletedFields = findDeletedFields(query, value)
    addedFields.forEach((fieldName) => onAddParameter(fieldName))
    deletedFields.forEach((fieldName) => onDeleteParameter(fieldName))
    setQuery(value)
  }

  const onAddParameter = (param) => {
    const paramName = param.split(".")[0]
    const existingParam = parameters.find((p) => p.title === paramName)
    if (existingParam) {
      return
    }
    setParameters((current) => [
      ...current,
      {
        global: false,
        locals: [],
        name: paramName,
        title: paramName,
        type: "text",
      },
    ])
  }

  const onDeleteParameter = (param) => {
    const paramName = param.split(".")[0]
    setParameters((current) => current.filter((p) => p.title !== paramName))
  }

  const findAddedFields = (prevValue, currValue) => {
    const prevFields = getFieldNames(prevValue)
    const currFieldsSet = new Set(getFieldNames(currValue))
    const addedFields = [...currFieldsSet].filter(
      (fieldName) => !prevFields.includes(fieldName)
    )
    return addedFields
  }

  const findDeletedFields = (prevValue, currValue) => {
    const prevFields = getFieldNames(prevValue)
    const currFields = getFieldNames(currValue)
    return prevFields.filter((fieldName) => !currFields.includes(fieldName))
  }

  const getFieldNames = (value) => {
    const matches = value.match(/{{(.+?)}}/g)
    return matches ? matches.map((match) => match.slice(2, -2).trim()) : []
  }

  const handelInputChange = (param, value) => {
    setParameters((prev) =>
      prev.map((p) => {
        return p.name === param.name ? { ...p, value: value } : p
      })
    )
  }

  const formatInput = (props) => {
    if (props.type === "enum") {
      return (
        <Select
          mode={props.multiValuesOptions && "multiple"}
          showArrow
          options={props.enumOptions
            .split("\n")
            .map((e) => ({ value: e, label: e }))}
          value={props.value}
          onChange={(value) => handelInputChange(props, value)}
          style={{ minWidth: "100px" }}
        />
      )
    } else if (props.type === "query") {
      return (
        <RequestDropwon
          props={props}
          handelInputChange={handelInputChange}
          isCreated={false}
        />
      )
    } else if (props.type === "user") {
      return (
        <Input
          value={session.user.email}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "text") {
      return (
        <Input
          defaultValue={props.value}
          onChange={(value) => handelInputChange(props, value.target.value)}
        />
      )
    } else if (props.type === "number") {
      return (
        <InputNumber
          onChange={(value) => handelInputChange(props, value)}
          defaultValue={props.value}
          style={{
            display: "inline-block",
          }}
        />
      )
    } else if (props.type === "date") {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : dayjs(props.value, "YYYY-MM-DD")
          }
          format="YYYY-MM-DD"
          showTime={props.type === "datetime-local"}
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (
      props.type === "datetime-local" ||
      props.type === "datetime-with-seconds"
    ) {
      return (
        <DatePicker
          defaultValue={
            props.value === "d_now"
              ? dayjs()
              : props.value === "d_yesterday"
              ? dayjs().subtract(1, "day")
              : props.type === "datetime-with-seconds"
              ? dayjs(props.value, "YYYY-MM-DD HH:mm:ss")
              : dayjs(props.value, "YYYY-MM-DD HH:mm")
          }
          format={
            props.type === "datetime-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => handelInputChange(props, value)}
        />
      )
    } else if (props.type === "date-range") {
      return (
        <DatePicker.RangePicker
          // defaultValue={[
          //   props?.value?.start === "d_now"
          //     ? dayjs()
          //     : props.value.start === "d_yesterday"
          //       ? dayjs().subtract(1, "day")
          //       : dayjs(props.value.start, "YYYY-MM-DD")
          //   ,
          //   props.value.end === "d_now"
          //     ? dayjs()
          //     : props.value.end === "d_yesterday"
          //       ? dayjs().subtract(1, "day")
          //       : dayjs(props.value.end, "YYYY-MM-DD")
          // ]}
          format="YYYY-MM-DD"
          onChange={(_, value) => {
            setParameters((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
          }}
        />
      )
    } else if (
      props.type === "datetime-range" ||
      props.type === "datetime-range-with-seconds"
    ) {
      return (
        <DatePicker.RangePicker
          // defaultValue={[
          //   props.value.start === "d_now"
          //     ? dayjs()
          //     : props.value.start === "d_yesterday"
          //       ? dayjs().subtract(1, "day")
          //       : props.type.start === "datetime-local-seconds"
          //         ? dayjs(props.value.start, "YYYY-MM-DD HH:mm:ss")
          //         : dayjs(props.value.start, "YYYY-MM-DD HH:mm")
          //   ,
          //   props.value.end === "d_now"
          //     ? dayjs()
          //     : props.value.end === "d_yesterday"
          //       ? dayjs().subtract(1, "day")
          //       : props.type.end === "datetime-local-seconds"
          //         ? dayjs(props.value.end, "YYYY-MM-DD HH:mm:ss")
          //         : dayjs(props.value.end, "YYYY-MM-DD HH:mm")
          // ]}
          format={
            props.type === "datetime-range-with-seconds"
              ? "YYYY-MM-DD HH:mm:ss"
              : "YYYY-MM-DD HH:mm"
          }
          showTime
          onChange={(_, value) => {
            setParameters((prev) =>
              prev.map((p) => {
                return p.name === props.name
                  ? { ...p, value: { start: value[0], end: value[1] } }
                  : p
              })
            )
          }}
        />
      )
    }
  }

  const onEditParameters = (newParameter, closeModal) => {
    const updatedParameters = [...parameters]

    const indexToUpdate = updatedParameters.findIndex(
      (param) => param.name === newParameter.name
    )

    if (indexToUpdate !== -1) {
      updatedParameters[indexToUpdate] = {
        ...updatedParameters[indexToUpdate],
        ...newParameter,
      }
    } else {
      updatedParameters.push(newParameter)
    }

    setParameters(updatedParameters)
    closeModal()
  }

  return (
    <Main>
      <div className="queryContainer">
        <AceEditor
          ref={editorRef}
          mode="mysql"
          theme="xcode"
          name="blah2"
          fontSize={14}
          showPrintMargin={false}
          enableSnippets={true}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          value={query}
          onChange={handleEditorChange}
          focus={true}
          width="100%"
          height="100%"
        />
      </div>
      <div className="actions">
        <div>
          <FormModal
            Form={AddParameters}
            hasChanged={(name) => !!name}
            title={t("requests.addParams")}
            formProps={{
              onAddParameters: onAddParameters,
              parameters: parameters,
            }}
          >
            <IconButton type="transparent">{`{{}}`}</IconButton>
          </FormModal>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            type="primary"
            onClick={onSaveQuery}
            loading={createRequest.isLoading}
            disabled={createRequest.isLoading}
          >
            {t("global.save")}
          </Button>
          <Button
            type="text"
            onClick={onExecuteQuery}
            loading={createQuery.isLoading}
            disabled={createQuery.isLoading}
          >
            {t("global.execute")}
          </Button>
        </div>
      </div>
      <div className="result">
        <div className="parameters">
          {parameters.map((param, idx) => (
            <InputContainer
              activeTab="defsult"
              key={idx}
              direction={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
            >
              <div className="parametersXX">
                <label>{param.title}</label>
                {formatInput({ ...param })}
              </div>
              <FormModal
                Form={EditParameters}
                hasChanged={(name) => !!name}
                title={t("requests.editParams")}
                formProps={{
                  parameter: param,
                  onEditParameters: onEditParameters,
                  isLoading: false,
                }}
              >
                <IconButton size="sm" type="transparent">
                  <Icon type="settings" />
                </IconButton>
              </FormModal>
            </InputContainer>
          ))}
        </div>
        <div className="errors">{queryError && <p>{queryError}</p>}</div>
        <div className="data">
          {queryResult && (
            <Vis
              widget={{
                visualization: {
                  type: "TABLE",
                  options: {
                    columns: undefined,
                  },
                },
              }}
              data={queryResult}
            />
          )}
        </div>
      </div>
    </Main>
  )
})

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  .queryContainer {
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    height: 300px;
  }

  .actions {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .result {
    width: 100%;
    height: max-content;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .parameters {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }

    .errors {
      background-color: #fff2f0;
      p {
        padding: 0.5rem;
        font-weight: 600;
      }
    }

    .data {
      width: 100%;
      overflow: auto;
    }
  }
`

const InputContainer = styled.div`
  position: relative;

  button {
    display: ${(p) => (p.activeTab === "data" ? "none" : "block")};
    position: absolute;
    top: 0;
    right: ${({ direction }) => (direction === "rtl" ? "unset" : 0)};
    left: ${({ direction }) => (direction === "rtl" ? 0 : "unset")};
  }

  .parametersXX {
    display: flex;
    flex-direction: column;
  }
`

export default RequestQuery
