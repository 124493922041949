import { Card, Statistic, Table, Tabs, Tag } from "antd"
import styled from "styled-components"
import React from "react"
import { format } from "date-fns"

const gridStyle = {
  width: "50%",
  textAlign: "center",
}

const queuedColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Queued",
    dataIndex: "queued",
    key: "queued",
  },
  {
    title: "Started",
    dataIndex: "started",
    key: "started",
  },
]

const workersColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Birth date",
    dataIndex: "birth_date",
    key: "birth_date",
    render: (date) => format(new Date(date), "yyyy:MM:dd"),
  },
  {
    title: "Last Heartbeat",
    dataIndex: "last_heartbeat",
    key: "last_heartbeat",
    render: (date) => format(new Date(date), "yyyy:MM:dd"),
  },
  {
    title: "Successful jobs",
    dataIndex: "successful_jobs",
    key: "successful_jobs",
  },
  {
    title: "Failed jobs",
    dataIndex: "failed_jobs",
    key: "failed_jobs",
  },
  {
    title: "Total Working Time",
    dataIndex: "total_working_time",
    key: "total_working_time",
    render: (date) => format(new Date(date * 1000), "HH:mm:ss"),
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (tag) => <Tag color={tag === "busy" ? "red" : "green"}>{tag}</Tag>,
  },
]

const QueryJobsStatus = ({ status }) => {
  if (!status) {
    return null
  }

  const queuedTotal = Object.values(status?.queues).reduce((acc, curr) => {
    return acc + curr.queued
  }, 0)

  const startedTotal = Object.values(status?.queues).reduce((acc, curr) => {
    return acc + curr.started.length
  }, 0)

  const queuedDataSource = Object.values(status?.queues).map((item) => ({
    key: item.name,
    name: item.name,
    queued: item.queued,
    started: item.started.length,
  }))

  const workersDataSource = status?.workers.map((item) => ({
    key: item.name,
    name: item.name,
    birth_date: item.birth_date,
    last_heartbeat: item.last_heartbeat,
    successful_jobs: item.successful_jobs,
    failed_jobs: item.failed_jobs,
    total_working_time: item.total_working_time,
    state: item.state,
  }))

  const items = [
    {
      label: "Queues",
      key: "queued",
      children: (
        <Table
          dataSource={queuedDataSource}
          columns={queuedColumns}
          scroll={{ x: "max-contentmax" }}
          pagination={false}
        />
      ),
    },
    {
      label: "Workers",
      key: "Workers",
      children: (
        <Table
          dataSource={workersDataSource}
          columns={workersColumns}
          scroll={{ x: "max-contentmax" }}
          // pagination={false}
        />
      ),
    },
  ]

  return (
    <Container>
      <Card>
        <Card.Grid style={gridStyle}>
          <Statistic title="Started Jobs" value={startedTotal} />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic title="Queued Jobs" value={queuedTotal} />
        </Card.Grid>
      </Card>
      <Tabs items={items} />
    </Container>
  )
}

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export default QueryJobsStatus
