export function generateGridBackground({ cellSize, margin, cols, gridWidth }) {
  const XMLNS = "http://www.w3.org/2000/svg"
  const [horizontalMargin, verticalMargin] = margin
  const rowHeight = cellSize.height + verticalMargin
  const cellStrokeColor = "#1586d6"

  const y = 0
  const w = cellSize.width
  const h = cellSize.height

  const rectangles = [...new Array(cols)].map((_, i) => {
    const x = i * (cellSize.width + horizontalMargin) + horizontalMargin
    return `<rect stroke='${cellStrokeColor}' strokeWidth='0.5' fill='none' x='${x}' y='${
      y + verticalMargin
    }' width='${w}' height='${h}' stroke-dasharray='3' />`
  })

  const svg = [
    `<svg xmlns='${XMLNS}' width='${gridWidth}' height='${rowHeight}'>`,
    ...rectangles,
    `</svg>`,
  ].join("")

  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`
}
