import React from "react"
import { useRequestState } from "../Provider"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import format from "date-fns/format"
import Button from "components/shared/Button"
import { Avatar, Space, Tooltip } from "antd"
import styled from "styled-components"
import FormModal from "components/shared/FormModal"
import EditRequestSchedule from "../EditRequestSchedule"
import Text from "components/shared/Text/Text"
import { useDataSource } from "hooks/dataSources"
import { convertTime } from "./convetTime"
import { ar, enUS } from "date-fns/locale"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

const RequestFooter = () => {
  const { t, i18n } = useTranslation()
  const { memoizedRequest } = useRequestState()
  const { data: source } = useDataSource(memoizedRequest?.data_source_id)

  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Footer>
      <Space className="info">
        <Space>
          <Avatar
            size="small"
            src={memoizedRequest?.user?.profile_image_url}
            alt={memoizedRequest?.user?.name}
          />
          <Text>{memoizedRequest?.user?.name}</Text>
          <Text size="xs">
            {t("global.create")}{" "}
            <Tooltip
              title={format(
                new Date(memoizedRequest?.created_at),
                "MM/dd/yyyy H:mm",
                {
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            >
              {formatDistanceToNowStrict(
                new Date(memoizedRequest?.created_at),
                {
                  addSuffix: true,
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            </Tooltip>
          </Text>
        </Space>
        <Space>
          <Avatar
            size="small"
            src={memoizedRequest?.last_modified_by?.profile_image_url}
            alt={memoizedRequest?.last_modified_by?.name}
          />
          <Text>{memoizedRequest?.last_modified_by?.name}</Text>
          <Text size="xs">
            {t("global.update")}{" "}
            <Tooltip
              title={format(
                new Date(memoizedRequest?.updated_at),
                "MM/dd/yyyy H:mm",
                {
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            >
              {formatDistanceToNowStrict(
                new Date(memoizedRequest?.updated_at),
                {
                  addSuffix: true,
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            </Tooltip>
          </Text>
        </Space>
      </Space>
      <Space className="info">
        <Space>
          {t("requests.dataSource")}:<Text size="xc">{source?.name}</Text>
        </Space>
        <Space>
          {t("requests.schedule")}:
          {IS_ADMIN ? (
            <FormModal
              Form={EditRequestSchedule}
              hasChanged={(schedule) => !!schedule}
              title={t("requests.schedule")}
              width={500}
              formProps={{ schedule: memoizedRequest.schedule }}
            >
              <Button type="text" size="xs">
                {memoizedRequest?.schedule?.interval
                  ? convertTime(memoizedRequest?.schedule?.interval)
                  : t("global.never")}
              </Button>
            </FormModal>
          ) : (
            <Button type="text" size="xs">
              {memoizedRequest?.schedule?.interval
                ? convertTime(memoizedRequest?.schedule?.interval)
                : t("global.never")}
            </Button>
          )}
        </Space>
      </Space>
    </Footer>
  )
}

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 4rem;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      gap: 1rem;

      & .info {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
    }
  }
`

export default RequestFooter
