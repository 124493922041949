import client from "services/client"

async function getDataSources() {
  const res = await client.get("/data_sources")
  return res.data
}

async function getDataSource(id) {
  const res = await client.get(`/data_sources/${id}`)
  return res.data
}

async function createDataSource(data) {
  const res = await client.post("/data_sources", data)
  return res.data
}

async function updateDataSources(id, data) {
  const res = await client.post(`/data_sources/${id}`, data)
  return res.data
}

async function deleteDataSource(id) {
  const res = await client.delete(`/data_sources/${id}`)
  return res.data
}

async function getDataSourceTypes() {
  const res = await client.get("/data_sources/types")
  return res.data
}

async function testDataSource(id) {
  const res = await client.post(`/data_sources/${id}/test`)
  return res.data
}

async function getSchema(id) {
  const res = await client.get(`/data_sources/${id}/schema`)
  if (res?.data?.job) {
    let result = res.data.job.result
    let error = res.data.job.error
    while (!result && !error) {
      let r = await client.get(`/jobs/${res.data.job.id}`)
      result = r.data.job.result
      error = r.data.job.error
    }
    if (result) {
      return { result: result }
    } else if (error) {
      return { error: error }
    }
  } else if (res.data?.schema) {
    return { result: res.data.schema }
  }
  // return res.data
}

export {
  getDataSources,
  getDataSource,
  createDataSource,
  updateDataSources,
  deleteDataSource,
  getDataSourceTypes,
  testDataSource,
  getSchema,
}
