import React from "react"
import { useCreateGroup } from "hooks/groups"
import { Form, Input } from "antd"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useTranslation } from "react-i18next"

const CreateNewGroup = ({ onChange, onCancel, closeModal }) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useCreateGroup()

  const onFinish = (value) => {
    mutate(
      {
        data: value,
      },
      {
        onSuccess: (newGroup) => {
          if (newGroup.message) {
            return
          } else {
            closeModal()
          }
        },
      }
    )
  }

  React.useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t("groups.groupName")}
        name="name"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <FormModal.Footer>
          <Button onClick={onCancel} type="cancel">
            {t("global.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {t("global.create")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default CreateNewGroup
