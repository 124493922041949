import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal, Space, Table, Tag, notification } from "antd"
import Button from "components/shared/Button"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Icon from "components/shared/Icon"
import { TableSkeleton } from "components/shared/Table"
import Text from "components/shared/Text"
import { format } from "date-fns"
import { useUpdateFavoriteRequest, useUpdateRequest } from "hooks/requests"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const RequestsListView = ({
  loading,
  requests,
  queryParams,
  onParamsChange,
}) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useUpdateFavoriteRequest()
  const { mutateAsync } = useUpdateRequest()

  const handleEnableSchedule = (request) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Enable Schedule",
      content: "Are you sure want to enable schedule for this request?",
      onOk() {
        return mutateAsync(
          {
            requestId: request.id,
            data: {
              schedule: {
                ...request?.schedule,
                disabled: false,
              },
            },
          },
          {
            onSuccess: (data) => {
              if (data.message) {
                notification.error({
                  message: "Failed Enable Schedule",
                })
              } else {
                notification.success({
                  message: "Successfuly Enable Schedule",
                })
              }
            },
          }
        )
      },
    })
  }

  const columns = [
    {
      title: t("global.tabels.name"),
      dataIndex: "name",
      key: "name",
      render: (dashboard, { id, name }) => {
        return (
          <Space>
            <Icon
              type="requestIcon"
              className="dashboardIcon"
              style={{ minWidth: "40px" }}
            />
            <CustomLink to={`/requests/${id}`}>
              <div className="dashboardTitle">
                <span className="dashboardName">{name}</span>
                <span className="dashboardDesc">{t("requests.request")}</span>
              </div>
            </CustomLink>
          </Space>
        )
      },
    },
    {
      title: t("global.tabels.createdBy"),
      dataIndex: "user",
      key: "user",
      render: (_, { user }) => <p>{user.name}</p>,
      width: "15%",
    },
    {
      title: t("global.tabels.createdAt"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <p>{format(new Date(created_at), "dd/MM/yyyy hh:mm")}</p>
        ) : (
          "N/A"
        ),
      width: "15%",
    },
    {
      title: t("global.tabels.lastExecuted"),
      dataIndex: "updated_at",
      key: "updated_at",
      render: (_, { updated_at }) =>
        updated_at ? (
          <p>{format(new Date(updated_at), "dd/MM/yyyy hh:mm")}</p>
        ) : (
          "N/A"
        ),
      width: "15%",
    },
    {
      title: t("global.tabels.lastRun"),
      dataIndex: "last_run_at",
      key: "last_run_at",
      render: (_, { last_run_at }) =>
        last_run_at ? (
          <p>{format(new Date(last_run_at), "dd/MM/yyyy hh:mm")}</p>
        ) : (
          "N/A"
        ),
      width: "15%",
    },
    {
      title: t("global.tabels.tagged"),
      key: "",
      render: (_, { tags }) => tags?.map((tag, i) => <Tag key={i}>{tag}</Tag>),
      width: "10%",
    },
    {
      title: t("global.tabels.schedule"),
      key: "schedule",
      dataIndex: "schedule",
      render: (schedule, request) => {
        if (schedule?.disabled) {
          return (
            <Button
              type="primary"
              size="xxs"
              shape="outlined"
              onClick={() => handleEnableSchedule(request)}
            >
              Disabled
            </Button>
          )
        } else {
          return (
            <Text size="xs" style={{ paddingLeft: "0.4rem" }}>
              Enabled
            </Text>
          )
        }
      },
    },
    {
      key: "actions",
      width: "5%",
      render: (_, dashboard) => {
        const { id, is_favorite: isFavorite } = dashboard
        const handleClick = () => {
          mutate(dashboard)
        }
        const icon = isFavorite ? "starFilled" : "starOutlined"
        return (
          <Button
            key={id}
            type="text"
            shape="circle"
            icon={<Icon type={icon} style={{ color: "#fbd208" }} />}
            onClick={handleClick}
            loading={isLoading}
            disabled={isLoading}
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        <Table
          scroll={{ x: "1000px" }}
          size="sm"
          rowKey="id"
          dataSource={requests?.results}
          columns={columns}
          onChange={onParamsChange}
          pagination={{
            current: queryParams?.page,
            pageSize: queryParams?.page_size,
            total: requests?.count,
            // position: ["bottomCenter"],
            showSizeChanger: true,
          }}
        />
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding: 1rem;

  .dashboardIcon {
    font-size: 1.8rem;
  }

  .dashboardTitle {
    display: flex;
    flex-direction: column;

    .dashboardName {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .dashboardDesc {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
`

export default RequestsListView
