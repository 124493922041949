import React, { useState } from "react"
import { Modal, Space, notification } from "antd"
import styled from "styled-components"
import ConfigWidget from "./CreateWidgetForm/ConfigWidget"
import SelectRequest from "./CreateWidgetForm/SelectRequest"
import { useTranslation } from "react-i18next"
import { useCreateDashboard, useCreateWidget } from "hooks/dashboards"
import Button from "components/shared/Button"
import { useUpdateUser } from "hooks/users"

const AddHomeWidget = ({ homepage_dashboard_id, position, user, layoutId }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedVis, setSelectedVis] = useState(null)

  const IS_COUNTER = layoutId < 5

  const { mutate: createDashboard, isLoading: createDashboardLoading } =
    useCreateDashboard()
  const { mutate: createWidget, isLoading: createWidgetLoading } =
    useCreateWidget()
  const { mutate: updateUser, isLoading: updateUserLoading } = useUpdateUser()

  const onRemoveRequest = React.useCallback(() => {
    setSelectedRequest(null)
    setSelectedVis(null)
  }, [])

  const onAddRequest = React.useCallback((request) => {
    setSelectedRequest(request)
  }, [])

  const handleSave = () => {
    if (homepage_dashboard_id) {
      createWidget(
        {
          dashboard_id: homepage_dashboard_id,
          visualization_id: selectedVis?.id,
          text: "",
          width: 1,
          options: {
            parameterMappings: {},
            isHidden: false,
            position: {
              x: position.col,
              y: position.row,
              w: position.sizeX,
              h: position.sizeY,
              minW: position.minSizeX,
              maxW: position.maxSizeX,
              minH: position.minSizeY,
              maxH: position.maxSizeY,
            },
          },
        },
        {
          onSuccess: () => {
            notification.success({
              message: "Successfuly Addd Widget",
            })
            setSelectedRequest(null)
            setSelectedVis(null)
            setIsOpen(false)
          },
          onError: () => {
            notification.error({
              message: "Faild Add Widget",
            })
          },
        }
      )
    } else {
      createDashboard(
        { name: "Home Dashboard" },
        {
          onSuccess: (dashboard) => {
            if (dashboard?.id) {
              updateUser({
                id: user.id,
                data: {
                  ...user,
                  homepage_dashboard_id: dashboard.id,
                },
              })
              createWidget(
                {
                  dashboard_id: dashboard?.id,
                  visualization_id: selectedVis.id,
                  text: "",
                  width: 1,
                  options: {
                    parameterMappings: {},
                    isHidden: false,
                    position: {
                      x: position.col,
                      y: position.row,
                      w: position.sizeX,
                      h: position.sizeY,
                      minW: position.minSizeX,
                      maxW: position.maxSizeX,
                      minH: position.minSizeY,
                      maxH: position.maxSizeY,
                    },
                  },
                },
                {
                  onSuccess: () => {
                    notification.success({
                      message: "Successfuly Addedd Widget",
                    })
                    setSelectedRequest(null)
                    setSelectedVis(null)
                    setIsOpen(false)
                  },
                  onError: () => {
                    notification.error({
                      message: "Faild Add Widget",
                    })
                  },
                }
              )
            }
          },
        }
      )
    }
  }

  const footer = (
    <Space>
      <Button
        type="primary"
        shape="outlined"
        onClick={() => {
          setSelectedRequest(null)
          setSelectedVis(null)
          setIsOpen(false)
        }}
      >
        {t("global.cancel")}
      </Button>
      <Button
        type="primary"
        disabled={
          createDashboardLoading ||
          createWidgetLoading ||
          updateUserLoading ||
          !selectedRequest ||
          !selectedVis
        }
        loading={
          createDashboardLoading || createWidgetLoading || updateUserLoading
        }
        onClick={handleSave}
      >
        {t("global.save")}
      </Button>
    </Space>
  )

  return (
    <Wrapper>
      <Button type="link" onClick={() => setIsOpen(true)}>
        Add Widget
      </Button>
      <Modal
        open={isOpen}
        onCancel={() => {
          setSelectedRequest(null)
          setSelectedVis(null)
          setIsOpen(false)
        }}
        title="Add a widget"
        footer={footer}
        width={800}
        style={{
          top: 20,
        }}
      >
        <div className="content">
          {selectedRequest ? (
            <ConfigWidget
              selectedRequest={selectedRequest}
              onRemoveRequest={onRemoveRequest}
              selectedVis={selectedVis}
              setSelectedVis={setSelectedVis}
              IS_COUNTER={IS_COUNTER}
            />
          ) : (
            <SelectRequest onAddRequest={onAddRequest} />
          )}
        </div>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`

export default AddHomeWidget
