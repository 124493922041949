import React from "react"
import styled from "styled-components"
import FormModal from "components/shared/FormModal"
import Icon from "components/shared/Icon"
import Button from "components/shared/Button"
import CreateNewQuery from "components/query/CreateNewQuery"
import Tabel from "components/query/Tabel"
import { useTranslation } from "react-i18next"

function Header() {
  const { t } = useTranslation()
  return (
    <Head>
      <FormModal
        title={t("query.createRequest")}
        width={500}
        hasChanged={({ isLoading }) => !!isLoading}
        Form={CreateNewQuery}
        style={{ top: "1rem" }}
      >
        <Button type="primary">
          <Icon type="plusCircle" /> {t("query.newRequest")}
        </Button>
      </FormModal>
    </Head>
  )
}

const Query = () => {
  return (
    <Wrapper>
      <Header />
      <Tabel />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* padding: 32px 2px; */
  /* max-width: 1400px; */
  align-items: start;
`
const Head = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;

  .searchBox {
    width: 30%;
  }
`

export default Query
