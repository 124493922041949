import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import client from "services/client"
import { useRequestState } from "components/requests/Provider"

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const useGetRequestData = ({ request, RequestParameters }) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [error, setError] = useState("")

  const fetchData = async () => {
    try {
      setIsLoading(true)

      let res

      if (request.latest_query_data_id && RequestParameters.length === 0) {
        res = await client.get(
          `/queries/${request.id}/results/${request.latest_query_data_id}.json`
        )
      } else {
        const { data } = await client.post(`/queries/${request.id}/results`, {
          id: request.id,
          ...request.options,
          parameters: RequestParameters?.reduce((x, param) => {
            return {
              ...x,
              [param.name]:
                param.value === "d_now"
                  ? dayjs().format("YYYY-MM-DD")
                  : param.value,
            }
          }, {}),
        })

        if (data.job) {
          let result = data.job.result
          let error = data.job.error

          while (!result && !error) {
            const r = await client.get(`/jobs/${data.job.id}`)
            result = r.data.job.result
            error = r.data.job.error
            await sleep(1000)
          }

          if (result) {
            const queryResultBody = await client.get(`/query_results/${result}`)
            setData(queryResultBody.data.query_result)
            setIsLoading(false)
            setError("")
          }

          if (error) {
            throw new Error(error)
          }
        } else {
          res = { data }
        }
      }

      if (res) {
        setData(res.data.query_result)
        setIsLoading(false)
      }
    } catch (error) {
      setData(null)
      setIsLoading(false)
      setError(error.message)
    }
  }

  const updateData = async (parameters = RequestParameters) => {
    try {
      setIsUpdateLoading(true)
      setError(null)
      const { data } = await client.post(`/queries/${request.id}/results`, {
        id: request.id,
        max_age: 0,
        parameters: RequestParameters?.reduce((x, param) => {
          return {
            ...x,
            [param.name]:
              param.value === "d_now"
                ? dayjs().format("YYYY-MM-DD")
                : param.value,
          }
        }, {}),
      })

      let result, error

      if (data.job) {
        while (!result && !error) {
          const response = await client.get(`/jobs/${data.job.id}`)
          result = response.data.job.result
          error = response.data.job.error
          await sleep(1000)
        }

        if (result) {
          const queryResultBody = await client.get(`/query_results/${result}`)
          setData(queryResultBody.data.query_result)
        }

        if (error) {
          throw new Error(error)
        }
      } else {
        setData(data.query_result)
      }

      setIsUpdateLoading(false)
    } catch (error) {
      setIsUpdateLoading(false)
      setError(error.message)
      setData(null)
    }
  }

  useEffect(() => {
    if (request?.visualizations?.length === 0) {
      setError(null)
      setIsLoading(false)
    } else {
      fetchData()
    }
  }, [])

  // useEffect(() => {
  //   if (shouldWidgetUpdate) {
  //     updateData()
  //     setShouldWidgetUpdate(false)
  //   }
  // }, [shouldWidgetUpdate])

  return [data, isLoading, isUpdateLoading, error, updateData]
}

export default useGetRequestData
