import React from "react"
import { useDashboardState } from "../Provider"
import GridLayout from "components/shared/GridLayout"
import Widget from "../Widget"
import Markdown from "../Markdown/Markdown"
import { useAuth } from "context/AuthContext"

function Widgets({ isPublic }) {
  const {
    layout,
    onLayoutChange,
    editable,
    dashboardWidgets,
    onRemoveWidget,
    dashboardParameters,
    onRemoveWidgetDirect,
  } = useDashboardState()

  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <GridLayout
      isEditing={editable}
      layout={layout}
      onDragStop={onLayoutChange}
      onResizeStop={onLayoutChange}
    >
      {dashboardWidgets?.map((widget) => (
        <div key={widget.id.toString()}>
          {widget?.visualization ? (
            <Widget
              widget={widget}
              isPublic={isPublic}
              isAdmin={IS_ADMIN}
              editable={editable}
              onRemoveWidget={onRemoveWidget}
              dashboardParameters={dashboardParameters}
              onRemoveWidgetDirect={onRemoveWidgetDirect}
            />
          ) : (
            <Markdown widget={widget} isPublic={isPublic} isAdmin={IS_ADMIN} />
          )}
        </div>
      ))}
    </GridLayout>
  )
}

export default Widgets
