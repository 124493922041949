export function extract(obj, keys) {
  let result = { ...obj }

  for (let key of keys) {
    delete result[key]
  }

  return result
}

export function slugify(str) {
  return str.toLowerCase().replace(/\s/g, "-")
}

export function serialize(obj) {
  return JSON.stringify(obj)
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.length === 0)
  )
}
