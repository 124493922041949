import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Radio, Select, Space, Spin, notification } from "antd"
import Button from "components/shared/Button"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import { useAuth } from "context/AuthContext"
import {
  useCreateETLConnections,
  useGetETLSourceSchema,
  useSyncETLConnection,
} from "hooks/ETL/connections"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

// {
//   "sourceId": "ebe5a9f6-98d3-42ce-86ec-acfa2cc35a8f",
//   "destinationId": "d9dd8095-30a5-40c6-a160-d791f0722e36",
//   "name": "Google Analytics 4 (GA4) → Google Sheets",
//   "scheduleType": "basic",
//   "scheduleData": {
//       "basicSchedule": {
//           "units": 8,
//           "timeUnit": "hours"
//       }
//   },
//   "namespaceDefinition": "destination",
//   "nonBreakingChangesPreference": "propagate_columns",
//   "geography": "eu",
//   "status": "active",
//   "sourceCatalogId": "9424920f-5a40-4f6a-bc7f-d6e70e1c6cef"
// }

// {
//   "sourceId": "ebe5a9f6-98d3-42ce-86ec-acfa2cc35a8f",
//   "destinationId": "d9dd8095-30a5-40c6-a160-d791f0722e36",
//   "name": "Google Analytics 4 (GA4) → Google Sheets",
//   "scheduleType": "basic",
//   "scheduleData": {
//       "basicSchedule": {
//           "units": 24,
//           "timeUnit": "hours"
//       }
//   },
//   "namespaceDefinition": "destination",
//   "nonBreakingChangesPreference": "propagate_columns",
//   "geography": "auto",
//   "status": "active",
//   "sourceCatalogId": "9424920f-5a40-4f6a-bc7f-d6e70e1c6cef"
// }

// {
//   "sourceId": "ebe5a9f6-98d3-42ce-86ec-acfa2cc35a8f",
//   "destinationId": "d9dd8095-30a5-40c6-a160-d791f0722e36",
//   "name": "Google Analytics 4 (GA4) → Google Sheets",
//   "scheduleType": "manual",
//   "namespaceDefinition": "destination",
//   "nonBreakingChangesPreference": "propagate_columns",
//   "geography": "auto",
//   "status": "active",
//   "sourceCatalogId": "9424920f-5a40-4f6a-bc7f-d6e70e1c6cef"
// }

const ConnectionsConfig = ({ sourceData, destinationData, onPrev }) => {
  const { session } = useAuth()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { mutate, isLoading } = useCreateETLConnections()
  const { mutate: sync, isLoading: syncLoading } = useSyncETLConnection()
  const { mutate: getSourceSchema, isLoading: getSchemaLoading } =
    useGetETLSourceSchema()
  const [sourceSchema, setSourceSchema] = useState(undefined)

  useEffect(() => {
    getSourceSchema(sourceData.sourceId, {
      onSuccess: (data) => {
        setSourceSchema(data)
      },
    })
    return () => {
      setSourceSchema(undefined)
    }
  }, [sourceData.sourceId])

  const initialValues = {
    name: `${sourceData.name}-to-${destinationData.name}`,
    dataResidency: "auto",
    scheduleType: "basic",
    scheduleData: "24",
    namespaceDefinition: "destination",
    namespaceFormat: "${SOURCE_NAMESPACE}",
    nonBreakingSchemaUpdatesBehavior: "propagate_columns",
    normalization: 1,
  }

  const handelSetupConnection = (values) => {
    const { scheduleData, scheduleType, normalization, ...credential } = values
    let data
    if (scheduleData) {
      data = {
        ...credential,
        sourceId: sourceData.sourceId,
        destinationId: destinationData.destinationId,
        name: values.name,
        scheduleType: scheduleType,
        scheduleData: {
          basicSchedule: {
            units: scheduleData,
            timeUnit: "hours",
          },
        },
        status: "active",
        syncCatalog: {
          streams: sourceSchema?.catalog?.streams,
        },
        operations:
          normalization === 1
            ? [
                {
                  name: "Normalization",
                  workspaceId: session?.org_workspace,
                  operatorConfiguration: {
                    operatorType: "normalization",
                    normalization: {
                      option: "basic",
                    },
                  },
                },
              ]
            : [],
      }
    } else {
      data = {
        ...credential,
        sourceId: sourceData.sourceId,
        destinationId: destinationData.destinationId,
        name: values.name,
        scheduleType: scheduleType,
        status: "active",
        syncCatalog: {
          streams: sourceSchema?.catalog?.streams,
        },
        operations:
          normalization === 1
            ? [
                {
                  name: "Normalization",
                  workspaceId: session?.org_workspace,
                  operatorConfiguration: {
                    operatorType: "normalization",
                    normalization: {
                      option: "basic",
                    },
                  },
                },
              ]
            : [],
      }
    }
    mutate(
      {
        data,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 400 || data.status === 500) {
            notification.error({
              message: `Faild set up connection: ${data.title}`,
              description: data.detail,
            })
          } else {
            notification.success({
              message: "Connection set up successfuly, ",
            })
            if (data.connectionId) {
              sync(data.connectionId, {
                onSuccess: (data) => {
                  if (data?.job?.status === "succeeded") {
                    notification.success({
                      message: "Data sync successfuly",
                    })
                    navigate(-1)
                  } else if (data?.job?.status === "failed") {
                    notification.error({
                      message: `Failed sync data!`,
                    })
                  } else if (data?.message) {
                    notification.success({
                      message: "Data sync will take long time",
                    })
                    navigate(-1)
                  }
                },
              })
            }
          }
        },
        onError: () => {
          notification.error({
            message: "Faild set up connection",
          })
        },
      }
    )
  }

  if (getSchemaLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    )
  }

  return (
    <Wrapper
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handelSetupConnection}
    >
      <div className="FormBox">
        <h2>Connection</h2>
        <Form.Item
          label="Connection name"
          name="name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="FormBox">
        <h2>Data residency</h2>
        <Form.Item
          label="Data residency"
          name="dataResidency"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            options={[
              {
                label: "No preference",
                value: "auto",
              },
              {
                label: "United States",
                value: "us",
              },
              {
                label: "European Union",
                value: "eu",
              },
            ]}
          />
        </Form.Item>
      </div>
      <div className="FormBox">
        <h2>Configuration</h2>
        <Form.Item
          label="Schedule type"
          name="scheduleType"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            options={[
              {
                value: "basic",
                label: "Scheduled",
              },
              {
                value: "manual",
                label: "Manual",
              },
              // {
              //   value: "cron",
              //   label: "Scheduled",
              // },
            ]}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.scheduleType !== currentValues.scheduleType
          }
        >
          {({ getFieldValue }) =>
            // getFieldValue("scheduleType") === "cron" ? (
            //   <Form.Item
            //     label="Replication frequency"
            //     name="cronExpression"
            //     rules={[{ required: true, message: "Required" }]}
            //   >
            //     <Select
            //       options={[
            //         {
            //           label: "Every hour",
            //           value: "0 * * * * ?",
            //         },
            //         {
            //           label: "Every 2 hours",
            //           value: "0 */2 * * * ?",
            //         },
            //         {
            //           label: "Every 3 hours",
            //           value: "0 */3 * * * ?",
            //         },
            //         {
            //           label: "Every 6 hours",
            //           value: "0 */6 * * * ?",
            //         },
            //         {
            //           label: "Every 8 hours",
            //           value: "0 */8 * * * ?",
            //         },
            //         {
            //           label: "Every 12 hours",
            //           value: "0 */12 * * * ?",
            //         },
            //         {
            //           label: "Every 24 hours",
            //           value: "0 0 * * * ?",
            //         },
            //       ]}
            //     />
            //   </Form.Item>
            // ) : null
            getFieldValue("scheduleType") === "basic" ? (
              <Form.Item
                label="Replication frequency"
                name="scheduleData"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  options={[
                    {
                      label: "Every hour",
                      value: "1",
                    },
                    {
                      label: "Every 2 hours",
                      value: "2",
                    },
                    {
                      label: "Every 3 hours",
                      value: "3",
                    },
                    {
                      label: "Every 6 hours",
                      value: "6",
                    },
                    {
                      label: "Every 8 hours",
                      value: "8",
                    },
                    {
                      label: "Every 12 hours",
                      value: "12",
                    },
                    {
                      label: "Every 24 hours",
                      value: "24",
                    },
                  ]}
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          label="Destination Namespace"
          name="namespaceDefinition"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            options={[
              {
                value: "destination",
                label: "Destination default",
              },
              {
                value: "source",
                label: "Mirror source structure",
              },
              {
                value: "custom_format",
                label: "Custom format",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.namespaceDefinition !== currentValues.namespaceDefinition
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("namespaceDefinition") === "custom_format" ? (
              <Form.Item
                label="Destination Namespace Format"
                name="namespaceFormat"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        {/* <Form.Item
          label="Destination Stream Prefix"
          name="prefix"
        >
          <Select
            options={[]}
          />
        </Form.Item> */}
        <Form.Item
          label="Detect and propagate schema changes"
          name="nonBreakingSchemaUpdatesBehavior"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            options={[
              {
                value: "ignore",
                label: "Propagate field changes only",
              },
              {
                value: "disable_connection",
                label: "Propagate all field and stream changes",
              },
              {
                value: "propagate_columns",
                label: "Detect changes and manually approve",
              },
              {
                value: "propagate_fully",
                label: "Detect changes and pause connection",
              },
            ]}
          />
        </Form.Item>
      </div>
      <div className="FormBox">
        <h2>Normalization</h2>
        <Form.Item name="normalization">
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={0}>Raw data (JSON)</Radio>
              <Radio value={1}>Normalized tabular data</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
      <>
        {syncLoading ? (
          <div className="FormBox">
            <Space>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 22,
                    }}
                    spin
                  />
                }
              />
              <span>sync data...</span>
            </Space>
          </div>
        ) : null}
      </>
      <Form.Item>
        <div className="btnBox">
          <Button
            type="cancel"
            onClick={onPrev}
            disabled={isLoading || syncLoading}
          >
            Prev
          </Button>
          <Button
            htmlType="submit"
            loading={isLoading || syncLoading}
            disabled={isLoading || syncLoading || !sourceSchema}
          >
            Set up connection
          </Button>
        </div>
      </Form.Item>
    </Wrapper>
  )
}

const Wrapper = styled(Form)`
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;

  .FormBox {
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1rem;
    border-radius: 12px;
  }

  .btnBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
  }
`

export default ConnectionsConfig
