import Text from "components/shared/Text"
import format from "date-fns/format"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { Space, Tooltip } from "antd"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import FormModal from "components/shared/FormModal"
import UpdateQuery from "../UpdateQuery"
import { useTranslation } from "react-i18next"

function formatDistanceDate(date) {
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true })
}

function formatDate(date) {
  return format(new Date(date), "MM/dd/yyyy hh:mm")
}

function renderTriggerColumn(_, query) {
  const { trigger } = query
  // return <Columns.Name group={group} />
  return <Text size="sm">{trigger}</Text>
}

// function renderDescriptionColumn(_, query) {
//  const { description } = query
//  return <Text>{description}</Text>
// }

// function renderSnippetColumn(_, query) {
//  const { snippet } = query
//  return <Text>{snippet}</Text>
// }

function renderCreatedAtColumn(_, query) {
  const { created_at } = query

  return (
    <Tooltip title={formatDate(created_at)}>
      <Text size="sm">{formatDistanceDate(created_at)}</Text>
    </Tooltip>
  )
}

function renderActionBtns(_, query) {
  const { id, onDeleteQuery } = query
  return (
    <Space size={10}>
      <FormModal
        title="Update User"
        width={700}
        hasChanged={({ isLoading }) => !!isLoading}
        Form={UpdateQuery}
        formProps={query}
        style={{ top: "1rem" }}
      >
        <IconButton type="transparent">
          <Icon type="editOutlined" />
        </IconButton>
      </FormModal>
      <IconButton type="transparent" onClick={() => onDeleteQuery(id)}>
        <Icon type="deleteOutlined" />
      </IconButton>
    </Space>
  )
}

export const Columns = () => {
  const { t } = useTranslation()
  return [
    {
      title: t("global.tabels.trigger"),
      key: "trigger",
      dataIndex: "trigger",
      render: renderTriggerColumn,
    },
    // {
    //  title: "Description",
    //  key: "description",
    //  dataIndex: "description",
    //  render: renderDescriptionColumn,
    // },
    // {
    //  title: "Snippet",
    //  key: "snippet",
    //  dataIndex: "snippet",
    //  render: renderSnippetColumn,
    // },
    {
      title: t("global.tabels.createdAt"),
      key: "created_at",
      dataIndex: "created_at",
      render: renderCreatedAtColumn,
    },
    {
      title: t("global.tabels.actions"),
      key: "actions",
      render: (_, query) => {
        const { id, onDeleteQuery } = query
        return (
          <Space size={10}>
            <FormModal
              title={t("query.updateRequest")}
              width={700}
              hasChanged={({ isLoading }) => !!isLoading}
              Form={UpdateQuery}
              formProps={query}
              style={{ top: "1rem" }}
            >
              <IconButton type="transparent">
                <Icon type="editOutlined" />
              </IconButton>
            </FormModal>
            <IconButton type="transparent" onClick={() => onDeleteQuery(id)}>
              <Icon type="deleteOutlined" />
            </IconButton>
          </Space>
        )
      },
    },
  ]
}
