import { css } from "styled-components"

const variants = {
  shape: {
    sharp: css`
      border-radius: 0px;
    `,
    round: css`
      border-radius: var(--border-radius-0);
    `,
  },
  size: {
    sm: css`
      min-height: 30px;
      font-size: var(--fz-sm);
      padding: 6px 8px;
    `,
    md: css`
      min-height: 36px;
      font-size: var(--fz-md);
      padding: 8px 12px;
    `,
    lg: css`
      min-height: 48px;
      font-size: var(--fz-md);
      padding: 8px 16px;
    `,
  },
}

export default variants
