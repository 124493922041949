import React from "react"
import styled from "styled-components"
import { useDataSources } from "hooks/dataSources"
import { Space, Spin, Empty, Result } from "antd"
import { datasourceImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/datasource/mucs.png"
import { useTranslation } from "react-i18next"
import CustomLink from "components/shared/CustomLink/CustomLink"

const SourcesList = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useDataSources()

  if (isLoading) {
    return (
      <Wrapper>
        <Spin />
      </Wrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data.length === 0) {
    return (
      <Wrapper>
        <Empty description={<span>{t("dataSource.noDataSource")}</span>} />
      </Wrapper>
    )
  }

  return (
    <Space size={[20, 20]} style={{ justifyContent: "space-around" }} wrap>
      {Array.isArray(data) &&
        data?.map((source) => (
          <CustomLink to={`/source/${source.id}`}>
            <Source>
              <img
                src={`${datasourceImages[source.type] || defaultImage}`}
                alt={source.type}
                width={48}
              />
              <span>{source.name}</span>
            </Source>
          </CustomLink>
        ))}
    </Space>
  )
}

const Source = styled.div`
  background-color: ${({ theme }) => theme.color.button};
  color: ${({ theme }) => theme.color.text};
  width: 200px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
    /* box-shadow: rgba(59, 182, 216, 0.3) 0px 0px 0px 3px; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }

  & span {
    word-break: break-all;
  }

  @media (max-width: 768px) {
    & {
      width: 300px;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SourcesList
