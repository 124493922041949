import { Form, message } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { Input, Textarea } from "components/shared/Input"
import { useCreateQuerySnippets } from "hooks/queries"
import React from "react"
import { useTranslation } from "react-i18next"

const CreateNewQuery = ({ onChange, onCancel, closeModal }) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useCreateQuerySnippets()

  const onFinish = (value) => {
    mutate(
      {
        data: {
          trigger: value.trigger,
          description: value.description || "",
          snippet: value.snippet,
        },
      },
      {
        onSuccess: (newGroup) => {
          if (newGroup.message) {
            message.error({
              content: newGroup.message,
            })
            return
          } else {
            message.success({
              content: t("query.queryCreated"),
            })
            closeModal()
          }
        },
        onError: (error) => {
          message.error({
            content: t("query.queryNotCreated"),
          })
        },
      }
    )
  }

  React.useEffect(() => {
    onChange(isLoading)
  }, [isLoading])

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t("query.trigger")}
        name="trigger"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t("query.description")} name="description">
        <Input />
      </Form.Item>
      <Form.Item
        label={t("query.snippet")}
        name="snippet"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Textarea />
      </Form.Item>
      <Form.Item>
        <FormModal.Footer>
          <Button onClick={onCancel} type="cancel">
            {t("global.cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("global.create")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default CreateNewQuery
