import React, { useState } from "react"
import { notification } from "antd"
import {
  generateParameters,
  generateLayout,
  generateWidgets,
} from "./Provider.utils"
import {
  useUpdateDashboard,
  useUpdateWidget,
  useRefreshDashboard,
  useRemoveWidget,
  useUpdateFavoriteDashboard,
} from "hooks/dashboards"
import useObjectMemo from "hooks/useObjectMemo"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

const DashboardStateContext = React.createContext()

function DashboardStateProvider({ dashboard, isRefetching, children }) {
  const { session } = useAuth()
  const { t } = useTranslation()
  const [editable, setEditable] = React.useState(false)
  const [layout, setLayout] = React.useState()
  const [dashboardParameters, setDashboardParameters] = useState([])
  const [shouldWidgetUpdate, setShouldWidgetUpdate] = useState(false)
  const [dashboardWidgets, setDashboardWidgets] = useState(dashboard.widgets)
  const [removedWidgets, setRemovedWidgets] = React.useState([])
  const [createWidgets, setCreatedWidgets] = React.useState([])
  const refresh = useRefreshDashboard(dashboard.id)
  const { value: memoizedDashboard } = useObjectMemo(dashboard)
  const { mutate: updateDashboard, isLoading: updateLoading } =
    useUpdateDashboard()
  const { mutate: removeDirect } = useRemoveWidget()
  // const { mutate: updateWidget, isLoading: isSaving } = useUpdateWidget()
  const { mutate: updateFavorite, isLoading: favLoading } =
    useUpdateFavoriteDashboard()
  const { mutateAsync: updateWidget, isLoading: isSaving } = useUpdateWidget()
  const { mutateAsync: removeWidget, isLoading: isRemovingWidget } =
    useRemoveWidget()

  React.useEffect(() => {
    setLayout(generateLayout(dashboardWidgets))
  }, [dashboardWidgets])
  React.useEffect(() => {
    setDashboardParameters(
      generateParameters(
        dashboardWidgets,
        memoizedDashboard.filters,
        session?.user?.email
      )
    )
  }, [dashboardWidgets, memoizedDashboard])

  const onEdit = React.useCallback(() => setEditable(true), [])

  const onLayoutChange = React.useCallback(
    (newLayout) => {
      const newDashboardWidgets = dashboardWidgets.map((component) => {
        const newPosition = newLayout.find(
          (pos) => pos.i === component.id.toString()
        )
        if (newPosition) {
          return {
            ...component,
            options: {
              ...component.options,
              position: {
                ...component.options.position,
                sizeX: newPosition.w,
                sizeY: newPosition.h,
                col: newPosition.x,
                row: newPosition.y,
              },
            },
          }
        }
        return component
      })
      setDashboardWidgets(newDashboardWidgets)
      // refresh()
    },
    [dashboardWidgets, setDashboardWidgets]
  )

  const onRemoveWidget = React.useCallback(
    (widgetId) => {
      setRemovedWidgets([...removedWidgets, widgetId])
      setDashboardWidgets((prevWidgets) =>
        prevWidgets.filter((w) => w.id !== widgetId)
      )
    },
    [removedWidgets]
  )

  const onAddWidget = React.useCallback(
    (widget) => {
      setCreatedWidgets([...createWidgets, widget])
      if (removedWidgets.includes(widget.id)) {
        setRemovedWidgets(removedWidgets.filter((id) => id !== widget.id))
      }
      setDashboardWidgets((prev) => [...prev, widget])
    },
    [createWidgets, removedWidgets]
  )

  const onUpdateWidget = React.useCallback(
    (id, newWidget) => {
      const updatedWidgets = dashboardWidgets.map((widget) =>
        widget.id === id ? newWidget : widget
      )
      setDashboardWidgets(updatedWidgets)
    },
    [dashboardWidgets]
  )

  const onRemoveWidgetDirect = React.useCallback((widgetId) => {
    removeDirect(widgetId, {
      onSuccess: () => {
        setDashboardWidgets((prevWidgets) =>
          prevWidgets.filter((w) => w.id !== widgetId)
        )
      },
    })
  }, [])

  const onCancelEdit = React.useCallback(async () => {
    const removePromises = createWidgets.map((widget) =>
      removeWidget(widget.id)
    )
    await Promise.all(removePromises)

    // Filter the memoizedDashboard.widgets array to remove any widgets that are in createWidgets
    const defaultWidgets = memoizedDashboard.widgets.filter(
      (widget) =>
        !createWidgets.find((createWidget) => createWidget.id === widget.id)
    )

    // Generate the new widget layout using the default widgets
    const newLayout = generateLayout(defaultWidgets)

    // Generate the new dashboard widgets using the default widgets and new layout
    const newWidgets = generateWidgets(defaultWidgets, newLayout)

    setDashboardWidgets(newWidgets)
    setRemovedWidgets([])
    setCreatedWidgets([])
    setEditable(false)
    // *important* not add laayout in dependency array
  }, [createWidgets, removeWidget, memoizedDashboard.widgets])

  const onSaveLayout = React.useCallback(async () => {
    try {
      const widgets = generateWidgets(dashboardWidgets, layout)

      const updatePromises = widgets.map((widget) =>
        updateWidget({
          id: widget.id,
          data: {
            id: widget.id,
            width: 1,
            options: widget.options,
            dashboard_id: widget.dashboard_id,
            text: widget.text,
          },
        })
      )

      const removePromises = removedWidgets.map((widget) =>
        removeWidget(widget)
      )

      await Promise.all(updatePromises)
      await Promise.all(removePromises)
      updateDashboard({
        id: memoizedDashboard.id,
        data: { filters: dashboardParameters.map((param) => param.name) },
      })
      // refresh();
      setRemovedWidgets([])
      setCreatedWidgets([])
      setEditable(false)
    } catch (error) {
      // Handle the error here
      console.error(
        "An error occurred while saving the dashboard layout:",
        error
      )
    }
  }, [
    layout,
    removedWidgets,
    dashboardWidgets,
    dashboardParameters,
    updateWidget,
    removeWidget,
  ])

  const onTogglePublish = React.useCallback(() => {
    updateDashboard(
      {
        id: memoizedDashboard.id,
        data: { is_draft: !memoizedDashboard.is_draft },
      },
      {
        onSuccess: (dash) => {
          if (dash.is_draft === true) {
            notification.warning({
              message: t("dashboards.dashboardUnpublish"),
              duration: 2,
              placement: "bottomRight",
            })
          } else if (dash.is_draft === false) {
            notification.success({
              message: t("dashboards.dashboardPublish"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("dashboards.somethingWrong"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: (error) => {
          notification.error({
            message: error.message,
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [memoizedDashboard.is_draft])

  const onToggleArchive = React.useCallback(() => {
    updateDashboard(
      {
        id: memoizedDashboard.id,
        data: { is_archived: !memoizedDashboard.is_archived },
      },
      {
        onSuccess: (dash) => {
          if (dash.is_archived === true) {
            notification.warning({
              message: t("dashboards.dashboardArchive"),
              duration: 2,
              placement: "bottomRight",
            })
          } else if (dash.is_archived === false) {
            notification.success({
              message: t("dashboards.dashboardUnArchive"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("dashboards.somethingWrong"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: (error) => {
          notification.error({
            message: error.message,
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [memoizedDashboard.is_archived])

  const onRefresh = React.useCallback(() => refresh, [refresh])

  const onAddTags = React.useCallback(
    (tags, callback) => {
      updateDashboard(
        {
          id: memoizedDashboard.id,
          data: { tags: tags },
        },
        {
          onSuccess: () => {
            callback()
          },
        }
      )
    },
    [memoizedDashboard.tags]
  )

  const onUpdateName = React.useCallback(
    (name) => {
      updateDashboard({
        id: memoizedDashboard.id,
        data: { name: name },
      })
    },
    [memoizedDashboard.name]
  )

  const onToggleFavorite = React.useCallback(() => {
    updateFavorite({
      id: memoizedDashboard.id,
      is_favorite: memoizedDashboard.is_favorite,
    })
  }, [memoizedDashboard.is_favorite])

  const value = React.useMemo(
    () => ({
      memoizedDashboard,
      layout: layout,
      editable,
      isSaving: isSaving || isRemovingWidget,
      isRefetching,
      dashboardParameters,
      shouldWidgetUpdate,
      favLoading,
      dashboardWidgets,
      onEdit,
      onCancelEdit,
      onSaveLayout,
      onLayoutChange,
      onRemoveWidget,
      onAddWidget,
      onTogglePublish,
      onToggleArchive,
      onRefresh,
      onAddTags,
      setDashboardParameters,
      onUpdateName,
      setShouldWidgetUpdate,
      onToggleFavorite,
      onUpdateWidget,
      onRemoveWidgetDirect,
      updateLoading,
    }),
    [
      memoizedDashboard,
      layout,
      editable,
      isSaving,
      isRemovingWidget,
      isRefetching,
      dashboardParameters,
      shouldWidgetUpdate,
      favLoading,
      dashboardWidgets,
      onEdit,
      onCancelEdit,
      onSaveLayout,
      onLayoutChange,
      onRemoveWidget,
      onAddWidget,
      onTogglePublish,
      onToggleArchive,
      onRefresh,
      onAddTags,
      setDashboardParameters,
      onUpdateName,
      setShouldWidgetUpdate,
      onToggleFavorite,
      onUpdateWidget,
      onRemoveWidgetDirect,
      updateLoading,
    ]
  )

  return (
    <DashboardStateContext.Provider value={value}>
      {children}
    </DashboardStateContext.Provider>
  )
}

export function useDashboardState() {
  const context = React.useContext(DashboardStateContext)

  if (!context) {
    throw new Error(
      "useDashboardState must be used inside a DashboardStateProvider."
    )
  }

  return context
}

export default DashboardStateProvider
