import React from "react"
import { createPortal } from "react-dom"
import styled, { css } from "styled-components"

function FirstStepClip() {
  return (
    <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="firstStepClip">
          <path d="M155.893 56H7.978C3.572 56 0 51.821 0 46.667V9.333C0 4.179 3.572 0 7.978 0H155.893C157.62 0.0157664 159.284 0.648932 160.585 1.785L189.178 26.114C189.437 26.3552 189.644 26.6471 189.785 26.9717C189.926 27.2963 189.999 27.6465 189.999 28.0005C189.999 28.3545 189.926 28.7047 189.785 29.0293C189.644 29.3539 189.437 29.6458 189.178 29.887L160.585 54.216C159.284 55.3517 157.62 55.9845 155.893 56Z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function MiddleStepClip() {
  return (
    <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="middleStepClip">
          <path d="M157.754 55.9999H1.5737C0.607702 55.9999 0.205697 54.5549 0.986697 53.8889L29.2067 29.8889C29.4658 29.6477 29.6725 29.3557 29.8139 29.0312C29.9552 28.7066 30.0281 28.3564 30.0281 28.0024C30.0281 27.6484 29.9552 27.2981 29.8139 26.9736C29.6725 26.649 29.4658 26.357 29.2067 26.1159L0.986697 2.11586C0.205697 1.45086 0.607702 0.00585938 1.5737 0.00585938H157.754C158.617 0.013498 159.45 0.329903 160.101 0.897865L189.753 26.1189C190.012 26.36 190.219 26.652 190.36 26.9766C190.501 27.3011 190.574 27.6514 190.574 28.0054C190.574 28.3594 190.501 28.7096 190.36 29.0341C190.219 29.3587 190.012 29.6507 189.753 29.8919L160.101 55.1069C159.45 55.6752 158.618 55.9919 157.754 55.9999Z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function LastStepClip() {
  return (
    <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="lastStepClip">
          <path d="M1.24575 4.2L26.8107 26.006C27.0683 26.2465 27.2736 26.5375 27.414 26.8607C27.5545 27.1839 27.6269 27.5326 27.6269 27.885C27.6269 28.2374 27.5545 28.5861 27.414 28.9093C27.2736 29.2325 27.0683 29.5235 26.8107 29.764L1.24575 51.57C-0.306255 52.894 0.491754 55.77 2.41075 55.77L181.5 56C185.877 56 189.426 51.838 189.426 46.7V9.52C189.426 4.386 185.877 0.220001 181.5 0.220001L2.41173 0C0.49173 0 -0.305255 2.879 1.24575 4.2Z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function ArrowStepsBar({ steps, step }) {
  const clips = (
    <div>
      <FirstStepClip />
      <MiddleStepClip />
      <LastStepClip />
    </div>
  )

  return (
    <Wrapper className="steps-bar">
      {steps.map(({ title }, index) => (
        <Step
          active={index < step}
          index={index}
          lastIndex={steps.length - 1}
          key={index}
        >
          <span>{title}</span>
        </Step>
      ))}

      {createPortal(clips, document.querySelector("#svg-box"))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.mixins.flexCenter};
`

const Step = styled.div`
  width: 190px;
  height: 56px;
  ${({ theme }) => theme.mixins.flexCenter};
  color: hsla(0, 0%, 100%, 0.19);
  background: rgb(0, 0, 0, 0.2);
  transition: all 0.2s;
  clippath: url(${(props) =>
    props.index === 0
      ? "#firstStepClip"
      : props.index === props.lastIndex
      ? "#lastStepClip"
      : "#middleStepClip"});
  margin: 0 -8px;

  span {
    line-height: 0;

    ${(props) =>
      props.index !== 0 &&
      css`
        margin: 0 0 0 10px;
      `}
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;
      background: linear-gradient(
        107.97deg,
        #fea462 12.24%,
        #feb270 52.01%,
        #ffe58e 140.93%
      );
    `}
`

export default ArrowStepsBar
