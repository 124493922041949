import * as Columns from "./Columns"
import { Space, Tooltip } from "antd"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"

export function renderGroupColumn(_, group) {
  return <Columns.Name group={group} />
}

export function renderMembersColumn(_, group) {
  return <Columns.Member group={group} />
}

export function renderDataSouecesColumn(_, group) {
  return <Columns.DataSource group={group} />
}

export function renderActionBtns(_, data) {
  const { id, type, user, onDeleteGroup } = data
  // builtin
  return (
    <Space size={10}>
      {type === "builtin" ? (
        <Tooltip title="Cannot delete build-in group">
          <IconButton type="deleteOutlined" disabled>
            <Icon type="deleteOutlined" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton type="transparent" onClick={() => onDeleteGroup(id)}>
          <Icon type="deleteOutlined" style={{ color: "red" }} />
        </IconButton>
      )}
    </Space>
  )
}

// export const columns = [
//   {
//     title: "Group",
//     key: "name",
//     dataIndex: "name",
//     render: renderGroupColumn,
//   },
//   {
//     title: "Members",
//     key: "email",
//     dataIndex: "email",
//     render: renderMembersColumn,
//   },
//   {
//     title: "Data Sourcess",
//     key: "groups",
//     dataIndex: "groups",
//     render: renderDataSouecesColumn,
//   },
//   {
//     title: "Actions",
//     key: "action",
//     render: renderActionBtns,
//   },
// ]
