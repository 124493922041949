import React from "react"
import styled from "styled-components"
import useQueryParams from "hooks/useQueryParams"
import { Divider, Radio, Space } from "antd"
import IconButton from "components/shared/IconButton"
import { AppstoreOutlined, MenuOutlined } from "@ant-design/icons"
import { addDays, format, subDays, subMonths } from "date-fns"
import { useNavigate } from "react-router-dom"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"

const AgentFilter = ({ layout, setLayout, agentsCount }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tenantName } = useAuth()
  const [queryParams, setQueryParams] = useQueryParams({
    q: String,
    favorites: Boolean,
    tags: String,
    date_from: String,
  })

  const filterValue =
    queryParams?.date_from === format(subDays(new Date(), 1), "yyyy-MM-dd")
      ? "24"
      : queryParams?.date_from === format(subDays(new Date(), 7), "yyyy-MM-dd")
      ? "7"
      : queryParams?.date_from === format(subDays(new Date(), 30), "yyyy-MM-dd")
      ? "30"
      : queryParams?.date_from ===
        format(subMonths(new Date(), 12), "yyyy-MM-dd")
      ? "12"
      : "1"

  const handleDateFilter = (value) => {
    const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd")
    let dateFrom

    switch (value) {
      case "1":
        dateFrom = undefined
        break
      case "24":
        dateFrom = format(subDays(new Date(), 1), "yyyy-MM-dd")
        break
      case "7":
        dateFrom = format(subDays(new Date(), 7), "yyyy-MM-dd")
        break
      case "30":
        dateFrom = format(subDays(new Date(), 30), "yyyy-MM-dd")
        break
      case "12":
        dateFrom = format(subMonths(new Date(), 12), "yyyy-MM-dd")
        break
      default:
        return // Exit if none of the cases match
    }

    if (value === "1") {
      navigate(`/${tenantName}/agents`)
    } else {
      setQueryParams(
        { page: 1, page_size: 10, date_from: dateFrom, date_to: tomorrow },
        "push"
      )
    }
  }

  return (
    <Wrapper>
      <div className="header">
        <h2>
          {t("agents.allAgents")}
          {": "}{" "}
          <span>
            {agentsCount} {t("agents.agents")}
          </span>
        </h2>
        <Space size="middle">
          <Space size="small">
            <IconButton
              type={layout === "card" ? "transparent" : "secondary"}
              onClick={() => setLayout("list")}
            >
              <MenuOutlined />
            </IconButton>
            <IconButton
              type={layout === "card" ? "secondary" : "transparent"}
              onClick={() => setLayout("card")}
            >
              <AppstoreOutlined />
            </IconButton>
          </Space>
        </Space>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className="filter">
        <Radio.Group
          onChange={(e) => handleDateFilter(e.target.value)}
          value={filterValue}
        >
          <Radio.Button value="1">{t("agents.allAgents")}</Radio.Button>
          <Radio.Button value="24">{t("global.times.24")}</Radio.Button>
          <Radio.Button value="7">{t("global.times.7")}</Radio.Button>
          <Radio.Button value="30">{t("global.times.30")}</Radio.Button>
          <Radio.Button value="12">{t("global.times.12")}</Radio.Button>
        </Radio.Group>
        {/* <Space>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="search"
            size="sm"
            placeholder={t("dashboards.searchDashboards")}
            prefix={<SearchOutlined />}
            allowClear
          />
          <Button icon={<Icon type="filter" />}>Filters</Button>
        </Space> */}
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .filter {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export default AgentFilter
