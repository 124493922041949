export function groupViz(visualizations) {
  let hash = visualizations.reduce((acc, viz) => {
    let { type } = viz

    if (acc[type]) {
      acc[type].push(viz)
    } else {
      acc[type] = [viz]
    }

    return acc
  }, {})

  return Object.entries(hash)
}
