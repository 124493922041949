import client from "services/client"
import qs from "query-string"

function getEndpoint(pending, disabled) {
  if (pending) return "/pending"
  if (disabled) return "/disabled"
  return ""
}

async function getUserPage(options = {}) {
  const {
    page = 1,
    pageSize: page_size = 10,
    name: q,
    pending,
    disabled,
  } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
    pending,
    disabled,
  })

  const res = await client.get(`/users?${params}`)
  return res.data
}

async function getUsers() {
  const res = await client.get(`/users`)
  return res.data
}

async function getUserByName(name = "") {
  const res = await client.get(`/users?q=${name}`)

  return res.data
}

async function getUser(id) {
  const res = await client.get(`/users/${id}`)

  return res.data
}

async function updateUser(id, data) {
  const res = await client.post(`/users/${id}`, data)

  return res.data
}

async function createUser(user) {
  const res = await client.post(`/users`, user)
  return res.data
}

async function regenerateApi(id) {
  const res = await client.post(`/users/${id}/regenerate_api_key`)
  return res.data
}

async function getSession() {
  const res = await client.get("/session")
  return res.data
}

async function disableUser(id) {
  const res = await client.post(`/users/${id}/disable`)
  return res.data
}

async function enableUser(id) {
  const res = await client.delete(`/users/${id}/disable`)
  return res.data
}

async function deleteUser(id) {
  const res = await client.delete(`/users/${id}`)
  return res.data
}

async function inviteUser(id) {
  const res = await client.post(`/users/${id}/invite`)
  return res.data
}

async function resetPassword(id) {
  const res = await client.post(`/users/${id}/reset_password`)
  return res.data
}

export {
  getUserPage,
  getUsers,
  getUserByName,
  getUser,
  updateUser,
  createUser,
  regenerateApi,
  getSession,
  disableUser,
  enableUser,
  deleteUser,
  inviteUser,
  resetPassword,
}
