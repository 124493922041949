import {
  Avatar,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Space,
  Tag,
} from "antd"
import styled from "styled-components"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import Icon from "components/shared/Icon"
import Table from "components/shared/Table"
import Text from "components/shared/Text"
import {
  useCopyAllDashboard,
  useCopyDashboard,
  useJobbLogArray,
} from "hooks/admin"
import { useDataSourceTypes } from "hooks/dataSources"
import CreateForm from "components/shared/CreateForm/CreateForm"
import IconButton from "components/shared/IconButton"
import {
  datasourceImages,
  destinationImages,
} from "components/shared/Icon/CustomIcons.utils"

function CopyDashboardForm({ onChange, onCancel, closeModal, types }) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useCopyDashboard()
  const [sources, setSources] = useState([])

  const { data: dataSourceTypes, isLoading: loadingTypes } =
    useDataSourceTypes()

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  const handleAddSource = (value, closeModal) => {
    setSources([...sources, { ...value }])
    closeModal()
  }

  const handelDeleteSource = (indexToDelete) => {
    const updatedSources = [...sources]
    updatedSources.splice(indexToDelete, 1)
    setSources(updatedSources)
  }

  const handelSave = (value) => {
    const finalValue = {
      ...value,
      sources,
      new_requests_prefix: "",
      data_sources_prefix: "",
    }
    mutate(finalValue, {
      onSuccess: (value) => {
        closeModal()
      },
    })
  }

  return (
    <Form layout="vertical" onFinish={handelSave}>
      <Form.Item
        label="Host URL"
        name="idataworkers_host"
        rules={[{ required: "true" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Destination URL"
        name="idataworkers_host_destination"
        rules={[{ required: "true" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Dashboard ID"
        name="slug"
        rules={[{ required: "true" }]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label="Host Api key"
        name="api_key"
        rules={[{ required: "true" }]}
      >
        <Input type="password" autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        label="Destination Api Key"
        name="api_key_dst"
        rules={[{ required: "true" }]}
      >
        <Input type="password" autoComplete="new-password" />
      </Form.Item>

      <Divider />

      {sources.length ? (
        <List
          itemLayout="horizontal"
          dataSource={sources}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                // <IconButton size="sm" type="transparent" shape="round">
                //   <Icon type="edit" />
                // </IconButton>,
                <IconButton
                  size="sm"
                  type="transparent"
                  shape="round"
                  onClick={() => handelDeleteSource(index)}
                >
                  <Icon type="delete" />
                </IconButton>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`${
                      datasourceImages[item.type] ||
                      destinationImages[item.type]
                    }`}
                  />
                }
                title={item.name}
                description={item.type}
              />
            </List.Item>
          )}
        ></List>
      ) : null}

      <Form.Item>
        <FormModal
          title={t("dataSource.newDataSource")}
          width={500}
          hasChanged={(name) => !!name}
          Form={CreateForm}
          formProps={{ Types: dataSourceTypes, onCreate: handleAddSource }}
          style={{ top: "1rem" }}
        >
          <Button size="sm" type="blue" disabled={loadingTypes}>
            + {t("dataSource.newDataSource")}
          </Button>
        </FormModal>
      </Form.Item>

      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

function CopyAllDashboardsForm({ onChange, onCancel, closeModal }) {
  const { t } = useTranslation()
  const { mutate, isLoading } = useCopyAllDashboard()
  const [sources, setSources] = useState([])

  const { data: dataSourceTypes, isLoading: loadingTypes } =
    useDataSourceTypes()

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  const handleAddSource = (value, closeModal) => {
    setSources([...sources, { ...value }])
    closeModal()
  }

  const handelDeleteSource = (indexToDelete) => {
    const updatedSources = [...sources]
    updatedSources.splice(indexToDelete, 1)
    setSources(updatedSources)
  }

  const handelSave = (value) => {
    const finalValue = {
      ...value,
      sources,
      new_requests_prefix: "",
      data_sources_prefix: "",
    }
    mutate(finalValue, {
      onSuccess: (value) => {
        closeModal()
      },
    })
  }

  return (
    <Form layout="vertical" onFinish={handelSave}>
      <Form.Item
        label="Host URL"
        name="idataworkers_host"
        rules={[{ required: "true" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Destination URL"
        name="idataworkers_host_destination"
        rules={[{ required: "true" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Host Api key"
        name="api_key"
        rules={[{ required: "true" }]}
      >
        <Input type="password" autoComplete="new-password" />
      </Form.Item>

      <Form.Item
        label="Destination Api Key"
        name="api_key_dst"
        rules={[{ required: "true" }]}
      >
        <Input type="password" autoComplete="new-password" />
      </Form.Item>

      <Divider />

      {sources.length ? (
        <List
          itemLayout="horizontal"
          dataSource={sources}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                // <IconButton size="sm" type="transparent" shape="round">
                //   <Icon type="edit" />
                // </IconButton>,
                <IconButton
                  size="sm"
                  type="transparent"
                  shape="round"
                  onClick={() => handelDeleteSource(index)}
                >
                  <Icon type="delete" />
                </IconButton>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`${
                      datasourceImages[item.type] ||
                      destinationImages[item.type]
                    }`}
                  />
                }
                title={item.name}
                description={item.type}
              />
            </List.Item>
          )}
        ></List>
      ) : null}

      <Form.Item>
        <FormModal
          title={t("dataSource.newDataSource")}
          width={500}
          hasChanged={(name) => !!name}
          Form={CreateForm}
          formProps={{ Types: dataSourceTypes, onCreate: handleAddSource }}
          style={{ top: "1rem" }}
        >
          <Button size="sm" type="blue" disabled={loadingTypes}>
            + {t("dataSource.newDataSource")}
          </Button>
        </FormModal>
      </Form.Item>

      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

function formatDate(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm")
}

const Columns = () => {
  const customDateSorter = (a, b) => {
    const aTimestamp = new Date(a.created_at).getTime()
    const bTimestamp = new Date(b.created_at).getTime()
    return aTimestamp - bTimestamp
  }

  return [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      sorter: customDateSorter,
      render: (_, { created_at }) => (
        <Text size="sm">{created_at ? formatDate(created_at) : ""}</Text>
      ),
    },
    {
      title: "Updated At",
      key: "updated_at",
      dataIndex: "updated_at",
      render: (_, { updated_at }) => (
        <Text size="sm">{updated_at ? formatDate(updated_at) : ""}</Text>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          color={
            status === "Finished"
              ? "#87d068"
              : status === "Failed"
              ? "#f50"
              : "#2db7f5"
          }
        >
          {status}
        </Tag>
      ),
    },
  ]
}

const OrganizationsData = ({ orgData }) => {
  const fetchDataMutation = useJobbLogArray()
  const { data: dataSourceTypes, isLoading: loadingTypes } =
    useDataSourceTypes()

  const handleClick = async () => {
    await fetchDataMutation.mutateAsync(orgData)
  }

  return (
    <div>
      <Header>
        <Space>
          <FormModal
            title="Copy Dashboard"
            width={500}
            hasChanged={({ isLoading }) => !!isLoading}
            Form={CopyDashboardForm}
            formProps={{ types: dataSourceTypes }}
          >
            <Button type="blue" size="lg">
              <Icon type="plusCircle" /> Copy Dashboard
            </Button>
          </FormModal>
          <FormModal
            title="Copy All Dashboard"
            width={500}
            hasChanged={({ isLoading }) => !!isLoading}
            Form={CopyAllDashboardsForm}
          >
            <Button type="blue" size="lg">
              <Icon type="plusCircle" /> Copy All Dashboard
            </Button>
          </FormModal>
        </Space>
        <Button
          type="primary"
          loading={fetchDataMutation?.isLoading}
          disabled={fetchDataMutation?.isLoading}
          onClick={() => handleClick()}
        >
          Refresh
        </Button>
      </Header>
      <Table
        scroll={{ x: "max-contentmax" }}
        size="sm"
        rowKey="id"
        columns={Columns()}
        dataSource={orgData}
        // pagination={false}
        style={{
          marginTop: "1rem",
        }}
      />
    </div>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default OrganizationsData
