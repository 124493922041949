import React from "react"
import styled from "styled-components"
import Text from "components/shared/Text"
import { slugify } from "lib/utils"

function Error({ children }) {
  return (
    <Text color="danger" size="xxs" className="form-error">
      {children}
    </Text>
  )
}

function FormGroup({ label, children, error, ...delegated }) {
  const id = React.useMemo(() => slugify(label), [label])

  return (
    <Wrapper {...delegated}>
      <Text as="label" htmlFor={id}>
        {label}
      </Text>

      {children}

      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.mixins.flexColumn};

  label {
    margin: 0 0 8px;
  }

  .form-error {
    margin: 8px 0 0;
    animation: margin 0.2s linear;

    @keyframes margin {
      0% {
        margin: 0 0 0;
      }
    }
  }
`

export default FormGroup
