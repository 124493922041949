import React from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { Empty, Result, Spin, Tabs } from "antd"
import { useDashboardByTags } from "hooks/dashboards"
import ApplicationDashboard from "components/Application/ApplicationDashboard"
import { useTranslation } from "react-i18next"

const Application = () => {
  const { t, i18n } = useTranslation()
  let { tag } = useParams()
  const { data, isLoading, isError } = useDashboardByTags(tag)

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <h2 size="heading">{t("dashboards.dashboards")}</h2>
      </div>
      {data.count === 0 ? (
        <Empty />
      ) : (
        <Tabs
          tabPosition={i18n.resolvedLanguage === "ar" ? "right" : "left"}
          size="small"
          type="card"
        >
          {data?.results.map((d) => (
            <Tabs.TabPane
              tab={d.name}
              key={d.id}
              style={{ backgroundColor: "#FFF" }}
            >
              <ApplicationDashboard id={d.id} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & > .ant-tabs-card .ant-tabs-content {
    /* margin-top: 1rem; */
    /* margin-left: 0.1rem; */
  }

  & > .ant-tabs-card .ant-tabs-tab-active .ant-tabs-tab-btn,
  & > .ant-tabs-card .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: var(--color-brand);
  }

  & > .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    /* gap: 0.8rem; */
    width: 180px;
  }

  & > .ant-tabs-card .ant-tabs-tab-btn {
    padding: 0.5rem;
    white-space: initial;
  }

  .header {
    width: 180px;
    padding: 1rem;
    background-color: #00244d;
    border-radius: 6px;
    text-align: center;

    h2 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  @media (max-width: 768px) {
    & > .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
      box-shadow: inset -22px 0 8px -8px rgb(0 0 0 / 21%);
    }

    & > .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
      box-shadow: inset 22px 0 8px -8px rgba(0, 0, 0, 0.21);
    }
  }
`
const SpinnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Application
