import { Button, Modal, Space, Table, Tag, Tooltip, notification } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Icon from "components/shared/Icon"
import { TableSkeleton } from "components/shared/Table"
import Text from "components/shared/Text"
import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import AgentDestinations from "./AgentDestinations"
import {
  AudioMutedOutlined,
  AudioOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
} from "@ant-design/icons"
import { useDeleteAgent, useMuteAgent, useUnMuteAgent } from "hooks/agents"
import EditUserAgentForm from "./EditUserAgentForm"

const onConvertSecondToRearm = (seconds) => {
  let result = {
    number: 0,
    unit: "s",
  }

  if (seconds >= 604800) {
    result.number = Math.floor(seconds / 604800)
    result.unit = "w"
  } else if (seconds >= 86400) {
    result.number = Math.floor(seconds / 86400)
    result.unit = "d"
  } else if (seconds >= 3600) {
    result.number = Math.floor(seconds / 3600)
    result.unit = "h"
  } else if (seconds >= 60) {
    result.number = Math.floor(seconds / 60)
    result.unit = "m"
  } else {
    result.number = seconds
    result.unit = "s"
  }

  return result
}

const Actions = ({ agent, IS_ADMIN }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteAgent } = useDeleteAgent()
  const { mutateAsync: muteAgent } = useMuteAgent()
  const { mutateAsync: unmuteAgent } = useUnMuteAgent()

  const handelDeleteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("agents.deleteAgent"),
      content: t("agents.deleteAgentConfirem"),
      okText: t("global.delete"),
      okType: "danger",
      cancelText: t("global.cancel"),
      onOk() {
        return deleteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.alertNotDeleted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.success({
                  message: t("agents.alertDeleted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.alertNotDeleted"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }

  const handelMuteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("agents.muteAgent"),
      content: t("agents.muteAgentConfirm"),
      okText: t("agents.mute"),
      okType: "danger",
      cancelText: t("global.cancel"),
      onOk() {
        return muteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.notfNotmuted"),
                  duration: 2,
                  placement: "topRight",
                })
              } else {
                notification.warning({
                  message: t("agents.notfMuted"),
                  duration: 2,
                  placement: "topRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.notfNotmuted"),
                duration: 2,
                placement: "topRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }

  const handelUnMuteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("agents.unmuteAgent"),
      content: t("agents.unmuteAgentConfirm"),
      okText: t("agents.unmete"),
      cancelText: t("global.cancel"),
      onOk() {
        return unmuteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.notfNotrestored"),
                  duration: 2,
                  placement: "topRight",
                })
              } else {
                notification.success({
                  message: t("agents.notfRestored"),
                  duration: 2,
                  placement: "topRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.notfNotrestored"),
                duration: 2,
                placement: "topRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }
  return (
    <Space>
      {!IS_ADMIN && <EditUserAgentForm agent={agent} />}
      {agent?.options?.muted ? (
        <Tooltip title="Unmute">
          <Button
            type="text"
            shape="circle"
            icon={<AudioMutedOutlined />}
            onClick={handelUnMuteAlert}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Mute">
          <Button
            type="text"
            shape="circle"
            icon={<AudioOutlined />}
            onClick={handelMuteAlert}
          />
        </Tooltip>
      )}
      <Tooltip title="Delete">
        <Button
          type="text"
          shape="circle"
          icon={<StopOutlined />}
          onClick={handelDeleteAlert}
        />
      </Tooltip>
    </Space>
  )
}

const AgentListView = ({
  IS_ADMIN,
  agent,
  queryParams,
  onParamsChange,
  loading,
}) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t("global.tabels.name"),
      dataIndex: "name",
      key: "name",
      render: (_, agent) => (
        <Space>
          <Icon type="agentIcon" className="agentIcon" />
          {IS_ADMIN ? (
            <CustomLink to={`/agents/${agent?.id}`}>
              <div className="dashboardTitle">
                <span className="dashboardName">{agent?.name}</span>
                <span className="dashboardDesc">{t("agents.agent")}</span>
              </div>
            </CustomLink>
          ) : (
            <div className="dashboardTitle">
              <span className="dashboardName">{agent?.name}</span>
              <span className="dashboardDesc">{t("agents.agent")}</span>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: t("global.tabels.createdBy"),
      dataIndex: "user",
      key: "user",
      render: (_, { user }) => <p>{user.name}</p>,
      width: "10%",
    },
    {
      title: t("global.tabels.createdAt"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => (
        <p>{format(new Date(created_at), "dd/MM/yyyy hh:mm")}</p>
      ),
      width: "12%",
    },
    {
      title: t("global.tabels.status"),
      dataIndex: "state",
      key: "state",
      width: "10%",
      render: (state) => {
        const color =
          state === "ok" ? "green" : state === "unknown" ? "warning" : "error"
        return (
          <Tag bordered={false} size="sm" color={color}>
            {state.toUpperCase()}
          </Tag>
        )
      },
    },
    {
      key: "actions",
      width: "10%",
      render: (agent) => <Actions agent={agent} IS_ADMIN={IS_ADMIN} />,
    },
  ]

  const expandedRowRender = (agentData) => {
    const expandcolumns = [
      {
        title: "Column",
        key: "column",
        dataIndex: "options",
        width: "10%",
        render: (options) => options?.column,
      },
      {
        title: "Condition",
        key: "condition",
        dataIndex: "options",
        width: "10%",
        render: (options) => options?.op,
      },
      {
        title: "Treshold",
        key: "treshold",
        dataIndex: "options",
        width: "10%",
        render: (options) => options?.value,
      },
      {
        title: "Query",
        key: "query",
        dataIndex: "query",
        width: "12%",
        render: (query) => query?.name,
      },
      {
        title: "Notifications",
        key: "notifications",
        dataIndex: "rearm",
        width: "12%",
        render: (rearm) => (
          <Text size="xs">
            {/* {t("agents.notificationSend")} */}
            {rearm === 1
              ? t("agents.notificationAlert")
              : rearm > 1
              ? ` ${t("agents.atMostEvery")} ${
                  onConvertSecondToRearm(rearm).number
                } ${onConvertSecondToRearm(rearm).unit}, ${t(
                  "agents.whenEvaluted"
                )}`
              : t("agents.justOnce")}
          </Text>
        ),
      },
      {
        title: "Template",
        key: "template",
        dataIndex: "options",
        width: "10%",
        render: (options) =>
          options?.custom_subject ? "Custome Template" : "Default Template",
      },
      {
        title: "Destinations",
        key: "destinations",
        render: (agent) => <AgentDestinations agent={agent} type="list" />,
      },
    ]
    return (
      <Table
        columns={expandcolumns}
        dataSource={[agentData]}
        pagination={false}
      />
    )
  }

  return (
    <Wrapper>
      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        <Table
          scroll={{ x: "max-contentmax" }}
          size="sm"
          rowKey="id"
          dataSource={agent}
          columns={columns}
          onChange={onParamsChange}
          pagination={false}
          expandable={{
            expandedRowRender,
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  .agentIcon {
    font-size: 1.8rem;
  }

  .dashboardTitle {
    display: flex;
    flex-direction: column;

    .dashboardName {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .dashboardDesc {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
`

export default AgentListView
