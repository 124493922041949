import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClientProvider,
} from "react-query"
import qs from "query-string"
import { dashboardKeys } from "./dashboards"

import {
  getRequest,
  getRequestResult,
  getRequestByName,
  getRequestPage,
  getRequestsTags,
  removeFavoriteRequest,
  addFavoriteRequest,
  createRequest,
  updateRequest,
  runRequest,
  getJobs,
  forkRequest,
  createeQueryResult,
  runnewRequest,
  getFavoritesRequests,
  createVisualization,
  deleteVisualization,
  updateVisualization,
  updateRequestResult,
  getRequestDropdown,
  getQueryDropdown,
} from "apis/requests"
import { useCallback } from "react"

const keys = {
  all: ["requests"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  results: () => [...keys.all, "result"],
  result: (id) => [...keys.results(), id],
  searchs: () => [...keys.all, "search"],
  search: (name) => [...keys.searchs(), name],
  tags: () => [...keys.all, "tags"],
  jobs: (id) => [...keys.all, "jobs", id],
  favorites: () => [...keys.all, "favorites"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

const defaultMutationConfig = (queryClient) => ({
  onSettled: () => {
    queryClient.removeQueries(keys.searchs())

    queryClient.prefetchQuery({
      queryKey: keys.search(""),
      queryFn: () => getRequestByName(""),
      ...defaultQueryConfig,
    })
  },
})

// helpers

function updateRequestDetail(queryClient, id, data) {
  const request = queryClient.getQueryData(keys.detail(id))

  if (request) {
    queryClient.setQueryData(keys.detail(id), (previous) => ({
      ...previous,
      ...data,
    }))
  }
}

function getCurrentPaginationOptions() {
  const {
    page = 1,
    page_size: pageSize = 10,
    my_requests: myRequests = false,
    archived = false,
    favorites = false,
    q: name,
  } = qs.parse(window.location.search)

  return {
    page,
    pageSize,
    myRequests: Boolean(myRequests),
    archived: Boolean(archived),
    favorites: Boolean(favorites),
    name,
  }
}

function updateCurrentRequestPage(queryClient, id, data) {
  const pagination = getCurrentPaginationOptions()

  queryClient.setQueryData(keys.list(pagination), (previous) => ({
    ...previous,
    results: previous.results.map((request) =>
      request.id === id ? { ...request, ...data } : request
    ),
  }))
}

// hooks
function useRequests(pagination, options = {}) {
  return useQuery({
    queryKey: keys.list(pagination),
    queryFn: () => getRequestPage(pagination),
    ...defaultQueryConfig,
    ...options,
  })
}

function useRequestResult(
  query,
  RequestParameters,
  isLoadingReqParam,
  options = {}
) {
  return useQuery({
    queryKey: keys.result(query.id),
    queryFn: () => getRequestResult(query, RequestParameters),
    staleTime: 0,
    cacheTime: 5 * (60 * 1000),
    refetchOnWindowFocus: false,
    ...options,
    enabled: !isLoadingReqParam,
  })
}

function useUpdateRequestResult(id, newData, options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, newdata }) => updateRequestResult(id, newdata),
    onSuccess: (request) => {
      if (request.id) {
        queryClient.invalidateQueries(keys.result(request.id))
      }
    },
  })
}

function useRequest(id, options = {}) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getRequest(id),
    ...defaultQueryConfig,
    ...options,
  })
}

function useSearchRequests(name, options = {}) {
  return useQuery({
    queryKey: keys.search(name),
    queryFn: () => getRequestByName(name),
    enabled: !!name,
    ...defaultQueryConfig,
    ...options,
  })
}

function useRequestsTags(options = {}) {
  return useQuery({
    queryKey: keys.tags(),
    queryFn: () => getRequestsTags(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useFavoritesRequests(options = {}) {
  return useQuery({
    queryKey: keys.favorites(),
    queryFn: () => getFavoritesRequests(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useUpdateFavoriteRequest(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, is_favorite }) =>
      !is_favorite ? addFavoriteRequest(id) : removeFavoriteRequest(id),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries(keys.details())
        queryClient.invalidateQueries(keys.lists())
        queryClient.refetchQueries(keys.favorites())
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
}

function useCreateRequest() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createRequest(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useUpdateRequest(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ requestId, data }) => updateRequest(requestId, data), {
    ...options,
    onSuccess: (newRequest) => {
      options.onSuccess?.(newRequest)
      const { id } = newRequest
      queryClient.invalidateQueries(keys.detail(id))
      queryClient.invalidateQueries(keys.lists())
      queryClient.refetchQueries(keys.favorites())
    },
  })
}

function useRunRequest(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ requestId, data }) => runRequest(requestId, data),
    onSuccess: (newRequest) => {
      // const { id } = newRequest
      // return queryClient.invalidateQueries(keys.detail(id))
    },
  })
}

function useGetJobs(id, options = {}) {
  return useQuery({
    queryKey: keys.jobs(id),
    queryFn: () => getJobs(id),
    ...defaultQueryConfig,
    ...options,
  })
}

function useForkRequest() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => forkRequest(id),
    onSuccess: (_, { requestId }) => {
      return queryClient.invalidateQueries(keys.result(requestId))
    },
  })
}

function useCreateQueryResult() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createeQueryResult(data),
    onSuccess: (newQuery) => {
      // console.log(newQuery)
      // return queryClient.invalidateQueries(keys.result(newQuery.id))
    },
  })
}

function useRefreshRequest(id, options = {}) {
  const queryClient = useQueryClient()
  return useCallback(() => queryClient.invalidateQueries(keys.detail(id)), [id])
}

function useRunNewRequest(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ queryId, data }) => runnewRequest(queryId, data),
    onSuccess: (newQuery, variables) => {
      options.onSuccess?.(newQuery)
      queryClient.setQueryData(
        keys.detail(variables.queryId),
        variables.queryId
      )
      queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useCreateVisualization(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createVisualization(data),
    onSuccess: (data) => {
      const { id } = data
      queryClient.setQueryData(keys.detail(id), data)
    },
  })
}

function useUpdateVisualization(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => updateVisualization(id, data),
    onSuccess: (vis) => {
      if (vis) {
        queryClient.invalidateQueries(keys.detail(vis.id))
        queryClient.refetchQueries(dashboardKeys.details())
      }
    },
  })
}

function useDeleteVisualization(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => deleteVisualization(id),
    onSuccess: (vis) => {
      if (!vis) {
        queryClient.invalidateQueries(keys.details())
      }
    },
  })
}

function useGetRequestDropdown(requestId, dropdownId, options = {}) {
  return useQuery({
    queryFn: () => getRequestDropdown(requestId, dropdownId),
    queryKey: dropdownId,
    refetchOnWindowFocus: false,
    ...options,
  })
}

function useGetQueryDropdown(queryId, options = {}) {
  return useQuery({
    queryFn: () => getQueryDropdown(queryId),
    queryKey: queryId,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export {
  useRequests,
  useRequestResult,
  useRequest,
  useSearchRequests,
  useRequestsTags,
  useUpdateFavoriteRequest,
  useCreateRequest,
  useUpdateRequest,
  useRunRequest,
  useGetJobs,
  useForkRequest,
  useCreateQueryResult,
  useRefreshRequest,
  useRunNewRequest,
  useFavoritesRequests,
  useCreateVisualization,
  useDeleteVisualization,
  useUpdateVisualization,
  useUpdateRequestResult,
  useGetRequestDropdown,
  useGetQueryDropdown,
}
