import styled from "styled-components"
import { Table as AntTable } from "antd"
import TableSkeleton from "./TableSkeleton"

const Table = styled(AntTable)`
  .ant-table {
    overflow-x: auto;
  }
  .ant-table-thead th {
    background: ${({ theme }) => theme.color.table} !important;
    color: #fff !important;

    &::before {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.color.table};
    }
  }

  .ant-table-tbody {
    background: #fff;
  }
`

Table.Skeleton = TableSkeleton

export default Table
