import { getRequestResult } from "apis/requests"
import React, { useState } from "react"

const CreateAgentContext = React.createContext()

// React.useReducer would have been better

function CreateAgentProvider(props) {
  const [step, setStep] = React.useState(1)
  const [agentName, setAgentName] = React.useState("New Alert")
  const [request, setRequest] = React.useState(null)
  const [column, setColumn] = React.useState(null)
  const [condition, setCondition] = React.useState(">")
  const [threshold, setThreshold] = React.useState("1")
  const [template, setTemplate] = React.useState("default")
  const [agentOptions, setAgentOptions] = useState({})
  const [users, setUsers] = React.useState([])
  const [queryData, setQueryData] = React.useState(null)
  const [queryLoading, setQueryLoading] = React.useState(false)
  const [queryError, setQueryError] = React.useState(false)
  const [isTemplate, setIsTemplate] = useState(false)
  const [varname, setVarname] = useState(null)
  const [vartype, setVartype] = useState(null)
  const [dashboard, setDashboard] = useState(null)

  const getQueryData = async (query, parameters = []) => {
    try {
      setQueryLoading(true)
      const res = await getRequestResult(query, parameters)
      setQueryData(res)
      setColumn(res?.data?.columns[0]?.name)
      setQueryLoading(false)
    } catch (error) {
      setQueryError(error.message)
      setQueryLoading(false)
    }
  }

  React.useEffect(() => {
    if (request) {
      getQueryData(request, request?.options?.parameters)
    }
  }, [request])

  const onNextStep = React.useCallback(() => setStep(step + 1), [step])

  const onPreviousStep = React.useCallback(() => setStep(step - 1), [step])

  const onSelectRequest = React.useCallback((selectedRequest) => {
    setRequest(selectedRequest)
    setThreshold("1")
    setCondition(">")
    setColumn(null)
    setUsers([])
  }, [])

  const onSelectColumn = React.useCallback(
    (selectedColumn) => setColumn(selectedColumn),
    []
  )

  const onSelectCondition = React.useCallback(
    (selectedCondition) => setCondition(selectedCondition),
    []
  )

  const onChangeThreshold = React.useCallback(
    (newThreshold) => setThreshold(newThreshold),
    []
  )

  const onChangeTemplate = React.useCallback(
    (selectedTemplate) => setTemplate(selectedTemplate),
    []
  )

  const onSelectUsers = React.useCallback((list) => setUsers(list), [])

  const onRemoveUser = React.useCallback(
    (id) => {
      const newUsers = users.filter((user) => user.id !== id)
      setUsers(newUsers)
    },
    [users]
  )

  const value = React.useMemo(
    () => ({
      step,
      setStep,
      agentName,
      request,
      setRequest,
      queryData,
      queryLoading,
      queryError,
      column,
      condition,
      threshold,
      template,
      agentOptions,
      users,
      setAgentName,
      onSelectRequest,
      onNextStep,
      onPreviousStep,
      onSelectColumn,
      onSelectCondition,
      onChangeThreshold,
      onChangeTemplate,
      setAgentOptions,
      onSelectUsers,
      onRemoveUser,
      isTemplate,
      varname,
      vartype,
      setIsTemplate,
      setVarname,
      setVartype,
      dashboard,
      setDashboard,
    }),
    [
      step,
      setStep,
      agentName,
      request,
      setRequest,
      queryData,
      queryLoading,
      queryError,
      column,
      condition,
      threshold,
      template,
      agentOptions,
      users,
      onSelectRequest,
      onNextStep,
      onPreviousStep,
      onSelectColumn,
      onSelectCondition,
      onChangeThreshold,
      onChangeTemplate,
      setAgentOptions,
      onSelectUsers,
      onRemoveUser,
      isTemplate,
      varname,
      vartype,
      setIsTemplate,
      setVarname,
      setVartype,
      dashboard,
      setDashboard,
    ]
  )

  return <CreateAgentContext.Provider value={value} {...props} />
}

export function useCreateAgentContext() {
  const context = React.useContext(CreateAgentContext)

  if (!context) {
    throw new Error(
      "useCreateAgentContext muse be used inside CreateAgentProvider"
    )
  }

  return context
}

export function withProvider(Component) {
  return function (props) {
    return (
      <CreateAgentProvider>
        <Component {...props} />
      </CreateAgentProvider>
    )
  }
}

export default CreateAgentProvider
