import React from "react"
import styled from "styled-components"
import {
  Avatar,
  Empty,
  Modal,
  Result,
  Space,
  Spin,
  Table,
  notification,
} from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import {
  useDeleteETLDestinations,
  useETLDestinations,
} from "hooks/ETL/destinations"
import { useTranslation } from "react-i18next"

const DestinationsList = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useETLDestinations()
  const { mutateAsync } = useDeleteETLDestinations()

  const handelDeleteDestination = (destinationId) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("destinations.deleteDes"),
      content: t("destinations.deleteDesConfirm"),
      onOk() {
        return mutateAsync(destinationId, {
          onSuccess: (data) => {
            if (data.message) {
              notification.error({
                message: t("destinations.failedDelete"),
              })
            } else {
              notification.success({
                message: t("destinations.successfullyDelete"),
              })
            }
          },
        })
      },
      onCancel() {},
    })
  }

  const columns = [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("global.tabels.type"),
      key: "destinationName",
      dataIndex: "destinationName",
      render: (destinationName, object) => {
        return (
          <Space>
            <Avatar src={object?.icon} shape="square" />
            <span>{destinationName}</span>
          </Space>
        )
      },
    },
    {
      key: "action",
      render: (source) => {
        return (
          <Space>
            <IconButton
              type="transparent"
              shape="round"
              size="sm"
              danger
              onClick={() => handelDeleteDestination(source.destinationId)}
            >
              <Icon type="delete" />
            </IconButton>
          </Space>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  if (isError) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data.length === 0) {
    return (
      <Wrapper>
        <Empty description={<span>{t("dataSource.noDataSource")}</span>} />
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <div className="header">
        <Space direction="vertical" size={0}>
          <h2>
            {t("destinations.allDes")}
            {": "}
            <span>
              {data?.destinations?.length} {t("destinations.destination")}
            </span>
          </h2>
          {/* <Text size="xs">keep tracking your destinations easily</Text> */}
        </Space>
      </div>
      <Table
        rowKey={(c) => c.sourceId}
        scroll={{ x: "max-contentmax" }}
        size="sm"
        columns={columns}
        dataSource={data.destinations}
      />
    </Wrapper>
  )
}

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;

  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
`

export default DestinationsList
