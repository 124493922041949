import { Avatar, Card, Col, Input, Row, Space, Spin, Tag } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"
import { useETLSourcesDefination } from "hooks/ETL/sources"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const CreateEtlSource = () => {
  const { t } = useTranslation()
  const [sourceType, setSourceType] = useState([])
  const [search, setSearch] = useState("")
  const { data: sourcesDefination, isLoading } = useETLSourcesDefination()

  useEffect(() => {
    if (sourcesDefination) {
      setSourceType(sourcesDefination.sourceDefinitions)
    }

    return () => {
      setSourceType([])
    }
  }, [sourcesDefination])

  useEffect(() => {
    if (search.trim()) {
      const filteredSources = sourcesDefination?.sourceDefinitions?.filter(
        (definition) =>
          definition?.name
            ?.toLowerCase()
            ?.includes(search?.trim()?.toLowerCase())
      )
      setSourceType(filteredSources)
    } else {
      setSourceType(sourcesDefination?.sourceDefinitions)
    }
  }, [search, sourcesDefination?.sourceDefinitions])

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <h2>{t("sources.createSourceDesc")}</h2>
        <Input.Search
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          size="large"
        />
      </div>
      <Row gutter={[16, 24]} wrap>
        {sourceType?.map((source) => (
          <Col
            key={source.sourceDefinitionId}
            span={6}
            style={{
              minWidth: 300,
              marginTop: 16,
            }}
          >
            <CustomLink
              to={`/sources/etl/create/${
                source.sourceDefinitionId
              }?sourceType=${source.name.toLowerCase().replace(/\s+/g, "-")}`}
            >
              <Card
                style={{
                  borderRadius: "8px",
                }}
              >
                <Card.Meta
                  title={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Space
                        style={{
                          width: "65%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "clip",
                        }}
                      >
                        <Avatar src={source?.icon} shape="square" />
                        <Text size="sm">{source?.name}</Text>
                      </Space>
                      <p>
                        <Tag>{source?.supportLevel}</Tag>
                      </p>
                    </div>
                  }
                />
              </Card>
            </CustomLink>
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: first baseline;
  justify-content: center;

  .header {
    width: 100%;
  }
`

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default CreateEtlSource
