import React from "react"
import styled from "styled-components"
import Text from "components/shared/Text"
import { useCreateAgentContext } from "context/CreateAgentContext"
import Button from "components/shared/Button"
import { useCreateAgent, useCreateAgentDestination } from "hooks/agents"
import { useNavigate } from "react-router-dom"
import { AgentSubscription } from "../SelectDestinations"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"

function Summary({ onClose }) {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  const {
    agentName,
    request,
    column,
    condition,
    threshold,
    agentOptions,
    users,
    onRemoveUser,
    onPreviousStep,
    isTemplate,
    varname,
    vartype,
    dashboard,
  } = useCreateAgentContext()

  const { mutateAsync: createSubscription } = useCreateAgentDestination()

  const navigate = useNavigate()

  // const handleSuccess = async (agent) => {
  //   console.log(users)
  //   const subscriptions = await Promise.all(
  //     users?.forEach((user) => {
  //       createSubscription({
  //         id: agent.id,
  //         data: {
  //           destination_id: user.id,
  //           alert_id: agent.id,
  //         },
  //       })
  //     })
  //   )

  //   navigate(`/${tenantName}/agents/${agent.id}`)

  //   return subscriptions
  // }

  const { mutate, isLoading } = useCreateAgent({
    // onSuccess: (agent) => handleSuccess(agent),
  })

  const handleSave = React.useCallback(() => {
    const agent = {
      name: agentName,
      query_id: request.id,
      template: isTemplate,
      varname: isTemplate ? varname : null,
      vartype: isTemplate ? vartype : null,
      dashboard: dashboard.name,
      options: {
        op: condition,
        value: threshold,
        column: column,
        ...agentOptions,
      },
    }
    mutate(agent, {
      onSuccess: (data) => {
        if (users) {
          users?.forEach((user) => {
            createSubscription(
              {
                id: data.id,
                data: {
                  destination_id: user.id,
                  alert_id: data.id,
                },
              },
              {
                onSuccess: () => {
                  navigate(`/${tenantName}/agents/${data.id}`)
                },
              }
            )
          })
        }
        navigate(`/${tenantName}/agents/${data.id}`)
      },
    })
  }, [])

  const content = [
    { name: t("agents.valuesCol"), value: column },
    { name: t("agents.condition"), value: condition },
    { name: t("agents.threshold"), value: threshold },
  ]

  return (
    <Wrapper>
      <Text size="lg" color="brand" className="request-name" as="h3">
        {request?.name}
      </Text>

      <div className="destinations">
        {users.map((user) => (
          <AgentSubscription
            key={user.id}
            user={user}
            onRemove={() => onRemoveUser(user.id)}
          />
        ))}
      </div>

      {/* <Text color="secondary">This Query has no refresh schedule</Text> */}

      <TriggerContent>
        <Text size="lg">{t("agents.triggerWhen")}:</Text>

        <div className="content">
          {content.map(({ name, value }) => (
            <ContentItem key={name}>
              <Text color="brand" className="name">
                {name}
              </Text>
              <Text className="value">{value}</Text>
            </ContentItem>
          ))}
        </div>

        <div></div>
      </TriggerContent>

      <div className="save-btn">
        <Button
          type="primary"
          shape="outlined"
          onClick={onPreviousStep}
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.previous")}
        </Button>
        <Button
          type="primary"
          onClick={handleSave}
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 32px;

  .request-name {
    font-weight: 500;
    margin: 0 0 32px;
  }

  .save-btn {
    margin: 32px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* ${({ theme }) => theme.mixins.flexCenter}; */
  }
`

const TriggerContent = styled.div`
  ${({ theme }) => theme.mixins.flexSpaceBetween};
  padding: 16px;
  border: var(--border-0);
  border-radius: var(--border-radius-0);
  margin: 50px 0 0;
  .content {
    ${({ theme }) => theme.mixins.flexAlignCenter};
  }
`

const ContentItem = styled.div`
  ${({ theme }) => theme.mixins.flexAlignCenter};
  margin: 0 20px 0 0;

  .name {
    margin: 0 16px 0 0;
    font-weight: 500;
  }

  .value {
    border: var(--border-0);
    border-radius: var(--border-radius-0);
    padding: 8px 16px;
    min-width: 100px;
  }
`

export default Summary
