import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  getDataSources,
  getDataSource,
  createDataSource,
  updateDataSources,
  deleteDataSource,
  getDataSourceTypes,
  testDataSource,
  getSchema,
} from "apis/dataSources"

const keys = {
  all: ["dataSources"],
  list: () => [...keys.all, "list"],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  types: () => [...keys.all, "types"],
  schemas: () => [...keys.all, "schema"],
  schema: (id) => [...keys.schemas(), id],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useDataSources() {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getDataSources(),
    ...defaultQueryConfig,
  })
}

export function useDataSource(id) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDataSource(id),
    ...defaultQueryConfig,
  })
}

export function useCreateDataSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createDataSource(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}

export function useUpdateSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => updateDataSources(id, data),
    onSuccess: (source) => {
      return queryClient.invalidateQueries(keys.detail(source.id))
    },
  })
}

export function useDeleteSource(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => deleteDataSource(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(keys.list())
    },
  })
}

export function useDataSourceTypes() {
  return useQuery({
    queryKey: keys.types(),
    queryFn: () => getDataSourceTypes(),
    ...defaultQueryConfig,
  })
}

export function useTestDataSource(options = {}) {
  return useMutation(({ id }) => testDataSource(id), {
    ...options,
    onSuccess: () => {},
  })
}

export function useSchema(id, options = {}) {
  return useQuery({
    queryKey: keys.schema(id),
    queryFn: () => getSchema(id),
    ...defaultQueryConfig,
    ...options,
  })
}
