import React, { useState } from "react"
import styled from "styled-components"
import DashboardCardView from "./DashboardCardView"
import DashboardListView from "./DashboardListView"
import useQueryParams from "hooks/useQueryParams"
import { useDashboards } from "hooks/dashboards"
import { Empty, Result, Spin } from "antd"
import { useTranslation } from "react-i18next"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { PlusOutlined } from "@ant-design/icons"
import EmptyDashboard from "static/images/empty/no-dashboard.svg"
import CreateDashboardForm from "./CreateDashboardForm"
import { DashboardFilter } from "./DashboardFilter"

const DashboardData = ({ IS_ADMIN, homeDashboardId }) => {
  const { t } = useTranslation()
  const [layout, setLayout] = useState("list")

  const [queryParams, setQueryParams] = useQueryParams({
    page: Number,
    page_size: Number,
    q: String,
    favorites: Boolean,
    tags: String,
    date_from: String,
    date_to: String,
  })

  const {
    page = 1,
    page_size: pageSize = 20,
    q: name,
    favorites = false,
    tags,
    date_from,
    date_to,
  } = queryParams

  const { data, isLoading, isError, isFetching } = useDashboards(
    {
      page,
      pageSize,
      name,
      favorites,
      tags,
      date_from,
      date_to,
    },
    { keepPreviousData: true }
  )

  const handleChange = ({ current: page, pageSize: page_size }) => {
    setQueryParams({ page, page_size })
  }

  if (isLoading) {
    return (
      <EmptyWrapper>
        <Spin className="spinIcon" />
      </EmptyWrapper>
    )
  }

  if (isError || !data || data?.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data?.length === 0) {
    return (
      <EmptyWrapper>
        <Empty
          image={EmptyDashboard}
          description={
            <>
              <span className="emptyTitle">{t("dashboards.emptyTitle")}</span>
              <span className="emptyDesc">{t("dashboards.emptyDesc")}</span>
              {IS_ADMIN ? (
                <FormModal
                  title={t("dashboards.addDashboard")}
                  width={500}
                  hasChanged={({ name }) => !!name}
                  Form={CreateDashboardForm}
                >
                  <Button type="primary" id="newDashboard">
                    <PlusOutlined /> {t("dashboards.newDashboard")}
                  </Button>
                </FormModal>
              ) : null}
            </>
          }
          className="empty"
        />
      </EmptyWrapper>
    )
  }

  return (
    <Wrapper>
      <DashboardFilter
        layout={layout}
        setLayout={setLayout}
        dashboardCount={data?.count}
      />
      {layout === "card" ? (
        <DashboardCardView dashboard={data} />
      ) : (
        <DashboardListView
          loading={isLoading || isFetching}
          dashboard={data}
          queryParams={queryParams}
          onParamsChange={handleChange}
          homeDashboardId={homeDashboardId}
        />
      )}
    </Wrapper>
  )
}

const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinIcon {
    margin-top: 14rem;
  }

  & .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;

    & .ant-empty-image {
      width: 245px;
      height: 245px;
    }

    & .ant-empty-description {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.48px;
      text-transform: uppercase;

      & .emptyTitle {
        color: #0097c2;
      }

      & .emptyDesc {
        color: #143d66;
        margin-bottom: 1rem;
      }
    }
  }
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;
`

export default DashboardData
