import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import {
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
  notification,
} from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-mysql"
import "ace-builds/src-noconflict/theme-xcode"
import "ace-builds/src-noconflict/ext-language_tools"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { formatDistanceToNowStrict } from "date-fns"

import client from "services/client"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useAuth } from "context/AuthContext"
import AddToDashboard from "../RequestVisualizations/AddToDashboard"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import CreateRequestVisualization from "../CreateRequestVisualization"
import Text from "components/shared/Text"
import { ar, enUS } from "date-fns/locale"
import { useRequestState } from "../Provider"
import { useUpdateRequest } from "hooks/requests"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import useRequestData from "hooks/useRequestData"
import DataSource from "../RequestSnippets/DataSource"
import AddParameters from "../RequestQuery/AddParameters"

import { Vis } from "@icloudready/idataworkers-visualization"
import auth from "services/auth"
import { getBaseUrl } from "config"

const tenant = auth.getTenantName()
const BASE_URL = getBaseUrl(
  tenant === "enwz" ? "golf" : tenant === "euzq" ? "mawten" : "default"
)

const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`
}

function DownloadLink({ url, filename, text }) {
  const [isLoading, setIsLoading] = useState(false)

  async function handleClick(e) {
    e.preventDefault()
    setIsLoading(true)

    client
      .get(url, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.download = filename
        link.click()
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error downloading file:", error)
        setIsLoading(false)
      })
  }

  return (
    <a href={url} onClick={handleClick}>
      {isLoading ? "Downloading..." : text}
    </a>
  )
}

const EmbedRequest = ({
  onChange,
  onCancel,
  closeModal,
  visualization,
  request,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <h5>{t("global.publicURL")}</h5>
      <div>
        <Paragraph copyable>
          {`${BASE_URL}/embed/query/${request.id}/visualization/${visualization.id}`}
        </Paragraph>
      </div>
      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </div>
  )
}

const Footer = ({ request, data, visualization, RequestParameters }) => {
  const { t, i18n } = useTranslation()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const menu = (
    <Menu>
      <Menu.Item disabled={!IS_ADMIN || visualization.id === 1}>
        <FormModal
          Form={AddToDashboard}
          hasChanged={(value) => !!value}
          title={t("requests.addToDashboard")}
          width={500}
          formProps={{ visualization: visualization }}
        >
          <span>{t("requests.addToDashboard")}</span>
        </FormModal>
      </Menu.Item>
      <Menu.Item disabled={visualization.id === 1}>
        <FormModal
          Form={EmbedRequest}
          hasChanged={(change) => !!change}
          title={t("requests.embedRequest")}
          width={500}
          formProps={{ request: request, visualization: visualization }}
        >
          <span>{t("requests.embed")}</span>
        </FormModal>
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.csv`}
          filename={`${request.name}.csv`}
          text={t("global.downloadCSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.tsv`}
          filename={`${request.name}.tsv`}
          text={t("global.downloadTSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.xlsx`}
          filename={`${request.name}.xlsx`}
          text={t("global.downloadExcel")}
        />
      </Menu.Item>
    </Menu>
  )
  return (
    <FooterWrapper>
      <Space>
        <Dropdown overlay={menu} trigger={["click"]} className="export_btn">
          <IconButton type="primary" size="sm" shape="outline">
            <Icon type="verticalDots" />
          </IconButton>
        </Dropdown>
        {IS_ADMIN && visualization.id !== 1 ? (
          <FormModal
            Form={CreateRequestVisualization}
            hasChanged={(change) => !!change}
            title={t("requests.visEditor")}
            width={"97%"}
            formProps={{
              memoizedRequest: request,
              data,
              edit: true,
              visualization,
              params: RequestParameters.reduce((acc, curr) => {
                acc[curr.name] =
                  curr.value === "d_now"
                    ? new Date().toISOString().split("T")[0]
                    : curr.value === "d_yesterdaqy"
                    ? new Date(Date.now() - 86400000)
                        .toISOString()
                        .split("T")[0]
                    : curr.value
                return acc
              }, {}),
            }}
            style={{ top: "0.5rem", paddingBottom: "0" }}
            bodyStyle={{
              height: "calc(100vh - 80px)",
              maxHeight: "calc(100vh - 80px)",
              overflow: "hidden",
              padding: "1rem",
            }}
          >
            <Button type="text" size="xxs">
              <Icon type="edit" />
              {t("requests.editVis")}
            </Button>
          </FormModal>
        ) : null}
      </Space>
      <Text size="xxs">
        {t("global.refreshed")}{" "}
        <b>
          {formatDistanceToNowStrict(new Date(data?.retrieved_at), {
            addSuffix: true,
            locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
          })}
        </b>
      </Text>
    </FooterWrapper>
  )
}

function RequestBody() {
  const { t } = useTranslation()
  const [requestType, setRequestType] = useState("run")
  const {
    memoizedRequest,
    RequestParameters,
    setRequestParameters,
    onUpdateData,
    updateDataLoading,
    requestData,
    setRequestData,
    onDeleteVis,
    requestError,
    setRequestError,
    getDataLoading,
  } = useRequestState()
  const updateRequest = useUpdateRequest()
  const [query, setQuery] = React.useState(memoizedRequest?.query)
  const editorRef = useRef(null)
  const buttonRef = useRef(null)
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(+location.hash.substring(1))

  const { mutate, isLoading, error, reset, queryTimer } = useRequestData()

  useEffect(() => {
    const hash = location.hash
    const active = hash.substring(1) // remove the '#' character
    setActiveKey(+active)
  }, [location])

  const handleTabChange = (key) => {
    setActiveKey(key)
    // update the URL hash value
    window.location.hash = key
  }

  const onEditTab = (targetKey, action) => {
    if (action === "add") {
      buttonRef.current.click()
    } else {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: t("requests.deleteVis"),
        content: t("requests.deleteConfirm"),
        onOk() {
          onDeleteVis(targetKey, () => {
            setActiveKey(memoizedRequest?.visualizations[0].id)
          })
        },
        onCancel() {},
      })
    }
  }

  const onSaveQuery = () => {
    updateRequest.mutate(
      {
        requestId: memoizedRequest.id,
        data: {
          ...memoizedRequest,
          query,
          options: {
            ...memoizedRequest.options,
            parameters: RequestParameters,
          },
        },
      },
      {
        onSuccess: (request) => {
          if (request.id) {
            onUpdateData()
            notification.success({
              message: t("requests.querySaved"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("requests.queryNotSaved"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
      }
    )
  }

  const onAddParameters = (newParameter) => {
    setRequestParameters((current) => [...current, newParameter])
    // setUpdatedParameters((current) => [...current, newParameter])
    const editor = editorRef.current.editor
    const session = editor.getSession()
    const cursorPosition = editor.getCursorPosition()
    const row = cursorPosition.row
    const column = cursorPosition.column
    const paramStr =
      newParameter.type === "date-range" ||
      newParameter.type === "datetime-range" ||
      newParameter.type === "datetime-range-with-seconds"
        ? `{{${newParameter.title}.start}} {{${newParameter.title}.end}}`
        : `{{${newParameter.title}}}`
    session.insert({ row, column }, paramStr)
    setQuery(session.getValue())
  }

  const handleEditorChange = (value) => {
    const addedFields = findAddedFields(query, value)
    const deletedFields = findDeletedFields(query, value)
    addedFields.forEach((fieldName) => onAddParameter(fieldName))
    deletedFields.forEach((fieldName) => onDeleteParameter(fieldName))
    setQuery(value)
    setRequestType("execute")
  }

  const onAddParameter = (param) => {
    const paramName = param.split(".")[0]
    const existingParam = RequestParameters.find((p) => p.title === paramName)
    if (existingParam) {
      return
    }
    setRequestParameters((current) => [
      ...current,
      {
        global: false,
        locals: [],
        name: paramName,
        title: paramName,
        type: "text",
      },
    ])
    // setUpdatedParameters((current) => [
    //   ...current,
    //   {
    //     global: false,
    //     locals: [],
    //     name: param,
    //     title: param,
    //     type: "text",
    //   },
    // ])
  }

  const onDeleteParameter = (param) => {
    const paramName = param.split(".")[0]
    setRequestParameters((current) =>
      current.filter((p) => p.title !== paramName)
    )
    // setUpdatedParameters((current) => current.filter((p) => p.title !== param))
  }

  const findAddedFields = (prevValue, currValue) => {
    const prevFields = getFieldNames(prevValue)
    const currFieldsSet = new Set(getFieldNames(currValue))
    const addedFields = [...currFieldsSet].filter(
      (fieldName) => !prevFields.includes(fieldName)
    )
    return addedFields
  }

  const findDeletedFields = (prevValue, currValue) => {
    const prevFields = getFieldNames(prevValue)
    const currFields = getFieldNames(currValue)
    return prevFields.filter((fieldName) => !currFields.includes(fieldName))
  }

  const getFieldNames = (value) => {
    const matches = value.match(/{{(.+?)}}/g)
    return matches ? matches.map((match) => match.slice(2, -2).trim()) : []
  }

  const onAddSchema = (text) => {
    const editor = editorRef.current.editor
    const session = editor.getSession()
    const cursorPosition = editor.getCursorPosition()
    const row = cursorPosition.row
    const column = cursorPosition.column
    session.insert({ row, column }, text)
  }

  const handleexecuteclick = () => {
    mutate(
      {
        request: memoizedRequest,
        requestParameters: RequestParameters,
        query,
        requestType,
      },
      {
        onSuccess: (data) => {
          if (data?.id) {
            setRequestData(data)
            setRequestError(null)
          }
        },
        onError: (error) => {
          setRequestData(null)
          setRequestError(error?.message)
        },
      }
    )
  }

  if (getDataLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} md={6}>
        <DataSource onAddSchema={onAddSchema} />
      </Col>
      <Col xs={24} md={18}>
        <QueryEditor>
          <div className="editor">
            <AceEditor
              ref={editorRef}
              mode="mysql"
              theme="xcode"
              name="blah2"
              fontSize={14}
              showPrintMargin={false}
              enableSnippets={true}
              enableBasicAutocompletion={true}
              enableLiveAutocompletion={true}
              value={query}
              onChange={handleEditorChange}
              width="100%"
              height="100%"
            />
          </div>
          <div className="actions">
            <div>
              <FormModal
                Form={AddParameters}
                hasChanged={(name) => !!name}
                title={t("requests.addParams")}
                formProps={{
                  onAddParameters: onAddParameters,
                  parameters: RequestParameters,
                }}
              >
                <Tooltip title={t("requests.addParams")}>
                  <IconButton type="transparent">{`{{}}`}</IconButton>
                </Tooltip>
              </FormModal>
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="primary"
                onClick={onSaveQuery}
                loading={updateRequest.isLoading || updateDataLoading}
                disabled={updateRequest.isLoading || updateDataLoading}
              >
                {t("global.save")}
              </Button>
              <Button
                type="text"
                onClick={handleexecuteclick}
                loading={isLoading}
                disabled={isLoading}
              >
                {t("global.execute")}
              </Button>
            </div>
          </div>
          <div className="result">
            <div className="queryTimer">
              {isLoading && (
                <Space align="baseline">
                  <p>
                    {t("requests.queryQueue")}
                    {formatSeconds(queryTimer)}
                  </p>
                  <Button size="sm" onClick={() => reset()}>
                    Stop
                  </Button>
                </Space>
              )}
            </div>
            {error || requestError ? (
              <div className="errors">
                <p>{error?.message || requestError}</p>
              </div>
            ) : !requestData ? null : (
              <div className="data">
                <Wrapper>
                  {memoizedRequest.visualization?.length === 0 ? (
                    <Text>{t("requests.noVis")}</Text>
                  ) : (
                    <>
                      <Tabs
                        type={
                          requestData && IS_ADMIN ? "editable-card" : "card"
                        }
                        activeKey={activeKey}
                        onChange={handleTabChange}
                        onEdit={onEditTab}
                        items={[
                          {
                            id: 1,
                            name: "Query Data",
                            type: "TABLE",
                            options: {},
                          },
                          ...memoizedRequest?.visualizations,
                        ]
                          .sort((a, b) => a.id - b.id)
                          .map((vis, idx) => ({
                            label: vis.name,
                            key: vis.id,
                            id: vis.id,
                            closable: idx === 0 ? false : true,
                            children: (
                              <>
                                <div className="widget-content">
                                  {requestData && (
                                    <Vis
                                      data={requestData}
                                      widget={{
                                        visualization: vis,
                                      }}
                                      params={RequestParameters.reduce(
                                        (acc, curr) => {
                                          acc[curr.name] =
                                            curr.value === "d_now"
                                              ? new Date()
                                                  .toISOString()
                                                  .split("T")[0]
                                              : curr.value === "d_yesterday"
                                              ? new Date(Date.now() - 86400000)
                                                  .toISOString()
                                                  .split("T")[0]
                                              : curr.value
                                          return acc
                                        },
                                        {}
                                      )}
                                    />
                                  )}
                                </div>
                                {requestData && (
                                  <Footer
                                    request={memoizedRequest}
                                    data={requestData}
                                    visualization={vis}
                                    RequestParameters={RequestParameters}
                                  />
                                )}
                              </>
                            ),
                          }))}
                      />
                      <FormModal
                        Form={CreateRequestVisualization}
                        hasChanged={(change) => !!change}
                        title={t("requests.visEditor")}
                        width={"97%"}
                        formProps={{
                          memoizedRequest,
                          data: requestData,
                        }}
                        style={{ top: "0.5rem", paddingBottom: "0" }}
                        bodyStyle={{
                          height: "calc(100vh - 80px)",
                          maxHeight: "calc(100vh - 80px)",
                          overflow: "hidden",
                          padding: "1rem",
                        }}
                      >
                        <button ref={buttonRef} style={{ display: "none" }}>
                          + {t("addVis")}
                        </button>
                      </FormModal>
                    </>
                  )}
                </Wrapper>
              </div>
            )}
          </div>
        </QueryEditor>
      </Col>
    </Row>
  )
}

const QueryEditor = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .editor {
    height: 200px;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .actions {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    position: relative;
  }

  .result {
    border-radius: 0.5rem;
    padding: 1rem;

    .parameters {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }

    .errors {
      background-color: #fff2f0;
      p {
        padding: 0.5rem;
        font-weight: 600;
      }
    }

    .queryTimer {
      background-color: #e6f4ff;
      p {
        padding: 0.5rem;
        font-weight: 600;
      }
    }

    .data {
      .ant-tabs-tabpane div {
        position: relative !important;
      }
      .ant-table-wrapper {
        overflow-x: scroll;
      }
    }
  }
`

const Wrapper = styled.div`
  height: 100vh;

  & > .ant-tabs {
    height: 100%;
  }

  & > .ant-tabs-card .ant-tabs-content {
    height: 100%;
    margin-top: -16px;
  }

  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px 16px 0 16px;
    background: #fff;
  }

  .widget-content {
    height: 90%;
    overflow: auto;
    padding: 1rem;

    .widget-chart {
      height: 100%;
    }

    .widget-error {
      background-color: #f77b72;
      color: #fff;
      padding: 0.5rem;
    }
  }
`

const FooterWrapper = styled.div`
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default RequestBody
