import styled from "styled-components"
import Button from "components/shared/Button"
import { useTranslation } from "react-i18next"

function Footer({ onNext, onPrevious, nextButtonProps, ...delegated }) {
  const { t } = useTranslation()
  return (
    <Wrapper {...delegated}>
      {onPrevious ? (
        <Button onClick={onPrevious} type="primary" shape="outlined">
          {t("global.previous")}
        </Button>
      ) : (
        <div></div>
      )}
      {onNext ? (
        <Button onClick={onNext} type="primary" {...nextButtonProps}>
          {t("global.next")}
        </Button>
      ) : (
        <div></div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme }) => theme.mixins.flexSpaceBetween};
  margin: 1rem 0 0;
`

export default Footer
