import { css } from "styled-components"

const variants = {
  color: {
    primary: css`
      --color: var(--color-text-primary);
    `,
    secondary: css`
      --color: var(--color-text-secondary);
    `,
    tertiary: css`
      --color: var(--color-text-tertiary);
    `,
    danger: css`
      --color: var(--color-text-danger);
    `,
    warning: css`
      --color: var(--color-text-warning);
    `,
    success: css`
      --color: var(--color-text-success);
    `,
    "gray-0": css`
      --color: var(--color-gray-0);
    `,
    "gray-1": css`
      --color: var(--color-gray-1);
    `,
    "gray-2": css`
      --color: var(--color-gray-2);
    `,
    brand: css`
      --color: var(--color-brand);
    `,
    blue: css`
      --color: var(--color-blue-2);
    `,
  },
  size: {
    xxs: css`
      --size: var(--fz-xxs);
    `,
    xs: css`
      --size: var(--fz-xs);
    `,
    sm: css`
      --size: var(--fz-sm);
    `,
    md: css`
      --size: var(--fz-md);
    `,
    lg: css`
      --size: var(--fz-lg);
    `,
    xl: css`
      --size: var(--fz-xl);
      font-weight: 600;
    `,
    xxl: css`
      --size: var(--fz-xxl);
    `,
    heading: css`
      --size: var(--fz-heading);
    `,
  },
}

export default variants
