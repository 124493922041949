import { useMutation } from "react-query"

import { forgotPasswors, resetPassword } from "apis/auth"

function useForgetPassword(options = {}) {
  return useMutation(({ org, data }) => forgotPasswors(org, data), {
    ...options,
    onSuccess: (response) => {
      options.onSuccess?.(response)
    },
  })
}

function useResetPassword(options = {}) {
  return useMutation(
    ({ org, token, data }) => resetPassword(org, token, data),
    {
      ...options,
      onSuccess: (response) => {
        options.onSuccess?.(response)
      },
    }
  )
}

export { useForgetPassword, useResetPassword }
