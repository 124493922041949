import React from "react"
import {
  useDeleteQuerySnippets,
  useQuerySnippetsPage,
  useUpdateQuerySnippets,
} from "hooks/queries"
import { Columns } from "./Table.utils"
import useQueryParams from "hooks/useQueryParams"
import { Result, Table } from "antd"
import { useTranslation } from "react-i18next"
import { TableSkeleton } from "components/shared/Table"

const QueryTabel = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: Number,
    page_size: Number,
    q: String,
  })

  const { page = 1, page_size: pageSize = 10, q: name } = queryParams

  const { data, isLoading, isError } = useQuerySnippetsPage({
    page,
    pageSize,
    name,
  })
  const { t } = useTranslation()
  const { mutate: deleteQuery, isLoading: deleteLoading } =
    useDeleteQuerySnippets()

  const onDeleteQuery = (id) => {
    deleteQuery({
      id: id,
    })
  }

  const datasource =
    data && Array.isArray(data)
      ? data?.map((data) => ({
          ...data,
          onDeleteQuery,
          onUpdateQuery: useUpdateQuerySnippets,
        }))
      : []

  const handleChange = ({ current: page, pageSize: page_size }) => {
    setQueryParams({ page, page_size })
  }

  if (isLoading || deleteLoading) {
    return <TableSkeleton columns={Columns()} rowsCount={10} />
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Table
      scroll={{ x: "max-contentmax" }}
      rowKey="id"
      columns={Columns()}
      dataSource={datasource}
      onChange={handleChange}
      pagination={{
        current: page,
        pageSize: pageSize,
        total: data.count,
      }}
    />
  )
}

export default QueryTabel
