import React from "react"
import styled from "styled-components"
import useTypedParams from "hooks/useTypedParams"
import SourceDetails from "components/sources/SourceDetails"
import {
  useDataSource,
  useDeleteSource,
  useTestDataSource,
  useUpdateSource,
} from "hooks/dataSources"
import { Result, Spin, notification } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const Source = () => {
  const { t } = useTranslation()
  const { id } = useTypedParams({ id: Number })
  const { data, isLoading, isError } = useDataSource(id)
  const { mutate, isLoading: updateLoading } = useUpdateSource()
  const { mutate: deleteSource, isLoading: deleteLoading } = useDeleteSource()
  const { mutate: testSource, isLoading: testLoading } = useTestDataSource(id)

  const navigate = useNavigate()

  const onEditSource = (data) => {
    mutate({
      id: data.id,
      data,
    })
  }

  const onDeleteSource = () => {
    deleteSource(
      {
        id: data.id,
      },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  const onTestSource = () => {
    testSource(
      {
        id: data.id,
      },
      {
        onSuccess: (d) => {
          if (d.message === "success") {
            notification.success({
              message: d.message,
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: d.message,
              uration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: (error) => {
          notification.error({
            message: error.message,
            uration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }

  if (isLoading || deleteLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <SourceDetails
        data={data}
        onEditSource={onEditSource}
        onDeleteSource={onDeleteSource}
        onTestSource={onTestSource}
        testLoading={testLoading}
        updateLoading={updateLoading}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: start;
`
const SpinnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Source
