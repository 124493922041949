import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { formatDistanceToNowStrict } from "date-fns"
import { Dropdown, Menu, Modal, Space, Spin, Tabs } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import Text from "components/shared/Text"
import CreateRequestVisualization from "../CreateRequestVisualization"
import AddToDashboard from "./AddToDashboard"
import { Vis } from "@icloudready/idataworkers-visualization"
import { useRequestState } from "../Provider"
import client from "services/client"
import { useTranslation } from "react-i18next"
import { ar, enUS } from "date-fns/locale"
import { useAuth } from "context/AuthContext"
const { confirm } = Modal

const BASE_URL = process.env.REACT_APP_BASE_URL

function DownloadLink({ url, filename, text }) {
  const [isLoading, setIsLoading] = useState(false)

  async function handleClick(e) {
    e.preventDefault()
    setIsLoading(true)

    client
      .get(url, { responseType: "blob" })
      .then((response) => {
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.download = filename
        link.click()
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error downloading file:", error)
        setIsLoading(false)
      })
  }

  return (
    <a href={url} onClick={handleClick}>
      {isLoading ? "Downloading..." : text}
    </a>
  )
}

const EmbedRequest = ({
  onChange,
  onCancel,
  closeModal,
  visualization,
  request,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <h5>{t("global.publicURL")}</h5>
      <div>
        <Paragraph copyable>
          {`${BASE_URL}/embed/query/${request.id}/visualization/${visualization.id}`}
        </Paragraph>
      </div>
      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </div>
  )
}

const Footer = ({ request, data, visualization, RequestParameters }) => {
  const { t, i18n } = useTranslation()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const menu = (
    <Menu>
      <Menu.Item disabled={!IS_ADMIN}>
        <FormModal
          Form={AddToDashboard}
          hasChanged={(value) => !!value}
          title={t("requests.addToDashboard")}
          width={500}
          formProps={{ visualization: visualization }}
        >
          <span>{t("requests.addToDashboard")}</span>
        </FormModal>
      </Menu.Item>
      <Menu.Item>
        <FormModal
          Form={EmbedRequest}
          hasChanged={(change) => !!change}
          title={t("requests.embedRequest")}
          width={500}
          formProps={{ request: request, visualization: visualization }}
        >
          <span>{t("requests.embed")}</span>
        </FormModal>
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.csv`}
          filename={`${request.name}.csv`}
          text={t("global.downloadCSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.tsv`}
          filename={`${request.name}.tsv`}
          text={t("global.downloadTSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${request?.id}/results/${data?.id}.xlsx`}
          filename={`${request.name}.xlsx`}
          text={t("global.downloadExcel")}
        />
      </Menu.Item>
    </Menu>
  )
  return (
    <FooterWrapper>
      <Space>
        <Dropdown overlay={menu} trigger={["click"]} className="export_btn">
          <IconButton type="primary" size="sm" shape="outline">
            <Icon type="verticalDots" />
          </IconButton>
        </Dropdown>
        {IS_ADMIN ? (
          <FormModal
            Form={CreateRequestVisualization}
            hasChanged={(change) => !!change}
            title={t("requests.visEditor")}
            width={"97%"}
            formProps={{
              memoizedRequest: request,
              data,
              edit: true,
              visualization,
              params: RequestParameters.reduce((acc, curr) => {
                acc[curr.name] =
                  curr.value === "d_now"
                    ? new Date().toISOString().split("T")[0]
                    : curr.value === "d_yesterdaqy"
                    ? new Date(Date.now() - 86400000)
                        .toISOString()
                        .split("T")[0]
                    : curr.value
                return acc
              }, {}),
            }}
            style={{ top: "0.5rem", paddingBottom: "0" }}
            bodyStyle={{
              height: "calc(100vh - 80px)",
              maxHeight: "calc(100vh - 80px)",
              overflow: "hidden",
              padding: "1rem",
            }}
          >
            <Button type="text" size="xxs">
              <Icon type="edit" />
              {t("requests.editVis")}
            </Button>
          </FormModal>
        ) : null}
      </Space>
      <Text size="xxs">
        {t("global.refreshed")}{" "}
        <b>
          {formatDistanceToNowStrict(new Date(data?.retrieved_at), {
            addSuffix: true,
            locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
          })}
        </b>
      </Text>
    </FooterWrapper>
  )
}

function RequestVisualizations() {
  const { t } = useTranslation()
  const {
    memoizedRequest,
    onDeleteVis,
    requestData,
    getDataLoading,
    requestError,
    RequestParameters,
  } = useRequestState()
  const buttonRef = useRef(null)
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(+location.hash.substring(1))

  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  useEffect(() => {
    const hash = location.hash
    const active = hash.substring(1) // remove the '#' character
    setActiveKey(+active)
  }, [location])

  const handleTabChange = (key) => {
    setActiveKey(key)
    // update the URL hash value
    window.location.hash = key
  }

  const onEditTab = (targetKey, action) => {
    if (action === "add") {
      buttonRef.current.click()
    } else {
      confirm({
        icon: <ExclamationCircleOutlined />,
        title: t("requests.deleteVis"),
        content: t("requests.deleteConfirm"),
        onOk() {
          onDeleteVis(targetKey, () => {
            setActiveKey(memoizedRequest?.visualizations[0].id)
          })
        },
        onCancel() {},
      })
    }
  }

  if (getDataLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }
  return (
    <Wrapper>
      {memoizedRequest.visualization?.length === 0 ? (
        <Text>{t("requests.noVis")}</Text>
      ) : (
        <>
          <Tabs
            type={requestData && IS_ADMIN ? "editable-card" : "card"}
            activeKey={activeKey}
            onChange={handleTabChange}
            onEdit={onEditTab}
            items={memoizedRequest?.visualizations
              .sort((a, b) => a.id - b.id)
              .map((vis, idx) => ({
                label: vis.name,
                key: vis.id,
                id: vis.id,
                closable: idx === 0 ? false : true,
                children: (
                  <>
                    <div className="widget-content">
                      {requestError && (
                        <p className="widget-error">{requestError}</p>
                      )}
                      {requestData && (
                        <Vis
                          data={requestData}
                          widget={{
                            visualization: vis,
                          }}
                          params={RequestParameters.reduce((acc, curr) => {
                            acc[curr.name] =
                              curr.value === "d_now"
                                ? new Date().toISOString().split("T")[0]
                                : curr.value === "d_yesterdaqy"
                                ? new Date(Date.now() - 86400000)
                                    .toISOString()
                                    .split("T")[0]
                                : curr.value
                            return acc
                          }, {})}
                        />
                      )}
                    </div>
                    {requestData && (
                      <Footer
                        request={memoizedRequest}
                        data={requestData}
                        visualization={vis}
                        RequestParameters={RequestParameters}
                      />
                    )}
                  </>
                ),
              }))}
          />
          <FormModal
            Form={CreateRequestVisualization}
            hasChanged={(change) => !!change}
            title={t("requests.visEditor")}
            width={"97%"}
            formProps={{ memoizedRequest, data: requestData }}
            style={{ top: "0.5rem", paddingBottom: "0" }}
            bodyStyle={{
              height: "calc(100vh - 80px)",
              maxHeight: "calc(100vh - 80px)",
              overflow: "hidden",
              padding: "1rem",
            }}
          >
            <button ref={buttonRef} style={{ display: "none" }}>
              + {t("addVis")}
            </button>
          </FormModal>
        </>
      )}
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
  height: 100vh;

  & > .ant-tabs {
    height: 100%;
  }

  & > .ant-tabs-card .ant-tabs-content {
    height: 100%;
    margin-top: -16px;
  }

  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px 16px 0 16px;
    background: #fff;
  }

  .widget-content {
    height: 90%;
    overflow: auto;
    padding: 1rem;

    .widget-chart {
      height: 100%;
    }

    .widget-error {
      background-color: #f77b72;
      color: #fff;
      padding: 0.5rem;
    }
  }
`

const FooterWrapper = styled.div`
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default RequestVisualizations
