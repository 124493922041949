import React from "react"
import styled from "styled-components"
import NormalStepsBar from "./NormalStepsBar"
import ArrowStepsBar from "./ArrowStepsBar"

function Steps({ step, children, bar = "normal", ...delegated }) {
  const steps = React.useMemo(() => {
    const cache = {}

    React.Children.forEach(children, (child, index) => {
      const { title, description } = child.props
      cache[index] = { title, description }
    })

    return cache
  }, [children])

  let BarComponent

  if (bar === "normal") {
    BarComponent = NormalStepsBar
  } else if (bar === "arrow") {
    BarComponent = ArrowStepsBar
  }

  return (
    <Wrapper {...delegated}>
      <BarComponent steps={Object.values(steps)} step={step} />

      <div className="steps-content">
        {React.Children.map(children, (child, index) =>
          index + 1 === step ? child : undefined
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .steps-bar {
    margin: 0 0 30px;
  }
`

export default Steps
