import React, { useState } from "react"
import styled from "styled-components"
import { useApplications } from "hooks/applications"
import { useTranslation } from "react-i18next"
import { Image, Result, Space, Spin } from "antd"
import Text from "components/shared/Text"
import AppForm from "./AppForm"
import EmptyApps from "static/images/empty/no-apps.svg"
import ApplicationsCardView from "./ApplicationsCardView"
import ApplicationsListView from "./ApplicationsListView"
import ApplicationFilter from "./ApplicationFilter"

const ApplicationsdData = () => {
  const { t } = useTranslation()
  const [layout, setLayout] = useState("list")
  const { data, isLoading, isError } = useApplications()

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data?.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data?.length === 0) {
    return (
      <EmptyWrapper>
        <Space direction="vertical" size={0}>
          <Text size="xl" color="primary">
            {t("home.homeAppPhase1")}
          </Text>
          <Text size="xl" color="brand">
            {t("home.homeAppPhase2")}
          </Text>
        </Space>
        <AppForm />
        <Image
          width={200}
          src={EmptyApps}
          preview={false}
          alt="No Favorite Dashboard Yet!"
        />
      </EmptyWrapper>
    )
  }

  return (
    <Wrapper>
      <ApplicationFilter
        applications={data}
        layout={layout}
        setLayout={setLayout}
      />
      {layout === "card" ? (
        <ApplicationsCardView applications={data} />
      ) : (
        <ApplicationsListView applications={data} />
      )}
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptyWrapper = styled.div`
  /* background-color: #ecf5f8; */
  /* border: 1px solid #F1F1F2; */
  height: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 2rem;

  & .ant-typography,
  .ant-space {
    text-align: center;
  }
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;
`

export default ApplicationsdData
