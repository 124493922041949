import { useAuth } from "context/AuthContext"
import Layout from "components/layout/Layout"
import { Navigate, Outlet } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import GlobalTour from "../GlobalTour/GlobalTour"
import { useDashboards } from "hooks/dashboards"

function PrivateRoute() {
  const { isAuth, tenantName, session } = useAuth()
  const homepage_dashboard_id = session?.user?.homepage_dashboard_id
  const userName = session?.user?.name

  if (isAuth) {
    return (
      <Layout>
        <ErrorBoundary>
          <GlobalTour
            tenantName={tenantName}
            isHomeDashboard={!!homepage_dashboard_id}
            userName={userName}
          />
          <Outlet />
        </ErrorBoundary>
      </Layout>
    )
  }

  return <Navigate to={`/login/${tenantName}`} />
}

export default PrivateRoute
