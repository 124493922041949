import React, { useState } from "react"
import { Form, Select } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"

import { useCreateWidget } from "hooks/dashboards"
import { getDashboardByName } from "apis/dashboards"
import { getWidgetOptions } from "./AddToDashboard.utils"
import { calcVisPosition } from "visualizations/utils/calcVisPosition"
import { t } from "i18next"

const AddToDashboard = ({ onChange, onCancel, closeModal, visualization }) => {
  const [data, setData] = useState([])
  const [dashboard, setDashboard] = useState()

  const createWidget = useCreateWidget()

  const handleSearch = (newValue) => {
    if (newValue) {
      const searchRequest = async () => {
        const d = await getDashboardByName(newValue)
        setData(d.results)
      }
      searchRequest()
    } else {
      setData([])
    }
  }

  const handleChange = (newValue) => {
    setDashboard(data.find((d) => d.id === newValue))
    getWidgetOptions(
      data.find((d) => d.id === newValue),
      visualization
    )
  }

  const onAddDashboaed = () => {
    createWidget.mutate(
      {
        dashboard_id: dashboard.id,
        visualization_id: visualization.id,
        text: "",
        width: 1,
        options: {
          parameterMappings: {},
          isHidden: false,
          position: calcVisPosition(visualization, dashboard),
        },
      },
      {
        onSuccess: (newWidget) => {
          if (newWidget) {
            closeModal()
          }
        },
      }
    )
  }

  React.useEffect(() => onChange(dashboard), [dashboard, onChange])
  return (
    <Form layout="vertical">
      <Form.Item label={t("requests.chooseDashboard")}>
        <Select
          placeholder={t("requests.searchDashboards")}
          allowClear
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          options={(data || []).map((d) => ({
            value: d.id,
            label: d.name,
          }))}
        />
      </Form.Item>
      <FormModal.Footer>
        <Button
          type="secondary"
          onClick={onAddDashboaed}
          loading={createWidget.isLoading}
          disabled={createWidget.isLoading || !dashboard}
        >
          {t("global.save")}
        </Button>

        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

export default AddToDashboard
