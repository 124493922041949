import styled from "styled-components"
import { Button as AntButton } from "antd"
import variants from "./Button.styles"
import { extract } from "lib/utils"

// Using the same antd props for convenience
const antKeys = ["type", "shape", "size"]

const Button = styled((props) => {
  return <AntButton {...extract(props, antKeys)} />
})`
  ${({ size = "md" }) => variants.size[size]};
  ${({ type = "primary" }) => variants.type[type]};
  ${({ shape = "contained" }) => variants.shape[shape]};

  cursor: pointer;
  transition: all 0.3s;
  line-height: 0;
  background: var(--background);
  color: var(--color);

  &:hover,
  &:focus,
  &:active {
    background: var(--background);
    color: var(--color);
  }
`

export default Button
