import { Skeleton } from "antd"
import { useDashboardByTags } from "hooks/dashboards"
import { useTranslation } from "react-i18next"

const DashboardCount = ({ tag, color }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useDashboardByTags(tag)

  if (isLoading) {
    return (
      <Skeleton
        paragraph={{
          rows: 0,
        }}
      />
    )
  }

  return (
    <h4 className="count" style={{ color: color ? color : "" }}>
      {t("applications.applicationsNumber", { value: data?.count || 0 })}
    </h4>
  )
}

export default DashboardCount
