import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import qs from "query-string"

// A similar implementation for https://www.npmjs.com/package/use-query-params
// as its not supported in react-router v6

function useQueryParams(params) {
  const { search } = useLocation()
  const navigate = useNavigate()

  const queryParams = React.useMemo(() => {
    const parsed = qs.parse(search)
    let result = {}

    for (let [key, Type] of Object.entries(params)) {
      result[key] = parsed[key] && Type(parsed[key])
    }

    return result
  }, [search])

  const setQueryParams = React.useCallback(
    (params, action) => {
      const parsed = qs.parse(search)

      if (action === "delete") {
        for (let key of Object.keys(params)) {
          delete parsed[key]
        }
      } else {
        for (let key of Object.keys(params)) {
          parsed[key] = params[key]
        }
      }

      navigate({
        search: qs.stringify(parsed),
      })
    },
    [search]
  )

  return [queryParams, setQueryParams]
}

export default useQueryParams
