import client from "services/client"

async function getApplications() {
  const res = await client.get("/application")

  return res.data
}

async function createApplications(data) {
  const res = await client.post("/application", data)

  return res.data
}

async function deleteApplications(id) {
  const res = await client.delete(`/application/${id}`)
  return res
}

async function updateApplications(id, data) {
  const res = await client.post(`/application/${id}`, data)
  return res.data
}

export {
  getApplications,
  createApplications,
  deleteApplications,
  updateApplications,
}
