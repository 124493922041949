import { Result, Space, Spin, Table } from "antd"
import Text from "components/shared/Text"
import { format } from "date-fns"
import { useWareHouse } from "hooks/warehouse"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

function formatDate(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm")
}

const WarehouseList = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useWareHouse()

  const columns = [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "dbname",
    },
    {
      title: t("global.tabels.host"),
      key: "host",
      dataIndex: "host",
    },
    {
      title: t("global.tabels.user"),
      key: "user",
      dataIndex: "user",
    },
    {
      title: t("global.tabels.port"),
      key: "port",
      dataIndex: "port",
    },
    {
      title: t("global.tabels.createdAt"),
      key: "created_at",
      dataIndex: "created_at",
      render: (created_at) => <Text size="sm">{formatDate(created_at)}</Text>,
    },
    {
      title: t("global.tabels.updateAt"),
      key: "updated_at",
      dataIndex: "updated_at",
      render: (updated_at) => <Text size="sm">{formatDate(updated_at)}</Text>,
    },
  ]

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  if (isError || data?.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <Space direction="vertical" size={0}>
          <h2>
            {t("warehouse.allWarehouse")}
            {": "}{" "}
            <span>
              {data?.warehouse?.length} {t("warehouse.warehouse")}
            </span>
          </h2>
        </Space>
      </div>
      <Table
        scroll={{ x: "1000px" }}
        rowKey={(c) => c.id}
        columns={columns}
        dataSource={data.warehouse}
      />
    </Wrapper>
  )
}

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  margin: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 14px;

  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
`

export default WarehouseList
