import Text from "components/shared/Text"
import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import * as Col from "../requests/Table/Columns"
import { Table } from "antd"

function formatDate(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm")
}

const Columns = () => {
  const { t } = useTranslation()
  return [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
      render: (_, request) => <Col.Name request={request} />,
    },
    {
      title: t("global.tabels.createdBy"),
      key: "created_by",
      dataIndex: "user",
      render: (_, { user }) => <Text size="sm">{user.name}</Text>,
    },
    {
      title: t("global.tabels.createdAt"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => (
        <Text size="sm">{formatDate(created_at)}</Text>
      ),
    },
    {
      title: t("global.tabels.lastExecuted"),
      key: "updated_at",
      dataIndex: "updated_at",
      render: (_, { retrieved_at }) => (
        <Text size="sm">{retrieved_at ? formatDate(retrieved_at) : ""}</Text>
      ),
    },
  ]
}

const QueryOutdated = ({ status }) => {
  return (
    <div>
      <Table
        scroll={{ x: "max-contentmax" }}
        size="sm"
        rowKey="id"
        columns={Columns()}
        dataSource={status.queries}
        // pagination={false}
      />
    </div>
  )
}

export default QueryOutdated
