import React from "react"
import styled from "styled-components"
import { Divider, Form, Input, Select, Spin, Tag, Tooltip, message } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import {
  useInviteUser,
  useRegenerateApi,
  useResetPassword,
  useUpdateUser,
  useUser,
} from "hooks/users"
import Text from "components/shared/Text"
import Icon from "components/shared/Icon"
import ResetPassword from "../ResetPassword"
import { useTranslation } from "react-i18next"

const UpdateUser = (props) => {
  const { onChange, id, user, allGroups, onEnableUser } = props

  const { t } = useTranslation()
  const { data, isLoading } = useUser(id)
  const { mutate: update, isLoading: updateLoading } = useUpdateUser()
  const { mutate: generateApi, isLoading: generateApiLoading } =
    useRegenerateApi()
  const { mutate: reset, isLoading: resetLoading } = useResetPassword()
  const { mutate: invite, isLoading: inviteLoading } = useInviteUser()

  React.useEffect(() => {
    onChange(id)
  }, [id])

  const onFinish = (value) => {
    update(
      {
        id: data?.id,
        data: value,
      },
      {
        onSuccess: (result) => {
          message.success({
            content: t("users.userUpdated"),
          })
        },
        onError: () => {
          message.error({
            content: t("users.userNotUpdated"),
          })
        },
      }
    )
  }

  const onRegenerateApi = () => {
    generateApi(
      {
        id: data?.id,
      },
      {
        onSuccess: (success) => {
          message.success({
            content: t("account.regenerateKeySucces"),
          })
        },
        onError: () => {
          message.error({
            content: t("account.regenerateKeyFaild"),
          })
        },
      }
    )
  }

  const onResetPassword = () => {
    reset(
      {
        id: data?.id,
      },
      {
        onSuccess: (success) => {
          message.success({
            content: t("users.passSent"),
          })
        },
        onError: () => {
          message.error({
            content: t("users.passNotSent"),
          })
        },
      }
    )
  }

  const onInviteUser = () => {
    invite(
      {
        id: data?.id,
      },
      {
        onSuccess: (success) => {
          message.success({
            content: t("users.invitSent"),
          })
        },
        onError: () => {
          message.error({
            content: t("users.invitNotSent"),
          })
        },
      }
    )
  }

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  return (
    <Details>
      <div className="header">
        <img src={data?.profile_image_url} alt={data?.name} />
        <Text size="md">{data?.name}</Text>
      </div>
      <Divider />
      <Form
        layout="vertical"
        className="form"
        size="small"
        onFinish={onFinish}
        initialValues={{
          ...data,
          group_ids: data?.groups,
        }}
      >
        <Form.Item
          label={t("global.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("users.pleaseInputName"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("global.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("users.pleaseInputEmail"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("global.tabels.groups")}
          name="group_ids"
          rules={[
            {
              required: user.id === id || data?.is_disabled ? false : true,
              message: t("users.pleaseInputGroups"),
            },
          ]}
        >
          {user.id === id || data?.is_disabled ? (
            allGroups?.map((group) => {
              if (data?.groups.includes(group.id)) {
                return (
                  <Tag key={group.id}>
                    <small>{group?.name}</small>
                  </Tag>
                )
              }
            })
          ) : (
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder={t("users.pleaseInputGroups")}
              options={allGroups?.map((g) => ({ label: g.name, value: g.id }))}
            />
          )}
        </Form.Item>
        {data?.is_disabled ? null : (
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100%" }}
              disable={updateLoading}
              loading={updateLoading}
            >
              {t("global.save")}
            </Button>
          </Form.Item>
        )}
        {!data?.is_disabled ? (
          <>
            <Divider />
            <Form className="form">
              <Form.Item label={t("requests.apiKey")}>
                <Input.Group compact>
                  <Input
                    style={{
                      width: "calc(100% - 60px)",
                    }}
                    value={data?.api_key}
                    disabled
                  />
                  <Tooltip title={t("users.copyApi")}>
                    <Button
                      size="sm"
                      type="primary"
                      style={{ width: "20px" }}
                      icon={<Icon type="copy" />}
                      onClick={navigator.clipboard.writeText(data?.api_key)}
                    />
                  </Tooltip>
                </Input.Group>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => onRegenerateApi(id)}
                  type="primary"
                  disable={generateApiLoading}
                  loading={generateApiLoading}
                >
                  {t("users.regenerate")}
                </Button>
              </Form.Item>
            </Form>
            <Divider />
            <Form layout="vertical" className="form">
              <Form.Item label={t("global.password")}>
                {user?.id === id ? (
                  <FormModal
                    title={t("users.resetPass")}
                    width={400}
                    Form={ResetPassword}
                    hasChanged={({ updateLoading }) => !!updateLoading}
                    formProps={{ id: data?.id }}
                  >
                    <Button style={{ width: "100%" }} type="primary">
                      {t("users.changePass")}
                    </Button>
                  </FormModal>
                ) : data?.is_invitation_pending ? (
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => onInviteUser(id)}
                    type="primary"
                    loading={inviteLoading}
                    disable={inviteLoading}
                  >
                    {t("users.resendInvitation")}
                  </Button>
                ) : (
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => onResetPassword(id)}
                    type="primary"
                    loading={resetLoading}
                    disable={resetLoading}
                  >
                    {t("users.sendResetPassEmail")}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </>
        ) : (
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => onEnableUser(id)}
          >
            {t("users.enableUser")}
          </Button>
        )}
      </Form>
    </Details>
  )
}

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Details = styled.div`
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 40px;
      border-radius: 50%;
    }
  }

  .form {
    width: 400px;
  }

  .footer-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export default UpdateUser
