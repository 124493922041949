import React, { useState } from "react"
import SourceConfig from "./SourceConfig"
import DestinationConfig from "./DestinationConfig"
import ConnectionsConfig from "./ConnectionsConfig"

const SelectedStep = ({ step, onChangeStep }) => {
  const [sourceData, setSourceData] = useState(undefined)
  const [destinationData, setDestinationData] = useState(undefined)

  const nextStep = () => {
    onChangeStep((prev) => prev + 1)
  }

  const prevStep = () => {
    onChangeStep((prev) => prev - 1)
  }

  const handleSourceData = (data) => {
    setSourceData(data)
    nextStep()
  }

  const handleDestinationData = (data) => {
    setDestinationData(data)
    nextStep()
  }

  return (
    <div>
      {step === 0 && <SourceConfig onNext={handleSourceData} />}
      {step === 1 && (
        <DestinationConfig onNext={handleDestinationData} onPrev={prevStep} />
      )}
      {step === 2 && (
        <ConnectionsConfig
          sourceData={sourceData}
          destinationData={destinationData}
          onPrev={prevStep}
        />
      )}
    </div>
  )
}

export default SelectedStep
