import React from "react"
import styled, { keyframes } from "styled-components"
import headerLogo from "static/images/headerlogo.svg"
import NewLogo from "static/images/NewiDataWorkeroLogo.svg"

const AnimatedLogo = () => {
  return (
    <Container>
      <img src={NewLogo} alt="idataworkers logo" width={200} />
    </Container>
  )
}

const bounceLogo = keyframes`
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
   opacity: 0;
 }
 38% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
   opacity: 1;
 }
 55% {
   -webkit-transform: scale(0.7);
           transform: scale(0.7);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 72% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
 81% {
   -webkit-transform: scale(0.84);
           transform: scale(0.84);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 89% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
 95% {
   -webkit-transform: scale(0.95);
           transform: scale(0.95);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
}
@keyframes bounce-in-fwd {
 0% {
   -webkit-transform: scale(0);
           transform: scale(0);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
   opacity: 0;
 }
 38% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
   opacity: 1;
 }
 55% {
   -webkit-transform: scale(0.7);
           transform: scale(0.7);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 72% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
 81% {
   -webkit-transform: scale(0.84);
           transform: scale(0.84);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 89% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
 95% {
   -webkit-transform: scale(0.95);
           transform: scale(0.95);
   -webkit-animation-timing-function: ease-in;
           animation-timing-function: ease-in;
 }
 100% {
   -webkit-transform: scale(1);
           transform: scale(1);
   -webkit-animation-timing-function: ease-out;
           animation-timing-function: ease-out;
 }
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    animation: ${bounceLogo} 1.1s linear both;
  }
`

export default AnimatedLogo
