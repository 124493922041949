import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Checkbox,
  Form,
  Select,
  Input,
  Row,
  Col,
  Divider,
  notification,
  Image,
  Space,
} from "antd"
import Button from "components/shared/Button"
import { useUpdateOrganization } from "hooks/settings"
import Text from "components/shared/Text"
import { useTranslation } from "react-i18next"
import FormModal from "components/shared/FormModal"
import { useUpdateCurrentOrganizationLogo } from "hooks/admin"

const dataFormat = [
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    label: "DD/MM/YY",
    value: "DD/MM/YY",
  },
  {
    label: "MM/DD/YY",
    value: "MM/DD/YY",
  },
]

const timeFormat = [
  {
    label: "HH:mm:ss",
    value: "HH:mm:ss",
  },
  {
    label: "HH:mm:ss:SSS",
    value: "HH:mm:ss:SSS",
  },
  {
    label: "HH:mm",
    value: "HH:mm",
  },
]

function EditOrganizationLogo({ onChange, onCancel, closeModal, org }) {
  const { t } = useTranslation()
  const [logoUrl, setLogoUrl] = useState(org.logo)
  const { mutate, isLoading } = useUpdateCurrentOrganizationLogo()

  const handelUpdateLogo = (value) => {
    mutate(
      {
        data: value,
      },
      {
        onSuccess: closeModal,
      }
    )
  }

  useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  return (
    <Form
      layout="vertical"
      initialValues={{ ...org }}
      onFinish={handelUpdateLogo}
    >
      <Form.Item label={t("general.logoUrl")} name="logo">
        <Input onChange={(e) => setLogoUrl(e.target.value)} />
      </Form.Item>
      <Form.Item label={t("general.logoImage")}>
        <Image src={logoUrl} width={200} height={60} />
      </Form.Item>
      <FormModal.Footer>
        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
        <Button
          htmlType="submit"
          type="secondary"
          loading={isLoading}
          disabled={isLoading}
        >
          {t("global.save")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

const GeneralForm = ({ data, info }) => {
  const { t } = useTranslation()
  const [enableSaml, setEnableSaml] = useState(data?.auth_saml_enabled)
  const { mutate, isLoading } = useUpdateOrganization()
  const onFinish = (value) => {
    mutate(
      {
        data: value,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("general.dataUpdated"),
            placement: "topRight",
          })
        },
        onError: (error) => {
          notification.error({
            message: error.message,
            placement: "topRight",
          })
        },
      }
    )
  }
  return (
    <Form
      className="form"
      onFinish={onFinish}
      layout="vertical"
      initialValues={data}
    >
      <Div>
        <Space direction="vertical">
          <Text size="xl">{t("settings.profile.title")}</Text>
          <Text size="xs" color="gray-1">
            {t("settings.profile.desc")}
          </Text>
        </Space>
        <Divider />
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Form.Item label={t("general.orgName")}>
              <Text>{info?.organization?.name}</Text>
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t("general.orgSlug")}>
              <Text>{info?.organization?.slug}</Text>
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              label={t("general.orgLogo")}
              extra={
                <FormModal
                  title={t("general.editLogo")}
                  width={600}
                  hasChanged={({ isLoading }) => !!isLoading}
                  Form={EditOrganizationLogo}
                  formProps={{ org: info?.organization }}
                >
                  <Button
                    size="sm"
                    type="primary"
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    {t("general.editLogo")}
                  </Button>
                </FormModal>
              }
            >
              <Image
                src={info?.organization?.logo}
                width={200}
                height={60}
                preview={false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Form.Item
              label={t("general.dateFormat")}
              name="date_format"
              colon={false}
            >
              <Select options={dataFormat} />
            </Form.Item>
            <Form.Item label={t("general.chartVis")} className="column">
              <Form.Item name="hide_plotly_mode_bar" valuePropName="checked">
                <Checkbox>{t("general.hidePlotly")}</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item label={t("general.anonymous")}>
              <Form.Item name="beacon_consent" valuePropName="checked">
                <Checkbox>{t("general.helpIdataworkers")}</Checkbox>
              </Form.Item>
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item label={t("general.timeFormat")} name="time_format">
              <Select options={timeFormat} />
            </Form.Item>
            <Form.Item label={t("general.featureFlags")}>
              <div className="column">
                <Form.Item
                  name="feature_show_permissions_control"
                  valuePropName="checked"
                >
                  <Checkbox>
                    {t("general.feature_show_permissions_control")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="send_email_on_failed_scheduled_queries"
                  valuePropName="checked"
                >
                  <Checkbox>
                    {t("general.send_email_on_failed_scheduled_queries")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="multi_byte_search_enabled"
                  valuePropName="checked"
                >
                  <Checkbox>{t("general.multi_byte_search_enabled")}</Checkbox>
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Div>
      <Div>
        <Text size="heading">{t("general.auth")}</Text>
        <Divider />
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Form.Item label={t("general.passLogin")}>
              <Form.Item
                name="auth_password_login_enabled"
                valuePropName="checked"
              >
                <Checkbox>{t("general.auth_password_login_enabled")}</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item label="SAML">
              <Form.Item name="auth_saml_enabled" valuePropName="checked">
                <Checkbox onChange={() => setEnableSaml((c) => !c)}>
                  {t("general.auth_saml_enabled")}
                </Checkbox>
              </Form.Item>
              <Form.Item hidden={!enableSaml}>
                <Form.Item
                  name="auth_saml_metadata_url"
                  label={t("general.auth_saml_metadata_url")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="auth_saml_entity_id"
                  label={t("general.auth_saml_entity_id")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="auth_saml_nameid_format"
                  label={t("general.auth_saml_nameid_format")}
                >
                  <Input />
                </Form.Item>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item label={t("general.googleLogin")}>
              <Form.Item
                name="auth_google_apps_domains"
                label={t("general.auth_google_apps_domains")}
              >
                <Select
                  mode="tags"
                  allowClear
                  options={data?.auth_google_apps_domains.map((domain) => ({
                    value: domain,
                    label: domain,
                  }))}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                disable={isLoading}
                loading={isLoading}
              >
                {t("global.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Div>
    </Form>
  )
}

const Div = styled.div``

export default GeneralForm
