import React from "react"
import { serialize } from "lib/utils"

function useObjectMemo(object) {
  const ref = React.useRef(object)

  if (serialize(ref.current) !== serialize(object)) {
    ref.current = object
  }

  return { value: ref.current, ref }
}

export default useObjectMemo
