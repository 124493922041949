import client from "services/client"

async function getDestinations() {
  const res = await client.get("/destinations")
  return res.data
}

async function getDestination(id) {
  const res = await client.get(`/destinations/${id}`)
  return res.data
}

async function createDestination(data) {
  const res = await client.post("/destinations", data)

  if (res.status === 400) {
    return data
  }

  return res.data
}

async function updateDestination(id, data) {
  const res = await client.post(`/destinations/${id}`, data)
  return res.data
}

async function deleteDestination(id) {
  const res = await client.delete(`/destinations/${id}`)
  return res.data
}

async function getDestinationTypes() {
  const res = await client.get("/destinations/types")
  return res.data
}

export {
  getDestinations,
  getDestination,
  createDestination,
  updateDestination,
  deleteDestination,
  getDestinationTypes,
}
