import React, { useState } from "react"
import { Button, Space, Table, Tag, Tooltip } from "antd"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Icon from "components/shared/Icon"
import { TableSkeleton } from "components/shared/Table"
import { format } from "date-fns"
import { useUpdateFavoriteDashboard } from "hooks/dashboards"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const DashboardListView = ({
  dashboard,
  queryParams,
  onParamsChange,
  loading,
  homeDashboardId,
}) => {
  const { t } = useTranslation()
  const { mutate } = useUpdateFavoriteDashboard()
  const [dashboardLoadingId, setDashboardLoaingId] = useState(undefined)

  const columns = [
    {
      title: t("global.tabels.name"),
      dataIndex: "name",
      key: "name",
      render: (_, { id, name }) => {
        return (
          <Space>
            <Icon
              type="dashboardIcon"
              className="dashboardIcon"
              style={{ minWidth: "40px" }}
            />
            <CustomLink to={`/dashboard/${id}`}>
              <div className="dashboardTitle">
                <span className="dashboardName">{name}</span>
                <span className="dashboardDesc">
                  {t("dashboards.dashboard")}
                </span>
              </div>
            </CustomLink>
          </Space>
        )
      },
    },
    {
      title: t("global.tabels.createdBy"),
      dataIndex: "user",
      key: "user",
      render: (_, { user }) => <p>{user.name}</p>,
      width: "15%",
    },
    {
      title: t("global.tabels.createdAt"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) => (
        <p>{format(new Date(created_at), "dd/MM/yyyy hh:mm")}</p>
      ),
      width: "15%",
    },
    {
      title: t("global.tabels.tagged"),
      key: "",
      render: (_, { tags }) =>
        tags?.map((tag, i) => (
          <Tooltip title={tag} key={i}>
            <Tag
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {tag}
            </Tag>
          </Tooltip>
        )),
      width: "15%",
    },
    {
      key: "actions",
      width: "5%",
      render: (_, dashboard) => {
        const { id, is_favorite: isFavorite } = dashboard
        const icon = isFavorite ? "starFilled" : "starOutlined"
        const loading = dashboardLoadingId === id

        const handleClick = () => {
          setDashboardLoaingId(id)
          mutate(dashboard, {
            onSettled: () => {
              setDashboardLoaingId(undefined)
            },
          })
        }
        return (
          <Button
            key={id}
            type="text"
            shape="circle"
            loading={loading}
            disabled={loading}
            icon={<Icon type={icon} style={{ color: "#fbd208" }} />}
            onClick={handleClick}
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      {loading ? (
        <TableSkeleton columns={columns} />
      ) : (
        <Table
          scroll={{ x: 800 }}
          size="sm"
          rowKey="id"
          dataSource={dashboard?.results?.filter(
            (d) => d.id !== +homeDashboardId
          )}
          columns={columns}
          onChange={onParamsChange}
          pagination={{
            current: queryParams?.page,
            pageSize: queryParams?.page_size,
            total: dashboard?.count,
            showSizeChanger: true,
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  .dashboardIcon {
    font-size: 1.8rem;
  }

  .dashboardTitle {
    display: flex;
    flex-direction: column;

    .dashboardName {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .dashboardDesc {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }
`

export default DashboardListView
