import React, { useEffect, useState } from "react"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { Form, Input, Popover, Radio, Select, Space, Table } from "antd"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import { useUpdateWidget } from "hooks/dashboards"
import { useDashboardState } from "../Provider"
import { useTranslation } from "react-i18next"

const EditParamSource = ({ param, dashboardParameters, onChangeParams }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(param)
  const [source, setSource] = useState(value.source)
  const [open, setOpen] = useState(false)

  const [form] = Form.useForm()

  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const onFinish = (v) => {
    if (v.key) {
      onChangeParams({ ...value, source: v.source, mapTo: v.key })
    } else {
      onChangeParams({ ...value, source: v.source })
    }
    setOpen(false)
  }

  return (
    <Popover
      title={t("dashboards.editSource_value")}
      trigger="click"
      placement="left"
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <Form
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={{
            source: value.source,
            // key: value.name,
          }}
        >
          <Form.Item label={t("dashboards.sources")} name="source">
            <Radio.Group onChange={(e) => setSource(e.target.value)}>
              <Space direction="vertical">
                <Radio value="new-dashboard-level">
                  {t("dashboards.newParameters")}
                </Radio>
                <Radio
                  value="dashboard-level"
                  disabled={dashboardParameters.length === 0}
                >
                  {t("dashboards.existingParameters")}
                </Radio>
                <Radio value="widget-level">
                  {t("dashboards.widgetParameters")}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {source !== "widget-level" ? (
            source === "new-dashboard-level" ? (
              <Form.Item
                label={t("dashboards.key")}
                name="key"
                initialValue={value.name}
                rules={[
                  {
                    required: true,
                    message: t("dashboards.pleaseInputParameter"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!dashboardParameters.some((d) => d.name === value)) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(t("dashboards.parameterexist"))
                      )
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                label={t("dashboards.key")}
                name="key"
                rules={[
                  {
                    required: true,
                    message: t("dashboards.pleaseSelectParameter"),
                  },
                ]}
              >
                <Select
                  options={dashboardParameters.map((d) => ({
                    value: d.name,
                    label: d.title,
                  }))}
                  placeholder="Please Select Parameter"
                />
              </Form.Item>
            )
          ) : null}
          <Form.Item>
            <FormModal.Footer>
              <Button onClick={hide} type="blue" size="sm">
                {t("global.cancel")}
              </Button>
              <Button htmlType="submit" type="blue" shape="outlined" size="sm">
                {t("global.save")}
              </Button>
            </FormModal.Footer>
          </Form.Item>
        </Form>
      }
    >
      <IconButton type="text" size="sm">
        <Icon type="edit" />
      </IconButton>
    </Popover>
  )
}

function EditWidgetParametersForm({
  onChange,
  onCancel,
  closeModal,
  parameters,
  widgetParam,
  dashboardParameters,
  widget,
}) {
  const { t } = useTranslation()
  const { onUpdateWidget } = useDashboardState()
  const [params, setParams] = useState(
    parameters.map((p) => ({
      ...p,
      source:
        widgetParam && widgetParam.find((v) => v.name === p.name)
          ? "widget-level"
          : "dashboard-level",
    }))
  )

  const { mutate, isLoading } = useUpdateWidget()

  const onChangeParams = (value) => {
    const newParams = params.map((p) => {
      if (p.name === value.name) {
        return {
          ...value,
          source:
            value.source === "new-dashboard-level"
              ? "dashboard-level"
              : value.source,
        }
      }
      return p
    })
    setParams(newParams)
  }

  useEffect(() => {
    onChange(params)
  }, [params, onChange])

  const handelSaveConfirm = (e) => {
    e.preventDefault()
    mutate(
      {
        id: widget.id,
        data: {
          dashboard_id: widget.dashboard_id,
          id: widget.id,
          text: "",
          widget: 1,
          options: {
            ...widget.options,
            parameterMappings: {
              ...params.reduce(
                (obj, item) =>
                  Object.assign(obj, {
                    [item.name]: {
                      mapTo: item.mapTo,
                      name: item.name,
                      title: "",
                      type: item.source,
                      value: null,
                    },
                  }),
                {}
              ),
            },
          },
        },
      },
      {
        onSuccess: (newWidget) => {
          onUpdateWidget(widget.id, newWidget)
          closeModal()
        },
      }
    )
  }

  const columns = [
    {
      title: t("global.tabels.title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("global.tabels.keyword"),
      dataIndex: "name",
      key: "name",
      render: (text) => `{{ ${text} }}`,
    },
    {
      title: t("global.tabels.defaultValue"),
      dataIndex: "value",
      key: "value",
    },
    {
      title: t("global.tabels.valueSource"),
      dataIndex: "source",
      key: "source",
      render: (text, value) => {
        return (
          <Space>
            {text}
            <EditParamSource
              param={value}
              dashboardParameters={dashboardParameters}
              onChangeParams={onChangeParams}
            />
          </Space>
        )
      },
    },
  ]

  return (
    <Form>
      <Table dataSource={params} columns={columns} pagination={false} />
      <FormModal.Footer>
        <Button
          type="blue"
          shape="outlined"
          onClick={handelSaveConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("global.save")}
        </Button>

        <Button type="cancel" onClick={onCancel}>
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

export default EditWidgetParametersForm
