import { Menu } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { DEFAULT_NAV_LINKS, Admin_NAV_LINKS } from "./Navigation.constants"
import auth from "services/auth"
import React from "react"
import styled from "styled-components"

function DefaultNavigation({ IS_ADMIN, IS_SUPER_ADMIN, USER }) {
  const { pathname } = useLocation()
  const IS_SUPER_ADMIN_TENANT =
    process.env.REACT_APP_SUPER_ADMIN_TENANT === auth.getTenantName()
  const navigate = useNavigate()

  const MENU_ITEMS = IS_ADMIN
    ? Admin_NAV_LINKS(IS_SUPER_ADMIN_TENANT)
    : DEFAULT_NAV_LINKS()

  const items = MENU_ITEMS.map((item) => ({
    label: item.label,
    key: item.key,
    icon: item.icon,
    children: item.children,
    onClick: () => {
      if (!item.children) {
        navigate(item.href)
      }
    },
  }))

  return (
    <>
      <MenuWrapper
        mode="inline"
        selectedKeys={[pathname]}
        className="menu"
        items={items}
      />
    </>
  )
}

const MenuWrapper = styled(Menu)`
  & .ant-avatar {
    width: 20px !important;
  }

  & .ant-menu-title-content {
    margin-left: 8px !important;
  }
`

export default DefaultNavigation
