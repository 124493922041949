export const calcVisPosition = (visualization, dashboardWidgets) => {
  // #1 Initialize some variables to store the current column, row, max row, size_x and size_y
  let currentCol = 0
  let currentRow = 0
  const SIZE_X = visualization?.type
    ? visualization?.type === "COUNTER"
      ? 2
      : 3
    : 2
  const SIZE_Y = visualization?.type
    ? visualization?.type === "COUNTER"
      ? 4
      : 10
    : 3
  const MAX_COL = 6

  if (dashboardWidgets?.length) {
    // GET LAST WIDGET IN DASHBOARD
    let maxRowWidget = dashboardWidgets.reduce((prev, current) => {
      return prev.options.position.row > current.options.position.row
        ? prev
        : current
    })
    let lastWidgetCol = maxRowWidget.options.position.col
    let lastWidgetRow = maxRowWidget.options.position.row
    let lastWidgetSizeX = maxRowWidget.options.position.sizeX
    let lastWidgetSizeY = maxRowWidget.options.position.sizeY

    // CODE TO CALCULATE CURRENTCOL AND CURRENTROW FOR NEW WIDGET POSITION
    const availableCols = MAX_COL - (lastWidgetCol + lastWidgetSizeX)
    if (availableCols >= SIZE_X) {
      currentCol = lastWidgetCol + lastWidgetSizeX
      currentRow = lastWidgetRow
      // Check if the new widget would overlap with any existing widgets
      for (let i = 0; i < dashboardWidgets.length; i++) {
        const widget = dashboardWidgets[i]
        const widgetCol = widget.options.position.col
        const widgetRow = widget.options.position.row
        const widgetSizeX = widget.options.position.sizeX
        const widgetSizeY = widget.options.position.sizeY
        // Check if the new widget would overlap with this widget
        if (
          widgetCol <= currentCol + SIZE_X - 1 &&
          currentCol <= widgetCol + widgetSizeX - 1 &&
          widgetRow <= currentRow + SIZE_Y - 1 &&
          currentRow <= widgetRow + widgetSizeY - 1
        ) {
          // If there is overlap, move to the next row
          currentRow = widgetRow + widgetSizeY
          // Check if there is enough space in this row for the new widget
          if (currentCol + SIZE_X > MAX_COL) {
            // If not, move to the next row
            currentRow += 1
            currentCol = 0
          }
          // If wehave found a suitable position, break out of the loop
          break
        }
      }
    } else {
      // If there is not enough space in the current row, move to the next row
      currentRow = lastWidgetRow + lastWidgetSizeY
      // Check if there is enough space in this row for the new widget
      if (currentCol + SIZE_X > MAX_COL) {
        // If not, move to the next row
        currentRow += 1
        currentCol = 0
      }
    }
  }

  // Return object with position and size properties
  return {
    autoHeight: false,
    sizeX: SIZE_X,
    sizeY: SIZE_Y,
    minSizeX: 1,
    maxSizeX: MAX_COL,
    minSizeY: 2,
    maxSizeY: 1000,
    col: currentCol,
    row: currentRow,
  }
}
