import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { DndContext, closestCenter } from "@dnd-kit/core"
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import Icon from "components/shared/Icon"
import IconButton from "components/shared/IconButton"
import { Checkbox, Form, Input, Radio, Select, Space, Tooltip } from "antd"
import { useTranslation } from "react-i18next"

const Column = ({ column, visOptions, onOptionsChange }) => {
  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.order })
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  return (
    <Container ref={setNodeRef} style={style}>
      <dt className="columnTitle">
        <Space align="center">
          <IconButton
            {...attributes}
            {...listeners}
            type="transparent"
            size="xs"
          >
            <Icon type="holder" />
          </IconButton>
          <span>{column?.name}</span>
        </Space>
        <Space>
          <IconButton
            type="transparent"
            saie="sm"
            onClick={(e) => {
              e.stopPropagation()
              const updatedColumns = [...visOptions.columns]
              const columnIndex = updatedColumns.findIndex(
                (col) => col.order === column.order
              )

              if (columnIndex !== -1) {
                const updatedCol = {
                  ...updatedColumns[columnIndex],
                  visible: !updatedColumns[columnIndex]?.visible,
                }

                updatedColumns[columnIndex] = updatedCol
              }
              onOptionsChange({
                ...visOptions,
                columns: updatedColumns,
              })
            }}
          >
            <Icon type={column?.visible ? "eyeOpen" : "eyeClose"} />
          </IconButton>
          <IconButton type="transparent" saie="sm" onClick={toggle}>
            <Icon type={isOpen ? "downArrow" : "rightArrow"} />
          </IconButton>
        </Space>
      </dt>
      <dd className={`${!isOpen ? "hidden" : undefined} columnBody`}>
        <Form.Item>
          <Input.Group>
            <Input
              style={{
                width: "75%",
              }}
              value={column?.title}
              onChange={(e) => {
                const updatedColumns = [...visOptions.columns]
                const updatedCol = {
                  ...column,
                  title: e.target.value,
                }
                const columnIndex = updatedColumns.findIndex(
                  (col) => col.order === column.order
                )
                updatedColumns[columnIndex] = updatedCol
                onOptionsChange({
                  ...visOptions,
                  columns: updatedColumns,
                })
              }}
            />
            <Radio.Group
              value={column?.alignContent}
              onChange={(e) => {
                const updatedColumns = [...visOptions.columns]
                const updatedCol = {
                  ...column,
                  alignContent: e.target.value,
                }
                const columnIndex = updatedColumns.findIndex(
                  (col) => col.order === column.order
                )
                updatedColumns[columnIndex] = updatedCol
                onOptionsChange({
                  ...visOptions,
                  columns: updatedColumns,
                })
              }}
            >
              <Radio.Button value="left">
                <Icon type="alignLeft" />
              </Radio.Button>
              <Radio.Button value="center">
                <Icon type="alignCenter" />
              </Radio.Button>
              <Radio.Button value="right">
                <Icon type="alignRight" />
              </Radio.Button>
            </Radio.Group>
          </Input.Group>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={column?.allowSearch}
            onChange={(e) => {
              const updatedColumns = [...visOptions.columns]
              const updatedCol = {
                ...column,
                allowSearch: !column?.allowSearch,
              }
              const columnIndex = updatedColumns.findIndex(
                (col) => col.order === column.order
              )
              updatedColumns[columnIndex] = updatedCol
              onOptionsChange({
                ...visOptions,
                columns: updatedColumns,
              })
            }}
          >
            {t("requests.useForSearch")}
          </Checkbox>
        </Form.Item>
        <Form.Item label={t("requests.desc")}>
          <Input
            value={column?.description}
            onChange={(e) => {
              const updatedColumns = [...visOptions.columns]
              const updatedCol = {
                ...column,
                description: e.target.value,
              }
              const columnIndex = updatedColumns.findIndex(
                (col) => col.order === column.order
              )
              updatedColumns[columnIndex] = updatedCol
              onOptionsChange({
                ...visOptions,
                columns: updatedColumns,
              })
            }}
          />
        </Form.Item>
        <Form.Item label={t("requests.displayAs")}>
          <Select
            value={column?.displayAs}
            options={[
              {
                value: "string",
                label: t("requests.text"),
              },
              {
                value: "datetime",
                label: t("requests.dateTime"),
              },
              {
                value: "number",
                label: t("requests.number"),
              },
              {
                value: "boolean",
                label: t("requests.bollean"),
              },
              {
                value: "link",
                label: t("requests.link"),
              },
              {
                value: "image",
                label: t("requests.image"),
              },
              {
                value: "json",
                label: "JSON",
              },
            ]}
            onChange={(value) => {
              const updatedColumns = [...visOptions.columns]
              const updatedCol = { ...column, displayAs: value }
              const columnIndex = updatedColumns.findIndex(
                (col) => col.order === column.order
              )
              updatedColumns[columnIndex] = updatedCol
              onOptionsChange({
                ...visOptions,
                columns: updatedColumns,
              })
            }}
          />
        </Form.Item>
        {column.displayAs === "string" ? (
          <>
            <Form.Item>
              <Checkbox
                checked={column?.allowHTML}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    allowHTML: !column?.allowHTML,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              >
                {t("requests.allowHtml")}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={column?.highlightLinks}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    highlightLinks: !column?.highlightLinks,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              >
                {t("requests.highlightLinks")}
              </Checkbox>
            </Form.Item>
          </>
        ) : column.displayAs === "number" ? (
          <>
            <Form.Item label={t("requests.numberFormat")}>
              <Input
                value={column?.numberFormat}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    numberFormat: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
          </>
        ) : column.displayAs === "datetime" ? (
          <>
            <Form.Item label={t("requests.dateTimeFormat")}>
              <Input
                value={column?.dateTimeFormat}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    dateTimeFormat: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
          </>
        ) : column.displayAs === "boolean" ? (
          <>
            <Form.Item label={t("requests.valueForFalse")}>
              <Input
                value={column?.booleanValues[0]}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    booleanValues: [e.target.value, column?.booleanValues[1]],
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={t("requests.valueForTrue")}>
              <Input
                value={column?.booleanValues[1]}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    booleanValues: [column?.booleanValues[0], e.target.value],
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
          </>
        ) : column.displayAs === "link" ? (
          <>
            <Form.Item label={t("requests.urlTemplate")}>
              <Input
                value={column?.linkUrlTemplate}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    linkUrlTemplate: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={t("requests.textTemplate")}>
              <Input
                value={column?.linkTextTemplate}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    linkTextTemplate: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={t("requests.titleTemplate")}>
              <Input
                value={column?.linkTitleTemplate}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    linkTitleTemplate: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                checked={column?.linkOpenInNewTab}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    linkOpenInNewTab: !column?.linkOpenInNewTab,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              >
                {t("requests.openNewTap")}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Tooltip
                placement="topLeft"
                title={
                  <div>
                    {`All columns can be referenced using {{ column_name }} syntax.\n
                                        Use {{ @ }} to reference current (this) column.\n
                                        This syntax is applicable to URL, Text and Title options.`}
                  </div>
                }
              >
                {t("requests.formatSpace")}
              </Tooltip>
            </Form.Item>
          </>
        ) : column.displayAs === "image" ? (
          <>
            <Form.Item label={t("requests.urlTemplate")}>
              <Input
                value={column?.imageUrlTemplate}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    imageUrlTemplate: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={t("requests.size")}>
              <Space>
                <Input
                  placeholder={t("global.width")}
                  value={column?.imageWidth}
                  onChange={(e) => {
                    const updatedColumns = [...visOptions.columns]
                    const updatedCol = {
                      ...column,
                      imageWidth: e.target.value,
                    }
                    const columnIndex = updatedColumns.findIndex(
                      (col) => col.order === column.order
                    )
                    updatedColumns[columnIndex] = updatedCol
                    onOptionsChange({
                      ...visOptions,
                      columns: updatedColumns,
                    })
                  }}
                />
                X
                <Input
                  placeholder={t("global.height")}
                  value={column?.imageHeight}
                  onChange={(e) => {
                    const updatedColumns = [...visOptions.columns]
                    const updatedCol = {
                      ...column,
                      imageHeight: e.target.value,
                    }
                    const columnIndex = updatedColumns.findIndex(
                      (col) => col.order === column.order
                    )
                    updatedColumns[columnIndex] = updatedCol
                    onOptionsChange({
                      ...visOptions,
                      columns: updatedColumns,
                    })
                  }}
                />
              </Space>
            </Form.Item>
            <Form.Item label={t("requests.titleTemplate")}>
              <Input
                value={column?.imageTitleTemplate}
                onChange={(e) => {
                  const updatedColumns = [...visOptions.columns]
                  const updatedCol = {
                    ...column,
                    imageTitleTemplate: e.target.value,
                  }
                  const columnIndex = updatedColumns.findIndex(
                    (col) => col.order === column.order
                  )
                  updatedColumns[columnIndex] = updatedCol
                  onOptionsChange({
                    ...visOptions,
                    columns: updatedColumns,
                  })
                }}
              />
            </Form.Item>
            <Form.Item>
              <Tooltip
                placement="topLeft"
                title={
                  <div>
                    {`All columns can be referenced using {{ column_name }} syntax.\n
                                        Use {{ @ }} to reference current (this) column.\n
                                        This syntax is applicable to URL, Text and Title options.`}
                  </div>
                }
              >
                {t("requests.formatSpace")}
              </Tooltip>
            </Form.Item>
          </>
        ) : null}
      </dd>
    </Container>
  )
}

const TabelColumns = ({ columns, visOptions, onOptionsChange }) => {
  const columnsIds = useMemo(
    () => columns?.map((column) => column.order),
    [columns]
  )

  const handelOnDragEnd = (event) => {
    const { active, over } = event
    if (active.id === over.id) {
      return
    }

    const updatedColumns = [...visOptions.columns]
    const oldIndex = updatedColumns?.findIndex(
      (column) => column.order === active.id
    )
    const newIndex = updatedColumns?.findIndex(
      (column) => column.order === over.id
    )
    const sortableColumns = arrayMove(updatedColumns, oldIndex, newIndex)
    onOptionsChange({
      ...visOptions,
      columns: sortableColumns,
    })
  }
  if (!columns) {
    return <p>Loading...</p>
  }
  return (
    <dl>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handelOnDragEnd}
      >
        <SortableContext
          items={columnsIds}
          strategy={verticalListSortingStrategy}
          handle
        >
          {columns?.map((column) => (
            <Column
              key={column?.order}
              column={column}
              visOptions={visOptions}
              onOptionsChange={onOptionsChange}
              handle={true}
            />
          ))}
        </SortableContext>
      </DndContext>
    </dl>
  )
}

export default TabelColumns

const Container = styled.div`
  border-bottom: 1px solid #bbb;
  .columnTitle {
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;
  }

  .columnBody {
    padding: 0.5rem;
    background-color: #f1f1f1;
  }

  .hidden {
    display: none;
  }
`
