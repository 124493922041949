import { useMutation, useQuery, useQueryClient } from "react-query"

import {
  getUserPage,
  getUser,
  createUser,
  updateUser,
  regenerateApi,
  getSession,
  disableUser,
  enableUser,
  deleteUser,
  inviteUser,
  resetPassword,
  getUserByName,
  getUsers,
} from "apis/users"

const keys = {
  all: ["users"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  results: () => [...keys.all, "result"],
  result: (id) => [...keys.results(), id],
  searchs: () => [...keys.all, "search"],
  search: (name) => [...keys.searchs(), name],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

const defaultMutationConfig = (queryClient) => ({
  onSettled: () => {
    queryClient.removeQueries(keys.searchs())

    queryClient.prefetchQuery({
      queryKey: keys.search(""),
      queryFn: () => getUserByName(""),
      ...defaultQueryConfig,
    })
  },
})

// healpers

function updateRequestDetail(queryClient, id, data) {
  const request = queryClient.getQueryData(keys.detail(id))

  if (request) {
    queryClient.setQueryData(keys.detail(id), (previous) => ({
      ...previous,
      ...data,
    }))
  }
}

function updateCurrentRequestPage(queryClient, id, data, page, pagination) {
  queryClient.setQueryData(keys.list(pagination), (previous) => ({
    ...previous,
    results: previous.results.map((request) =>
      request.id === id ? { ...request, ...data } : request
    ),
  }))
}

// hooks

function useUsersPage(pagination, options = {}) {
  return useQuery({
    queryKey: keys.list(pagination),
    queryFn: () => getUserPage(pagination),
    ...defaultQueryConfig,
    ...options,
  })
}

function useUsers(options = {}) {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getUsers(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useUser(id, options = {}) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getUser(id),
    ...defaultQueryConfig,
    enabled: !!id,
    ...options,
  })
}

function useSearchUser(name, options = {}) {
  return useQuery({
    queryKey: keys.search(name),
    queryFn: () => getUserByName(name),
    ...defaultQueryConfig,
    ...options,
  })
}

function useCreateUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createUser(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useUpdateUser(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateUser(id, data), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)

      const { id } = newUser

      queryClient.setQueryData(keys.detail(id), newUser)

      queryClient.invalidateQueries(keys.lists())
      queryClient.invalidateQueries(["session"])
    },
  })
}

function useDisableUser(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => disableUser(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)

      const { id } = newUser

      queryClient.setQueryData(keys.detail(id), newUser)

      queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useEnableUser(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => enableUser(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)

      const { id } = newUser

      queryClient.setQueryData(keys.detail(id), newUser)

      queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useRegenerateApi(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => regenerateApi(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)
      const { id } = newUser
      queryClient.setQueryData(keys.detail(id), newUser)
    },
  })
}

function useDeleteUser(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => deleteUser(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)

      const { id } = newUser

      queryClient.setQueryData(keys.detail(id), newUser)

      queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useInviteUser(...options) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => inviteUser(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)
    },
  })
}

function useResetPassword(...options) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => resetPassword(id), {
    ...options,
    onSuccess: (newUser) => {
      options.onSuccess?.(newUser)
    },
  })
}

function useSession() {
  return useQuery({
    queryFn: () => getSession(),
    ...defaultQueryConfig,
  })
}

export {
  useUsersPage,
  useUsers,
  useUser,
  useCreateUser,
  useUpdateUser,
  useRegenerateApi,
  useSession,
  useDisableUser,
  useEnableUser,
  useDeleteUser,
  useInviteUser,
  useResetPassword,
  useSearchUser,
}
