import {
  getOutdatedQueries,
  getRequestsStatus,
  getSystemStatus,
  getOrganizations,
  getOrganization,
  createOrganization,
  disableOrganization,
  enableOrganization,
  updatedOrganizationLogo,
  getAllLogs,
  copyDashboard,
  copyAllDashboards,
  getLogJob,
  getCurrentOrganization,
  getCurrentOrganizationStatus,
  getCurrentOrganizationSettings,
  updatedCurrentOrganizationLogo,
} from "apis/admin"
import { useMutation, useQuery, useQueryClient } from "react-query"

const keys = {
  admin: ["admin"],
  orgs: ["organizations"],
  logs: ["logs"],
  current_org: ["current_organization"],
  status: () => [...keys.admin, "system-status"],
  q_status: () => [...keys.admin, "queries_status"],
  q_outdated: () => [...keys.admin, "queries_outdated"],
  orgs_list: () => [...keys.orgs, "organizations"],
  org_details: () => [...keys.orgs, "detail"],
  org_detail: (id) => [...keys.org_details(), id],
  logs_list: () => [...keys.logs, "list"],
  org_data: () => [...keys.logs, "organizations-data"],
  current_org_detail: () => [...keys.current_org, "detail"],
}

const defaultQueryConfig = {
  staleTime: 0,
  cacheTime: 0,
}

const cashQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

function useSystemStatus(options = {}) {
  return useQuery({
    queryKey: keys.status,
    queryFn: () => getSystemStatus(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useQueryStatus(options = {}) {
  return useQuery({
    queryKey: keys.q_status,
    queryFn: () => getRequestsStatus(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useQueryOutdated(options = {}) {
  return useQuery({
    queryKey: keys.q_outdated,
    queryFn: () => getOutdatedQueries(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useOrganizations(options = {}) {
  return useQuery({
    queryKey: keys.orgs_list(),
    queryFn: () => getOrganizations(),
    ...cashQueryConfig,
    ...options,
  })
}

function useOrganization(id, options = {}) {
  return useQuery({
    queryKey: keys.org_detail(id),
    queryFn: () => getOrganization(id),
    ...cashQueryConfig,
    ...options,
  })
}

function useCreateOrganization(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((data) => createOrganization(data), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.orgs_list())
    },
  })
}

function useDisablOrganization(id, options = {}) {
  const queryClient = useQueryClient()

  return useMutation(() => disableOrganization(id), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.org_detail(id))
    },
  })
}

function useEnableOrganization(id, options = {}) {
  const queryClient = useQueryClient()

  return useMutation(() => enableOrganization(id), {
    ...options,
    onSuccess: (newAgent) => {
      options.onSuccess?.(newAgent)
      queryClient.invalidateQueries(keys.org_detail(id))
    },
  })
}

function useUpdateOrganizationLogo(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updatedOrganizationLogo(id, data), {
    ...options,
    onSuccess: (data) => {
      options.onSuccess?.(data)
      queryClient.invalidateQueries(keys.orgs_list())
    },
    onError: (error) => {
      console.log(error)
    },
  })
}

function useLogs(options = {}) {
  return useQuery({
    queryKey: keys.logs_list(),
    queryFn: () => getAllLogs(),
    ...cashQueryConfig,
    ...options,
  })
}

function useCopyDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((data) => copyDashboard(data), {
    ...options,
    onSuccess: (data) => {
      options.onSuccess?.(data)
      queryClient.invalidateQueries(keys.logs_list())
    },
  })
}

function useCopyAllDashboard(options = {}) {
  const queryClient = useQueryClient()

  return useMutation((data) => copyAllDashboards(data), {
    ...options,
    onSuccess: (data) => {
      options.onSuccess?.(data)
      queryClient.invalidateQueries(keys.logs_list())
    },
  })
}

function useJobbLog(options = {}) {
  return useQuery({
    queryKey: keys.logs_list(),
    queryFn: (id) => getLogJob(id),
    ...cashQueryConfig,
    ...options,
  })
}

function useJobbLogArray(options = {}) {
  const queryClient = useQueryClient()

  const fetchData = async (array) => {
    const filteredArray = array.filter((item) => item.status !== "Finished")
    const names = filteredArray.map((item) => item.name)

    // Fetch data using useJobbLog for each name in names array
    try {
      const fetchData = names.map((name) => getLogJob(name))
      const responses = await Promise.all(fetchData)

      // Add the fetched data to the query cache
      responses.forEach((response, index) => {
        queryClient.setQueryData(["logs", names[index]], response)
      })

      return responses
    } catch (error) {
      console.log(error)
    }
  }

  return useMutation(fetchData, {
    ...options,
    onSuccess: (data) => {
      options.onSuccess?.(data)
      queryClient.invalidateQueries(keys.logs_list())
    },
  })
}

function useCurrentOrg(options = {}) {
  return useQuery({
    queryKey: keys.current_org_detail(),
    queryFn: () => getCurrentOrganization(),
    ...cashQueryConfig,
    ...options,
  })
}

function useUpdateCurrentOrganizationLogo(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ data }) => updatedCurrentOrganizationLogo(data), {
    ...options,
    onSuccess: (data) => {
      options.onSuccess?.(data)
      queryClient.invalidateQueries(keys.current_org_detail())
    },
    onError: (error) => {
      console.log(error)
    },
  })
}

export {
  useSystemStatus,
  useQueryStatus,
  useQueryOutdated,
  useOrganizations,
  useOrganization,
  useCreateOrganization,
  useDisablOrganization,
  useEnableOrganization,
  useUpdateOrganizationLogo,
  useLogs,
  useCopyDashboard,
  useCopyAllDashboard,
  useJobbLog,
  useJobbLogArray,
  useCurrentOrg,
  useUpdateCurrentOrganizationLogo,
}
