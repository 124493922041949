import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useDashboardTags } from "hooks/dashboards"
import useDebounce from "hooks/useDebounce"
import useQueryParams from "hooks/useQueryParams"
import { useTranslation } from "react-i18next"
import { Button, Divider, Dropdown, Radio, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Input } from "components/shared/Input"
import Icon from "components/shared/Icon"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"
import { addDays, format, subDays, subMonths } from "date-fns"
import { useNavigate } from "react-router-dom"
import { useAuth } from "context/AuthContext"

export const DashboardFilter = ({ layout, setLayout, dashboardCount }) => {
  const navigate = useNavigate()
  const { tenantName } = useAuth()
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const debounced = useDebounce(name)
  const [queryParams, setQueryParams] = useQueryParams({
    q: String,
    favorites: Boolean,
    tags: String,
    date_from: String,
    date_to: String,
  })
  const { data: tags } = useDashboardTags()

  useEffect(() => {
    setQueryParams(
      { page: 1, page_size: 10, q: debounced || undefined },
      "push"
    )
  }, [debounced])

  const filterValue =
    queryParams?.date_from === format(subDays(new Date(), 1), "yyyy-MM-dd")
      ? "24"
      : queryParams?.date_from === format(subDays(new Date(), 7), "yyyy-MM-dd")
      ? "7"
      : queryParams?.date_from === format(subDays(new Date(), 30), "yyyy-MM-dd")
      ? "30"
      : queryParams?.date_from ===
        format(subMonths(new Date(), 12), "yyyy-MM-dd")
      ? "12"
      : queryParams?.tags
      ? undefined
      : queryParams?.favorites
      ? undefined
      : "1"

  const handleDateFilter = (value) => {
    const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd")
    let dateFrom

    switch (value) {
      case "1":
        dateFrom = undefined
        break
      case "24":
        dateFrom = format(subDays(new Date(), 1), "yyyy-MM-dd")
        break
      case "7":
        dateFrom = format(subDays(new Date(), 7), "yyyy-MM-dd")
        break
      case "30":
        dateFrom = format(subDays(new Date(), 30), "yyyy-MM-dd")
        break
      case "12":
        dateFrom = format(subMonths(new Date(), 12), "yyyy-MM-dd")
        break
      default:
        return // Exit if none of the cases match
    }

    if (value === "1") {
      navigate(`/${tenantName}/dashboards`)
    } else {
      setQueryParams(
        { page: 1, page_size: 10, date_from: dateFrom, date_to: tomorrow },
        "push"
      )
    }
  }

  const items = [
    {
      key: "fav",
      label: (
        <CustomLink to="/dashboards?favorites=true">
          <Text size="sm">
            <Space>{t("dashboards.favDashboards")}</Space>
          </Text>
        </CustomLink>
      ),
      icon: <Icon type="starFilled" style={{ color: "#fbd208" }} />,
    },
    {
      type: "divider",
    },
  ].concat(
    tags?.map(({ name, count }, index) => {
      return {
        key: index,
        label: (
          <CustomLink to={`/dashboards?tags=${name}`} key={`${name}-${index}`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text size="sm">{name}</Text>
              <Text size="xs" color="warning">
                {count}
              </Text>
            </div>
          </CustomLink>
        ),
      }
    })
  )

  return (
    <Wrapper>
      <div className="header">
        <h2>
          {t("dashboards.allDashboards")}
          {": "}
          <span className="count">
            {dashboardCount} {t("dashboards.dashboard")}
          </span>
        </h2>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className="filter">
        <Radio.Group
          onChange={(e) => handleDateFilter(e.target.value)}
          value={filterValue}
        >
          <Radio.Button value="1">{t("dashboards.dashboards")}</Radio.Button>
          <Radio.Button value="24">{t("global.times.24")}</Radio.Button>
          <Radio.Button value="7">{t("global.times.7")}</Radio.Button>
          <Radio.Button value="30">{t("global.times.30")}</Radio.Button>
          <Radio.Button value="12">{t("global.times.12")}</Radio.Button>
        </Radio.Group>
        <Space>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="search"
            size="sm"
            placeholder={t("dashboards.searchDashboards")}
            prefix={<SearchOutlined />}
            allowClear
          />
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button type="transparent">
              <Icon type="filter" size="lg" />
              <span>{t("global.filter")}</span>
            </Button>
          </Dropdown>
        </Space>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }

    .count {
      border-radius: 14px;
      font-size: 12px;
      padding: 2px 8px;
      background-color: #00244d;
      color: #fff;
      font-weight: 500;
    }
  }
  .filter {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    .filter {
      flex-direction: column;
    }
  }
`
