import React from "react"
import { Outlet, useNavigate, useHref } from "react-router-dom"
import { Radio } from "antd"
import styled from "styled-components"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"
import Text from "components/shared/Text"

const Admin_Header_Tabs = () => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  return [
    {
      value: `/${tenantName}/settings/profile`,
      label: t("global.pages.myDetails"),
      key: "profile",
      title: t("settings.myDetails.title"),
    },
    {
      value: `/${tenantName}/settings/password`,
      label: t("global.pages.password"),
      key: "password",
      title: t("settings.password.title"),
    },
    {
      value: `/${tenantName}/settings/general`,
      label: t("global.pages.profile"),
      key: "general",
      title: t("settings.profile.title"),
    },
    {
      value: `/${tenantName}/settings/users`,
      label: t("global.pages.users"),
      key: "users",
      title: t("settings.users.title"),
    },
    {
      value: `/${tenantName}/settings/groups`,
      label: t("global.pages.groups"),
      key: "groups",
      title: t("settings.groups.title"),
    },
    {
      value: `/${tenantName}/settings/channels`,
      label: t("global.pages.channels"),
      key: "channels",
      title: t("settings.channels.title"),
    },
    {
      value: `/${tenantName}/settings/query_snippets`,
      label: t("global.pages.query_snippets"),
      key: "query",
      title: t("settings.query.title"),
    },
  ]
}

const Default_Header_Tabs = () => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  return [
    {
      value: `/${tenantName}/settings/profile`,
      label: t("global.pages.myDetails"),
      key: "profile",
      title: t("settings.myDetails.title"),
    },
    {
      value: `/${tenantName}/settings/password`,
      label: t("global.pages.password"),
      key: "password",
      title: t("settings.password.title"),
    },
    {
      value: `/${tenantName}/settings/users`,
      label: t("global.pages.users"),
      key: "users",
      title: t("settings.users.title"),
    },
    {
      value: `/${tenantName}/settings/groups`,
      label: t("global.pages.groups"),
      key: "groups",
      title: t("settings.groups.title"),
    },
    {
      value: `/${tenantName}/settings/query_snippets`,
      label: t("global.pages.query_snippets"),
      key: "query",
      title: t("settings.query.title"),
    },
  ]
}

const Settings = () => {
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const navigate = useNavigate()
  const param = useHref()

  const onChangeTabs = ({ target: { value } }) => {
    navigate(`${value}`)
  }

  const items = IS_ADMIN
    ? Admin_Header_Tabs().map((t) => ({
        label: t.label,
        key: t.value,
        title: t.title,
        children: <Outlet />,
      }))
    : Default_Header_Tabs().map((t) => ({
        label: t.label,
        key: t.value,
        children: <Outlet />,
      }))

  const activeTab = items?.find((item) => param.includes(item?.key))

  return (
    <Wrapper>
      <Text size="heading">{activeTab?.title}</Text>
      <Radio.Group value={activeTab?.key} onChange={onChangeTabs}>
        {items?.map((item) => (
          <Radio.Button key={item?.key} value={item?.key}>
            {item?.label}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Outlet />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  & .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

export default Settings
