import { Form, Input } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { Textarea } from "components/shared/Input"
import React from "react"
import { useTranslation } from "react-i18next"

const UpdateQuery = (props) => {
  const {
    onChange,
    onCancel,
    closeModal,
    onUpdateQuery,
    id,
    trigger,
    description,
    snippet,
  } = props
  const { t } = useTranslation()
  const { mutate, isLoading } = onUpdateQuery()

  const onFinish = (value) => {
    mutate(
      {
        id: id,
        data: value,
      },
      {
        onSuccess: (newGroup) => {
          if (newGroup.message) {
            return
          } else {
            closeModal()
          }
        },
      }
    )
  }

  React.useEffect(() => {
    onChange(isLoading)
  }, [isLoading, onChange])

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        trigger,
        description,
        snippet,
      }}
    >
      <Form.Item
        label={t("query.trigger")}
        name="trigger"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t("query.description")} name="description">
        <Input />
      </Form.Item>
      <Form.Item
        label={t("query.snippet")}
        name="snippet"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Textarea />
      </Form.Item>
      <Form.Item>
        <FormModal.Footer>
          <Button onClick={onCancel} type="cancel">
            {t("global.cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("global.edit")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default UpdateQuery
