import { css } from "styled-components"

const variants = {
  type: {
    primary: css`
      --color: #fff;
      --background: #143d66;

      &:hover {
        color: #fff !important;
      }
    `,
    secondary: css`
      --color: #fff;
      --background: #becede;

      &:hover {
        --background: rgb(0, 0, 0, 0.08);
      }
    `,
    transparent: css`
      --color: #98b0c6;
      --background: transparent;
      box-shadow: none;

      &:hover {
        --background: rgb(0, 0, 0, 0.02);
        /* color: #98b0c6 !important; */
      }
    `,
    white: css`
      --color: var(--color-blue-2);
      --background: #fff;
      /* box-shadow: none; */

      &:hover {
        --background: rgb(255, 255, 255, 0.07);
      }
    `,
    blue: css`
      --color: #fff;
      --background: var(--color-blue-2);
      font-weight: 600;
      &:hover {
        color: #fff !important;
      }
    `,
    text: css`
      --color: #143d66;
      --background: #fff;
      font-weight: 600;
      border: none;
      box-shadow: none;

      &:hover {
        --background: var(--color-btn-light);
      }
    `,
  },
  shape: {
    contained: css`
      border-radius: var(--border-radius-0);
      border: none;
    `,
    round: css`
      border-radius: 50%;
      border: none;
    `,
  },
  size: {
    xs: css`
      /* min-height: 12px; */
      /* min-width: 30px; */
      font-size: var(--fz-xs);
      padding: 3px 4px;
    `,
    sm: css`
      --size: 24px;
      --font-size: var(--fz-md);
    `,
    md: css`
      --size: 32px;
      --font-size: var(--fz-lg);
    `,
    lg: css`
      --size: 40px;
      --font-size: var(--fz-xl);
    `,
  },
}

export default variants
