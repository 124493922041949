import React from "react"
import styled from "styled-components"
import { Dropdown, Space, message } from "antd"
import { useRequestState } from "../Provider"
import Text from "components/shared/Text"
import {
  useForkRequest,
  useRequestsTags,
  useUpdateFavoriteRequest,
} from "hooks/requests"
import IconButton, { RoundIconButton } from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useAuth } from "context/AuthContext"
import Name from "components/dashboards/Header/Name"
import APIKeyModel from "./APIKeyModel"
import { useTranslation } from "react-i18next"
import Tags from "components/shared/Tags/Tags"

const RequestHeader = ({ activeTab, onChangeTab }) => {
  const { t } = useTranslation()
  const {
    memoizedRequest,
    onTogglePublish,
    onToggleArchive,
    onUpdateName,
    onUpdateData,
    updateDataLoading,
    onAddTags,
    updateLoading,
    isDrillDown,
    setIsDrillDown,
  } = useRequestState()

  const { session, tenantName } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const { data: allTags } = useRequestsTags()

  const {
    is_favorite: isFavorite,
    name,
    is_draft,
    is_archived,
  } = memoizedRequest

  const { mutate, isLoading: favLoading } = useUpdateFavoriteRequest()
  const { mutate: fork } = useForkRequest()

  const handleFavIconClick = React.useCallback(() => {
    mutate(memoizedRequest)
  }, [isFavorite])

  const forkHandeler = () => {
    fork(
      { id: memoizedRequest.id },
      {
        onSuccess: (data) => {
          if (data.message) {
            message.error({
              content: data.message,
            })
          } else {
            window.open(`/${tenantName}/requests/${data.id}`, "_blank")
          }
        },
        onError: (error) => {
          message.error({
            content: error.message,
          })
        },
      }
    )
  }

  const handleRefresh = () => {
    onUpdateData()
  }

  const icon = isFavorite ? "starFilled" : "starOutlined"

  const items = [
    {
      key: "edit",
      label: (
        <>
          <span>{t("requests.fork")}</span> <Icon type="export" />
        </>
      ),
      onClick: forkHandeler,
      disabled: !IS_ADMIN,
    },
    {
      type: "divider",
    },
    {
      key: "publish",
      label: `${is_draft ? t("global.publish") : t("global.unpublish")}`,
      onClick: onTogglePublish,
      disabled: !IS_ADMIN,
    },
    {
      key: "archive",
      label: `${is_archived ? t("global.unarchive") : t("global.archive")}`,
      onClick: onToggleArchive,
      disabled: !IS_ADMIN,
    },
    {
      type: "divider",
    },
    {
      key: "showAPI",
      label: (
        <FormModal
          Form={APIKeyModel}
          hasChanged={(change) => !!change}
          // title="API Key"
          width={500}
          formProps={{ API: memoizedRequest.api_key }}
        >
          <span>{t("requests.showApi")}</span>
        </FormModal>
      ),
      disabled: !IS_ADMIN,
    },
  ]

  return (
    <Header>
      <div className="left">
        <Space>
          <RoundIconButton
            size="sm"
            loading={favLoading}
            onClick={handleFavIconClick}
            className="icon"
          >
            <Icon type={icon} />
          </RoundIconButton>
          <Name name={name} onUpdateName={onUpdateName} IS_ADMIN={IS_ADMIN} />
        </Space>

        <Space>
          {is_draft && (
            <Text size="xs" className="qualities">
              {t("global.unpublished")}
            </Text>
          )}
          {is_archived && (
            <Text size="xs" className="qualities">
              {t("global.archived")}
            </Text>
          )}
        </Space>

        <Tags
          allTags={allTags}
          dashboaredTags={memoizedRequest.tags}
          IS_ADMIN={IS_ADMIN}
          onAddTags={onAddTags}
          updateLoading={updateLoading}
        />
      </div>
      <div className="right">
        <Button
          type="primary"
          size="sm"
          onClick={handleRefresh}
          disable={updateDataLoading}
          loading={updateDataLoading}
        >
          {t("global.refresh")}
        </Button>

        {is_draft && IS_ADMIN && (
          <Button type="text" size="xs" onClick={() => onTogglePublish()}>
            <Icon type="send" className="sendIcon" />
            <span>{t("global.publish")}</span>
          </Button>
        )}

        {IS_ADMIN && (
          <Button
            type="text"
            size="sm"
            onClick={() => setIsDrillDown((prev) => !prev)}
          >
            {isDrillDown ? (
              <Space>
                <Icon type="edit" />
                <span>{t("requests.query")}</span>
              </Space>
            ) : (
              <Space>
                <Icon type="tabel" />
                <span>{t("requests.vis.vis")}</span>
              </Space>
            )}
          </Button>
        )}
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <IconButton type="text">
            <Icon type="verticalDots" />
          </IconButton>
        </Dropdown>
      </div>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & .qualities {
      padding: 4px 6px;
      background-color: grey;
      color: #fff;
      border-radius: 4px;
    }

    & .icon {
      color: rgb(251, 210, 8);
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &:hover {
    .tagBtn {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      .tagBtn {
        opacity: 1;
      }
    }

    .left {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

export default RequestHeader
