import React, { useState } from "react"
import styled from "styled-components"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import format from "date-fns/format"
import {
  Avatar,
  Divider,
  Modal,
  Select,
  Space,
  Spin,
  Tooltip,
  notification,
} from "antd"
import { useRequestState } from "../Provider"
import { useDataSources } from "hooks/dataSources"
import Button from "components/shared/Button/Button"
import FormModal from "components/shared/FormModal/FormModal"
import { convertTime } from "../Footer/convetTime"
import EditRequestSchedule from "../EditRequestSchedule"
import { useTranslation } from "react-i18next"
import { ar, enUS } from "date-fns/locale"
import DataSourceSchema from "./DataSourceSchema"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useUpdateRequest } from "hooks/requests"

const DataSource = ({ onAddSchema }) => {
  const { t, i18n } = useTranslation()
  const { memoizedRequest, onUpdateDataSource, onEnableSchadule } =
    useRequestState()
  const { data: dataSources, isLoading } = useDataSources()
  const [dataSourcesId, setDataSourceId] = useState(
    memoizedRequest?.data_source_id
  )

  const { mutateAsync } = useUpdateRequest()

  const onChangeDataSource = (id) => {
    onUpdateDataSource(id)
    setDataSourceId(id)
  }

  const handleEnableSchedule = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Enable Schedule",
      content: "Are you sure want to enable schedule for this request?",
      onOk() {
        return mutateAsync(
          {
            requestId: memoizedRequest.id,
            data: {
              schedule: {
                ...memoizedRequest?.schedule,
                interval: memoizedRequest?.schedule?.interval || null,
                time: memoizedRequest?.schedule?.time || null,
                day_of_week: memoizedRequest?.schedule?.day_of_week || null,
                until: memoizedRequest?.schedule?.until || null,
                disabled: false,
              },
            },
          },
          {
            onSuccess: (data) => {
              if (data.message) {
                notification.error({
                  message: "Failed Enable Schedule",
                })
              } else {
                notification.success({
                  message: "Successfuly Enable Schedule",
                })
              }
            },
          }
        )
      },
    })
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  const selectedDataSource = dataSources?.find(
    (ds) => ds.id === memoizedRequest.data_source_id
  )

  return (
    <Wrapper>
      <Select
        defaultValue={selectedDataSource?.id}
        options={dataSources?.map((d) => ({ value: d.id, label: d.name }))}
        loading={isLoading}
        onChange={onChangeDataSource}
        style={{
          width: "100%",
        }}
      />
      <DataSourceSchema
        dataSourcesId={dataSourcesId}
        onAddSchema={onAddSchema}
      />
      <Divider
        style={{
          marginTop: "auto",
        }}
      />
      <Space
        direction="vertical"
        size="small"
        style={{
          display: "flex",
        }}
      >
        <Space size="small">
          <Avatar
            size="small"
            src={memoizedRequest?.user?.profile_image_url}
            alt={memoizedRequest?.user?.name}
          />
          <small>{memoizedRequest?.user?.name}</small>
          <small>
            {t("global.create")}{" "}
            <Tooltip
              title={format(
                new Date(memoizedRequest?.created_at),
                "MM/dd/yyyy H:mm"
              )}
            >
              {formatDistanceToNowStrict(
                new Date(memoizedRequest?.created_at),
                {
                  addSuffix: true,
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            </Tooltip>
          </small>
        </Space>
        <Space size="small">
          <Avatar
            size="small"
            src={memoizedRequest?.last_modified_by?.profile_image_url}
            alt={memoizedRequest?.last_modified_by?.name}
          />
          <small>{memoizedRequest?.last_modified_by?.name}</small>
          <small>
            {t("global.update")}{" "}
            <Tooltip
              title={format(
                new Date(memoizedRequest?.updated_at),
                "MM/dd/yyyy H:mm"
              )}
            >
              {formatDistanceToNowStrict(
                new Date(memoizedRequest?.updated_at),
                {
                  addSuffix: true,
                  locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                }
              )}
            </Tooltip>
          </small>
        </Space>
        <Space
          size="small"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <small>{t("requests.schedule")}:</small>
          {memoizedRequest?.schedule?.disabled ? (
            <Button type="textblue" size="xxs" onClick={handleEnableSchedule}>
              Disabled
            </Button>
          ) : (
            <FormModal
              Form={EditRequestSchedule}
              hasChanged={(schedule) => !!schedule}
              title={t("requests.schedule")}
              width={500}
              formProps={{ schedule: memoizedRequest.schedule }}
            >
              <Button type="textblue" size="xxs">
                {memoizedRequest?.schedule?.interval
                  ? convertTime(memoizedRequest?.schedule?.interval)
                  : t("global.never")}
              </Button>
            </FormModal>
          )}
        </Space>
      </Space>
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default DataSource
