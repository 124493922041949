import axios from "axios"
import { getBaseUrl } from "../config"

class Auth {
  AUTH_KEY = "__auth__"
  TENANT_URL = "__url__"
  TENANT_Name = "__tenantName__"

  constructor() {
    this.onLogin = null
    this.onLogout = null
    this.onRefresh = null
  }

  async login({ tenantName, ...credentials }) {
    const BASE_URL = getBaseUrl(
      tenantName === "enwz"
        ? "golf"
        : tenantName === "euzq"
        ? "mawten"
        : "default"
    )
    let URL = `${BASE_URL}/${tenantName}`

    try {
      const { data } = await axios.post(`${URL}/api/user/login`, credentials)
      window.localStorage.setItem(this.AUTH_KEY, JSON.stringify(data))
      window.localStorage.setItem(this.TENANT_URL, URL)
      window.localStorage.setItem(this.TENANT_Name, URL.match(/\/([^\/]+)$/)[1])
      this.onLogin?.(data)
    } catch (err) {
      if (err.message === "Network Error") {
        throw new Error("Something went wrong")
      } else {
        throw new Error("Invalid email or password.")
      }
    }
  }

  async refreshToken() {
    const auth = this.getAuth()
    const URL = this.getTenantURL()

    try {
      const { data } = await axios.post(
        `${URL}/api/refreshtoken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.refresh_token}`,
          },
        }
      )

      this.onRefresh?.(data)

      window.localStorage.setItem(
        this.AUTH_KEY,
        JSON.stringify({
          ...this.getAuth(),
          access_token: data.access_token,
        })
      )

      return data.access_token
    } catch (err) {
      console.log(err)
    }
  }

  async logout() {
    const tenantName = this.getTenantName()
    try {
      // const { data } = await client.delete(`/user/logout`)
      this.onLogout?.()
      window.localStorage.removeItem(this.AUTH_KEY)
      window.localStorage.removeItem(this.TENANT_URL)
      window.localStorage.removeItem(this.TENANT_Name)
      window.location.href = `/login/${tenantName}`
    } catch (error) {
      throw new Error("Can't Logout")
    }
  }

  getAuth() {
    return JSON.parse(window.localStorage.getItem(this.AUTH_KEY) || null)
  }

  getTenantURL() {
    return window.localStorage.getItem(this.TENANT_URL)
  }

  getTenantName() {
    return window.localStorage.getItem(this.TENANT_Name)
  }
}

const auth = new Auth()

export default auth
