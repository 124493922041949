import React, { useEffect } from "react"
import {
  useCreateAgentDestination,
  useDeleteAgent,
  useMuteAgent,
  useUnMuteAgent,
  useUpdateAgent,
} from "hooks/agents"
import { useNavigate } from "react-router-dom"
import { notification } from "antd"
import { useRemoveAgentDestination } from "hooks/agents"
import { getRequestResult } from "apis/requests"
import { useTranslation } from "react-i18next"

const AgentStateContext = React.createContext()

const AgentStateProvider = ({ agent: memoizedAgent, children }) => {
  const { t } = useTranslation()
  const [editable, setEditable] = React.useState(false)
  const [shouldUpdate, setSHouldUpdate] = React.useState(false)
  const [queryData, setQueryData] = React.useState(null)
  const [queryLoading, setQueryLoading] = React.useState(false)
  const [queryError, setQueryError] = React.useState(false)

  const { mutate: update, isLoading: updateLoading } = useUpdateAgent()
  const { mutate: mute, isLoading: muteLoading } = useMuteAgent()
  const { mutate: unMute, isLoading: unMuteLoading } = useUnMuteAgent()
  const { mutate: deleteAgent } = useDeleteAgent()
  const { mutate: addDestination, isLoading: addDestinationLoading } =
    useCreateAgentDestination()
  const { mutate: deleteDestination } = useRemoveAgentDestination()

  const navigate = useNavigate()

  const getQueryData = async (query) => {
    try {
      setQueryLoading(true)
      const res = await getRequestResult(query)
      setQueryData(res)
      setQueryLoading(false)
    } catch (error) {
      setQueryError(error.message)
      setQueryLoading(false)
    }
  }
  const onGetAgentQuery = React.useCallback((query) => {
    getQueryData(query)
  }, [])

  const onUpdateName = React.useCallback(
    (name) => {
      update({
        id: memoizedAgent.id,
        data: { name: name },
      })
    },
    [memoizedAgent.name]
  )

  const onUpdateAgent = React.useCallback((data) => {
    update(
      {
        id: data.id,
        data: data,
      },
      {
        onSuccess: (agent) => {
          if (agent.id) {
            setSHouldUpdate(false)
            setEditable(false)
            notification.success({
              message: t("agents.agentUpdated"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            setSHouldUpdate(true)
            notification.error({
              message: t("agents.agentNotUpdated"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          setSHouldUpdate(true)
          notification.error({
            message: t("agents.agentNotUpdated"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [])

  const onMuteAgent = React.useCallback(() => {
    mute(
      {
        id: memoizedAgent.id,
      },
      {
        onSuccess: (agent) => {
          if (agent) {
            notification.error({
              message: t("agents.notfNotmuted"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.warning({
              message: t("agents.notfMuted"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("agents.notfNotmuted"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [memoizedAgent.options])

  const onUnMuteAgent = React.useCallback(() => {
    unMute(
      {
        id: memoizedAgent.id,
      },
      {
        onSuccess: (agent) => {
          if (agent) {
            notification.error({
              message: t("agents.notfNotrestored"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.success({
              message: t("agents.notfRestored"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("agents.notfNotrestored"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [memoizedAgent.options])

  const onDeleteAgent = React.useCallback(() => {
    deleteAgent(
      {
        id: memoizedAgent.id,
      },
      {
        onSuccess: (agent) => {
          if (agent) {
            notification.error({
              message: t("agents.alertNotDeleted"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            navigate(-1)
            notification.success({
              message: t("agents.alertDeleted"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("agents.alertNotDeleted"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [memoizedAgent])

  const onAddDestination = React.useCallback((subscriptionId, closeModal) => {
    addDestination(
      {
        id: memoizedAgent.id,
        data: {
          alert_id: memoizedAgent.id,
          destination_id: subscriptionId,
        },
      },
      {
        onSuccess: (destination) => {
          if (destination.id) {
            closeModal()
            notification.success({
              message: t("agents.subscribed"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.error({
              message: t("agents.failedSubscribed"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: () => {
          notification.error({
            message: t("agents.failedSubscribed"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [])

  const onDeleteDestination = React.useCallback((subscriptionId) => {
    deleteDestination(
      {
        agentId: memoizedAgent.id,
        subscriptionId: subscriptionId,
      },
      {
        onSuccess: (agent) => {
          if (agent) {
            notification.error({
              message: t("agents.failedUnSubscribed"),
              duration: 2,
              placement: "bottomRight",
            })
          } else {
            notification.success({
              message: t("agents.unSubscribed"),
              duration: 2,
              placement: "bottomRight",
            })
          }
        },
        onError: (error) => {
          notification.error({
            message: t("agents.failedUnSubscribed"),
            duration: 2,
            placement: "bottomRight",
          })
        },
      }
    )
  }, [])

  const onConvertSecondToRearm = (seconds) => {
    let result = {
      number: 0,
      unit: "s",
    }

    if (seconds >= 604800) {
      result.number = Math.floor(seconds / 604800)
      result.unit = "w"
    } else if (seconds >= 86400) {
      result.number = Math.floor(seconds / 86400)
      result.unit = "d"
    } else if (seconds >= 3600) {
      result.number = Math.floor(seconds / 3600)
      result.unit = "h"
    } else if (seconds >= 60) {
      result.number = Math.floor(seconds / 60)
      result.unit = "m"
    } else {
      result.number = seconds
      result.unit = "s"
    }

    return result
  }

  const value = React.useMemo(
    () => ({
      agent: memoizedAgent,
      editable,
      loading: updateLoading || muteLoading || unMuteLoading,
      addDestinationLoading,
      shouldUpdate,
      queryData,
      queryLoading,
      queryError,
      setEditable,
      onUpdateName,
      onMuteAgent,
      onUnMuteAgent,
      onDeleteAgent,
      onAddDestination,
      onDeleteDestination,
      setSHouldUpdate,
      onGetAgentQuery,
      onUpdateAgent,
      onConvertSecondToRearm,
    }),
    [
      memoizedAgent,
      editable,
      updateLoading,
      muteLoading,
      unMuteLoading,
      addDestinationLoading,
      shouldUpdate,
      queryData,
      queryLoading,
      queryError,
      setEditable,
      onUpdateName,
      onMuteAgent,
      onUnMuteAgent,
      onDeleteAgent,
      onAddDestination,
      onDeleteDestination,
      setSHouldUpdate,
      onGetAgentQuery,
      onUpdateAgent,
      onConvertSecondToRearm,
    ]
  )

  return (
    <AgentStateContext.Provider value={value}>
      {children}
    </AgentStateContext.Provider>
  )
}

export function useAgentState() {
  const context = React.useContext(AgentStateContext)

  if (!context) {
    throw new Error("useAgentState must be used inside a AgentStateProvider.")
  }

  return context
}

export default AgentStateProvider
