import React, { useState } from "react"
import styled from "styled-components"
import { Form, Input, message } from "antd"
import Logo from "static/images/Logo.svg"
import Button from "components/shared/Button/Button"
import { useForgetPassword } from "hooks/auth"

const ForgotPassword = () => {
  const [showForm, setshowForm] = useState(true)
  const { mutate: forget, isLoading } = useForgetPassword()

  const handelForgetPassowrd = async (value) => {
    forget(
      {
        org: value.org,
        data: {
          email: value.email,
        },
      },
      {
        onSuccess: () => {
          message.success("We have sent you a link to reset your password")
          setshowForm(false)
        },
        onError: () => {
          message.error("Something went wrong")
        },
      }
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <img src={Logo} alt="Idataworkers" />
        <h2>Password Reset</h2>
      </div>
      <div className="form">
        {showForm ? (
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handelForgetPassowrd}
          >
            <Form.Item
              name="org"
              label="Enter the Organization name you used for this account:"
              rules={[
                {
                  required: true,
                  message: "Please input Organization name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Enter the email address you used for this account:"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="blue"
                shape="outlined"
                className="btn"
                disable={isLoading}
                loading={isLoading}
              >
                Reset my password
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <p>
            If we found an account associated with that email address, you will
            find an email from us in your inbox shortly.
          </p>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    text-align: center;
    h2 {
      margin-top: 0.4rem;
    }
  }

  .form {
    width: 40%;
    background-color: #fff;
    padding: 1rem;

    .btn {
      width: 100%;
    }
  }

  p {
    padding: 1rem;
    font-size: 1rem;
  }
`

export default ForgotPassword
