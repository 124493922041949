import React from "react"

function useFormState({ initialState, validate, onSubmit }) {
  const [values, setValues] = React.useState(initialState)
  const [errors, setErrors] = React.useState({})

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors({})

    const currentErrors = validate(values)

    if (!Object.keys(currentErrors).length) {
      onSubmit(values)
      return
    }

    setErrors(currentErrors)
  }

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  }
}

export default useFormState
