import React, { useEffect, useState } from "react"
import { Form, Select, Input } from "antd"
import dayjs from "dayjs"
import { getRequestByName } from "apis/requests"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { useAuth } from "context/AuthContext"
import { useRequest } from "hooks/requests"
import { useTranslation } from "react-i18next"

const parametersTypes = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "User",
    value: "user",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Dropdown List",
    value: "enum",
  },
  {
    label: "Query Based Dropdown list",
    value: "query",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Date and Time",
    value: "datetime-local",
  },
  {
    label: "Date and Time (with seconds)",
    value: "datetime-with-seconds",
  },
  {
    label: "Date Range",
    value: "date-range",
  },
  {
    label: "Date and Time Range",
    value: "datetime-range",
  },
  {
    label: "Date and Time Range (with seconds)",
    value: "datetime-range-with-seconds",
  },
]

const EditParameters = ({
  onChange,
  onCancel,
  closeModal,
  parameter,
  onEditParameters,
  isLoading,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [Ptype, setPtype] = useState(parameter?.type)
  const [data, setData] = useState([])
  const [queryId, setQueryId] = useState(parameter?.queryId)

  const { session } = useAuth()
  const userEmail = session.user.email

  const { data: request } = useRequest(queryId, {
    disabled: !queryId,
  })

  const handleSearch = (newValue) => {
    if (newValue) {
      const searchRequest = async () => {
        const d = await getRequestByName(newValue)
        setData(d.results)
      }
      searchRequest()
    } else {
      setData([])
    }
  }

  const handleChange = (newValue) => {
    setQueryId(newValue)
  }

  const onQuotationChange = (value) => {
    switch (value) {
      case "none":
        form.setFieldsValue({
          multiValuesOptions: null,
        })
        return
      case "without":
        form.setFieldsValue({
          multiValuesOptions: { prefix: "", suffix: "", separator: "," },
        })
        return
      case "single":
        form.setFieldsValue({
          multiValuesOptions: { prefix: "'", suffix: "'", separator: "," },
        })
        return
      case "double":
        form.setFieldsValue({
          multiValuesOptions: { prefix: '"', suffix: '"', separator: "," },
        })
        return
      default:
        break
    }
  }

  const onFinish = (value) => {
    let newParameter
    if (value.type === "text") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: value.value,
      }
    } else if (value.type === "number") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: 0,
      }
    } else if (value.type === "user") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: userEmail,
      }
    } else if (value.type === "enum") {
      newParameter = {
        enumOptions: value.enumOptions,
        global: false,
        locals: [],
        multiValuesOptions: value.multiValuesOptions,
        name: value.name,
        title: value.title,
        type: value.type,
        value:
          !value.multiValuesOptions && Array.isArray(value?.value)
            ? value.value[0]
            : value.value,
      }
    } else if (value.type === "query") {
      newParameter = {
        global: false,
        locals: [],
        multiValuesOptions: value.multiValuesOptions,
        name: value.name,
        queryId: queryId,
        title: value.title,
        type: value.type,
        value:
          !value.multiValuesOptions && Array.isArray(value?.value)
            ? value.value[0]
            : value.value,
      }
    } else if (value.type === "date") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD"),
      }
    } else if (value.type === "datetime-local") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD HH:mm"),
      }
    } else if (value.type === "datetime-with-seconds") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      }
    } else if (value.type === "date-range") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          end: dayjs().format("YYYY-MM-DD"),
        },
      }
    } else if (value.type === "datetime-range") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm"),
          end: dayjs().format("YYYY-MM-DD HH:mm"),
        },
      }
    } else if (value.type === "datetime-range-with-seconds") {
      newParameter = {
        global: false,
        locals: [],
        name: value.name,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
          end: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
      }
    }

    onEditParameters(newParameter, closeModal)
  }

  useEffect(() => onChange(Ptype), [Ptype, onChange])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        ...parameter,
      }}
    >
      <Form.Item name="name" hidden></Form.Item>
      <Form.Item name="value" hidden></Form.Item>
      <Form.Item label="Title" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <Select options={parametersTypes} onChange={(v) => setPtype(v)} />
      </Form.Item>
      {Ptype === "enum" && (
        <>
          <Form.Item
            label="Values"
            name="enumOptions"
            extra="Dropdown list values (newline delimited)"
            rules={[
              {
                required: "true",
                message: t("global.requiredField"),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Multi Values">
            <Select
              defaultValue={
                parameter?.multiValuesOptions
                  ? parameter?.multiValuesOptions?.prefix === "'"
                    ? "single"
                    : parameter?.multiValuesOptions?.prefix === '"'
                    ? "double"
                    : "without"
                  : "none"
              }
              options={[
                {
                  label: "None",
                  value: "none",
                },
                {
                  label: "Without Quotation Mark",
                  value: "without",
                },
                {
                  label: "Single Quotation Mark",
                  value: "single",
                },
                {
                  label: "Double Quotation Mark",
                  value: "double",
                },
              ]}
              onChange={onQuotationChange}
            />
          </Form.Item>
          <Form.Item hidden name="multiValuesOptions" />
        </>
      )}
      {Ptype === "query" && (
        <>
          <Form.Item
            label="Query"
            extra="Select query to load dropdown values from."
            rules={[
              {
                required: "true",
                message: t("global.requiredField"),
              },
            ]}
          >
            <Select
              value={request?.name}
              placeholder="Search a query by name"
              allowClear
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={(data || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
          </Form.Item>
          <Form.Item label="Multi Values">
            <Select
              defaultValue={
                parameter?.multiValuesOptions
                  ? parameter?.multiValuesOptions?.prefix === "'"
                    ? "single"
                    : parameter?.multiValuesOptions?.prefix === '"'
                    ? "double"
                    : "without"
                  : "none"
              }
              options={[
                {
                  label: "None",
                  value: "none",
                },
                {
                  label: "Without Quotation Mark",
                  value: "without",
                },
                {
                  label: "Single Quotation Mark",
                  value: "single",
                },
                {
                  label: "Double Quotation Mark",
                  value: "double",
                },
              ]}
              onChange={onQuotationChange}
            />
          </Form.Item>
          <Form.Item hidden name="multiValuesOptions" />
        </>
      )}
      <Form.Item>
        <FormModal.Footer>
          <Button type="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="secondary"
            htmlType="submit"
            disabled={
              Ptype === "query" ? (queryId ? false : true) : false || isLoading
            }
            loading={isLoading}
          >
            Edit Parameters
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default EditParameters
