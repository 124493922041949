import { useMutation, useQuery, useQueryClient } from "react-query"
import { getOrganization, updateOrganization } from "apis/settings"

const keys = {
  all: ["settings"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  results: () => [...keys.all, "result"],
  result: (id) => [...keys.results(), id],
  searchs: () => [...keys.all, "search"],
  search: (name) => [...keys.searchs(), name],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

function useOrganization() {
  return useQuery({
    queryKey: keys.lists(),
    queryFn: () => getOrganization(),
    ...defaultQueryConfig,
  })
}

function useUpdateOrganization() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => updateOrganization(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

export { useOrganization, useUpdateOrganization }
