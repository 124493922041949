import React from "react"
import { Select } from "antd"
import { useGetQueryDropdown } from "hooks/requests"

const RequestDropwon = ({ props, handelInputChange }) => {
  const {
    data: dropdownData,
    isLoading,
    isError,
  } = useGetQueryDropdown(props?.queryId)

  const uniqueData = dropdownData
    ?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    )
    .map((item) => ({
      label: <span dangerouslySetInnerHTML={{ __html: item.name }} />,
      value: item.value,
    }))

  return (
    <Select
      mode={props.multiValuesOptions && "multiple"}
      defaultValue={props.value}
      options={uniqueData}
      onChange={(value) => handelInputChange(props, value)}
      style={{ minWidth: "100px" }}
      loading={isLoading}
      disabled={isError}
      popupMatchSelectWidth={false}
    />
  )
}

export default RequestDropwon
