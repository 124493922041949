import client from "services/client"

async function getWarehouse() {
  const res = await client.get("/warehouse")
  return res.data
}

async function createWarehouse(data) {
  const res = await client.post(`/create_database`, data)
  return res.data
}

export { getWarehouse, createWarehouse }
