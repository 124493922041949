import { Avatar, List, Modal, Space, Spin, Tooltip, notification } from "antd"
import Icon from "components/shared/Icon"
import { useAgentDestinations, useRemoveAgentDestination } from "hooks/agents"
import React from "react"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/datasource/mucs.png"
import { useTranslation } from "react-i18next"

const AgentDestinations = ({ agent, type }) => {
  const { t } = useTranslation()
  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useAgentDestinations(agent.id)
  const { mutateAsync: deleteDestination } = useRemoveAgentDestination()

  const onDeleteDestination = (destinationId) => {
    Modal.confirm({
      title: "Unsubscribe Destination",
      content: "Are you sure want to unsubscribe this destination?",
      okText: "Unsubscribe",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        return deleteDestination(
          {
            agentId: agent.id,
            subscriptionId: destinationId,
          },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.failedUnSubscribed"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.success({
                  message: t("agents.unSubscribed"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: (error) => {
              notification.error({
                message: t("agents.failedUnSubscribed"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      },
    })
  }

  return type === "list" ? (
    <List
      loading={subscriptionsLoading}
      size="small"
      dataSource={subscriptions}
      split={false}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          style={{
            display: item.destination ? "flex" : "none",
            justifyContent: "space-between",
            paddingRight: "1rem",
            paddingLeft: "1rem",
          }}
        >
          <Space>
            <img
              src={`${
                destinationImages[item?.destination?.type] || defaultImage
              }`}
              alt={item?.destination?.type}
              width={32}
            />
            {item?.destination?.name}
          </Space>
          <Icon
            type="x"
            style={{
              cursor: "pointer",
            }}
            onClick={() => onDeleteDestination(item.id)}
          />
        </List.Item>
      )}
    />
  ) : (
    <Spin spinning={subscriptionsLoading}>
      <Space>
        {subscriptions?.map((subscription) => {
          return subscription.destination ? (
            <Tooltip title={subscription?.destination?.name}>
              <Avatar
                src={`${
                  destinationImages[subscription?.destination?.type] ||
                  defaultImage
                }`}
                size={32}
                shape="square"
                style={{ border: "1px solid #D1D9E2" }}
              />
            </Tooltip>
          ) : null
        })}
      </Space>
    </Spin>
  )
}

export default AgentDestinations
