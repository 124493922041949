//Data Source
import athena from "static/images/datasource/athena.png"
import aws_es from "static/images/datasource/aws_es.png"
import axibasetsd from "static/images/datasource/axibasetsd.png"
import Cassandra from "static/images/datasource/Cassandra.png"
import clickhouse from "static/images/datasource/clickhouse.png"
import cloudwatch from "static/images/datasource/cloudwatch.png"
import cloudwatch_insights from "static/images/datasource/cloudwatch_insights.png"
import cockroach from "static/images/datasource/cockroach.png"
import couchbase from "static/images/datasource/couchbase.png"
import dgraph from "static/images/datasource/dgraph.png"
import drill from "static/images/datasource/drill.png"
import druid from "static/images/datasource/druid.png"
import dynamodb_sql from "static/images/datasource/dynamodb_sql.png"
import elasticsearch from "static/images/datasource/elasticsearch.png"
import exasol from "static/images/datasource/exasol.png"
import graphite from "static/images/datasource/graphite.png"
import hive from "static/images/datasource/hive.png"
import hive_http from "static/images/datasource/hive_http.png"
import impala from "static/images/datasource/impala.png"
import influxdb from "static/images/datasource/influxdb.png"
import jirajql from "static/images/datasource/jirajql.png"
import json from "static/images/datasource/json.png"
import kibana from "static/images/datasource/kibana.png"
import kylin from "static/images/datasource/kylin.png"
import memsql from "static/images/datasource/memsql.png"
import mongodb from "static/images/datasource/mongodb.png"
import mysql from "static/images/datasource/mysql.png"
import pg from "static/images/datasource/pg.png"
import phoenix from "static/images/datasource/phoenix.png"
import presto from "static/images/datasource/presto.png"
import prometheus from "static/images/datasource/prometheus.png"
import qubole from "static/images/datasource/qubole.png"
import rds_mysql from "static/images/datasource/rds_mysql.png"
import redshift from "static/images/datasource/redshift.png"
import redshift_iam from "static/images/datasource/redshift_iam.png"
import rightnow from "static/images/datasource/rightnow.png"
import rockset from "static/images/datasource/rockset.png"
import salesforce from "static/images/datasource/salesforce.png"
import scylla from "static/images/datasource/scylla.png"
import snowflake from "static/images/datasource/snowflake.png"
import sqlite from "static/images/datasource/sqlite.png"
import treasuredata from "static/images/datasource/treasuredata.png"
import uptycs from "static/images/datasource/uptycs.png"
import vertica from "static/images/datasource/vertica.png"
import yandex_appmetrika from "static/images/datasource/yandex_appmetrika.png"
import mucs from "static/images/datasource/mucs.png"
import yandex_metrika from "static/images/datasource/yandex_metrika.png"

//Destnations
import chatwork from "static/images/destinations/chatwork.png"
import email from "static/images/destinations/email.png"
import hangouts_chat from "static/images/destinations/hangouts_chat.png"
import mattermost from "static/images/destinations/mattermost.png"
import slack from "static/images/destinations/slack.png"
import webhook from "static/images/destinations/webhook.png"

export const datasourceImages = {
  athena: athena,
  aws_es: aws_es,
  axibasetsd: axibasetsd,
  Cassandra: Cassandra,
  clickhouse: clickhouse,
  cloudwatch: cloudwatch,
  cloudwatch_insights: cloudwatch_insights,
  cockroach: cockroach,
  couchbase: couchbase,
  dgraph: dgraph,
  drill: drill,
  druid: druid,
  dynamodb_sql: dynamodb_sql,
  elasticsearch: elasticsearch,
  exasol: exasol,
  graphite: graphite,
  hive: hive,
  hive_http: hive_http,
  impala: impala,
  influxdb: influxdb,
  jirajql: jirajql,
  json: json,
  kibana: kibana,
  kylin: kylin,
  memsql: memsql,
  mongodb: mongodb,
  mysql: mysql,
  pg: pg,
  phoenix: phoenix,
  presto: presto,
  prometheus: prometheus,
  qubole: qubole,
  rds_mysql: rds_mysql,
  redshift: redshift,
  redshift_iam: redshift_iam,
  rightnow: rightnow,
  rockset: rockset,
  salesforce: salesforce,
  scylla: scylla,
  snowflake: snowflake,
  sqlite: sqlite,
  treasuredata: treasuredata,
  uptycs: uptycs,
  vertica: vertica,
  yandex_appmetrika: yandex_appmetrika,
  mucs: mucs,
  results: mucs,
  yandex_metrika: yandex_metrika,
}

export const destinationImages = {
  chatwork: chatwork,
  email: email,
  hangouts_chat: hangouts_chat,
  mattermost: mattermost,
  slack: slack,
  webhook: webhook,
}
