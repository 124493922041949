import React, { useEffect, useState } from "react"
import { Form, Select, Input } from "antd"
import dayjs from "dayjs"
import { getRequestByName } from "apis/requests"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"

const parametersTypes = [
  {
    label: "",
    options: [
      {
        label: "Text",
        value: "text",
      },
      {
        label: "User",
        value: "user",
      },
      {
        label: "Number",
        value: "number",
      },
      {
        label: "Dropdown List",
        value: "enum",
      },
      {
        label: "Query Based Dropdown list",
        value: "query",
      },
      {
        label: "Date",
        value: "date",
      },
      {
        label: "Date and Time",
        value: "datetime-local",
      },
      {
        label: "Date and Time (with seconds)",
        value: "datetime-with-seconds",
      },
      {
        label: "Date Range",
        value: "date-range",
      },
      {
        label: "Date and Time Range",
        value: "datetime-range",
      },
      {
        label: "Date and Time Range (with seconds)",
        value: "datetime-range-with-seconds",
      },
    ],
  },
]

const AddParameters = ({
  onChange,
  onCancel,
  closeModal,
  onAddParameters,
  parameters,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [Ptype, setPtype] = useState("text")
  const [data, setData] = useState([])
  const [queryId, setQueryId] = useState()

  const { session } = useAuth()
  const userEmail = session.user.email

  const handleSearch = (newValue) => {
    const searchRequest = async () => {
      const d = await getRequestByName(newValue)
      setData(d.results)
    }
    searchRequest()
  }

  const handleChange = (newValue) => {
    setQueryId(newValue)
  }

  const onQuotationChange = (value) => {
    switch (value) {
      case "none":
        form.setFieldsValue({
          multiValuesOptions: null,
        })
        return
      case "without":
        form.setFieldsValue({
          multiValuesOptions: { prefix: "", suffix: "", separator: "," },
        })
        return
      case "single":
        form.setFieldsValue({
          multiValuesOptions: { prefix: "'", suffix: "'", separator: "," },
        })
        return
      case "double":
        form.setFieldsValue({
          multiValuesOptions: { prefix: '"', suffix: '"', separator: "," },
        })
        return
      default:
        break
    }
  }

  const onKeywordChange = (e) => {
    form.setFieldsValue({
      title: e.target.value.charAt(0) + e.target.value.slice(1),
    })
  }

  const onFinish = (value) => {
    let newParameter
    if (value.type === "text") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: value.value,
      }
    } else if (value.type === "number") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: 0,
      }
    } else if (value.type === "user") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: userEmail,
      }
    } else if (value.type === "enum") {
      newParameter = {
        enumOptions: value.enumOptions,
        global: false,
        locals: [],
        multiValuesOptions: value.multiValuesOptions,
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: value.enumOptions.split("\n")[0],
      }
    } else if (value.type === "query") {
      newParameter = {
        global: false,
        locals: [],
        multiValuesOptions: value.multiValuesOptions,
        name: value.keyword,
        queryId: queryId,
        title: value.title,
        type: value.type,
      }
    } else if (value.type === "date") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD"),
      }
    } else if (value.type === "datetime-local") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD HH:mm"),
      }
    } else if (value.type === "datetime-with-seconds") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      }
    } else if (value.type === "date-range") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          end: dayjs().format("YYYY-MM-DD"),
        },
      }
    } else if (value.type === "datetime-range") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm"),
          end: dayjs().format("YYYY-MM-DD HH:mm"),
        },
      }
    } else if (value.type === "datetime-range-with-seconds") {
      newParameter = {
        global: false,
        locals: [],
        name: value.keyword,
        title: value.title,
        type: value.type,
        value: {
          start: dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss"),
          end: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
      }
    }
    onAddParameters(newParameter)
    closeModal()
  }

  useEffect(() => onChange(Ptype), [Ptype, onChange])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        type: "text",
        multiValuesOptions: null,
      }}
    >
      <Form.Item
        label={t("requests.keyword")}
        name="keyword"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (parameters.find((p) => p.name === value)) {
                return Promise.reject(
                  new Error("Parameter with this name already exists")
                )
              }
              return Promise.resolve()
            },
          }),
        ]}
        onChange={onKeywordChange}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Title" name="title" hidden>
        <Input />
      </Form.Item>
      <Form.Item label={t("global.type")} name="type">
        <Select options={parametersTypes} onChange={(v) => setPtype(v)} />
      </Form.Item>
      {Ptype === "enum" && (
        <>
          <Form.Item
            label={t("global.values")}
            name="enumOptions"
            extra={t("requests.dropdownExtra")}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label={t("requests.multiValues")}>
            <Select
              defaultValue="none"
              options={[
                {
                  label: "None",
                  value: "none",
                },
                {
                  label: "Without Quotation Mark",
                  value: "without",
                },
                {
                  label: "Single Quotation Mark",
                  value: "single",
                },
                {
                  label: "Double Quotation Mark",
                  value: "double",
                },
              ]}
              onChange={onQuotationChange}
            />
          </Form.Item>
          <Form.Item hidden name="multiValuesOptions" />
        </>
      )}
      {Ptype === "query" && (
        <>
          <Form.Item
            label={t("requests.query")}
            extra={t("requests.QueryExtra")}
          >
            <Select
              placeholder={t("requests.searchRequest")}
              allowClear
              showSearch
              value={queryId}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              onFocus={handleSearch}
              notFoundContent={null}
              options={(data || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
          </Form.Item>
          <Form.Item label={t("requests.multiValues")}>
            <Select
              defaultValue="none"
              options={[
                {
                  label: "None",
                  value: "none",
                },
                {
                  label: "Without Quotation Mark",
                  value: "without",
                },
                {
                  label: "Single Quotation Mark",
                  value: "single",
                },
                {
                  label: "Double Quotation Mark",
                  value: "double",
                },
              ]}
              onChange={onQuotationChange}
            />
          </Form.Item>
          <Form.Item hidden name="multiValuesOptions" />
        </>
      )}
      <Form.Item>
        <FormModal.Footer>
          <Button type="cancel" onClick={() => closeModal()}>
            {t("global.cancel")}
          </Button>
          <Button type="secondary" htmlType="submit">
            {t("requests.addParams")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default AddParameters
