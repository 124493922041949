import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import AgentsdData from "components/agents/AgentsdData"
import AdminAgentForm from "components/agents/AdminAgentForm"
import AddUserAgentForm from "components/agents/AddUserAgentForm"

function Agents() {
  const { t } = useTranslation()
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Wrapper>
      <div className="header">
        <h1>{t("agents.agents")}</h1>
        {!IS_ADMIN ? <AddUserAgentForm /> : <AdminAgentForm />}
      </div>
      <AgentsdData IS_ADMIN={IS_ADMIN} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
`

export default Agents
