import { useMutation, useQuery, useQueryClient } from "react-query"
import { getWarehouse, createWarehouse } from "apis/warehouse"

const keys = {
  all: ["warehouse"],
  list: () => [...keys.all, "list"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useWareHouse() {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getWarehouse(),
    ...defaultQueryConfig,
  })
}

export function useCreateWareHouse(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createWarehouse(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}
