import React from "react"
import styled from "styled-components"
import ChannelsList from "components/channels/ChannelsList"
import CreateChannels from "components/channels/CreateChannels"
import { Space } from "antd"
import Text from "components/shared/Text"
import { useTranslation } from "react-i18next"

const Channels = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className="head">
        <Space direction="vertical">
          <Text size="xl">{t("settings.channels.title")}</Text>
          <Text size="xs" color="gray-1">
            {t("settings.channels.desc")}
          </Text>
        </Space>
        <CreateChannels />
      </div>
      <ChannelsList />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .head {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: first baseline;
    gap: 1rem;
  }

  @media (min-width: 768px) {
    & .head {
      flex-direction: row;
      align-items: center;
    }
  }
`

export default Channels
