import React from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { useDashboardState } from "../Provider"
import { Dropdown, Menu } from "antd"
import { Link } from "react-router-dom"
import CreateTextboxForm from "../CreateTextboxForm/CreateTextboxForm"
import FormModal from "components/shared/FormModal/FormModal"
import IconButton from "components/shared/IconButton/IconButton"
import Icon from "components/shared/Icon/Icon"
import { useTranslation } from "react-i18next"

const Markdown = ({ widget, isPublic, isAdmin }) => {
  const { t } = useTranslation()
  const { memoizedDashboard, onRemoveWidget, editable, onRemoveWidgetDirect } =
    useDashboardState()

  const handleRemove = () => {
    onRemoveWidget(widget?.id)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <FormModal
          title={t("dashboards.markdown.editTextBox")}
          Form={CreateTextboxForm}
          formProps={{
            dashboardId: memoizedDashboard.id,
            isEdit: true,
            widget: widget,
          }}
          hasChanged={({ values }) => !!values}
          width={700}
          style={{ top: "0.5rem", paddingBottom: "0" }}
        >
          <a>{t("global.edit")}</a>
        </FormModal>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={editable}>
        <Link onClick={() => onRemoveWidgetDirect(widget.id)}>
          {t("dashboards.removeFromDashboard")}
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Wrapper>
      <div className="widget-header">
        {isAdmin && !isPublic && (
          <>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
              className="actions"
            >
              <IconButton type="transparent" shape="round" size="sm">
                <Icon type="verticalDots" />
              </IconButton>
            </Dropdown>
            {editable && (
              <IconButton
                type="transparent"
                shape="round"
                size="sm"
                onClick={handleRemove}
              >
                <Icon type="delete" />
              </IconButton>
            )}
          </>
        )}
      </div>
      <ReactMarkdown children={widget.text} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: ${(props) => (props.isFullscreen ? "none" : "1px solid #DBE8F4")};
  border-radius: 5px;
  overflow: auto;
  position: relative;

  .widget-header {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;

    .actions {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
  }
`

export default Markdown
