import { formatDistanceStrict } from "date-fns"

export function convertTime(time) {
  const intervalInSeconds = Number(time)
  let intervalText = ""

  if (intervalInSeconds < 3600) {
    // If interval is less than 1 hour
    const intervalInMinutes = Math.floor(intervalInSeconds / 60) // Convert to minutes
    intervalText = `Every ${intervalInMinutes} minute`
    if (intervalInMinutes > 1) {
      intervalText += "s" // Add plural if necessary
    }
  } else if (intervalInSeconds < 86400) {
    // If interval is less than 1 day
    const intervalInHours = Math.floor(intervalInSeconds / 3600) // Convert to hours
    intervalText = `Every ${intervalInHours} hour`
    if (intervalInHours > 1) {
      intervalText += "s" // Add plural if necessary
    }
  } else if (intervalInSeconds < 604800) {
    // If interval is less than 1 week
    const intervalInDays = Math.floor(intervalInSeconds / 86400) // Convert to days
    intervalText = `Every ${intervalInDays} day`
    if (intervalInDays > 1) {
      intervalText += "s" // Add plural if necessary
    }
  } else {
    // If interval is more than 1 week
    const intervalInWeeks = Math.floor(intervalInSeconds / 604800) // Convert to weeks
    intervalText = `Every ${intervalInWeeks} week`
    if (intervalInWeeks > 1) {
      intervalText += "s" // Add plural if necessary
    }
  }

  intervalText = formatDistanceStrict(0, intervalInSeconds * 1000, {
    addSuffix: false,
    includeSeconds: false,
    // locale: {
    //  distanceInWords: {
    //   xMinutes: '%d min',
    //   xHours: '%d hr',
    //   xDays: '%d day',
    //   xWeeks: '%d week',
    //  },
    // },
  })

  return intervalText
}
