import client from "services/client"

async function getETLSources() {
  const res = await client.get("/etl/sources")
  return res.data.data
}

async function getETLSourceList() {
  const res = await client.get("/etl/sources_list")
  return res.data
}

async function getETLSourcesDefination() {
  const res = await client.get("/etl/sources_defination")
  return res.data
}

async function getETLSourceDefination(id) {
  const res = await client.get(`/etl/source_defination/${id}`)
  return res.data
}

async function addETLSource(data) {
  // const res = await client.post("/etl/source", data)
  const res = await client.post("/etl/source_create", data)
  return res.data
}

async function deleteETLSource(id) {
  const res = await client.delete(`/etl/source/${id}`, {
    data: {},
  })
  return res.data
}

async function testETLSource(sourceId) {
  const res = await client.get(`/etl/test_source/${sourceId}`)
  return res.data
}

export {
  getETLSources,
  getETLSourcesDefination,
  getETLSourceDefination,
  addETLSource,
  deleteETLSource,
  testETLSource,
  getETLSourceList,
}
