import React, { useState } from "react"
import styled from "styled-components"
import Createsources from "components/sources/Createsources"
import SourcesList from "components/sources/SourcesList"
import { useAuth } from "context/AuthContext"
import Icon from "components/shared/Icon"
import { DatabaseOutlined, PlusOutlined } from "@ant-design/icons"
import { Segmented } from "antd"
import ETLSourcesList from "components/ETL/Sources/ETLSourcesList"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Button from "components/shared/Button"
import withETLWorkspace from "components/ETL/withETLWorkspace"
import { useTranslation } from "react-i18next"

const VisSources = ({ isAdmin, t }) => {
  return (
    <Wrapper>
      <div className="header">
        <h2>{t("sources.visSource")}</h2>
        {isAdmin ? <Createsources /> : null}
      </div>
      <SourcesList />
    </Wrapper>
  )
}

const ETLSources = withETLWorkspace(({ isAdmin, t }) => {
  return (
    <>
      <div className="header">
        <h2>{t("sources.etlSource")}</h2>
        {isAdmin ? (
          <CustomLink to={`/sources/etl/create`}>
            <Button type="primary">
              <PlusOutlined />
              {t("sources.etlSource")}
            </Button>
          </CustomLink>
        ) : null}
      </div>
      <ETLSourcesList />
    </>
  )
})

const Sources = () => {
  const { t } = useTranslation()
  const [sourceType, setSourceType] = useState("etl_source")
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  return (
    <Wrapper>
      <Segmented
        size="large"
        value={sourceType}
        options={[
          {
            label: t("sources.etlSource"),
            value: "etl_source",
            icon: <DatabaseOutlined />,
          },
          {
            label: t("sources.visSource"),
            value: "vis_source",
            icon: <Icon type="sources" />,
          },
        ]}
        onChange={(value) => setSourceType(value)}
      />
      {
        {
          vis_source: <VisSources isAdmin={IS_ADMIN} t={t} />,
          etl_source: <ETLSources isAdmin={IS_ADMIN} t={t} />,
        }[sourceType]
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
`

export default Sources
