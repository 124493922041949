import { SlidersOutlined } from "@ant-design/icons"
import AdminAgentForm from "components/agents/AdminAgentForm"
import CreateDashboardForm from "components/dashboards/CreateDashboardForm"
import FormModal from "components/shared/FormModal"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Icon from "@ant-design/icons"
import { Avatar } from "antd"
import {
  OverviewIcon,
  ApplicationsIcon,
  DashboardIcon,
  RequestIcon,
  AgentIcon,
  SourcesIcon,
  DestinationsIcon,
  ConnectionsIcon,
  WarehouseIcon,
} from "./sidebarIcon"

export const DEFAULT_NAV_LINKS = () => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()

  return [
    {
      href: `/${tenantName}`,
      key: `/${tenantName}`,
      label: t("home.overview"),
      icon: (
        <Avatar
          icon={<OverviewIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/applications`,
      key: `/${tenantName}/applications`,
      label: t("global.pages.applications"),
      icon: (
        <Avatar
          icon={<ApplicationsIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/dashboards`,
      key: `/${tenantName}/dashboards`,
      label: t("global.pages.dashboards"),
      icon: (
        <Avatar
          icon={<DashboardIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/agents`,
      key: `/${tenantName}/agents`,
      label: t("global.pages.agents"),
      icon: (
        <Avatar
          icon={<AgentIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
  ]
}

export const Admin_NAV_LINKS = (IS_SUPER_ADMIN_TENANT) => {
  const { t } = useTranslation()
  const { tenantName } = useAuth()
  const navigate = useNavigate()

  return [
    {
      href: `/${tenantName}`,
      key: `/${tenantName}`,
      label: t("home.overview"),
      icon: (
        <Avatar
          icon={<OverviewIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/applications`,
      key: `/${tenantName}/applications`,
      label: t("global.pages.applications"),
      icon: (
        <Avatar
          icon={<ApplicationsIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/dashboards`,
      key: `/${tenantName}/dashboards`,
      label: t("global.pages.dashboards"),
      icon: (
        <Avatar
          icon={<DashboardIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/requests`,
      key: `/${tenantName}/requests`,
      label: t("global.pages.requests"),
      icon: (
        <Avatar
          icon={<RequestIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/agents`,
      key: `/${tenantName}/agents`,
      label: t("global.pages.agents"),
      icon: (
        <Avatar
          icon={<AgentIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/sources`,
      key: `/${tenantName}/sources`,
      label: t("global.pages.sources"),
      icon: (
        <Avatar
          icon={<SourcesIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/destinations`,
      key: `/${tenantName}/destinations`,
      label: t("global.pages.destinations"),
      icon: (
        <Avatar
          icon={<DestinationsIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/connections`,
      key: `/${tenantName}/connections`,
      label: t("global.pages.connections"),
      icon: (
        <Avatar
          icon={<ConnectionsIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/warehouse`,
      key: `/${tenantName}/warehouse`,
      label: t("global.pages.warehouse"),
      icon: (
        <Avatar
          icon={<WarehouseIcon />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      href: `/${tenantName}/admin/status`,
      key: `/${tenantName}/admin/status`,
      label: t("global.pages.system"),
      icon: (
        <Avatar
          icon={<SlidersOutlined />}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        />
      ),
      hidden: !IS_SUPER_ADMIN_TENANT,
    },
    {
      key: "create",
      icon: <Icon type="plusCircle" />,
      label: t("global.create"),
      className: "sideCreateBtn",
      children: [
        {
          key: "createDashboard",
          label: (
            <FormModal
              title="Add Dashboard"
              width={500}
              hasChanged={({ name }) => !!name}
              Form={CreateDashboardForm}
            >
              <a>{t("global.pages.newDashboard")}</a>
            </FormModal>
          ),
        },
        {
          key: "createRequest",
          label: t("global.pages.newRequest"),
          onClick: () => {
            navigate(`/${tenantName}/requests/create`)
          },
        },
        {
          key: "createAgent",
          label: <AdminAgentForm type="link" />,
        },
      ],
      hidden: true,
    },
  ].filter((item) => !item?.hidden)
}
