import React from "react"
import styled from "styled-components"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Form, Input, message } from "antd"
import Logo from "static/images/Logo.svg"
import Button from "components/shared/Button/Button"
import { useResetPassword } from "hooks/auth"

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { mutate: reset, isLoading } = useResetPassword()
  const navigate = useNavigate()

  const handelForgetPassowrd = async (value) => {
    reset(
      {
        org: searchParams.get("org"),
        token: searchParams.get("token"),
        data: {
          password: value.password,
        },
      },
      {
        onSuccess: () => {
          message.success("Password Reset Successfully")
          navigate("/login", { replace: true })
        },
        onError: () => {
          message.error("Something went wrong")
        },
      }
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <img src={Logo} alt="Idataworkers" />
        <h2>Password Reset</h2>
      </div>
      <div className="form">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handelForgetPassowrd}
        >
          <Form.Item
            name="password"
            label="Enter your new password:"
            rules={[
              {
                required: true,
                message: "Please input your new passowrd!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="blue"
              shape="outlined"
              className="btn"
              disable={isLoading}
              loading={isLoading}
            >
              Change my password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    text-align: center;
    h2 {
      margin-top: 0.4rem;
    }
  }

  .form {
    width: 40%;
    background-color: #fff;
    padding: 1rem;

    .btn {
      width: 100%;
    }
  }
`

export default ResetPassword
