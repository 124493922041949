import React from "react"
import styled from "styled-components"
import { useAgentState } from "../Provider/Provider"
import { useAuth } from "context/AuthContext"
import Name from "components/dashboards/Header/Name"
import { Dropdown, Modal, Space } from "antd"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon/Icon"
import Button from "components/shared/Button/Button"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import EditAdminAgentForm from "../EditAdminAgentForm"

const AgentHeader = () => {
  const { t } = useTranslation()
  const {
    agent,
    loading,
    onUpdateName,
    onMuteAgent,
    onUnMuteAgent,
    onDeleteAgent,
  } = useAgentState()
  const {
    name,
    options: { muted },
  } = agent

  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")

  const { confirm } = Modal
  const showDeleteConfirm = () => {
    confirm({
      title: t("agents.deleteAlert"),
      icon: <ExclamationCircleOutlined />,
      content: t("agent.deleteConfirm"),
      okText: t("global.delete"),
      okType: "danger",
      cancelText: t("global.cancel"),
      onOk() {
        onDeleteAgent()
      },
      onCancel() {},
    })
  }

  const items = [
    {
      key: "mute",
      label: `${
        muted ? t("agents.unmuteNotifications") : t("agents.muteNotifications")
      }`,
      onClick: muted ? onUnMuteAgent : onMuteAgent,
    },
    {
      key: "delete",
      label: t("agents.deleteAlert"),
      onClick: showDeleteConfirm,
    },
  ]

  return (
    <Header>
      <Name name={name} onUpdateName={onUpdateName} IS_ADMIN={IS_ADMIN} />
      {IS_ADMIN ? (
        <Actions>
          <EditAdminAgentForm agent={agent} />
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <IconButton type="text" disable={loading} loading={loading}>
              <Icon type="verticalDots" />
            </IconButton>
          </Dropdown>
        </Actions>
      ) : null}
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 678px) {
    & {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export default AgentHeader
