import React, { useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import Button from "components/shared/Button"
import { useTranslation } from "react-i18next"
import { Modal, Space } from "antd"
import Text from "components/shared/Text"
import Steps from "components/shared/Steps"
import { useCreateAgentContext, withProvider } from "context/CreateAgentContext"
import StepOne from "components/agents/ChooseRequest"
import StepTwo from "components/agents/ChooseTemplate"
import StepThree from "components/agents/SelectDestinations"
import StepFour from "components/agents/Summary"

const AdminAgentForm = ({ agent, type }) => {
  const isNew = !agent
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { step, setStep, onSelectRequest, onChangeTemplate } =
    useCreateAgentContext()

  return (
    <div>
      {type === "link" ? (
        <a onClick={() => setIsOpen(true)}>{t("agents.newAgent")}</a>
      ) : (
        <Button
          type="primary"
          className="newAgent"
          size="md"
          onClick={() => setIsOpen(true)}
        >
          <PlusOutlined />
          {t("agents.newAgent")}
        </Button>
      )}

      <Modal
        open={isOpen}
        onCancel={() => {
          setStep(1)
          onSelectRequest(null)
          onChangeTemplate("default")
          setIsOpen(false)
        }}
        title={
          isNew ? (
            <Space direction="vertical">
              <Text>{t("agents.newAgent")}</Text>
              <Text size="xs" color="gray-1">
                {t("agents.newAgentDesc")}
              </Text>
            </Space>
          ) : (
            <Space direction="vertical">
              <Text>{t("agents.editAgent")}</Text>
              <Text size="xs" color="gray-1">
                {t("agents.editAgentDesc")}
              </Text>
            </Space>
          )
        }
        width={1000}
        footer={null}
      >
        <Steps step={step}>
          <StepOne title={t("agents.chooseRequest")} />
          <StepTwo title={t("agents.chooseTemplate")} />
          <StepThree title={t("agents.destinations")} />
          <StepFour
            title={t("agents.summery")}
            onClose={() => setIsOpen(false)}
          />
        </Steps>
      </Modal>
    </div>
  )
}

export default withProvider(AdminAgentForm)
