import React, { useRef } from "react"
import styled from "styled-components"
import { Modal, Space, Table, notification } from "antd"
import DashboardCount from "./DashboardCount"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import AppForm from "./AppForm"
import { useDeleteApplications } from "hooks/applications"
import CustomLink from "components/shared/CustomLink/CustomLink"
import { useTranslation } from "react-i18next"

const ApplicationsListView = ({ applications }) => {
  const { t } = useTranslation()
  const editButtonRef = useRef(null)
  const { mutateAsync } = useDeleteApplications()

  const onCancelEdit = () => {
    editButtonRef.current = null
  }

  const handelDeleteApp = (id) => {
    Modal.confirm({
      title: t("applications.deleteApp"),
      content: t("applications.deleteAppConfirem"),
      okText: t("global.delete"),
      okType: "danger",
      cancelText: t("global.cancel"),
      onOk: async () => {
        return mutateAsync(id).then(() => {
          notification.success({
            message: t("applications.deleteSuccessfully"),
            duration: 2,
            placement: "bottomRight",
          })
        })
      },
    })
  }

  const columns = [
    {
      title: t("global.name"),
      key: "name",
      render: (app) => {
        return (
          <Space>
            <Icon
              type="ApplicationsPageIcon"
              className="dashboardIcon"
              style={{ minWidth: "40px" }}
            />
            <CustomLink to={`/application/${app.tag}`}>
              <Space direction="vertical" size={0}>
                <span
                  style={{
                    color: "#101828",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {app?.name}
                </span>
                <span
                  style={{
                    color: "#667085",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {app?.tag}
                </span>
              </Space>
            </CustomLink>
          </Space>
        )
      },
    },
    {
      title: t("applications.NamOfApps"),
      key: "number-of-dashboard",
      width: "30%",
      render: (app) => <DashboardCount tag={app?.tag} color="#0097C2" />,
    },
    {
      key: "action",
      width: "10%",
      render: (app) => {
        return (
          <Space>
            <AppForm
              key={app.id}
              app={app}
              // hideEdit={true}
              onCancelEdit={onCancelEdit}
              ref={editButtonRef}
            />
            <IconButton
              type="transparent"
              danger
              onClick={() => handelDeleteApp(app.id)}
            >
              <Icon type="delete" />
            </IconButton>
          </Space>
        )
      },
    },
  ]

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{ x: "800px" }}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding: 1rem;

  .dashboardIcon {
    font-size: 1.8rem;
  }
`

export default ApplicationsListView
