import React, { useState } from "react"
import styled from "styled-components"
import Footer from "../CreateAgentFooter"
import useDebounce from "hooks/useDebounce"
import { useCreateAgentContext } from "context/CreateAgentContext"
import { useSearchRequests } from "hooks/requests"
import { Checkbox, Form, Input, Select, Space, Spin, Tooltip } from "antd"
import Text from "components/shared/Text"
import Icon from "components/shared/Icon/Icon"
import { convertTime } from "components/requests/Footer/convetTime"
import { useTranslation } from "react-i18next"
import { getDashboardByName } from "apis/dashboards"
const { Option } = Select

function ChooseRequest() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const {
    agentName,
    setAgentName,
    request,
    onSelectRequest,
    queryData,
    queryLoading,
    queryError,
    onNextStep,
    column,
    onSelectColumn,
    condition,
    onSelectCondition,
    threshold,
    onChangeThreshold,
    isTemplate,
    varname,
    vartype,
    setIsTemplate,
    setVarname,
    setVartype,
    setDashboard,
  } = useCreateAgentContext()
  const [requestSearch, setRequestSearch] = React.useState("")
  const [error, setError] = useState(null)
  const value = useDebounce(requestSearch)

  const [data, setData] = useState([])

  const { data: requests, isLoading: isLoadingSearch } = useSearchRequests(
    value,
    {
      keepPreviousData: true,
      disabled: !requestSearch,
    }
  )

  const handleSearch = (newValue) => {
    if (newValue) {
      const searchRequest = async () => {
        const d = await getDashboardByName(newValue)
        setData(d.results)
      }
      searchRequest()
    } else {
      setData([])
    }
  }

  const handleChange = (newValue) => {
    setDashboard(data.find((d) => d.id === newValue))
  }

  const handleNext = React.useCallback(() => {
    setError(null)

    if (!request) {
      setError("Select a request!")
      return
    }

    onNextStep()
  }, [request])

  const handleSelect = (value) => {
    const selected = requests.results.find(({ id }) => id === value)
    onSelectRequest(selected)
  }

  const handleSelectColumn = React.useCallback(
    (name) => {
      const selected = queryData?.data?.columns?.find(
        (col) => col.name === name
      )
      onSelectColumn(selected.name)
    },
    [queryData?.data?.columns]
  )

  const results = requests?.results || []

  return (
    <Wrapper layout="vertical" form={form} preserve={false}>
      <Form.Item
        label={t("agents.agentName")}
        name="name"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="New Agent"
          value={agentName}
          onChange={(e) => setAgentName(e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t("agents.chooseRequest")}>
        <Select
          showSearch
          onSearch={setRequestSearch}
          onChange={handleSelect}
          placeholder={t("dashboards.searchRequest")}
          notFoundContent={null}
          filterOption={false}
          allowClear
          loading={isLoadingSearch}
        >
          {results.map(({ id, name }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
        </Select>
        {request && (
          <Text size="xxs">
            {request?.schedule ? (
              `${t("agents.scheduleEvery")}
                ${convertTime(request?.schedule?.interval)}`
            ) : (
              <Space>
                <Icon type="warn" style={{ color: "#f44336" }} />
                <span>{t("agents.noRefreshSchedule")}</span>
                <Text size="xs" style={{ color: "#2196f3" }}>
                  <Tooltip title={t("agents.scheduleDesc")}>
                    {t("agents.recommended")}{" "}
                  </Tooltip>
                  <Icon type="question" style={{ color: "#2196f3" }} />
                </Text>
              </Space>
            )}
          </Text>
        )}
      </Form.Item>

      {request && (
        <>
          <Form.Item label="Dashboard" name="dashboard">
            <Select
              placeholder={t("requests.searchDashboards")}
              allowClear
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={(data || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Spin spinning={queryLoading}>
              {(queryData?.error || queryError) && (
                <Text as="p" size="sm" color="danger">
                  {t("agents.requestNoData")}
                </Text>
              )}
              {queryData?.data && (
                <Form.Item label={t("agents.triggerWhen")}>
                  <Space>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <small>{t("agents.valuesCol")}</small>
                      <Select
                        style={{
                          minWidth: "100px",
                        }}
                        options={queryData?.data?.columns?.map((data) => {
                          return {
                            label: data.friendly_name,
                            value: data.name,
                          }
                        })}
                        value={column}
                        onChange={handleSelectColumn}
                      />
                    </Space>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "0 0.4rem",
                      }}
                    >
                      <small>{t("agents.condition")}</small>
                      <Select
                        optionLabelProp="value"
                        popupMatchSelectWidth={false}
                        value={condition}
                        options={[
                          {
                            value: ">",
                            label: "> greater than",
                          },
                          {
                            value: ">=",
                            label: "≥ greater than or equal",
                          },
                          {
                            value: "<",
                            label: "< less than",
                          },
                          {
                            value: "<=",
                            label: "≤ less than or equal",
                          },
                          {
                            value: "==",
                            label: "= equals",
                          },
                          {
                            value: "!=",
                            label: "≠ not equal to",
                          },
                        ]}
                        onChange={onSelectCondition}
                      />
                    </Space>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <small>{t("agents.threshold")}</small>
                      <Input
                        style={{
                          maxWidth: "100px",
                        }}
                        value={threshold}
                        onChange={(e) => onChangeThreshold(e.target.value)}
                      />
                    </Space>
                  </Space>
                </Form.Item>
              )}
              <Form.Item valuePropName="checked">
                <Checkbox
                  checked={isTemplate}
                  onChange={(e) => setIsTemplate(e.target.checked)}
                >
                  {t("agents.agentAsTemplate")}
                </Checkbox>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  if (isTemplate) {
                    return (
                      <>
                        <Form.Item
                          label={t("agents.question")}
                          name="varname"
                          rules={[{ required: true }]}
                        >
                          <Input
                            placeholder={t("agents.questionPlaceholder")}
                            value={varname}
                            onChange={(e) => setVarname(e.target.value)}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t("agents.expectedInput")}
                          name="vartype"
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={[
                              {
                                label: "String",
                                value: "string",
                              },
                              {
                                label: "Number",
                                value: "number",
                              },
                            ]}
                            placeholder={t("agents.expectedInputPlaceholder")}
                            value={vartype}
                            onChange={(value) => setVartype(value)}
                          />
                        </Form.Item>
                      </>
                    )
                  }
                }}
              </Form.Item>
            </Spin>
          </Form.Item>
        </>
      )}

      <Footer
        onNext={handleNext}
        className="footer"
        nextButtonProps={{
          disabled:
            !agentName ||
            !request ||
            queryLoading ||
            queryData?.error ||
            !threshold ||
            (isTemplate && !varname) ||
            (isTemplate && !vartype),
          loading: queryLoading,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Form)`
  width: 80%;
  margin: auto;

  .disclaimer {
    margin: 22px 0 0;

    a {
      color: var(--color-brand);
      text-decoration: underline;
    }
  }
`

export default ChooseRequest
