import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  getDestinations,
  createDestination,
  getDestinationTypes,
  getDestination,
  deleteDestination,
  updateDestination,
} from "apis/destinations"

const keys = {
  all: ["destinations"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  types: () => [...keys.all, "types"],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

function useDestinations(options = {}) {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getDestinations(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useDestination(id) {
  return useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDestination(id),
    ...defaultQueryConfig,
  })
}

function useCreateDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ data }) => createDestination(data), {
    ...options,
    onSuccess: (destination) => {
      options.onSuccess?.(destination)

      // invalidate the list
      queryClient.invalidateQueries(keys.list())

      // instantly update the data
      queryClient.setQueryData(keys.detail(destination.id), destination)
    },
  })
}

function useUpdateDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id, data }) => updateDestination(id, data), {
    ...options,
    onSuccess: (destination) => {
      options.onSuccess?.(destination)

      // invalidate the list
      queryClient.invalidateQueries(keys.list())

      // instantly update the data
      queryClient.setQueryData(keys.detail(destination.id), destination)
    },
  })
}

function useDeleteDestination(options = {}) {
  const queryClient = useQueryClient()

  return useMutation(({ id }) => deleteDestination(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(keys.list())
    },
  })
}

function useDestinationsTypes() {
  return useQuery({
    queryKey: keys.types(),
    queryFn: () => getDestinationTypes(),
    ...defaultQueryConfig,
  })
}

export {
  useDestinations,
  useDestination,
  useCreateDestination,
  useUpdateDestination,
  useDeleteDestination,
  useDestinationsTypes,
}
