import { PlusOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Space, notification } from "antd"
import Button from "components/shared/Button"
import { useCreateWareHouse } from "hooks/warehouse"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const WarehouseForm = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { mutate, isLoading } = useCreateWareHouse()

  const validateNoCapsOrSpecials = (_, value) => {
    const pattern = /^[a-z0-9]+$/
    if (value && !pattern.test(value)) {
      return Promise.reject(new Error(t("warehouse.invalidCharacters")))
    }
    return Promise.resolve()
  }

  const handelSave = (values) => {
    mutate(
      { data: values },
      {
        onSuccess: (warehouse) => {
          if (warehouse.status === "failed") {
            notification.error({
              message: t("warehouse.failedCreate"),
            })
          } else {
            notification.success({
              message: t("warehouse.successfullyCreate"),
            })
            setIsOpen(false)
          }
        },
        onError: () => {
          notification.error({
            message: t("warehouse.failedCreate"),
          })
        },
      }
    )
  }

  return (
    <div>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        <PlusOutlined />
        {t("warehouse.warehouse")}
      </Button>
      <Modal
        open={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        title={t("warehouse.newWarehouse")}
        width={800}
        footer={null}
        destroyOnClose={true}
      >
        <Form layout="vertical" onFinish={handelSave}>
          <Form.Item
            label={t("warehouse.name")}
            name="your_database_name"
            rules={[
              { required: true, message: t("global.requiredField") },
              { validator: validateNoCapsOrSpecials },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("global.username")}
            name="your_username"
            rules={[
              { required: true, message: t("global.requiredField") },
              { validator: validateNoCapsOrSpecials },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("global.password")}
            name="your_password"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                shape="outlined"
                size="sm"
                onClick={() => setIsOpen(false)}
                disabled={isLoading}
              >
                {t("global.cancel")}
              </Button>
              <Button
                size="sm"
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                {t("global.save")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default WarehouseForm
