import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useRequestsTags } from "hooks/requests"
import useDebounce from "hooks/useDebounce"
import useQueryParams from "hooks/useQueryParams"
import { addDays, format, subDays, subMonths } from "date-fns"
import CustomLink from "components/shared/CustomLink/CustomLink"
import Text from "components/shared/Text"
import { Button, Divider, Dropdown, Radio, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { Input } from "components/shared/Input"
import Icon from "components/shared/Icon"
import { useNavigate } from "react-router-dom"
import { useAuth } from "context/AuthContext"

const RequestsFilter = ({ layout, setLayout, requestCount }) => {
  const navigate = useNavigate()
  const { tenantName } = useAuth()
  const { t } = useTranslation()
  const [name, setName] = useState("")
  const debounced = useDebounce(name)
  const { data: tags } = useRequestsTags()

  const [queryParams, setQueryParams] = useQueryParams({
    q: String,
    favorites: Boolean,
    tags: String,
    my_requests: String,
    date_from: String,
    date_to: String,
  })

  const filterValue =
    queryParams?.date_from === format(subDays(new Date(), 1), "yyyy-MM-dd")
      ? "24"
      : queryParams?.date_from === format(subDays(new Date(), 7), "yyyy-MM-dd")
      ? "7"
      : queryParams?.date_from === format(subDays(new Date(), 30), "yyyy-MM-dd")
      ? "30"
      : queryParams?.date_from ===
        format(subMonths(new Date(), 12), "yyyy-MM-dd")
      ? "12"
      : queryParams?.tags
      ? undefined
      : queryParams?.archived
      ? undefined
      : queryParams?.my_requests
      ? undefined
      : queryParams?.favorites
      ? undefined
      : "1"

  const handleDateFilter = (value) => {
    const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd")
    let dateFrom

    switch (value) {
      case "1":
        dateFrom = undefined
        break
      case "24":
        dateFrom = format(subDays(new Date(), 1), "yyyy-MM-dd")
        break
      case "7":
        dateFrom = format(subDays(new Date(), 7), "yyyy-MM-dd")
        break
      case "30":
        dateFrom = format(subDays(new Date(), 30), "yyyy-MM-dd")
        break
      case "12":
        dateFrom = format(subMonths(new Date(), 12), "yyyy-MM-dd")
        break
      default:
        return // Exit if none of the cases match
    }

    if (value === "1") {
      navigate(`/${tenantName}/requests`)
    } else {
      setQueryParams(
        { page: 1, page_size: 10, date_from: dateFrom, date_to: tomorrow },
        "push"
      )
    }
  }

  useEffect(() => {
    setQueryParams(
      { page: 1, page_size: 10, q: debounced || undefined },
      "push"
    )
  }, [debounced])

  const items = [
    {
      key: "favorites",
      label: (
        <CustomLink to="/requests?favorites=true">
          <Text size="sm">
            <Space>{t("requests.favRequests")}</Space>
          </Text>
        </CustomLink>
      ),
      icon: <Icon type="starFilled" style={{ color: "#fbd208" }} />,
    },
    {
      key: "myRequests",
      label: (
        <CustomLink to="/requests?my_requests=true">
          <Text size="sm">
            <Space>{t("requests.myRequests")}</Space>
          </Text>
        </CustomLink>
      ),
      icon: <Icon type="userIcon" />,
    },
    {
      key: "archived",
      label: (
        <CustomLink to="/requests?archived=true">
          <Text size="sm">
            <Space>{t("requests.archived")}</Space>
          </Text>
        </CustomLink>
      ),
      icon: <Icon type="archiv" style={{ fontSize: "1rem" }} />,
    },
    {
      type: "divider",
    },
  ].concat(
    tags?.map(({ name, count }, index) => {
      return {
        key: index,
        label: (
          <CustomLink to={`/requests?tags=${name}`} key={`${name}-${index}`}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text size="sm">{name}</Text>
              <Text size="xs" color="warning">
                {count}
              </Text>
            </div>
          </CustomLink>
        ),
      }
    })
  )

  return (
    <Wrapper>
      <div className="header">
        <h2>
          {t("requests.allRequests")}
          {": "}{" "}
          <span>
            {requestCount} {t("requests.request")}
          </span>
        </h2>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className="filter">
        <Radio.Group
          onChange={(e) => handleDateFilter(e.target.value)}
          value={filterValue}
        >
          <Radio.Button value="1">{t("requests.allRequests")}</Radio.Button>
          <Radio.Button value="24">{t("global.times.24")}</Radio.Button>
          <Radio.Button value="7">{t("global.times.7")}</Radio.Button>
          <Radio.Button value="30">{t("global.times.30")}</Radio.Button>
          <Radio.Button value="12">{t("global.times.12")}</Radio.Button>
        </Radio.Group>
        <Space>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="search"
            size="sm"
            placeholder={t("requests.searchRequest")}
            prefix={<SearchOutlined />}
            allowClear
            // style={{ width: "400px", height: "33px" }}
          />
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Button type="transparent">
              <Icon type="filter" />
              <span>{t("global.filter")}</span>
            </Button>
          </Dropdown>
        </Space>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .header {
    padding: 1rem;
    margin-bottom: 0;
    h2 {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      span {
        border-radius: 14px;
        font-size: 12px;
        padding: 2px 8px;
        background-color: #00244d;
        color: #fff;
        font-weight: 500;
      }
    }
  }
  .filter {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    .filter {
      flex-direction: column;
    }
  }
`

export default RequestsFilter
