import styled from "styled-components"
import Text from "components/shared/Text"
import img from "static/images/under-construction.png"

function NotFound() {
  return (
    <Wrapper>
      <Text color="primary" size="heading">
        Feature Under Development
      </Text>

      <Image src={img} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: 300px;
  max-width: 100%;
  margin-top: 60px;
`

export default NotFound
