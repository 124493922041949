import React, { useState } from "react"
import styled from "styled-components"
import { List, Space, Tooltip } from "antd"
import Button from "components/shared/Button/Button"
import FormModal from "components/shared/FormModal/FormModal"
import Icon from "components/shared/Icon/Icon"
import { useAgentState } from "../Provider/Provider"
import { useTranslation } from "react-i18next"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/datasource/mucs.png"

const AddDestination = ({
  onChange,
  onCancel,
  closeModal,
  destinationsDate,
  destinationLoading,
  subscriptions,
}) => {
  const { t } = useTranslation()
  const { onAddDestination, addDestinationLoading } = useAgentState()
  const [selectList, setSelectList] = useState([])

  const addToList = (item) => {
    const index = selectList.findIndex((selected) => selected.id === item.id)
    if (index === -1) {
      setSelectList([...selectList, item])
    } else {
      const updatedList = [...selectList]
      updatedList.splice(index, 1)
      setSelectList(updatedList)
    }
  }

  const onSave = () => {
    selectList.forEach((item) => {
      onAddDestination(item.id, closeModal)
    })
  }

  React.useEffect(() => {
    onChange(selectList)
  }, [selectList])

  return (
    <>
      <ListContainer>
        <List
          dataSource={destinationsDate}
          loading={destinationLoading}
          renderItem={(item) => (
            <List.Item
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "1rem",
                paddingLeft: "1rem",
                cursor: subscriptions?.find(
                  (sub) => sub?.destination?.id === item?.id
                )
                  ? "not-allowed"
                  : "pointer",
                backgroundColor:
                  selectList?.find((value) => value?.id === item?.id) ||
                  subscriptions?.find(
                    (sub) => sub?.destination?.id === item?.id
                  )
                    ? "#f6f8f9"
                    : "#FFF",
              }}
              onClick={
                subscriptions?.find((sub) => sub?.destination?.id === item?.id)
                  ? null
                  : () => addToList(item)
              }
            >
              <Space>
                <img
                  src={`${destinationImages[item.type] || defaultImage}`}
                  alt={item.type}
                  width={32}
                />
                {item.name}
              </Space>
              <Tooltip
                title={
                  subscriptions?.find(
                    (sub) => sub?.destination?.id === item?.id
                  )
                    ? t("agents.selected")
                    : ""
                }
              >
                <Icon
                  type={
                    selectList.find((value) => value?.id === item?.id) ||
                    subscriptions?.find(
                      (sub) => sub?.destination?.id === item?.id
                    )
                      ? "check"
                      : "plus"
                  }
                />
              </Tooltip>
            </List.Item>
          )}
        />
      </ListContainer>
      <FormModal.Footer
        style={{
          display: "flex",
          // justifyContent: 'space-between',
        }}
      >
        {/* <Text size="xs">Create new destinations in Agent Destinations</Text> */}
        <Space size={2}>
          <Button size="sm" type="cancel" onClick={onCancel}>
            {t("global.cancel")}
          </Button>
          <Button
            size="sm"
            type="primary"
            onClick={onSave}
            disabled={addDestinationLoading}
            loading={addDestinationLoading}
          >
            {t("global.save")}
            {selectList?.length !== 0 && ` (${selectList?.length})`}
          </Button>
        </Space>
      </FormModal.Footer>
    </>
  )
}

const ListContainer = styled.div`
  max-height: 50vh;
  overflow: auto;

  .btns {
    display: flex;
  }
`

export default AddDestination
