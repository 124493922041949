import { css } from "styled-components"

const variants = {
  type: {
    primary: css`
      --color: #fff;
      --background: #143d66;

      &:hover {
        --color: #fff !important;
        border: #143d66 !important;
      }
    `,
    secondary: css`
      --color: #fff;
      --background: var(--color-btn-secondary);

      &:hover {
        --background: var(--color-btn-secondary-hover);
        color: #fff !important;
      }
    `,
    cancel: css`
      --color: #000;
      --background: var(--color-btn-light);

      &:hover {
        --background: var(--color-btn-light-hover);
        color: ${({ theme }) => theme.color.mainColor} !important;
      }
    `,
    text: css`
      --color: #143d66;
      --background: transparent;
      font-weight: 600;
      border: none;
      box-shadow: none;

      &:hover {
        --background: var(--color-btn-light);
      }
    `,
    blue: css`
      --color: ${({ theme }) => theme.color.mainColor};
      --background: ${({ theme }) => theme.color.button};
      font-weight: 600;

      &:hover {
        --background: var(--color-blue-2);
        color: var(--color-blue-1) !important;
      }
    `,
    // text: css`
    //   --color: var(--color-blue-2);
    //   --background: transparent;
    //   font-weight: 600;
    //   border: none;
    //   box-shadow: none;

    //   &:hover {
    //     --background: var(--color-btn-light);
    //   }
    // `,
  },
  shape: {
    contained: css`
      border-radius: var(--border-radius-0);
      border: none;
    `,
    outlined: css`
      color: var(--background) !important;
      background: var(--color) !important;
      border: 1px solid var(--background);
      border-radius: var(--border-radius-0);

      &:hover {
        color: var(--color) !important;
        background: var(--background) !important;
      }
    `,
  },
  size: {
    xxs: css`
      max-height: 27px;
      font-size: var(--fz-xxs);
      padding: 4px;
    `,
    xs: css`
      /* min-height: 12px; */
      /* min-width: 30px; */
      font-size: var(--fz-xs);
      padding: 4px 6px;
    `,
    sm: css`
      min-height: 24px;
      min-width: 60px;
      font-size: var(--fz-sm);
      padding: 6px 8px;
    `,
    md: css`
      min-height: 36px;
      min-width: 100px;
      font-size: var(--fz-md);
      padding: 10px;
    `,
    lg: css`
      font-size: var(--fz-md);
      min-height: 48px;
      min-width: 170px;
    `,
  },
}

export default variants
