import { Form, Input, message } from "antd"
import Button from "components/shared/Button"
import FormModal from "components/shared/FormModal"
import { useUpdateUser } from "hooks/users"
import React from "react"
import { useTranslation } from "react-i18next"

const ResetPassword = ({ onChange, onCancel, closeModal, id }) => {
  const { t } = useTranslation()
  const { mutate: update, isLoading: updateLoading } = useUpdateUser()

  React.useEffect(() => {
    onChange(updateLoading)
  }, [updateLoading, onChange])

  const onFinish = (value) => {
    update(
      {
        id,
        data: value,
      },
      {
        onSuccess: (success) => {
          message.success({
            content: t("account.passChanged"),
          })
          closeModal()
        },
        onError: () => {
          message.error({
            content: t("account.passNotChanged"),
          })
        },
      }
    )
  }
  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label={t("account.currentPass")}
        name="old_password"
        rules={[
          {
            required: "true",
            message: t("global.requiredField"),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="password"
        label={t("account.newPass")}
        rules={[
          {
            required: true,
            message: t("global.requiredField"),
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t("account.repeatNewPass")}
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("global.requiredField"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t("global.passNotMatched")))
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <FormModal.Footer>
          <Button onClick={onCancel} type="primary" shape="outlined">
            {t("global.cancel")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disable={updateLoading}
            loading={updateLoading}
          >
            {t("account.reset")}
          </Button>
        </FormModal.Footer>
      </Form.Item>
    </Form>
  )
}

export default ResetPassword
