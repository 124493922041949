import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import {
  Card,
  Divider,
  Dropdown,
  List,
  Modal,
  Space,
  Spin,
  Tag,
  notification,
} from "antd"
import Icon from "components/shared/Icon"
import CustomLink from "components/shared/CustomLink/CustomLink"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { format } from "date-fns"
import AgentDestinations from "./AgentDestinations"
import IconButton from "components/shared/IconButton"
import { useDeleteAgent, useMuteAgent, useUnMuteAgent } from "hooks/agents"
import EditUserAgentForm from "./EditUserAgentForm"

const AgentAction = ({ agent, loading, IS_ADMIN }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteAgent } = useDeleteAgent()
  const { mutateAsync: muteAgent } = useMuteAgent()
  const { mutateAsync: unmuteAgent } = useUnMuteAgent()

  const handelDeleteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Deleted Agent",
      content: "Are you sure want to delete this agent?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return deleteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.alertNotDeleted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.success({
                  message: t("agents.alertDeleted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.alertNotDeleted"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }

  const handelMuteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Mute Agent",
      content: "Are you sure want to mute this agent?",
      okText: "Mute",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return muteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.notfNotmuted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.warning({
                  message: t("agents.notfMuted"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.notfNotmuted"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }

  const handelUnMuteAlert = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "UnMute Agent",
      content: "Are you sure want to unmute this agent?",
      okText: "UnMute",
      cancelText: "Cancel",
      onOk() {
        return unmuteAgent(
          { id: agent.id },
          {
            onSuccess: (agent) => {
              if (agent) {
                notification.error({
                  message: t("agents.notfNotrestored"),
                  duration: 2,
                  placement: "bottomRight",
                })
              } else {
                notification.success({
                  message: t("agents.notfRestored"),
                  duration: 2,
                  placement: "bottomRight",
                })
              }
            },
            onError: () => {
              notification.error({
                message: t("agents.notfNotrestored"),
                duration: 2,
                placement: "bottomRight",
              })
            },
          }
        )
      },
      onCancel() {},
    })
  }

  const items = [
    {
      key: "mute",
      label: `${
        agent?.options?.muted
          ? t("agents.unmuteNotifications")
          : t("agents.muteNotifications")
      }`,
      onClick: agent?.options?.muted ? handelUnMuteAlert : handelMuteAlert,
    },
    {
      key: "delete",
      label: t("agents.deleteAlert"),
      onClick: handelDeleteAlert,
    },
  ]

  return (
    <Space>
      {!IS_ADMIN && <EditUserAgentForm agent={agent} iconSize="small" />}
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomLeft"
      >
        <IconButton type="text" disable={loading} loading={loading}>
          <Icon type="verticalDots" />
        </IconButton>
      </Dropdown>
    </Space>
  )
}

const AgentCardView = ({ IS_ADMIN, agent, loading }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {loading ? (
        <Spin />
      ) : (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            lg: 3,
          }}
          dataSource={agent}
          renderItem={(item) => (
            <List.Item>
              <Card>
                <div className="top">
                  <div className="header">
                    <Space>
                      <Icon
                        type="agentIcon"
                        className="agentIcon"
                        style={{
                          fontSize: "1.5rem",
                          border: "1px solid #D1D9E2",
                          borderRadius: "12px",
                          padding: "5px",
                        }}
                      />
                      {IS_ADMIN ? (
                        <CustomLink to={`/agents/${item?.id}`}>
                          <div className="dashboardTitle">
                            <span className="dashboardName">{item?.name}</span>
                            <span className="dashboardDesc">
                              <Tag
                                bordered={false}
                                size="sm"
                                color={
                                  item.state === "ok"
                                    ? "green"
                                    : item.state === "unknown"
                                    ? "warning"
                                    : "error"
                                }
                              >
                                {item?.state.toUpperCase()}
                              </Tag>
                            </span>
                          </div>
                        </CustomLink>
                      ) : (
                        <div className="dashboardTitle">
                          <span className="dashboardName">{item?.name}</span>
                          <span className="dashboardDesc">
                            <Tag
                              bordered={false}
                              size="sm"
                              color={
                                item.state === "ok"
                                  ? "green"
                                  : item.state === "unknown"
                                  ? "warning"
                                  : "error"
                              }
                            >
                              {item?.state.toUpperCase()}
                            </Tag>
                          </span>
                        </div>
                      )}
                    </Space>
                    {/* <Button type="text" icon={<EllipsisOutlined />} /> */}
                    <AgentAction
                      agent={item}
                      loading={loading}
                      IS_ADMIN={IS_ADMIN}
                    />
                  </div>
                  <div className="creationInfo">
                    <p>
                      {t("global.tabels.createdBy")}
                      {": "} <span>{item?.user.name}</span>
                    </p>
                    <p>
                      {t("global.tabels.createdAt")}
                      {": "}
                      <span>
                        {format(new Date(item?.created_at), "dd/MM/yyyy")}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider />
                <div className="bottom">
                  <AgentDestinations agent={item} type="icons" />
                  <div className="agentValues">
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <small>{t("agents.valuesCol")}</small>
                      <Tag
                        style={{
                          padding: "0.3rem",
                        }}
                      >
                        {item?.options?.column}
                      </Tag>
                    </Space>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "0 0.4rem",
                      }}
                    >
                      <small>{t("agents.condition")}</small>
                      <Tag
                        style={{
                          padding: "0.3rem",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {item?.options?.op}
                      </Tag>
                    </Space>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <small>{t("agents.threshold")}</small>
                      <Tag
                        style={{
                          padding: "0.3rem",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        {item?.options?.value}
                      </Tag>
                    </Space>
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  .agentIcon {
    font-size: 1.8rem;
  }

  .dashboardTitle {
    display: flex;
    flex-direction: column;

    .dashboardName {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    .dashboardDesc {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    & .creationInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & p {
        font-weight: 400;
        color: #7d8a9a;
      }

      & span {
        font-weight: 500;
        color: #000;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .agentValues {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export default AgentCardView
