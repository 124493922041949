import React from "react"
import styled from "styled-components"
import { Result, Spin } from "antd"
import { useAgent } from "hooks/agents"
import useTypedParams from "hooks/useTypedParams"
import AgentStateProvider from "components/agents/Provider/Provider"
import AgentHeader from "components/agents/AgentHeader/AgentHeader"
import AgentContent from "components/agents/AgentContent/AgentContent"
import { useTranslation } from "react-i18next"

function Agent() {
  const { t } = useTranslation()
  const { id } = useTypedParams({ id: Number })
  const { data: agent, isLoading, isError } = useAgent(id)

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !agent || agent.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <AgentStateProvider agent={agent}>
        <AgentHeader />
        <AgentContent />
      </AgentStateProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
`
const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default Agent
