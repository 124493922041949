import React from "react"
import styled from "styled-components"
import { Result, Spin } from "antd"
import { useOrganization } from "hooks/settings"
import GeneralForm from "components/General/GeneralForm"
import { useTranslation } from "react-i18next"
import { useCurrentOrg } from "hooks/admin"

const General = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useOrganization()
  const { data: info, isLoading: infoLoading } = useCurrentOrg()

  if (isLoading || infoLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }
  return (
    <Wrapper>
      <GeneralForm data={data} info={info} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  /* max-width: 1400px; */

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

const SpinnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default General
