import { Divider, Form, Input, Space, message } from "antd"
import Button from "components/shared/Button"
import Text from "components/shared/Text"
import { useAuth } from "context/AuthContext"
import { useUpdateUser } from "hooks/users"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Password = () => {
  const { t } = useTranslation()
  const {
    session: { user },
  } = useAuth()
  const { mutate: update, isLoading: updateLoading } = useUpdateUser()

  const onFinish = (value) => {
    update(
      {
        id: user?.id,
        data: value,
      },
      {
        onSuccess: () => {
          message.success({
            content: t("account.passChanged"),
          })
        },
        onError: () => {
          message.error({
            content: t("account.passNotChanged"),
          })
        },
      }
    )
  }

  return (
    <Wrapper>
      <div className="header">
        <Space direction="vertical">
          <Text size="xl">{t("settings.password.title")}</Text>
          <Text size="xs" color="gray-1">
            {t("settings.password.desc")}
          </Text>
        </Space>
      </div>
      <Divider />
      <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
        <Form.Item
          label={t("account.currentPass")}
          name="old_password"
          rules={[
            {
              required: "true",
              message: t("global.requiredField"),
            },
          ]}
        >
          <Input.Password style={{ width: "60%", borderRadius: "6px" }} />
        </Form.Item>
        <Divider />
        <Form.Item
          name="password"
          label={t("account.newPass")}
          rules={[
            {
              required: true,
              message: t("global.requiredField"),
            },
          ]}
          hasFeedback
        >
          <Input.Password style={{ width: "60%", borderRadius: "6px" }} />
        </Form.Item>
        <Divider />
        <Form.Item
          name="confirm"
          label={t("account.repeatNewPass")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("global.requiredField"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t("global.passNotMatched")))
              },
            }),
          ]}
        >
          <Input.Password style={{ width: "60%", borderRadius: "6px" }} />
        </Form.Item>
        <Divider />
        {/* <div>
          <Text size="sm">Can’t remember your current password?</Text>
          <Button  type="text" size="sm" ><span style={{textDecoration: "underline"}}>Send reset password to your email</span></Button>
        </div>
        <Divider /> */}
        <Form.Item style={{ textAlign: "right" }}>
          <Button
            type="primary"
            size="sm"
            htmlType="submit"
            onClick={onFinish}
            disable={updateLoading}
            loading={updateLoading}
          >
            {t("global.save")}
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export default Password
