import React from "react"
import styled from "styled-components"
import FormModal from "components/shared/FormModal"
import CreateDashboardForm from "components/dashboards/CreateDashboardForm"
import Button from "components/shared/Button"
import { useTranslation } from "react-i18next"
import { useAuth } from "context/AuthContext"
import { Space } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import DashboardData from "components/dashboards/DashboardData"

function Dashboards() {
  const { session } = useAuth()
  const { t } = useTranslation()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  const homepage_dashboard_id = session?.user?.homepage_dashboard_id

  return (
    <Wrapper>
      <div className="header">
        <h1>{t("dashboards.dashboards")}</h1>
        <Space size="middle">
          {IS_ADMIN ? (
            <FormModal
              title={t("dashboards.addDashboard")}
              width={500}
              hasChanged={({ name }) => !!name}
              Form={CreateDashboardForm}
            >
              <Button type="primary" id="newDashboard">
                <PlusOutlined /> {t("dashboards.newDashboard")}
              </Button>
            </FormModal>
          ) : null}
        </Space>
      </div>
      <DashboardData
        IS_ADMIN={IS_ADMIN}
        homeDashboardId={homepage_dashboard_id}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    & h2 {
      font-weight: 600;
      font-size: 24px;
      color: #181c32;
    }
  }

  @media (min-width: 768px) {
  }
`

export default Dashboards
