import styled from "styled-components"
import { Result, Spin } from "antd"
import useTypedParams from "hooks/useTypedParams"
import { useDashboard } from "hooks/dashboards"
import DashboardHeader from "components/dashboards/Header"
import DashboardStateProvider from "components/dashboards/Provider/Provider"
import Paramters from "components/dashboards/Paramters"
import Widgets from "components/dashboards/Widgets"
import AddToDashbard from "components/dashboards/AddToDashbard"
import { useTranslation } from "react-i18next"

function DashboardPage() {
  const { id } = useTypedParams({ id: Number })
  const { t } = useTranslation()
  const { data: dashboard, isLoading, isFetching, isError } = useDashboard(id)

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !dashboard || dashboard.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <DashboardStateProvider dashboard={dashboard} isRefetching={isFetching}>
        <DashboardHeader />
        <Paramters />
        <Widgets />
        <AddToDashbard />
      </DashboardStateProvider>
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 1rem 1rem 5rem 1rem;
  position: relative;
  height: 100%;

  /* .main {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 24px;
  } */
`

export default DashboardPage
