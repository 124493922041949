import { Skeleton, Table } from "antd"

function emptyDataSource(count) {
  return [...new Array(count)].map((_, index) => ({ key: `key-${index}` }))
}

function emptyColumns(columns) {
  return columns.map((column) => {
    return {
      ...column,
      render: () => (
        <Skeleton
          key={column.dataIndex}
          title={true}
          paragraph={false}
          active={true}
        />
      ),
    }
  })
}

// A skeleton to show when loading a Table

function TableSkeleton({ columns, rowsCount = 10 }) {
  return (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={emptyDataSource(rowsCount)}
      columns={emptyColumns(columns)}
    />
  )
}

export default TableSkeleton
