import client from "services/client"

async function getETLDestinations() {
  const res = await client.get("/etl/destinations")
  return res.data
}

async function getETLDestinationsList() {
  const res = await client.get("/etl/destination_list")
  return res.data
}

async function getETLDestinationsDefination() {
  const res = await client.get("/etl/destinations_defination")
  return res.data
}

async function getETLDestinationDefination(id) {
  const res = await client.get(`/etl/destination_defination/${id}`)
  return res.data
}

async function addETLDestination(data) {
  // const res = await client.post("/etl/destination", data)
  const res = await client.post("etl/destination_create", data)
  return res.data
}

async function deleteETLDestination(id) {
  const res = await client.delete(`/etl/destination/${id}`, {
    data: {},
  })
  return res.data
}

async function testETLDestination(destinationId) {
  const res = await client.get(`/etl/test_destination/${destinationId}`)
  return res.data
}

export {
  getETLDestinations,
  getETLDestinationsDefination,
  getETLDestinationDefination,
  addETLDestination,
  deleteETLDestination,
  getETLDestinationsList,
  testETLDestination,
}
