import React, { useEffect } from "react"
import styled from "styled-components"
import {
  Card,
  Col,
  Descriptions,
  Divider,
  List,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd"
import { formatDistance, format } from "date-fns"
import { useAgentState } from "../Provider/Provider"
import Text from "components/shared/Text/Text"
import Icon from "components/shared/Icon/Icon"
import { convertTime } from "components/requests/Footer/convetTime"
import Button from "components/shared/Button/Button"
import { useAuth } from "context/AuthContext"
import FormModal from "components/shared/FormModal/FormModal"
import AddDestination from "./AddDestination"
import { useDestinations } from "hooks/destinations"
import { useAgentDestinations } from "hooks/agents"
import { useTranslation } from "react-i18next"
import { ar, enUS } from "date-fns/locale"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/datasource/mucs.png"

const ViewAgent = () => {
  const { tenantName } = useAuth()
  const { t, i18n } = useTranslation()
  const {
    agent,
    loading,
    onUnMuteAgent,
    onAddDestination,
    onDeleteDestination,
    onGetAgentQuery,
    onConvertSecondToRearm,
  } = useAgentState()
  const {
    state,
    last_triggered_at,
    options: { muted, column, op, value },
    query: { id: queryId, name: queryName, schedule },
  } = agent

  const { data: destinationsDate, isLoading: destinationLoading } =
    useDestinations()

  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useAgentDestinations(agent.id)

  const { session } = useAuth()
  const user = session.user

  const onUserCheck = (check) => {
    if (check) {
      onAddDestination()
    } else {
      onDeleteDestination(
        subscriptions?.find((item) => item.user.id === user.id)?.id
      )
    }
  }

  useEffect(() => {
    onGetAgentQuery(agent.query)
  }, [])

  return (
    <RowContainer gutter={32}>
      <Col xs={24} md={14} className="info">
        <Space
          direction="vertical"
          size="small"
          style={{
            display: "flex",
          }}
        >
          <Tag
            color={
              state === "ok"
                ? "success"
                : state === "unknown"
                ? "warning"
                : "#F44336"
            }
            style={{
              fontSize: "14px",
              fontWeight: "500",
              padding: "5px 8px",
              textTransform: "uppercase",
            }}
          >
            {t("agents.status")}: {state}
          </Tag>
          <Text size="xs">
            {last_triggered_at ? (
              <>
                {t("agents.lastTrigger")}
                {": "}
                <Tooltip
                  title={format(
                    new Date(last_triggered_at),
                    "dd/MM/yyyy hh:mm",
                    {
                      locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                    }
                  )}
                >
                  <Text
                    size="sm"
                    style={{
                      fontWeight: "bold",
                      paddingLeft: "0.4rem",
                    }}
                  >
                    {formatDistance(new Date(last_triggered_at), new Date(), {
                      addSuffix: true,
                      locale: i18n.resolvedLanguage === "ar" ? ar : enUS,
                    })}
                  </Text>
                </Tooltip>
              </>
            ) : (
              t("agents.notTrigger")
            )}
          </Text>
        </Space>
        <Divider />
        <Descriptions
          column={1}
          labelStyle={{
            alignItems: "center",
            fontWeight: "700",
          }}
        >
          <Descriptions.Item label={t("requests.query")}>
            <Space direction="vertical" size={0}>
              <Tooltip title={t("agents.openQueryNewTap")}>
                <a
                  href={`/${tenantName}/requests/${queryId}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.1rem",
                    textDecorationLine: "underline",
                  }}
                >
                  <span>{queryName}</span>
                  <Icon type="export" />
                </a>
              </Tooltip>
              <Text size="xxs">
                {schedule ? (
                  `${t("agents.scheduleEvery")}
                    ${convertTime(schedule?.interval)}`
                ) : (
                  <Space>
                    <Icon type="warn" style={{ color: "#f44336" }} />
                    <span>{t("agents.noRefreshSchedule")}</span>
                    <Text size="xs" style={{ color: "#2196f3" }}>
                      <Tooltip title={t("agents.scheduleDesc")}>
                        {t("agents.recommended")}{" "}
                      </Tooltip>
                      <Icon type="question" style={{ color: "#2196f3" }} />
                    </Text>
                  </Space>
                )}
              </Text>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={t("agents.triggerWhen")}>
            <Space
              direction="vertical"
              size={0}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <small>{t("agents.valuesCol")}</small>
              <Tag
                style={{
                  padding: "0.5rem",
                }}
              >
                {column}
              </Tag>
            </Space>
            <Space
              direction="vertical"
              size={0}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 0.4rem",
              }}
            >
              <small>{t("agents.condition")}</small>
              <Tag
                style={{
                  padding: "0.5rem",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                {op}
              </Tag>
            </Space>
            <Space
              direction="vertical"
              size={0}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <small>{t("agents.threshold")}</small>
              <Tag
                style={{
                  padding: "0.5rem",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                {value}
              </Tag>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={t("agents.notifications")}>
            <Space direction="vertical" size={0}>
              <Text size="xs">
                {t("agents.notificationSend")}
                {agent.rearm === 1
                  ? t("agents.notificationAlert")
                  : agent.rearm > 1
                  ? ` ${t("agents.atMostEvery")} ${
                      onConvertSecondToRearm(agent.rearm).number
                    } ${onConvertSecondToRearm(agent.rearm).unit}, ${t(
                      "agents.whenEvaluted"
                    )}`
                  : t("agents.justOnce")}
              </Text>
              <Text size="xs">{t("agents.notificationsDefault")}</Text>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col xs={24} md={10}>
        {muted && (
          <Card
            style={{
              backgroundColor: "#fffbe6",
              border: "1px solid #ffe58f",
              marginBottom: "1rem",
            }}
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              padding: "0.5rem",
            }}
          >
            <Text size="md">{t("agents.notificationMuted")}</Text>
            <Text size="xs">{t("agents.notificationWillSend")}</Text>
            <Space>
              <Text size="xs">{t("agents.notificationRestore")}</Text>
              <Button
                size="xs"
                type="primary"
                onClick={onUnMuteAgent}
                disable={loading}
                loading={loading}
              >
                {t("agents.unmete")}
              </Button>
            </Space>
          </Card>
        )}
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Space size={4}>
            <Text size="lg">{t("agents.destinations")}</Text>
            <Tooltip title={t("agents.destinationsNewTab")}>
              <a
                href={`/${tenantName}/settings/channels`}
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="export" />
              </a>
            </Tooltip>
          </Space>
          <Space>
            <FormModal
              title={t("agents.addAgentDestination")}
              width={600}
              Form={AddDestination}
              hasChanged={(change) => !!change}
              formProps={{
                destinationsDate,
                destinationLoading,
                subscriptions,
              }}
            >
              <Button type="primary" size="xs">
                <Icon type="plusCircle" />
                {t("agents.addDestination")}
              </Button>
            </FormModal>
          </Space>
        </Space>
        {/* <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1.2rem 1rem 0",
          }}
        >
          <Text size="sm">
            <Space>
              <Icon type="mailDark" />
              <span>{user?.email}</span>
            </Space>
          </Text>
          <Switch
            size="small"
            checked={subscriptions?.find((item) => item.user.id === user.id)}
            onChange={onUserCheck}
          />
        </Space> */}
        <Divider
          style={{
            marginTop: "0.8rem",
            marginBottom: "0",
          }}
        />
        <List
          loading={subscriptionsLoading}
          dataSource={subscriptions}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              style={{
                display: item.destination ? "flex" : "none",
                justifyContent: "space-between",
                paddingRight: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <Space>
                <img
                  src={`${
                    destinationImages[item?.destination?.type] || defaultImage
                  }`}
                  alt={item?.destination?.type}
                  width={32}
                />
                {item?.destination?.name}
              </Space>
              <Icon
                type="x"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => onDeleteDestination(item.id)}
              />
            </List.Item>
          )}
        />
      </Col>
    </RowContainer>
  )
}

const RowContainer = styled(Row)`
  .info {
    padding-left: 8rem;
  }

  @media (max-width: 678px) {
    .info {
      padding-left: 0;
    }
  }
`

export default ViewAgent
