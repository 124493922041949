import React from "react"
import { Routes, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { useTranslation } from "react-i18next"
import ArLang from "antd/es/locale/ar_EG"
import { ConfigProvider } from "antd"
import ReactGA from "react-ga4"

import GlobalStyle from "components/shared/GlobalStyle"
import PrivateRoute from "components/shared/PrivateRoute"
import { useAppContext } from "context/AppContext"
import { useAuth } from "context/AuthContext"
import mixins, { darkTheme, lightTheme } from "lib/mixins"

import Home from "pages/home"
import Login from "pages/login"
import Dashboards from "pages/dashboards"
import Dashboard from "pages/dashboard"
import Requests from "pages/requests"
import Request from "pages/request"
import CreateRequest from "pages/create-request"
import Agents from "pages/agents"
import Agent from "pages/agent"
import CreateAgent from "pages/create-agent"
import Sources from "pages/sources"
import Source from "pages/source"
import Destination from "pages/channel"
import Destinations from "pages/destinations"
import NotFound from "pages/not-found"
import Settings from "pages/settings"
import Users from "pages/users"
import Groups from "pages/groups"
import Query from "pages/query"
import General from "pages/general"
import Account from "pages/account"
import Applications from "pages/applications"
import Application from "pages/application"
import ForgotPassword from "pages/forgot"
import ResetPassword from "pages/reset"
import SetUser from "pages/invite"
import PublicDashboard from "pages/PublicDashboard"
import Admin from "pages/admin"
import AdminStatus from "pages/admin-status"
import AdminQueriesJobs from "pages/admin-queries-jobs"
import AdminQueriesOutdated from "pages/admin-queries-outdated"
import AdminOrganizations from "pages/admin-organizations"
import AdminOrganizationsData from "pages/admin-organuzations-data"
import NotPage from "pages/not-page"
import ScrollToTop from "config/ScrollToTop"
import RouteChangeTracker from "config/RouteChangeTracker"
import Connections from "pages/connections"
import Connection from "pages/connection"
import CreateConnections from "pages/create-connections"
import Channels from "pages/channels"
import Channel from "pages/channel"
import CreateETLSources from "pages/create-etl-source-list"
import CreateETLSourcesDeatils from "pages/create-etl-source-details"
import CreateETLDestinations from "pages/create-etl-destinations-list"
import CreateETLDestinationsDeatials from "pages/create-etl-destinations-details"
import Password from "pages/Password"
import Warehouse from "pages/warehouse"

// import "antd/dist/antd.css"
ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`)

function App() {
  const { dark } = useAppContext()
  const { i18n } = useTranslation()
  const { tenantName } = useAuth()

  return (
    <ConfigProvider
      direction={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
      locale={i18n.resolvedLanguage === "ar" ? ArLang : ""}
      theme={{ components: { Modal: { wireframe: true } } }}
    >
      <ThemeProvider theme={{ mixins, color: dark ? darkTheme : lightTheme }}>
        <GlobalStyle
          direction={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
        />
        <ScrollToTop />
        <RouteChangeTracker />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login/:tenantName" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/invite" element={<SetUser />} />
          <Route
            path="/:tenant/public/dashboards/:token"
            element={<PublicDashboard />}
          />
          <Route path={`/${tenantName}`} element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="dashboards" element={<Dashboards />} />
            <Route path="dashboard/:id" element={<Dashboard />} />
            <Route path="applications" element={<Applications />} />
            <Route path="application/:tag" element={<Application />} />
            <Route path="requests" element={<Requests />} />
            <Route path="requests/:id" element={<Request />} />
            <Route path="requests/create" element={<CreateRequest />} />
            <Route path="agents" element={<Agents />} />
            <Route path="agents/:id" element={<Agent />} />
            <Route path="agents/create" element={<CreateAgent />} />
            <Route path="connections" element={<Connections />} />
            <Route path="connections/:id" element={<Connection />} />
            <Route path="connections/create" element={<CreateConnections />} />
            <Route path="sources" element={<Sources />} />
            <Route path="source/:id" element={<Source />} />
            <Route path="sources/etl/create" element={<CreateETLSources />} />
            <Route
              path="sources/etl/create/:id"
              element={<CreateETLSourcesDeatils />}
            />
            <Route path="destinations" element={<Destinations />} />
            <Route path="destination/:id" element={<Destination />} />
            <Route
              path="destinations/etl/create"
              element={<CreateETLDestinations />}
            />
            <Route
              path="destinations/etl/create/:id"
              element={<CreateETLDestinationsDeatials />}
            />
            <Route path="warehouse" element={<Warehouse />} />
            <Route path={`/${tenantName}/settings/`} element={<Settings />}>
              <Route path="profile" element={<Account />} />
              <Route path="password" element={<Password />} />
              <Route path="users" element={<Users />} />
              <Route path="groups" element={<Groups />} />
              <Route path="channels" element={<Channels />} />
              <Route path="channels/:id" element={<Channel />} />
              <Route path="query_snippets" element={<Query />} />
              <Route path="general" element={<General />} />
            </Route>
            <Route path={`/${tenantName}/admin`} element={<Admin />}>
              <Route path="status" element={<AdminStatus />} />
              <Route path="queries/jobs" element={<AdminQueriesJobs />} />
              <Route
                path="queries/outdated"
                element={<AdminQueriesOutdated />}
              />
              <Route path="organizations" element={<AdminOrganizations />} />
              <Route
                path="organizations/data"
                element={<AdminOrganizationsData />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
          <Route path="*" element={<NotPage />} />
        </Routes>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default App
