import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  addETLConnections,
  deleteETLConnections,
  getETLConnectionsList,
  getETLConnectons,
  getETLSourceSchema,
  syncETLConnection,
  updateETLConnection,
  getETLConnectionDetails,
  getETLConnectionStatus,
  getETLConnectionJobs,
} from "apis/ETL/connections"

const keys = {
  all: ["etl-connections"],
  list: () => [...keys.all, "list"],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  status: (id) => [...keys.list(), id],
  allHistory: () => [...keys.all, "history"],
  history: (pagenation) => [...keys.allHistory(), pagenation],
}

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

export function useETLConnections() {
  return useQuery({
    queryKey: keys.list(),
    // queryFn: () => getETLConnectons(),
    queryFn: () => getETLConnectionsList(),
    staleTime: 30 * 1000,
    cacheTime: 30 * 1000,
    refetchInterval: 30 * 1000,
  })
}

export function useETLConnectionDetails(connectionId, options = {}) {
  return useQuery({
    queryKey: keys.detail(connectionId),
    queryFn: () => getETLConnectionDetails(connectionId),
    staleTime: 30 * 1000,
    cacheTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    ...options,
    enabled: !!connectionId,
  })
}

export function useCreateETLConnections(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => addETLConnections(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}

export function useDeleteETLConnections(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (connectionId) => deleteETLConnections(connectionId),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.list())
    },
  })
}

export function useSyncETLConnection(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (connectionId) => syncETLConnection(connectionId),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(keys.list()),
        queryClient.invalidateQueries(keys.details()),
      ]),
    ...options,
  })
}

export function useGetETLSourceSchema(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sourceId) => getETLSourceSchema(sourceId),
  })
}

export function useUpdateETLConnections(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ connectionId, data }) =>
      updateETLConnection(connectionId, data),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries(keys.list()),
        queryClient.invalidateQueries(keys.details()),
      ]),
  })
}

export function useETLConnectionStatus(connectionId, options = {}) {
  return useQuery({
    queryKey: keys.status(connectionId),
    queryFn: () => getETLConnectionStatus(connectionId),
    staleTime: 30 * 1000,
    cacheTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    ...options,
    enabled: !!connectionId,
  })
}

export function useETLConnectionHistory(pagenation, options = {}) {
  return useQuery({
    queryKey: keys.history(pagenation),
    queryFn: () => getETLConnectionJobs(pagenation),
    staleTime: 30 * 1000,
    cacheTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    ...options,
    enabled: !!pagenation?.configId,
  })
}
