import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    :root{
        // font

        --fz-xxs: 0.75rem;
        --fz-xs: 0.8125rem;
        --fz-sm: 0.875rem;
        --fz-md: 1rem;
        --fz-lg: 1.125rem;
        --fz-xl: 1.25rem;
        --fz-xxl: 1.375rem;
        --fz-heading: 2rem;
        --font-display: 'Nunito', sans-serif;

        // border-radius
    
        --border-radius-0: 4px;
        --border-radius-1: 16px;
        --border-radius-2: 24px;

        // height

        --header-height: 60px;
        --sidebar-width: 205px;
        --sidebar-width-collapsed: 80px;

        // colors

        --color-purple-0: hsla(249, 28%, 95%, 1);
        --color-purple-1: hsla(209, 46%, 35%, 1);
        --color-purple-2: hsla(252, 48%, 51%, 1);
        --color-purple-3: hsla(252, 55%, 29%, 1);
        --color-purple-4: hsla(252, 80%, 19%, 1);
        --color-green-0: hsla(180, 15%, 94%, 1);
        --color-green-1: hsla(164, 100%, 38%, 1);
        --color-green-2: hsla(164, 53%, 47%, 1);
        --color-green-3: hsla(185, 85%, 15%, 1);
        --color-blue-0: hsla(210, 19%, 94%, 1);
        --color-blue-1: hsla(209, 53%, 91%, 1);
        --color-blue-2: hsla(193, 100%, 38%, 1);
        --color-blue-3: hsla(193, 67%, 54%, 1);
        --color-blue-4: hsla(210, 93%, 16%, 1);
        --color-gray-0: hsl(0deg, 0%, 100%);
        --color-gray-1: hsl(0deg, 0%, 57%);
        --color-gray-2: hsl(0deg, 0%, 76%);
        --color-side: #DBE8F4;

        
        // text colors

        --color-text-primary: hsla(207, 100%, 11%, 1);
        --color-text-secondary: hsla(209, 46%, 35%, 1);
        --color-text-tertiary: hsla(209, 46%, 35%, 1);
        --color-text-danger: hsla(359, 100%, 65%, 1);
        --color-text-warning: hsla(40, 96%, 53%, 1);
        --color-text-success: hsla(100, 77%, 44%, 1);
        --color-icon-filled: var(--color-purple-1);
        
        .field-services-industry{
            --color-brand: var(--color-blue-2);
            --color-body: #ffffff;
            --color-header: var(--color-purple-0);
            --color-sidebar: var(--color-purple-4);
            --color-text-sidebar: #ffffff;
            --color-aside: var(--color-purple-0);
            --color-btn-primary: var(--color-purple-3);
            --color-btn-primary-hover: var(--color-purple-2);
            --color-btn-primary: var(--color-purple-3);
            --color-btn-primary-hover: var(--color-purple-2);
            --color-btn-secondary: var(--color-green-2);
            --color-btn-secondary-hover: var(--color-green-1);
            --color-btn-light: var(--color-purple-0);
        }

        .realestate-industry{
            --color-brand: var(--color-green-2);
            --color-body: #ffffff;
            --color-header: var(--color-green-0);
            --color-sidebar: var(--color-green-3);
            --color-text-sidebar: #ffffff;
            --color-aside: var(--color-blue-0);
            --color-btn-primary: var(--color-green-2);
            --color-btn-primary-hover: var(--color-green-1);
            --color-btn-secondary: var(--color-green-2);
            --color-btn-secondary-hover: var(--color-green-1);
            --color-btn-light: var(--color-green-0);
        }

        .automotive-industry{
            --color-brand: var(--color-blue-2);
            --color-body: #ffffff;
            --color-header: var(--color-blue-0);
            --color-sidebar: var(--color-blue-4);
            --color-text-sidebar: #ffffff;
            --color-aside: var(--color-blue-0);
            --color-btn-primary: var(--color-blue-3);
            --color-btn-primary-hover: var(--color-blue-2);
            --color-btn-secondary: var(--color-blue-3);
            --color-btn-secondary-hover: var(--color-blue-2);
            --color-btn-light: var(--color-blue-0);
        }  
        
        // default theme 

        --color-brand: var(--color-blue-3);
        --color-body: #ffffff;
        --color-header: #ffffff;
        --color-sidebar: var(--color-blue-1);
        --color-text-sidebar: hsla(218, 34%, 57%, 1);
        --color-aside: var(--color-blue-0);
        --color-btn-primary: var(--color-blue-3);
        --color-btn-primary-hover: var(--color-blue-2);
        --color-btn-secondary: var(--color-blue-3);
        --color-btn-secondary-hover: var(--color-blue-1);
        --color-btn-light: var(--color-blue-0);
        --color-btn-light-hover: var(--color-gray-0);
    }

    html{
        font-size: 16px;
    }

    body{
        margin: 0;
        background: #ffffff;
        font-family: var(--font-display);
        direction: ${(props) => props.direction || "ltr"};
    }

    svg,
    img{
        max-width: 100%;
    }

    *,
    *::after,
    *::before{
        box-sizing: border-box;
    }

    input,
    button,
    textarea{
        font-family: inherit;
    }

    ul{
        list-style: none;
        padding: 0;
    }
    .ant-modal-close-x {
        width: 100%;
        height: 100%;
    }

    .ant-form-item-explain-error {
        margin-bottom: 2rem;
    }

    .ant-tour .ant-tour-inner {
        background-color: #F0FBFF;
    }
`

export default GlobalStyle
