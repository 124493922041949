import React from "react"
import styled from "styled-components"
import useTypedParams from "hooks/useTypedParams"
import {
  useDestination,
  useDeleteDestination,
  useUpdateDestination,
} from "hooks/destinations"
import { Result, Spin } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import ChannelDetails from "components/channels/ChannelDetails"

const Channel = () => {
  const { t } = useTranslation()
  const { id } = useTypedParams({ id: Number })
  const { data, isLoading, isError } = useDestination(id)
  const { mutate, isLoading: updateLoading } = useUpdateDestination()
  const { mutate: deleteDestination, isLoading: deleteLoading } =
    useDeleteDestination()

  const navigate = useNavigate()

  const onEditDestination = (data) => {
    mutate({
      id: data.id,
      data,
    })
  }

  const onDeleteDestination = () => {
    deleteDestination(
      {
        id: data.id,
      },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  if (isLoading || updateLoading || deleteLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <ChannelDetails
        data={data}
        onEditDestination={onEditDestination}
        onDeleteDestinaion={onDeleteDestination}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 32px;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: start;
`
const SpinnerWrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Channel
