import { Collapse, Input, Space, Spin } from "antd"
import styled from "styled-components"
import Icon from "components/shared/Icon"
import { useSchema } from "hooks/dataSources"
import React, { useState, useEffect } from "react"

const DataSourceSchema = ({ dataSourcesId, onAddSchema }) => {
  const { data, isLoading } = useSchema(dataSourcesId, {
    enabled: !!dataSourcesId,
  })
  const [searchName, setSearchName] = useState("")
  const [filteredSchema, setFilteredSchema] = useState([])

  useEffect(() => {
    const newFilteredArray = data?.result?.filter((item) =>
      item.name.toLowerCase().includes(searchName.toLowerCase())
    )
    setFilteredSchema(newFilteredArray)
  }, [data, searchName])

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Input
          placeholder="Search Schema..."
          className="seachInput"
          style={{ marginBottom: "0.5rem" }}
          value={searchName}
          disabled={!!data?.error}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Collapse
          ghost
          size="small"
          className="list"
          expandIconPosition="end"
          items={filteredSchema?.map((schema, idx) => ({
            id: idx,
            label: schema.name,
            children: schema?.columns.map((col) => (
              <div className="Item">
                {col?.name ? (
                  <Space>
                    <span>{col.name}</span>
                    <span className="type">{col.type}</span>
                  </Space>
                ) : (
                  <p>{col}</p>
                )}
                <Icon
                  type="plus"
                  className="iconBtn"
                  onClick={() => onAddSchema(col?.name || col)}
                />
              </div>
            )),
          }))}
        />
      </Spin>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;

  .seachInput {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .list {
    /* padding-top: 1.8rem; */
  }

  .Item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .type {
      text-transform: uppercase;
      color: #aaa;
    }

    .iconBtn {
      cursor: pointer;
    }
  }
`

export default DataSourceSchema
