import { Result } from "antd"
import { useAuth } from "context/AuthContext"
import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"

const NotPage = () => {
  const { isAuth, tenantName } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const extractTenantName = (pathname) => {
    const parts = pathname.split("/")
    if (parts.length >= 3) {
      return `/${parts[1]}`
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (isAuth) {
      navigate(`/${tenantName}`)
    } else {
      navigate(`/login${extractTenantName(location.pathname)}`)
    }
  }, [isAuth])

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

export default NotPage
