import React from "react"
import styled from "styled-components"
import {
  Avatar,
  Button,
  Empty,
  Modal,
  Result,
  Space,
  Table,
  notification,
} from "antd"
import { useDeleteDestination, useDestinations } from "hooks/destinations"
import { destinationImages } from "components/shared/Icon/CustomIcons.utils"
import defaultImage from "static/images/destinations/email.png"
import { useTranslation } from "react-i18next"
import CustomLink from "components/shared/CustomLink/CustomLink"
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { TableSkeleton } from "components/shared/Table"

const ChannelsList = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useDestinations()
  const { mutateAsync } = useDeleteDestination()

  const handleDeleteSouce = (source) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: t("settings.channels.deleteChannel"),
      content: t("settings.channels.deleteChannelConfirem"),
      okText: t("global.delete"),
      okType: "danger",
      onOk() {
        return mutateAsync(
          { id: source.id },
          {
            onSuccess: () => {
              notification.success({
                message: "",
                duration: 2,
                placement: "topRight",
              })
            },
            onError: () => {
              notification.error({
                message: "Fasild Delete Source",
                duration: 2,
                placement: "topRight",
              })
            },
          }
        )
      },
    })
  }

  const columns = [
    {
      title: t("global.tabels.name"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("global.tabels.type"),
      key: "type",
      dataIndex: "type",
      render: (type) => {
        return (
          <Space>
            <Avatar
              src={
                <img
                  src={`${destinationImages[type] || defaultImage}`}
                  alt={type}
                />
              }
              shape="square"
            />
            <span>{type}</span>
          </Space>
        )
      },
    },
    {
      title: t("global.tabels.actions"),
      key: "actions",
      render: (source) => {
        return (
          <Space>
            <CustomLink to={`/settings/channels/${source.id}`} key={source.id}>
              <Button type="text" icon={<EditOutlined />} />
            </CustomLink>
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteSouce(source)}
            />
          </Space>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <Wrapper>
        <TableSkeleton columns={columns} />
      </Wrapper>
    )
  }

  if (isError || !data || data.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  if (data && data.length === 0) {
    return (
      <Wrapper>
        <Empty description={<span>{t("channels.noChannels")}</span>} />
      </Wrapper>
    )
  }

  return (
    <>
      <Table
        scroll={{ x: "1000px" }}
        size="sm"
        rowKey="id"
        columns={columns}
        dataSource={data}
      />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ChannelsList
