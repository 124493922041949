import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  createQuerySnippets,
  deleteQuerySnippets,
  getQueryResult,
  getQuerySnippets,
  getQuerySnippetsPage,
  updateQuerySnippets,
} from "apis/queries"

const defaultQueryConfig = {
  staleTime: 10 * (60 * 1000),
  cacheTime: 15 * (60 * 1000),
}

const keys = {
  all: ["query"],
  lists: () => [...keys.all, "list"],
  list: (pagination) => [...keys.lists(), pagination],
  details: () => [...keys.all, "detail"],
  detail: (id) => [...keys.details(), id],
  results: () => [...keys.all, "result"],
  result: (id) => [...keys.results(), id],
  searchs: () => [...keys.all, "search"],
  search: (name) => [...keys.searchs(), name],
}

function useQueryResult(queryId) {
  return useQuery({
    queryKey: keys.result(queryId),
    queryFn: () => getQueryResult(queryId),
    ...defaultQueryConfig,
  })
}

function useQuerySnippetsPage(pagination, options = {}) {
  return useQuery({
    queryKey: keys.lists(pagination),
    queryFn: () => getQuerySnippetsPage(pagination),
    ...defaultQueryConfig,
    ...options,
  })
}

function useQuerySnippets(options = {}) {
  return useQuery({
    queryKey: keys.lists(),
    queryFn: () => getQuerySnippets(),
    ...defaultQueryConfig,
    ...options,
  })
}

function useDeleteQuerySnippets(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id }) => deleteQuerySnippets(id),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useCreateQuerySnippets(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ data }) => createQuerySnippets(data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

function useUpdateQuerySnippets(options = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => updateQuerySnippets(id, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(keys.lists())
    },
  })
}

export {
  useQueryResult,
  useQuerySnippets,
  useQuerySnippetsPage,
  useDeleteQuerySnippets,
  useCreateQuerySnippets,
  useUpdateQuerySnippets,
}
