import React, { useState } from "react"
import {
  Card,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  notification,
  InputNumber,
  Space,
} from "antd"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { LeftCircleOutlined } from "@ant-design/icons"
import IconButton from "components/shared/IconButton"
import {
  useCreateETLSource,
  useDeleteETLSource,
  useETLSourceDefination,
  useTestETLSource,
} from "hooks/ETL/sources"
import useTypedParams from "hooks/useTypedParams"
import Button from "components/shared/Button"
import { useAuth } from "context/AuthContext"
import Icon from "components/shared/Icon"

const FormInputBlock = ({ properties, requiredFileds, parentName }) => {
  const mainInputs = properties
    .filter((propertie) => {
      return (
        (requiredFileds
          ? requiredFileds?.find((d) => d === propertie.propertyKey)
          : true) ||
        propertie.type === "object" ||
        !!propertie.always_show
      )
    })
    .sort((a, b) => a?.order - b?.order)
  const optionInputs = properties
    .filter(
      (propertie) =>
        !mainInputs.find((input) => input.propertyKey === propertie.propertyKey)
    )
    ?.sort((a, b) => a?.order - b?.order)

  return (
    <>
      {mainInputs.map((input) =>
        renderFormItem(
          input,
          requiredFileds?.includes(input.propertyKey),
          parentName
        )
      )}
      {optionInputs?.length === 0 ? null : (
        <Collapse bordered={false} ghost>
          <Collapse.Panel header="Optional fields" key="optional_fields">
            {optionInputs.map((input) =>
              renderFormItem(
                input,
                requiredFileds?.includes(input.propertyKey),
                parentName
              )
            )}
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  )
}

const renderFormItem = (property, isRequired, parentName) => {
  switch (property?.type) {
    case "string":
      return (
        <Form.Item
          label={property?.title || property.propertyKey}
          name={
            parentName
              ? [parentName, property.propertyKey]
              : property.propertyKey
          }
          rules={[{ required: isRequired }]}
          tooltip={property?.description}
          extra={property?.examples?.join(" - ")}
        >
          {property.airbyte_secret ? (
            <Input.Password />
          ) : property.format === "date-time" ? (
            <DatePicker
              style={{
                width: "100%",
              }}
              showTime
              format="YYYY-MM-DDTHH:mm:ss[Z]"
            />
          ) : property?.enum ? (
            <Select
              options={property?.enum?.map((pro) => ({
                label: pro,
                value: pro,
              }))}
              popupMatchSelectWidth={false}
            />
          ) : (
            <Input />
          )}
        </Form.Item>
      )
    case "integer":
      return (
        <Form.Item
          label={property?.title || property.propertyKey}
          name={
            parentName
              ? [parentName, property.propertyKey]
              : property.propertyKey
          }
          rules={[{ required: isRequired }]}
          tooltip={property?.description}
          extra={property?.examples?.join(" - ")}
        >
          <InputNumber
            min={property.minimum}
            max={property.maximum}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      )
    case "array":
      // Assuming array is for select
      // return null;
      return (
        <Form.Item
          label={property?.title || property.propertyKey}
          name={
            parentName
              ? [parentName, property.propertyKey]
              : property.propertyKey
          }
          rules={[{ required: isRequired }]}
          tooltip={property?.description}
          extra={property?.examples?.join(" - ")}
        >
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            dropdownRender={(meue) => (
              <div style={{ display: "none" }}>{meue}</div>
            )}
            showSearch={false}
            popupMatchSelectWidth={false}
          />
        </Form.Item>
      )
    case "object":
      return property?.properties ? (
        <FormInputBlock
          properties={Object.keys(property.properties).map((propertyKey) => ({
            ...property.properties[propertyKey],
            propertyKey: propertyKey,
          }))}
          requiredFileds={property.required}
          parentName={
            property.group
              ? [property.group, property.propertyKey]
              : property.propertyKey
          }
        />
      ) : (
        <ObjectFormItem
          name={
            parentName
              ? [parentName, property.propertyKey]
              : property.propertyKey
          }
          property={property}
        />
      )
    case "boolean":
      return (
        <Form.Item
          label={property?.title || property.propertyKey}
          name={
            parentName
              ? [parentName, property.propertyKey]
              : property.propertyKey
          }
          rules={[{ required: isRequired }]}
          tooltip={property?.description}
          extra={property?.examples?.join(" - ")}
          initialValue={property?.default}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )
    // Add more cases for other types as needed
    default:
      return null
  }
}

const ObjectFormItem = ({ name, property }) => {
  const { oneOf = [], title } = property
  const [options, setOptions] = useState(oneOf[0])

  const optionsValues = oneOf.map((option) => ({
    label: option.title,
    value:
      option.properties[
        Object.keys(option.properties).find(
          (propertyKey) => option.properties[propertyKey].const
        )
      ].const,
  }))

  return (
    <Card
      size="small"
      title={title}
      extra={
        <Form.Item
          name={[
            name,
            Object.keys(options?.properties)?.find(
              (propertyKey) =>
                options.properties[propertyKey]?.const !== undefined ||
                options.properties[propertyKey]?.order === undefined
            ),
          ]}
          initialValue={optionsValues[0].value}
        >
          <Select
            options={optionsValues}
            onChange={(_, object) =>
              setOptions(
                oneOf.find(
                  (option) =>
                    option.title.toLowerCase() === object.label.toLowerCase()
                )
              )
            }
            popupMatchSelectWidth={false}
          />
        </Form.Item>
      }
    >
      <GroupWrapper>
        <FormInputBlock
          properties={Object.keys(options?.properties)
            .filter((propertyKey) => !options?.properties[propertyKey].const)
            .map((propertyKey) => ({
              ...options?.properties[propertyKey],
              propertyKey: propertyKey,
            }))}
          requiredFileds={options?.required}
          parentName={name}
        />
      </GroupWrapper>
    </Card>
  )
}

const CreateETLSourceDeatials = () => {
  const [form] = Form.useForm()
  const { tenantName, session } = useAuth()
  const navigate = useNavigate()
  const { id } = useTypedParams({ id: String })
  const { data: sourceDefinition, isLoading } = useETLSourceDefination(id)
  const { mutate, isLoading: createLoading } = useCreateETLSource()
  const { mutate: testSource, isLoading: loadingTest } = useTestETLSource()
  const { mutate: deleteSource, isLoading: deleteLoading } =
    useDeleteETLSource()

  const urlParams = new URLSearchParams(window.location.search)
  const sourceType = urlParams.get("sourceType")

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    )
  }

  const onSubmit = (values) => {
    const { name, ...configuration } = values
    mutate(
      {
        name,
        sourceDefinitionId: id,
        workspaceId: session?.org_workspace,
        connectionConfiguration: {
          ...configuration,
          sourceType,
        },
      },
      {
        onSuccess: (data) => {
          const sourceId = data?.sourceId
          if (sourceId) {
            testSource(sourceId, {
              onSuccess: (testData) => {
                if (testData?.status === "succeeded") {
                  notification.success({
                    message: "Set up source succssfuly",
                  })
                  navigate(`/${tenantName}/sources`)
                } else {
                  deleteSource(sourceId, {
                    onSuccess: () => {
                      notification.error({
                        message: "Faild set up source",
                      })
                    },
                  })
                }
              },
            })
          } else {
            notification.error({
              message: "Faild set up source",
            })
          }
        },
        onError: () => {
          notification.error({
            message: "Faild set up source",
          })
        },
      }
    )
  }

  return (
    <Wrapper>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <GroupWrapper>
          <div className="header">
            <IconButton type="white" onClick={() => navigate(-1)}>
              <LeftCircleOutlined />
            </IconButton>
            <h3>{sourceDefinition?.connectionSpecification?.title}</h3>
          </div>
          <Form.Item
            name="name"
            label="Source name"
            tooltip="Pick a name to help you identify this source in Airbyte"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </GroupWrapper>

        {sourceDefinition.connectionSpecification.groups ? (
          sourceDefinition.connectionSpecification.groups.map((group) => (
            <GroupWrapper key={group.id}>
              <h2>{group.title}</h2>
              <FormInputBlock
                properties={Object.keys(
                  sourceDefinition.connectionSpecification.properties
                )
                  .filter(
                    (propertyKey) =>
                      sourceDefinition.connectionSpecification.properties[
                        propertyKey
                      ].group === group.id
                  )
                  .map((propertyKey) => ({
                    ...sourceDefinition.connectionSpecification.properties[
                      propertyKey
                    ],
                    propertyKey: propertyKey,
                  }))}
                requiredFileds={
                  sourceDefinition?.connectionSpecification?.required
                }
              />
            </GroupWrapper>
          ))
        ) : (
          <>
            {/* Render properties without groups */}
            <GroupWrapper>
              <FormInputBlock
                properties={Object.keys(
                  sourceDefinition.connectionSpecification.properties
                ).map((propertyKey) => ({
                  ...sourceDefinition.connectionSpecification.properties[
                    propertyKey
                  ],
                  propertyKey: propertyKey,
                }))}
                requiredFileds={
                  sourceDefinition?.connectionSpecification?.required
                }
              />
            </GroupWrapper>
          </>
        )}

        {/* Submit Button */}
        {loadingTest ? (
          <Form.Item>
            <GroupWrapper>
              <Space>
                <IconButton
                  loading={loadingTest}
                  type="transparent"
                  shape="round"
                >
                  <Icon type="sync" />
                </IconButton>
                <span>Test the source</span>
              </Space>
            </GroupWrapper>
          </Form.Item>
        ) : null}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            id="submitBtn"
            loading={createLoading || loadingTest || deleteLoading}
            disabled={createLoading || loadingTest || deleteLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 1rem;
  width: 100%;

  #submitBtn {
    margin-top: 1rem;
    margin-left: auto;
  }
`
const GroupWrapper = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;

  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }
`

export default CreateETLSourceDeatials
