import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Dropdown, Menu, Modal, Space, Spin, notification } from "antd"
import Fullscreen from "components/shared/Fullscreen"
import IconButton from "components/shared/IconButton"
import Icon from "components/shared/Icon"
import Text from "components/shared/Text"
import FormModal from "components/shared/FormModal"
import EditWidgetParametersForm from "../EditWidgetParametersForm"
import useGetVisualizationData from "hooks/useGetVisualizationData"
import WidgetParameters from "./WidgetParameters"
import { Vis } from "@icloudready/idataworkers-visualization"
import { formatDistanceToNow } from "date-fns"
import { useTranslation } from "react-i18next"
import CustomLink from "components/shared/CustomLink/CustomLink"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useRemoveWidget } from "hooks/dashboards"
import { DownloadLink, formatSeconds } from "../utils"

function Widget({
  widget,
  isPublic,
  isAdmin,
  editable,
  onRemoveWidget,
  dashboardParameters,
  onRemoveWidgetDirect,
  isHome,
}) {
  const { t } = useTranslation()
  const { visualization } = widget
  const query = visualization?.query
  const parameters = query?.options?.parameters || []
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [widgetParam, setWidgetParam] = useState([])
  const [formattedTime, setFormattedTime] = useState("")
  const [homeEdit, setHomeEdit] = useState(false)

  const [data, isLoading, isUpdateLoading, error, updateData, params, timer] =
    useGetVisualizationData({
      widget,
      dashboardParameters,
    })

  const { mutateAsync: removeWidgetDirect } = useRemoveWidget()

  const handleEditHomeWidget = () => {
    setHomeEdit(false)
  }

  const urlParams = Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `p_${key}=${encodeURIComponent(JSON.stringify(value))}`
      } else {
        return `p_${key}=${encodeURIComponent(value)}`
      }
    })
    .join("&")

  useEffect(() => {
    const parameterMappings = widget?.options?.parameterMappings
    const widgetParameters = widget?.visualization?.query?.options?.parameters

    if (widgetParameters && parameterMappings) {
      widgetParameters.forEach((wp) => {
        const mapping = parameterMappings[wp.name]
        if (mapping) {
          if (mapping.type === "widget-level") {
            setWidgetParam((c) => [
              ...c,
              { ...wp, requestId: widget.visualization.query.id },
            ])
          }
        } else {
          setWidgetParam((c) => [
            ...c,
            { ...wp, requestId: widget.visualization.query.id },
          ])
        }
      })
    }

    return () => setWidgetParam([])
  }, [widget])

  useEffect(() => {
    if (data && data.retrieved_at) {
      const timeSince = formatDistanceToNow(new Date(data.retrieved_at), {
        addSuffix: true,
      })
      setFormattedTime(timeSince)
    } else {
      setFormattedTime("Data not available")
    }

    const timeoutId = setTimeout(() => {
      if (data && data.retrieved_at) {
        const timeSince = formatDistanceToNow(new Date(data.retrieved_at), {
          addSuffix: true,
        })
        setFormattedTime(timeSince)
      } else {
        setFormattedTime("Data not available")
      }
    }, 60 * 1000) // update every minute

    return () => clearTimeout(timeoutId)
  }, [data])

  const handleRemove = () => {
    onRemoveWidget(widget?.id)
  }

  const handelDeleteHodeWidget = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Deleted Widget",
      content: "Are you sure want to delete this widget?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return removeWidgetDirect(widget.widgetId, {
          onSuccess: () => {
            notification.success({
              message: "Successfuly Deleted Widget",
              duration: 2,
              placement: "bottomRight",
            })
          },
          onError: () => {
            notification.error({
              message: "Failed Delete Widget",
              duration: 2,
              placement: "bottomRight",
            })
          },
        })
      },
      onCancel() {},
    })
  }

  const menu = (
    <Menu>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${visualization.query?.id}/results/${data?.id}.csv`}
          filename={`${visualization.query.name}.csv`}
          text={t("global.downloadCSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${visualization.query?.id}/results/${data?.id}.tsv`}
          filename={`${visualization.query.name}.tsv`}
          text={t("global.downloadTSV")}
        />
      </Menu.Item>
      <Menu.Item disabled={!data}>
        <DownloadLink
          url={`/queries/${visualization.query?.id}/results/${data?.id}.xlsx`}
          filename={`${visualization.query.name}.xlsx`}
          text={t("global.downloadExcel")}
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={!isAdmin}>
        <CustomLink
          to={`/requests/${query.id}?${urlParams}#${visualization.id}`}
        >
          {t("dashboards.viewQuery")}
        </CustomLink>
      </Menu.Item>
      {parameters.length > 0 && isAdmin ? (
        <Menu.Item>
          <FormModal
            Form={EditWidgetParametersForm}
            hasChanged={({ change }) => !!change}
            title="Parameters"
            width={800}
            formProps={{
              parameters: parameters,
              widgetParam: widgetParam,
              dashboardParameters: dashboardParameters,
              widget: widget,
            }}
          >
            <a>{t("dashboards.editParameters")}</a>
          </FormModal>
        </Menu.Item>
      ) : null}
      {isAdmin ? (
        <>
          <Menu.Divider />
          <Menu.Item disabled={editable}>
            <Link onClick={() => onRemoveWidgetDirect(widget.id)}>
              {t("dashboards.removeFromDashboard")}
            </Link>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  )

  return (
    <Fullscreen
      vw={95}
      vh={95}
      isFullscreen={isFullscreen}
      onCancel={() => setIsFullscreen(false)}
    >
      <Wrapper isFullscreen={isFullscreen}>
        <div className="widget-header">
          <Text as="h2" size="sm">
            {visualization.name}
          </Text>

          {!isFullscreen && !isPublic && (
            <div className="actions">
              {(isUpdateLoading || isLoading) && (
                <>
                  <IconButton
                    type="text"
                    size="xxs"
                    loading={isUpdateLoading || isLoading}
                  >
                    <Icon type="sync" />
                  </IconButton>
                  <span>{formatSeconds(timer)}</span>
                </>
              )}
              {
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <IconButton type="transparent" shape="round" size="sm">
                    <Icon type="verticalDots" id="widgetOptions" />
                  </IconButton>
                </Dropdown>
              }
              {editable && (
                <IconButton
                  type="transparent"
                  shape="round"
                  size="sm"
                  onClick={handleRemove}
                >
                  <Icon type="delete" />
                </IconButton>
              )}
            </div>
          )}

          {isHome &&
            (isUpdateLoading || isLoading ? (
              <Space size={0}>
                <IconButton
                  type="text"
                  size="xxs"
                  loading={isUpdateLoading || isLoading}
                >
                  <Icon type="sync" />
                </IconButton>
                <span>{formatSeconds(timer)}</span>
              </Space>
            ) : (
              <Space size={0}>
                {parameters?.length !== 0 && (
                  <IconButton
                    type="transparent"
                    size="xs"
                    onClick={() => setHomeEdit(true)}
                  >
                    <Icon type="edit" style={{ fontSize: "12px" }} />
                  </IconButton>
                )}
                <IconButton
                  type="transparent"
                  size="xs"
                  onClick={handelDeleteHodeWidget}
                >
                  <Icon type="delete" style={{ fontSize: "12px" }} />
                </IconButton>
              </Space>
            ))}
        </div>

        {!isHome ? (
          <div className="widget-parameters">
            {widgetParam.length > 0 && (
              <WidgetParameters
                parameters={parameters}
                widgetParam={widgetParam}
                updateData={updateData}
              />
            )}
          </div>
        ) : homeEdit ? (
          <div className="dashboard-parameters">
            <IconButton
              type="transparent"
              size="xs"
              onClick={() => setHomeEdit(false)}
              className="exitEditBtn"
            >
              <Icon type="close" style={{ fontSize: "12px" }} />
            </IconButton>
            {widgetParam.length > 0 && (
              <WidgetParameters
                parameters={parameters}
                widgetParam={widgetParam}
                updateData={updateData}
                onAfterUpdate={handleEditHomeWidget}
              />
            )}
          </div>
        ) : null}

        <div className="widget-content">
          {isLoading && !data && (
            <div className="widget-loading">
              <Spin />
            </div>
          )}
          {error && <p className="widget-error">{error}</p>}
          {data && <Vis data={data} widget={widget} params={params} />}
        </div>

        <div className="widget-footer">
          {data && <small>{formattedTime}</small>}
          {!editable ? (
            <IconButton
              onClick={() => setIsFullscreen(!isFullscreen)}
              type="transparent"
              shape="round"
              size="xs"
              className="fullscreen"
            >
              <Icon type={isFullscreen ? "fullscreenExit" : "fullscreen"} />
            </IconButton>
          ) : null}
        </div>
      </Wrapper>
    </Fullscreen>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 6px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: ${(props) => (props.isFullscreen ? "none" : "1px solid #DBE8F4")};
  border-radius: 5px;
  position: relative;
  overflow: auto;

  .widget-header {
    ${({ theme }) => theme.mixins.flexSpaceBetween};
    align-items: flex-start;
    padding: 0;
    font-size: 100% !important;

    .actions {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
  }

  .dashboard-parameters {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    padding: 1rem;

    & .exitEditBtn {
      position: absolute;
      right: 0;
    }
  }

  .widget-content {
    height: 90%;
    overflow: auto;
    position: relative;

    .widget-chart {
      height: 100%;
    }

    .widget-counter {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    .widget-error {
      background-color: #f77b72;
      color: #fff;
      padding: 0.5rem;
    }

    .widget-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;
    }
  }

  .widget-footer {
    /* height: 5%; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .fullscreen {
      margin-left: auto;
    }
  }
`

export default Widget
