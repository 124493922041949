import React, { useState } from "react"
import styled from "styled-components"
import { Form, Switch, message } from "antd"
import { useDeleteShareDashboard, useShareDashboard } from "hooks/dashboards"
import Paragraph from "antd/lib/typography/Paragraph"
import { useTranslation } from "react-i18next"

const baseURL = window.location.origin

const ShareDashboard = ({
  id,
  onChange,
  onCancel,
  closeModal,
  public_url,
  isAdmin,
}) => {
  const share = useShareDashboard()
  const deleteShare = useDeleteShareDashboard()
  const [showUrl, setShowUrl] = useState(!!public_url)
  const [url, setUrl] = useState(public_url)
  const { t, i18n } = useTranslation()

  const onSwitch = (checked) => {
    if (checked) {
      share.mutate(
        {
          id: id,
        },
        {
          onSuccess: (share) => {
            if (share?.message) {
              message.error({
                content: share.message,
              })
            } else {
              setShowUrl(true)
              setUrl(share.public_url)
            }
          },
        }
      )
    } else {
      deleteShare.mutate(
        {
          id: id,
        },
        {
          onSuccess: (share) => {
            if (share?.message) {
              message.error({
                content: share.message,
              })
            } else {
              setUrl(false)
              setShowUrl(false)
            }
          },
        }
      )
    }
  }

  React.useEffect(() => onChange(url), [onChange, url])

  return (
    <Wrapper direction={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}>
      {isAdmin && (
        <Form.Item label={t("dashboards.allowPublic")}>
          <Switch
            checked={showUrl}
            loading={share.isLoading || deleteShare.isLoading}
            onChange={onSwitch}
          />
        </Form.Item>
      )}
      {url && (
        <Form.Item label={t("dashboards.secretAddress")}>
          <Paragraph className="url" copyable>
            {`${baseURL}${url.replace(
              url.match(/^(https?:\/\/[^\/]+)/)[1],
              ""
            )}`}
          </Paragraph>
        </Form.Item>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-typography {
    position: relative;
    background-color: #f4f4f4;
    padding: 0.5rem;
    border-radius: 0.3rem;

    .ant-typography-copy {
      position: absolute;
      bottom: 0;
      right: ${({ direction }) => (direction === "rtl" ? "unset" : "5px")};
      left: ${({ direction }) => (direction === "rtl" ? "5px" : "unset")};
    }
  }
`

export default ShareDashboard
