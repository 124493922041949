import React from "react"
import styled from "styled-components"
import { Input } from "components/shared/Input"
import { useSearchRequests, useRequest } from "hooks/requests"
import Text from "components/shared/Text"
import useDebounce from "hooks/useDebounce"
import { Skeleton, Space } from "antd"
import { useTranslation } from "react-i18next"

function sliced(requests, isCollapsed) {
  return isCollapsed ? requests.slice(0, 7) : requests
}

function RequestsSkeleton() {
  return [...new Array(7)].map((_, index) => (
    <RequestSkeleton key={index} title={true} paragraph={false} active />
  ))
}

function SelectRequest({ onAddRequest }) {
  const { t } = useTranslation()
  const [name, setName] = React.useState("")
  const [requestId, setRequestId] = React.useState(null)

  // You don't want to send a request every time the user types
  // the search query will be sent after the user ends typing by 50ms
  const value = useDebounce(name)

  const { data: requests, isLoading } = useSearchRequests(value, {
    keepPreviousData: true,
  })

  const { isLoading: isLoadingRequest, data: requestDetails } = useRequest(
    requestId,
    {
      enabled: requestId ? true : false,
      cacheTime: 0,
    }
  )

  React.useEffect(() => {
    if (requestDetails?.id) onAddRequest(requestDetails)
  }, [requestDetails])

  return (
    <Wrapper>
      <Space direction="vertical">
        <Text size="lg">Choose a request</Text>
        <Text size="sm" color="gray-1">
          To add a widget you have to choose a request first
        </Text>
      </Space>
      <div className="name">
        <Input
          type="search"
          onChange={(e) => setName(e.target.value)}
          value={name}
          size="sm"
          placeholder={t("dashboards.searchRequest")}
        />
      </div>

      {isLoadingRequest && (
        <Text size="xxs" className="loading">
          {t("global.loading")}
        </Text>
      )}

      <ul className="results">
        {isLoading ? (
          <RequestsSkeleton />
        ) : (
          requests?.results?.map(({ id, name }) => (
            <RequestItem key={id} onClick={() => setRequestId(id)}>
              <Text size="sm">{name}</Text>
            </RequestItem>
          ))
        )}
        {!!requests?.message ? (
          <Text color="danger" size="sm">
            {requests?.message}
          </Text>
        ) : null}
      </ul>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 68vh;
  overflow: hidden;

  .name {
    margin-top: 1rem;
  }

  .results {
    height: 100%;
    overflow: auto;
    margin: 20px 0 0;
  }

  .results li:last-child {
    margin: 0;
  }

  .more {
    padding: 4px;
    margin: 5px 0 0;
    display: inline-block;
    cursor: pointer;
  }

  .loading {
    margin: 6px 0 0;
    transform: translateY(6px);
    display: inline-block;
  }
`

const RequestItem = styled.li`
  border: var(--border-1);
  border-radius: var(--border-radius-0);
  padding: 8px 16px;
  margin: 0 0 8px;
  background-color: transparent;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: var(--color-brand);

    ${Text} {
      color: #fff;
    }
  }
`

const RequestSkeleton = styled(Skeleton)`
  border: 1px solid #dbe8f4;
  border-radius: var(--border-radius-0);
  padding: 12px 16px;
  margin: 0 0 8px;

  h3.ant-skeleton-title {
    margin: 0;
  }
`

export default SelectRequest
