import client from "services/client"
import AppError from "services/app-error"
import qs from "query-string"
import axios from "axios"

const { INVALID_PAGE_NUMBER, DASHBOARD_NOT_FOUND, NETWORK_ERROR } =
  AppError.codes

const BASE_URL = process.env.REACT_APP_BASE_URL

async function getDashboardPage(options = {}) {
  const {
    page = 1,
    pageSize: page_size = 10,
    name: q,
    favorites,
    tags,
    date_from,
    date_to,
  } = options

  const params = qs.stringify({
    page,
    page_size,
    q,
    tags,
    date_from,
    date_to,
  })

  const endpoint = "/dashboards" + (favorites ? "/favorites" : "")

  const res = await client.get(`${endpoint}?${params}`)

  if (res.status === 400) {
    throw new AppError("Invalid dashboard page number.", INVALID_PAGE_NUMBER)
  }

  return res.data
}

async function getDashboardByName(name = "") {
  const res = await client.get(`/dashboards?q=${name}`)

  return res.data
}

async function getDashboardByTag(tag = "") {
  const res = await client.get(`/dashboards?tags=${tag}`)
  return res.data
}

async function getDashboard(id) {
  const res = await client.get(`/dashboards/${id}`)

  if (res.data) {
    return res.data
  } else if (res.status === 404) {
    throw new AppError("Dashboard is not found.", DASHBOARD_NOT_FOUND)
  } else {
    throw new AppError("Network Failer", NETWORK_ERROR)
  }
}

async function updateDashboard(id, data) {
  const res = await client.post(`/dashboards/${id}`, data)

  return res.data
}

async function getFavoriteDashboards() {
  const res = await client.get(`/dashboards/favorites`)
  return res.data
}

async function addFavoriteDashboard(id) {
  const res = await client.post(`/dashboards/${id}/favorite`)

  return res.data
}

async function removeFavoriteDashboard(id) {
  const res = await client.delete(`/dashboards/${id}/favorite`)

  return res.data
}

async function createDashboard(dashboard) {
  const res = await client.post(`/dashboards`, dashboard)
  return res.data
}

async function createWidget(widget) {
  const res = await client.post(`/widgets`, widget)

  return res.data
}

async function removeDashboard(id) {
  const res = await client.delete(`/dashboards/${id}`)

  return res.data
}

async function updateWidget(id, data) {
  const res = await client.post(`/widgets/${id}`, data)

  return res.data
}

async function removeWidget(id) {
  const res = await client.delete(`/widgets/${id}`)

  return res.data
}

async function getDashboardTags() {
  const res = await client.get("/dashboards/tags")

  return res.data.tags
}

async function shareDashboard(id) {
  const res = await client.post(`/dashboards/${id}/share`)
  return res.data
}

async function deleteShareDashboard(id) {
  const res = await client.delete(`/dashboards/${id}/share`)
  return res.data
}

async function getPublicDashboard(tenant, token) {
  const res = await axios.get(
    `${BASE_URL}/${tenant}/api/dashboards/public/${token}`
  )
  return res.data
}

export {
  getDashboard,
  getDashboardPage,
  getDashboardByName,
  updateDashboard,
  updateWidget,
  addFavoriteDashboard,
  removeFavoriteDashboard,
  createDashboard,
  createWidget,
  removeDashboard,
  removeWidget,
  getDashboardByTag,
  getDashboardTags,
  shareDashboard,
  deleteShareDashboard,
  getFavoriteDashboards,
  getPublicDashboard,
}
