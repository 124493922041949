import React from "react"
import styled from "styled-components"
import Table from "components/groups/Tabel"
import FormModal from "components/shared/FormModal"
import Icon from "components/shared/Icon"
import Button from "components/shared/Button"
import CreateNewGroup from "components/groups/CreateNewGroup"
import { useAuth } from "context/AuthContext"
import { useTranslation } from "react-i18next"
import { Space } from "antd"
import Text from "components/shared/Text"

function Header({ isAdmin }) {
  const { t } = useTranslation()
  return (
    <Head>
      <div>
        <Space direction="vertical">
          <Text size="xl">{t("settings.groups.title")}</Text>
          <Text size="xs" color="gray-1">
            {t("settings.groups.desc")}
          </Text>
        </Space>
      </div>
      {isAdmin ? (
        <FormModal
          title={t("groups.newGroup")}
          width={500}
          hasChanged={({ isLoading }) => !!isLoading}
          Form={CreateNewGroup}
        >
          <Button type="primary" size="sm">
            <Icon type="plusCircle" /> {t("groups.newGroup")}
          </Button>
        </FormModal>
      ) : null}
    </Head>
  )
}

const Groups = () => {
  const { session } = useAuth()
  const IS_ADMIN = session && session?.user?.permissions?.includes("admin")
  return (
    <Wrapper>
      <Header isAdmin={IS_ADMIN} />
      <Table isAdmin={IS_ADMIN} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  /* max-width: 1400px; */
  align-items: start;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

export default Groups
