import React, { useState } from "react"
import FormModal from "components/shared/FormModal"
import Button from "components/shared/Button"
import { useRequestState } from "../Provider"
import { Form, DatePicker, Radio, Select, TimePicker } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

function EditRequsetSchedule({ onChange, onCancel, closeModal, schedule }) {
  const { t } = useTranslation()
  const { onUpdateSchedule, updateLoading } = useRequestState()
  const [interval, setInterval] = useState(schedule?.interval)
  const [until, setUntil] = useState(schedule?.until)
  const [time, setTime] = useState(schedule?.time)
  const [dayOfWeek, setDayOfWeek] = useState(schedule?.day_of_week)
  const [radioValue, setRadioValue] = useState(schedule?.until ? "on" : "never")

  const { Option, OptGroup } = Select

  const handleChangeInterval = (value) => {
    setInterval(value)
    if (+value < 86400) {
      setTime(null)
    }
    if (+value < 604800) {
      setDayOfWeek(null)
    }
  }

  const onRadioChange = (e) => {
    const newValue = e.target.value
    setRadioValue(newValue)
    if (newValue === "never") {
      setUntil(null)
    } else if (newValue === "on" && !until) {
      setUntil(dayjs().format("YYYY-MM-DD"))
    }
  }

  const handelUntilChange = (value) => {
    setUntil(value)
  }

  const handelTimeChange = (value) => {
    setTime(value)
  }

  const onDayOfWeekChange = (e) => {
    setDayOfWeek(e.target.value)
  }

  const handelSaveConfirm = (e) => {
    e.preventDefault()
    onUpdateSchedule(
      {
        interval: interval === "never" ? null : interval,
        until: until || null,
        time: +interval < 86400 ? null : time,
        day_of_week: +interval < 604800 ? null : dayOfWeek,
      },
      closeModal
    )
  }

  React.useEffect(() => onChange(schedule), [schedule])

  return (
    <Form
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
    >
      <Form.Item label={t("requests.refreshEvery")} name="refrsh-every">
        <Select
          defaultValue={interval || "never"}
          style={{
            width: 200,
          }}
          onChange={handleChangeInterval}
        >
          <OptGroup label="Never">
            <Option value="never">{t("global.never")}</Option>
          </OptGroup>
          <OptGroup label="Minute">
            <Option value="60">1 {t("global.minute")}</Option>
            <Option value="120">2 {t("global.minutes")}</Option>
            <Option value="180">3 {t("global.minutes")}</Option>
            <Option value="240">4 {t("global.minutes")}</Option>
            <Option value="300">5 {t("global.minutes")}</Option>
          </OptGroup>
          <OptGroup label="Hours">
            <Option value="3600">1 {t("global.hour")}</Option>
            <Option value="7200">2 {t("global.hours")}</Option>
            <Option value="10800">3 {t("global.hours")}</Option>
            <Option value="14400">4 {t("global.hours")}</Option>
            <Option value="18000">5 {t("global.hours")}</Option>
            <Option value="21600">6 {t("global.hours")}</Option>
            <Option value="25200">7 {t("global.hours")}</Option>
            <Option value="28800">8 {t("global.hours")}</Option>
            <Option value="32400">9 {t("global.hours")}</Option>
            <Option value="36000">10 {t("global.hours")}</Option>
            <Option value="39600">11 {t("global.hours")}</Option>
            <Option value="43200">12 {t("global.hours")}</Option>
          </OptGroup>
          <OptGroup label="Days">
            <Option value="86400">1 {t("global.day")}</Option>
            <Option value="2592000">30 {t("global.days")}</Option>
          </OptGroup>
          <OptGroup label="Weeks">
            <Option value="604800">1 {t("global.week")}</Option>
            <Option value="1209600">2 {t("global.weeks")}</Option>
          </OptGroup>
        </Select>
      </Form.Item>
      {+interval >= "86400" ? (
        <Form.Item label={t("requests.onTime")} name="on-time">
          <TimePicker
            defaultValue={time ? dayjs(time, "HH:mm") : dayjs()}
            format="HH:mm"
            onChange={(_, value) => handelTimeChange(value)}
          />
        </Form.Item>
      ) : null}
      {+interval >= "604800" ? (
        <Form.Item label={t("requests.onDay")} name="on-day">
          <Radio.Group
            onChange={onDayOfWeekChange}
            defaultValue={dayOfWeek || "Sunday"}
            buttonStyle="solid"
          >
            <Radio.Button value="Sunday">Sun</Radio.Button>
            <Radio.Button value="Monday">Mon</Radio.Button>
            <Radio.Button value="Tuesday">Tues</Radio.Button>
            <Radio.Button value="Wednesday">Wed</Radio.Button>
            <Radio.Button value="Thursday">Thurs</Radio.Button>
            <Radio.Button value="Friday">Fri</Radio.Button>
            <Radio.Button value="Saturday">Sat</Radio.Button>
          </Radio.Group>
        </Form.Item>
      ) : null}
      {interval !== "never" ? (
        <Form.Item label={t("global.ends")} name="ends">
          <Radio.Group onChange={onRadioChange} value={radioValue}>
            <Radio value="never">{t("global.never")}</Radio>
            <Radio value="on">{t("global.on")}</Radio>
          </Radio.Group>
          {radioValue === "on" ? (
            <DatePicker
              defaultValue={until ? dayjs(until, "YYYY-MM-DD") : dayjs()}
              format="YYYY-MM-DD"
              onChange={(_, value) => handelUntilChange(value)}
            />
          ) : null}
        </Form.Item>
      ) : null}

      <FormModal.Footer>
        <Button
          onClick={handelSaveConfirm}
          loading={updateLoading}
          disabled={updateLoading}
          type="primary"
        >
          {t("global.save")}
        </Button>

        <Button onClick={onCancel} type="cancel">
          {t("global.cancel")}
        </Button>
      </FormModal.Footer>
    </Form>
  )
}

export default EditRequsetSchedule
