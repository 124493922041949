export function validate({ email, password, tenantName }) {
  const errors = {}

  if (!email) {
    errors.email = "Email is required!"
  }

  if (!password) {
    errors.password = "Password is required!"
  }

  if (!tenantName) {
    errors.tenantName = "Tenant Name is required!"
  }

  return errors
}
