import React from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Result, Spin } from "antd"
import { useGetPublicDashboard } from "hooks/dashboards"
import DashboardStateProvider from "components/dashboards/Provider/Provider"
import Text from "components/shared/Text/Text"
import Paramters from "components/dashboards/Paramters/Paramters"
import Widgets from "components/dashboards/Widgets/Widgets"
import { useTranslation } from "react-i18next"

const PublicDashboard = () => {
  const { t } = useTranslation()
  const { tenant, token } = useParams()

  const {
    data: dashboard,
    isLoading,
    isError,
  } = useGetPublicDashboard(tenant, token)

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    )
  }

  if (isError || !dashboard || dashboard.message) {
    return (
      <Result
        status="500"
        title={t("global.errorTitle")}
        subTitle={t("global.errorsubTitle")}
      />
    )
  }

  return (
    <Wrapper>
      <DashboardStateProvider dashboard={dashboard}>
        <Text as="h1" size="heading">
          {dashboard.name}
        </Text>
        <Paramters />
        <Widgets isPublic={true} />
      </DashboardStateProvider>
    </Wrapper>
  )
}

const SpinnerWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 1rem 1rem 5rem 1rem;
  position: relative;
  height: 100%;

  .main {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 24px;
  }
`

export default PublicDashboard
