import React from "react"
import styled from "styled-components"
import LoginForm from "components/login/LoginForm"
import Text from "components/shared/Text"
import Background from "static/images/login-bg.jpg"
// import Logo from "static/images/idataworker-logo.png"
import NewLogo from "static/images/NewiDataWorkeroLogo.svg"
import { Link, Navigate } from "react-router-dom"
import { useAuth } from "context/AuthContext"
import { Col, Image, Row } from "antd"

function Login() {
  const { isAuth, tenantName } = useAuth()

  if (isAuth) {
    return <Navigate to={`/${tenantName}`} />
  }

  return (
    <Wrapper>
      <Col sm={24} md={12} className="left-side">
        <div className="form-wrapper">
          <Text as="h2" size="xxl">
            Login to idataworkers
          </Text>

          <LoginForm />

          <Link to="/forgot">I forgot my password</Link>
        </div>
      </Col>

      <Col sm={24} md={12} className="right-side">
        <div className="overlay" />
        <Image
          src={NewLogo}
          alt="idataworkers"
          preview={false}
          width={340}
          height={200}
        />
      </Col>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  height: 100vh;
  overflow: hidden;

  .left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .form-wrapper {
      width: 100%;
      max-width: 400px;

      h2 {
        margin: 0 0 30px;
      }
    }
  }

  .right-side {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${Background}) no-repeat center / cover;

    & .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 36, 77, 0.4);
    }
  }

  @media screen and (max-width: 768px) {
    .left-side {
      width: 100%;
      background: url(${Background}) no-repeat center / cover;

      .form-wrapper {
        & > h2,
        .ant-form-item-label label,
        a {
          color: #fff;
        }
      }
    }
    .right-side {
      display: none;
    }
  }
`

export default Login
